import { DirectivesModule } from './../directives/directives.module';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

import { PlayQueueViewerComponent } from './play-queue-viewer/play-queue-viewer.component';
import { DetailActionBarComponent } from './detail-action-bar/detail-action-bar.component';
import { ShowdownloadstatusComponent } from './showdownloadstatus/showdownloadstatus.component';
import { CachedimageComponent } from './cachedimage/cachedimage.component';
import { MiniplayerComponent } from './miniplayer/miniplayer.component';
import { ScrolltopfabComponent } from './scrolltopfab/scrolltopfab.component';
import { PipesModule } from '../pipes/pipes.module';
import { ShowHidePasswordComponent } from './show-hide-password/show-hide-password.component';

@NgModule({
  declarations: [
    CachedimageComponent,
    MiniplayerComponent,
    ScrolltopfabComponent,
    ShowdownloadstatusComponent,
    DetailActionBarComponent,
    PlayQueueViewerComponent,
    ShowHidePasswordComponent,
  ],
  imports: [
    CommonModule,
    PipesModule,
    FormsModule,
    IonicModule,
    DirectivesModule
  ],
  exports: [
    CachedimageComponent,
    MiniplayerComponent,
    ScrolltopfabComponent,
    ShowdownloadstatusComponent,
    DetailActionBarComponent,
    PlayQueueViewerComponent,
    ShowHidePasswordComponent
  ]
})
export class ComponentsModule { }
