import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Md5 } from 'ts-md5/dist/md5';
import { StoreService } from './store.service';

@Injectable({
  providedIn: 'root'
})
export class SubsonicService {

  private salt = '';
  private token = '';
  private saltedPass = '';
  private hexPass = '';

  constructor(private http: HttpClient,
              private store: StoreService) { }

  clearCreds() {
    this.salt = '';
    this.token = '';
    this.saltedPass = '';
    this.hexPass = '';
  }

  // compile the URL to connect to the subsonic server API
  // sets the subsonice API version in the query string!!
  urlBuilder(command: string): string {

    const _user: string = this.store.userState.username;
    const _password: string = this.store.userState.password;
    const _legacyAuth: boolean = this.store.userState.legacyAuth;
    let _urlsuffix: string;
    let _server: string;

    // use the preferred server (by default both are same)
    // internalServername and externalServername are only set
    // after successful login
    if (this.store.userState.internalServername.length || this.store.userState.externalServername.length){
      if (this.store.userState.preferredServer === 'external'){
        _server = this.store.userState.externalServername;
      } else {
        _server = this.store.userState.internalServername;
      }
    } else {
      _server = this.store.userState.servername;
    }

    if (!this.salt.length || !this.token.length || !this.store.userState.verified){
      this.salt = this.getSalt();
      this.saltedPass = _password + this.salt;
      this.token = this.md5Hash(this.saltedPass);
    }

    if (!this.hexPass.length) {
      this.hexPass = 'enc:' + this.utf8ToHex(_password);
    }

    const _command = command + '.view';
    const _urlprefix = _server + '/rest/';
    if (!_legacyAuth){
      _urlsuffix = '?' + 'u=' + _user + '&t=' + this.token + '&s=' + this.salt + '&v=' + '1.13.0' + '&c=' + 'substreamer' + '&f=' + 'json';
    } else {
      _urlsuffix = '?' + 'u=' + _user + '&p=' + encodeURIComponent(_password)  + '&v=' + '1.13.0' + '&c=' + 'substreamer' + '&f=' + 'json';
    }
    const _url = _urlprefix + _command + _urlsuffix;

    // URL encode to allow for special characters
    return encodeURI(_url);
  }

  // get salt value for authentication
  getSalt(){
    let text = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < 7; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  // create md5 hash from string
  md5Hash(stringArg: string) {
    return Md5.hashStr(stringArg).toString();
  }

  utf8ToHex(str: string) {
    return Array.from(str).map(c =>
      c.charCodeAt(0) < 128 ? c.charCodeAt(0).toString(16) :
      encodeURIComponent(c).replace(/\%/g,'').toLowerCase()
    ).join('');
  }

  hexToUtf8(hex) {
    return decodeURIComponent('%' + hex.match(/.{1,2}/g).join('%'));
  }

  // make sure it's a valid subsonic response
  validate(responseObject: any): boolean{
    if ('subsonic-response' in responseObject){
      console.log('valid subsonic response');
    } else {
      console.log('not a valid subsonic response');
      return false;
    }

    if (responseObject['subsonic-response'].status === 'ok'){
      console.log('api request ok');
      return true;
    }

    if (responseObject['subsonic-response'].status === 'failed'){
      console.log('api request failed');
      return false;
    }
  }

  // format http error messges
  formatError(errorObject): any{
    let _formattedError: any;
    console.log(errorObject);

    if (errorObject.statusText === 'Unknown Error'){
      _formattedError = {
        status: 'failed',
        error:  {code: 111, message: 'Could not connect to server'}
      };
      if (errorObject.status) {
        _formattedError.error.code = errorObject.status;
      }
    } else if (errorObject.status !== undefined){
      _formattedError = {
        status: 'failed',
        error:  {code: errorObject.status, message: errorObject.statusText}
      };
    } else {
      _formattedError = {
        status: 'failed',
        error:  {code: 112, message: errorObject.statusText}
      };
    }
    return _formattedError;
  }

  // get the base URL so an ID can just be appended
  getCoverArtBaseUrl(size?: number): string {
    let _url = this.urlBuilder('getCoverArt');
    if (size !== undefined){
      _url = _url + '&size=' + size.toString();
    } else {
      _url = _url + '&size=500';
    }
    _url = _url + '&id=';
    return _url;
  }

  // Used to test connectivity with the server. Takes no extra parameters.
  // Returns an empty <subsonic-response> element on success.
  async ping(): Promise<any> {
    let _response;
    try {
      _response = await this.http.get(this.urlBuilder('ping')).toPromise();
      console.log(_response);
      if (this.validate(_response)){
        _response = _response['subsonic-response'];
      } else {
        _response = {
          status: _response['subsonic-response'].status,
          error:  _response['subsonic-response'].error
        };
      }
    }
    catch (e) {
      _response = this.formatError(e);
    }
    finally {
      return _response;
    }
  }

  // Get details about the software license. Takes no extra parameters. Please note that
  // access to the REST API requires that the server has a valid license (after a 30-day
  // trial period). To get a license key you must upgrade to Subsonic Premium.
  // Returns a <subsonic-response> element with a nested <license> element on success.
  async getLicense(): Promise<any> {
    let _response;
    try {
      _response = await this.http.get(this.urlBuilder('getLicense')).toPromise();
      if (this.validate(_response)){
        _response = _response['subsonic-response'];
      } else {
        _response = {
          status: _response['subsonic-response'].status,
          error:  _response['subsonic-response'].error
        };
      }
    }
    catch (e) {
      _response = this.formatError(e);
    }
    finally {
      return _response;
    }
  }

  // Returns the current status for media library scanning. Takes no extra parameters.
  // Returns a <subsonic-response> element with a nested <scanStatus> element on success.
  async getScanStatus(): Promise<any> {
    let _response;
    try {
      _response = await this.http.get(this.urlBuilder('getScanStatus')).toPromise();
      if (this.validate(_response)){
        _response = _response['subsonic-response'];
      } else {
        _response = {
          status: _response['subsonic-response'].status,
          error:  _response['subsonic-response'].error
        };
      }
    }
    catch (e) {
      _response = this.formatError(e);
    }
    finally {
      return _response;
    }
  }

  // Initiates a rescan of the media libraries. Takes no extra parameters.
  // Returns a <subsonic-response> element with a nested <scanStatus> element on success.
  async startScan(): Promise<any> {
    let _response;
    try {
      _response = await this.http.get(this.urlBuilder('startScan')).toPromise();
      if (this.validate(_response)){
        _response = _response['subsonic-response'];
      } else {
        _response = {
          status: _response['subsonic-response'].status,
          error:  _response['subsonic-response'].error
        };
      }
    }
    catch (e) {
      _response = this.formatError(e);
    }
    finally {
      return _response;
    }
  }

  // Returns all configured top-level music folders. Takes no extra parameters.
  // Returns a <subsonic-response> element with a nested <musicFolders> element on success.
  async getMusicFolders(): Promise<any> {
    let _response;
    try {
      _response = await this.http.get(this.urlBuilder('getMusicFolders')).toPromise();
      if (this.validate(_response)){
        _response = _response['subsonic-response'];
      } else {
        _response = {
          status: _response['subsonic-response'].status,
          error:  _response['subsonic-response'].error
        };
      }
    }
    catch (e) {
      _response = this.formatError(e);
    }
    finally {
      return _response;
    }
  }

  // Returns an indexed structure of all artists assuming artist/album/files structure
  // @Param musicFolderId (optional)
  // only return artists in the music folder with the given ID
  // @Param ifModifiedSince (optional)
  // only return a result if the artist collection has changed since the given time (in milliseconds since 1 Jan 1970)
  // Returns a <subsonic-response> element with a nested <indexes> element on success.
  async getIndexes(musicFolderId?: string|number, ifModifiedSince?: string|number): Promise<any> {
    let _response;
    let _url = this.urlBuilder('getIndexes');
    if (musicFolderId !== undefined){
      _url = _url + '&musicFolderId=' + musicFolderId.toString();
    }
    if (ifModifiedSince !== undefined){
      _url = _url + '&ifModifiedSince=' + ifModifiedSince.toString();
    }
    try {
      _response = await this.http.get(_url).toPromise();
      if (this.validate(_response)){
        _response = _response['subsonic-response'];
      } else {
        _response = {
          status: _response['subsonic-response'].status,
          error:  _response['subsonic-response'].error
        };
      }
    }
    catch (e) {
      _response = this.formatError(e);
    }
    finally {
      return _response;
    }
  }

  // Returns a listing of all files in a music directory. Typically used to get list of albums for an artist,
  // or list of songs for an album. Assuming artist/album/files structure
  // @Param id (required)
  // A string which uniquely identifies the music folder.
  // Returns a <subsonic-response> element with a nested <directory> element on success.
  async getMusicDirectory(id: string|number): Promise<any> {
    let _response;
    const _url = this.urlBuilder('getMusicDirectory') + '&id=' + id.toString();
    try {
      _response = await this.http.get(_url).toPromise();
      if (this.validate(_response)){
        _response = _response['subsonic-response'];
      } else {
        _response = {
          status: _response['subsonic-response'].status,
          error:  _response['subsonic-response'].error
        };
      }
    }
    catch (e) {
      _response = this.formatError(e);
    }
    finally {
      return _response;
    }
  }

  // Returns all genres.
  // Returns a <subsonic-response> element with a nested <genres> element on success.
  async getGenres(): Promise<any> {
    let _response;
    try {
      _response = await this.http.get(this.urlBuilder('getGenres')).toPromise();
      if (this.validate(_response)){
        _response = _response['subsonic-response'];
      } else {
        _response = {
          status: _response['subsonic-response'].status,
          error:  _response['subsonic-response'].error
        };
      }
    }
    catch (e) {
      _response = this.formatError(e);
    }
    finally {
      return _response;
    }
  }

  // Returns an indexed structure of all artists by ID3 tags.
  // @Param musicFolderId (optional)
  // only return artists in the music folder with the given ID
  // Returns a <subsonic-response> element with a nested <artists> element on success.
  async getArtists(musicFolderId?: string|number): Promise<any> {
    let _response;
    let _url = this.urlBuilder('getArtists');
    if (musicFolderId !== undefined){
      _url = _url + '&musicFolderId=' + musicFolderId.toString();
    }
    try {
      _response = await this.http.get(_url).toPromise();
      if (this.validate(_response)){
        _response = _response['subsonic-response'];
      } else {
        _response = {
          status: _response['subsonic-response'].status,
          error:  _response['subsonic-response'].error
        };
      }
    }
    catch (e) {
      _response = this.formatError(e);
    }
    finally {
      return _response;
    }
  }

  // Returns details for an artist, including a list of albums. This method organizes music by ID3 tags.
  // @Param id (required)
  // A string of the artist ID
  // Returns a <subsonic-response> element with a nested <artist> element on success.
  async getArtist(id: string|number): Promise<any> {
    let _response;
    const _url = this.urlBuilder('getArtist') + '&id=' + id.toString();
    try {
      _response = await this.http.get(_url).toPromise();
      if (this.validate(_response)){
        _response = _response['subsonic-response'];
      } else {
        _response = {
          status: _response['subsonic-response'].status,
          error:  _response['subsonic-response'].error
        };
      }
    }
    catch (e) {
      _response = this.formatError(e);
    }
    finally {
      return _response;
    }
  }

  // Returns details for an album, including a list of songs. This method organizes music according to ID3 tags.
  // @Param id (required)
  // A string of the album ID
  // Returns a <subsonic-response> element with a nested <album> element on success.
  async getAlbum(id: string|number): Promise<any> {
    let _response;
    const _url = this.urlBuilder('getAlbum') + '&id=' + id.toString();
    try {
      _response = await this.http.get(_url).toPromise();
      if (this.validate(_response)){
        _response = _response['subsonic-response'];
      } else {
        _response = {
          status: _response['subsonic-response'].status,
          error:  _response['subsonic-response'].error
        };
      }
    }
    catch (e) {
      _response = this.formatError(e);
    }
    finally {
      return _response;
    }
  }

  // Returns details for a song.
  // @Param id (required)
  // A string of song ID
  // Returns a <subsonic-response> element with a nested <song> element on success.
  async getSong(id: string|number): Promise<any> {
    let _response;
    const _url = this.urlBuilder('getSong') + '&id=' + id.toString();
    try {
      _response = await this.http.get(_url).toPromise();
      if (this.validate(_response)){
        _response = _response['subsonic-response'];
      } else {
        _response = {
          status: _response['subsonic-response'].status,
          error:  _response['subsonic-response'].error
        };
      }
    }
    catch (e) {
      _response = this.formatError(e);
    }
    finally {
      return _response;
    }
  }

  // Returns all video files.
  // Returns a <subsonic-response> element with a nested <videos> element on success.
  async getVideos(): Promise<any> {
    let _response;
    try {
      _response = await this.http.get(this.urlBuilder('getVideos')).toPromise();
      if (this.validate(_response)){
        _response = _response['subsonic-response'];
      } else {
        _response = {
          status: _response['subsonic-response'].status,
          error:  _response['subsonic-response'].error
        };
      }
    }
    catch (e) {
      _response = this.formatError(e);
    }
    finally {
      return _response;
    }
  }

  // Returns details for a video, including information about available audio tracks, subtitles (captions) and conversions.
  // @Param id (required)
  // A string of video ID
  // Returns a <subsonic-response> element with a nested <videoInfo> element on success.
  async getVideoInfo(id: string|number): Promise<any> {
    let _response;
    const _url = this.urlBuilder('getVideoInfo') + '&id=' + id.toString();
    try {
      _response = await this.http.get(_url).toPromise();
      if (this.validate(_response)){
        _response = _response['subsonic-response'];
      } else {
        _response = {
          status: _response['subsonic-response'].status,
          error:  _response['subsonic-response'].error
        };
      }
    }
    catch (e) {
      _response = this.formatError(e);
    }
    finally {
      return _response;
    }
  }

  // Returns artist info with biography, image URLs and similar artists, using data from last.fm.
  // @Param id (required)
  // the artist, album or song ID
  // @Param count (optional)
  // number of similar artists to return
  // @Param includeNotPresent (optional)
  // include similar artists who are not present in the library
  // only return a result if the artist collection has changed since the given time (in milliseconds since 1 Jan 1970)
  // Returns a <subsonic-response> element with a nested <artistInfo> element on success.
  // NOTE: Similar Artists Not Return, both Optional params are ignored
  async getArtistInfo(id: string|number, count?: string|number, includeNotPresent?: boolean): Promise<any> {
    let _response;
    let _url = this.urlBuilder('getArtistInfo') + '&id=' + id.toString();
    if (count !== undefined){
      _url = _url + '&count=' + count;
    }
    if (includeNotPresent !== undefined){
      _url = _url + '&includeNotPresent=' + includeNotPresent;
    }
    try {
      _response = await this.http.get(_url).toPromise();
      if (this.validate(_response)){
        // If there is only 1 similar artist it returns an object instead of array
        if( _response['subsonic-response'].artistInfo.similarArtist){
          if(!Array.isArray(_response['subsonic-response'].artistInfo.similarArtist)){
            _response['subsonic-response'].artistInfo.similarArtist = [_response['subsonic-response'].artistInfo.similarArtist];
          }
        }
        _response = _response['subsonic-response'];
      } else {
        _response = {
          status: _response['subsonic-response'].status,
          error:  _response['subsonic-response'].error
        };
      }
    }
    catch (e) {
      _response = this.formatError(e);
    }
    finally {
      return _response;
    }
  }

  // Returns artist info with biography, image URLs and similar artists, using data from last.fm.
  // Similar to getArtistInfo, but organizes music according to ID3 tags.
  // @Param id (required)
  // the artist ID
  // @Param count (optional)
  // number of similar artists to return
  // @Param includeNotPresent (optional)
  // include similar artists who are not present in the library
  // only return a result if the artist collection has changed since the given time (in milliseconds since 1 Jan 1970)
  // Returns a <subsonic-response> element with a nested <artistInfo2> element on success.
  async getArtistInfo2(id: string|number, count?: string|number, includeNotPresent?: boolean): Promise<any> {
    let _response;
    let _url = this.urlBuilder('getArtistInfo2') + '&id=' + id.toString();
    if (count !== undefined){
      _url = _url + '&count=' + count;
    }
    if (includeNotPresent !== undefined){
      _url = _url + '&includeNotPresent=' + includeNotPresent;
    }
    try {
      _response = await this.http.get(_url).toPromise();
      if (this.validate(_response)){
        // If there is only 1 similar artist it returns an object instead of array
        if( _response['subsonic-response'].artistInfo2.similarArtist){
          if(!Array.isArray(_response['subsonic-response'].artistInfo2.similarArtist)){
            _response['subsonic-response'].artistInfo2.similarArtist = [_response['subsonic-response'].artistInfo2.similarArtist];
          }
        }
        _response = _response['subsonic-response'];
      } else {
        _response = {
          status: _response['subsonic-response'].status,
          error:  _response['subsonic-response'].error
        };
      }
    }
    catch (e) {
      _response = this.formatError(e);
    }
    finally {
      return _response;
    }
  }

  // Returns album notes, image URLs etc, using data from last.fm.
  // @Param id (required)
  // A string of album or Song ID
  // Returns a <subsonic-response> element with a nested <albumInfo> element on success.
  async getAlbumInfo(id: string|number): Promise<any> {
    let _response;
    const _url = this.urlBuilder('getAlbumInfo') + '&id=' + id.toString();
    try {
      _response = await this.http.get(_url).toPromise();
      if (this.validate(_response)){
        _response = _response['subsonic-response'];
      } else {
        _response = {
          status: _response['subsonic-response'].status,
          error:  _response['subsonic-response'].error
        };
      }
    }
    catch (e) {
      _response = this.formatError(e);
    }
    finally {
      return _response;
    }
  }

  // Returns album notes, image URLs etc, using data from last.fm.
  // Similar to getAlbumInfo, but organizes music according to ID3 tags.
  // @Param id (required)
  // A string of album or Song ID
  // Returns a <subsonic-response> element with a nested <albumInfo> element on success.
  async getAlbumInfo2(id: string|number): Promise<any> {
    let _response;
    const _url = this.urlBuilder('getAlbumInfo2') + '&id=' + id.toString();
    try {
      _response = await this.http.get(_url).toPromise();
      if (this.validate(_response)){
        _response = _response['subsonic-response'];
      } else {
        _response = {
          status: _response['subsonic-response'].status,
          error:  _response['subsonic-response'].error
        };
      }
    }
    catch (e) {
      _response = this.formatError(e);
    }
    finally {
      return _response;
    }
  }

  // Returns a random collection of songs from the given artist and similar artists, using data from last.fm.
  // Typically used for artist radio features.
  // @Param id (required)
  // the artist, album or song ID.
  // @Param count (optional)
  // number of songs to return
  // Returns a <subsonic-response> element with a nested <similarSongs> element on success
  async getSimilarSongs(id: string|number, count?: string|number): Promise<any> {
    let _response;
    let _url = this.urlBuilder('getSimilarSongs') + '&id=' + id.toString();
    if (count !== undefined){
      _url = _url + '&count=' + count.toString();
    }
    try {
      _response = await this.http.get(_url).toPromise();
      if (this.validate(_response)){
        _response = _response['subsonic-response'];
      } else {
        _response = {
          status: _response['subsonic-response'].status,
          error:  _response['subsonic-response'].error
        };
      }
    }
    catch (e) {
      _response = this.formatError(e);
    }
    finally {
      return _response;
    }
  }

  // Returns a random collection of songs from the given artist and similar artists, using data from last.fm.
  // Typically used for artist radio features.
  // Similar to getSimilarSongs, but organizes music according to ID3 tags.
  // @Param id (required)
  // the artist ID
  // @Param count (optional)
  // number of songs to return
  // Returns a <subsonic-response> element with a nested <similarSongs2> element on success
  async getSimilarSongs2(id: string|number, count?: string|number): Promise<any> {
    let _response;
    let _url = this.urlBuilder('getSimilarSongs2') + '&id=' + id.toString();
    if (count !== undefined){
      _url = _url + '&count=' + count.toString();
    }
    try {
      _response = await this.http.get(_url).toPromise();
      if (this.validate(_response)){
        _response = _response['subsonic-response'];
      } else {
        _response = {
          status: _response['subsonic-response'].status,
          error:  _response['subsonic-response'].error
        };
      }
    }
    catch (e) {
      _response = this.formatError(e);
    }
    finally {
      return _response;
    }
  }

  // Returns top songs for the given artist, using data from last.fm.
  // @Param artistName (required)
  // the artist's name as a string
  // @Param count (optional)
  // number of songs to return
  // Returns a <subsonic-response> element with a nested <topSongs> element on success
  async getTopSongs(artistName: string, count?: string|number): Promise<any> {
    let _response;
    let _url = this.urlBuilder('getTopSongs') + '&artist=' + encodeURIComponent(artistName);
    if (count !== undefined){
      _url = _url + '&count=' + count.toString();
    }
    try {
      _response = await this.http.get(_url).toPromise();
      if (this.validate(_response)){
        _response = _response['subsonic-response'];
      } else {
        _response = {
          status: _response['subsonic-response'].status,
          error:  _response['subsonic-response'].error
        };
      }
    }
    catch (e) {
      _response = this.formatError(e);
    }
    finally {
      return _response;
    }
  }

  // Returns a list of random, newest, highest rated etc. albums. Similar to the album lists on the home page of the Subsonic web interface.
  // @Param type (required)
  // random, newest, highest, frequent, recent, alphabeticalByName, alphabeticalByArtist, starred, byYear, byGenre
  // @Param size (optional)
  // number of albums to return (max 500)
  // @Param offset
  // the list offset for results (ie start from the 50th result, good for paging)
  // @Param fromYear & toYear (required if type is byYear)
  // The start & end years for by year (inclusive)
  // @Param genre (required if type is byGenre)
  // name of the genre as a string "rock"
  // @Param musicFolderId (optional)
  // Only return albums in this music folder
  // Returns a <subsonic-response> element with a nested <albumList> element on success
  async getAlbumList(type: string, size?: string|number, offset?: number, fromYear?: string, toYear?: string,
                     genre?: string, musicFolderId?: string|number): Promise<any> {
    let _response;
    let _url = this.urlBuilder('getAlbumList') + '&type=' + type;
    if (size !== undefined){
      _url = _url + '&size=' + size;
    }
    if (offset !== undefined){
      _url = _url + '&offset=' + offset;
    }
    if (type === 'byYear'){
      _url = _url + '&fromYear=' + fromYear + '&toYear=' + toYear;
    }
    if (type === 'byGenre'){
      _url = _url + '&genre=' + encodeURIComponent(genre);
    }
    if (musicFolderId !== undefined){
      _url = _url + '&musicFolderId=' + musicFolderId;
    }
    try {
      _response = await this.http.get(_url).toPromise();
      if (this.validate(_response)){
        _response = _response['subsonic-response'];
      } else {
        _response = {
          status: _response['subsonic-response'].status,
          error:  _response['subsonic-response'].error
        };
      }
    }
    catch (e) {
      _response = this.formatError(e);
    }
    finally {
      return _response;
    }
  }

  // Returns a list of random, newest, highest rated etc. albums. Similar to the album lists on the home page of the Subsonic web interface.
  // Similar to getAlbumList, but organizes music according to ID3 tags.
  // @Param type (required)
  // random, newest, highest, frequent, recent, alphabeticalByName, alphabeticalByArtist, starred, byYear, byGenre
  // @Param size (optional)
  // number of albums to return (max 500)
  // @Param offset
  // the list offset for results (ie start from the 50th result, good for paging)
  // @Param fromYear & toYear (required if type is byYear)
  // The start & end years for by year (inclusive)
  // @Param genre (required if type is byGenre)
  // name of the genre as a string "rock"
  // @Param musicFolderId (optional)
  // Only return albums in this music folder
  // Returns a <subsonic-response> element with a nested <albumList2> element on success
  async getAlbumList2(type: string, size?: string|number, offset?: number, fromYear?: string,
                      toYear?: string, genre?: string, musicFolderId?: string|number): Promise<any> {
    let _response;
    let _url = this.urlBuilder('getAlbumList2') + '&type=' + type;
    if (size !== undefined){
      _url = _url + '&size=' + size;
    }
    if (offset !== undefined){
      _url = _url + '&offset=' + offset;
    }
    if (type === 'byYear'){
      _url = _url + '&fromYear=' + fromYear + '&toYear=' + toYear;
    }
    if (type === 'byGenre'){
      _url = _url + '&genre=' + encodeURIComponent(genre);
    }
    if (musicFolderId !== undefined){
      _url = _url + '&musicFolderId=' + musicFolderId;
    }
    try {
      _response = await this.http.get(_url).toPromise();
      if (this.validate(_response)){
        _response = _response['subsonic-response'];
      } else {
        _response = {
          status: _response['subsonic-response'].status,
          error:  _response['subsonic-response'].error
        };
      }
    }
    catch (e) {
      _response = this.formatError(e);
    }
    finally {
      return _response;
    }
  }

  // Returns random songs matching the given criteria.
  // @Param size (optional)
  // number of albums to return (max 500)
  // @Param fromYear & toYear (optional, can specify just one or both)
  // The start & end years for by year (inclusive)
  // @Param genre (optional)
  // name of the genre as a string "rock"
  // @Param musicFolderId (optional)
  // Only return albums in this music folder
  // Returns a <subsonic-response> element with a nested <albumList2> element on success
  async getRandomSongs(size?: string|number, fromYear?: string, toYear?: string, genre?: string,
                       musicFolderId?: string|number): Promise<any> {
    let _response;
    let _url = this.urlBuilder('getRandomSongs');
    if (size !== undefined){
      _url = _url + '&size=' + size;
    }
    if (fromYear !== undefined && fromYear){
      _url = _url + '&fromYear=' + fromYear;
    }
    if (toYear !== undefined && toYear){
      _url = _url + '&toYear=' + toYear;
    }
    if (genre !== undefined && genre){
      _url = _url + '&genre=' + encodeURIComponent(genre);
    }
    if (musicFolderId !== undefined){
      _url = _url + '&musicFolderId=' + musicFolderId;
    }
    try {
      _response = await this.http.get(_url).toPromise();
      if (this.validate(_response)){
        _response = _response['subsonic-response'];
      } else {
        _response = {
          status: _response['subsonic-response'].status,
          error:  _response['subsonic-response'].error
        };
      }
    }
    catch (e) {
      _response = this.formatError(e);
    }
    finally {
      return _response;
    }
  }

  // Returns songs in a given genre.
  // @Param genre (required)
  // name of the genre as a string "rock"
  // @Param count (optional)
  // number of songs to return (max 500)
  // @Param offset (optional)
  // offset in results list to start from (good for paging)
  // @Param musicFolderId (optional)
  // Only return albums in this music folder
  // Returns a <subsonic-response> element with a nested <songsByGenre> element on success
  async getSongsByGenre(genre: string, count?: string|number, offset?: string|number, musicFolderId?: string|number): Promise<any> {
    let _response;
    let _url = this.urlBuilder('getSongsByGenre') + '&genre=' + encodeURIComponent(genre);
    if (count !== undefined){
      _url = _url + '&count=' + count;
    }
    if (offset !== undefined){
      _url = _url + '&offset=' + offset;
    }
    if (musicFolderId !== undefined){
      _url = _url + '&musicFolderId=' + musicFolderId;
    }
    try {
      _response = await this.http.get(_url).toPromise();
      if (this.validate(_response)){
        _response = _response['subsonic-response'];
      } else {
        _response = {
          status: _response['subsonic-response'].status,
          error:  _response['subsonic-response'].error
        };
      }
    }
    catch (e) {
      _response = this.formatError(e);
    }
    finally {
      return _response;
    }
  }

  // Returns what is currently being played by all users. Takes no extra parameters.
  // Returns a <subsonic-response> element with a nested <nowPlaying> element on success.
  async getNowPlaying(): Promise<any> {
    let _response;
    try {
      _response = await this.http.get(this.urlBuilder('getNowPlaying')).toPromise();
      if (this.validate(_response)){
        _response = _response['subsonic-response'];
      } else {
        _response = {
          status: _response['subsonic-response'].status,
          error:  _response['subsonic-response'].error
        };
      }
    }
    catch (e) {
      _response = this.formatError(e);
    }
    finally {
      return _response;
    }
  }

  // Returns starred songs, albums
  // @Param musicFolderId (optional)
  // Only results from this musicFolder
  // Returns a <subsonic-response> element with a nested <starred> element on success.
  async getStarred(musicFolderId?: string|number): Promise<any> {
    let _response;
    let _url = this.urlBuilder('getStarred');
    if (musicFolderId !== undefined){
      _url = _url + '&musicFolderId=' + musicFolderId;
    }
    try {
      _response = await this.http.get(_url).toPromise();
      if (this.validate(_response)){
        _response = _response['subsonic-response'];
      } else {
        _response = {
          status: _response['subsonic-response'].status,
          error:  _response['subsonic-response'].error
        };
      }
    }
    catch (e) {
      _response = this.formatError(e);
    }
    finally {
      return _response;
    }
  }

  // Returns starred songs, albums and artists.
  // @Param musicFolderId (optional)
  // Similar to getStarred, but organizes music according to ID3 tags.
  // Only results from this musicFolder
  // Returns a <subsonic-response> element with a nested <starred2> element on success.
  async getStarred2(musicFolderId?: string|number): Promise<any> {
    let _response;
    let _url = this.urlBuilder('getStarred2');
    if (musicFolderId !== undefined){
      _url = _url + '&musicFolderId=' + musicFolderId;
    }
    try {
      _response = await this.http.get(_url).toPromise();
      if (this.validate(_response)){
        _response = _response['subsonic-response'];
        if(_response.starred2.song === undefined) {
          _response.starred2.song = [];
        }
      } else {
        _response = {
          status: _response['subsonic-response'].status,
          error:  _response['subsonic-response'].error
        };
      }
    }
    catch (e) {
      _response = this.formatError(e);
    }
    finally {
      return _response;
    }
  }

  // Returns albums, artists and songs matching the given search criteria. Supports paging through the result.
  // @Param query (required)
  // String to search for
  // @Param musicFolderId (optional)
  // Only results from this musicFolder
  // @Param artistCount, AlbumCount, SongCount (optional)
  // count of results for each type of media
  // @Param artistOffset, AlbumOffset, songOffset (optional)
  // start point offset in results list (good for paging)
  // Returns a <subsonic-response> element with a nested <searchResult2> element on success.
  async search2(query: string, artistCount?: number, artistOffset?: number, albumCount?: number, albumOffset?: number,
                songCount?: number, songOffset?: number, musicFolderId?: string|number): Promise<any> {
    let _response;
    let _query = query;
    if (this.store.userState.improvedSearch){
      _query = `"` + query + `"`;
    }
    let _url = this.urlBuilder('search2') + '&query=' + encodeURIComponent(_query);
    if (musicFolderId !== undefined){
      _url = _url + '&musicFolderId=' + musicFolderId;
    }
    if (artistCount !== undefined){
      _url = _url + '&artistCount=' + artistCount;
    }
    if (artistOffset !== undefined){
      _url = _url + '&artistOffset=' + artistOffset;
    }
    if (albumCount !== undefined){
      _url = _url + '&albumCount=' + albumCount;
    }
    if (albumOffset !== undefined){
      _url = _url + '&albumOffset=' + albumOffset;
    }
    if (songCount !== undefined){
      _url = _url + '&songCount=' + songCount;
    }
    if (songOffset !== undefined){
      _url = _url + '&songOffset=' + songOffset;
    }
    try {
      _response = await this.http.get(_url).toPromise();
      if (this.validate(_response)){
        _response = _response['subsonic-response'];
      } else {
        _response = {
          status: _response['subsonic-response'].status,
          error:  _response['subsonic-response'].error
        };
      }
    }
    catch (e) {
      _response = this.formatError(e);
    }
    finally {
      return _response;
    }
  }

  // Returns albums, artists and songs matching the given search criteria. Supports paging through the result.
  // Similar to search2, but organizes music according to ID3 tags.
  // @Param query (required)
  // String to search for
  // @Param musicFolderId (optional)
  // Only results from this musicFolder
  // @Param artistCount, AlbumCount, SongCount (optional)
  // count of results for each type of media
  // @Param artistOffset, AlbumOffset, songOffset (optional)
  // start point offset in results list (good for paging)
  // Returns a <subsonic-response> element with a nested <searchResult3> element on success.
  async search3(query: string, artistCount?: number, artistOffset?: number, albumCount?: number, albumOffset?: number,
                songCount?: number, songOffset?: number, musicFolderId?: string|number): Promise<any> {
    let _response;
    let _query = query;
    if (this.store.userState.improvedSearch){
      _query = `"` + query + `"`;
    }
    let _url = this.urlBuilder('search3') + '&query=' + encodeURIComponent(_query);
    if (musicFolderId !== undefined){
      _url = _url + '&musicFolderId=' + musicFolderId;
    }
    if (artistCount !== undefined){
      _url = _url + '&artistCount=' + artistCount;
    }
    if (artistOffset !== undefined){
      _url = _url + '&artistOffset=' + artistOffset;
    }
    if (albumCount !== undefined){
      _url = _url + '&albumCount=' + albumCount;
    }
    if (albumOffset !== undefined){
      _url = _url + '&albumOffset=' + albumOffset;
    }
    if (songCount !== undefined){
      _url = _url + '&songCount=' + songCount;
    }
    if (songOffset !== undefined){
      _url = _url + '&songOffset=' + songOffset;
    }
    try {
      _response = await this.http.get(_url).toPromise();
      if (this.validate(_response)){
        _response = _response['subsonic-response'];
      } else {
        _response = {
          status: _response['subsonic-response'].status,
          error:  _response['subsonic-response'].error
        };
      }
    }
    catch (e) {
      _response = this.formatError(e);
    }
    finally {
      return _response;
    }
  }

  // Returns all playlists a user is allowed to play.
  // @Param user (optional)
  // return playlists for this user rather than for the authenticated user
  // Returns a <subsonic-response> element with a nested <playlists> element on success.
  async getPlaylists(user?: string): Promise<any> {
    let _response;
    let _url = this.urlBuilder('getPlaylists');
    if (user !== undefined){
      _url = _url + '&username=' + encodeURIComponent(user);
    }
    try {
      _response = await this.http.get(_url).toPromise();
      if (this.validate(_response)){
        _response = _response['subsonic-response'];
      } else {
        _response = {
          status: _response['subsonic-response'].status,
          error:  _response['subsonic-response'].error
        };
      }
    }
    catch (e) {
      _response = this.formatError(e);
    }
    finally {
      return _response;
    }
  }

  // Returns a listing of files in a saved playlist.
  // @Param id (required)
  // playlist ID to fetch
  // Returns a <subsonic-response> element with a nested <playlist> element on success.
  async getPlaylist(id: string|number): Promise<any> {
    let _response;
    const _url = this.urlBuilder('getPlaylist') + '&id=' + id.toString();
    try {
      _response = await this.http.get(_url).toPromise();
      if (this.validate(_response)){
        _response = _response['subsonic-response'];
      } else {
        _response = {
          status: _response['subsonic-response'].status,
          error:  _response['subsonic-response'].error
        };
      }
    }
    catch (e) {
      _response = this.formatError(e);
    }
    finally {
      return _response;
    }
  }

  // Returns the created playlist item.
  // @Param name (required if creating)
  // playlist name as string
  // @Param id (required if replacing)
  // playlist id to replace with this playlist
  // @Param songId (optional)
  // array of songId's to add to include in the playlist
  // Returns a <subsonic-response> element with a nested <playlist> element on success.
  async createPlaylist(name: string, id?: string|number, songId?: Array<any>): Promise<any> {
    let _response;
    let _url = this.urlBuilder('createPlaylist');

    if (id !== undefined){
      _url = _url + '&playlistId=' + id.toString();
    }
    if (name !== undefined){
      _url = _url + '&name=' + encodeURIComponent(name);
    }
    if (songId !== undefined){
      songId.forEach((sid, index) => {
        _url = _url + '&songId=' + sid.toString();
      });
    }
    try {
      _response = await this.http.get(_url).toPromise();
      if (this.validate(_response)){
        _response = _response['subsonic-response'];
      } else {
        _response = {
          status: _response['subsonic-response'].status,
          error:  _response['subsonic-response'].error
        };
      }
    }
    catch (e) {
      _response = this.formatError(e);
    }
    finally {
      return _response;
    }
  }

  // Updates a playlist. Only the owner of a playlist is allowed to update it.
  // @Param id (required)
  // id of playlist to update
  // @Param name (optional)
  // new playlist name, string
  // @Param comment (optional)
  // new comment for the playlist, string
  // @Param pub (optional)
  // is the array public? (available to all users) boolean
  // @Param songIdToAdd
  // array of song id's to add to the playlist
  // @Param songIndexToRemove
  // position of songs in the playlist array to remove
  // Returns an empty <subsonic-response> element on success.
  async updatePlaylist(id: string|number, name?: string, comment?: string, pub?: boolean, songIdToAdd?: Array<any>,
                       songIndexToRemove?: Array<any>): Promise<any> {
    let _response;
    let _url = this.urlBuilder('updatePlaylist') + '&playlistId=' + id;

    if (name !== undefined){
      _url = _url + '&name=' + encodeURIComponent(name);
    }
    if (comment !== undefined){
      _url = _url + '&comment=' + encodeURIComponent(comment);
    }
    if (pub !== undefined){
      _url = _url + '&public=' + pub;
    }
    if (songIdToAdd !== undefined){
      songIdToAdd.forEach((sid, index) => {
        _url = _url + '&songIdToAdd=' + sid;
      });
    }
    if (songIndexToRemove !== undefined){
      songIndexToRemove.forEach((sid, index) => {
        _url = _url + '&songIndexToRemove=' + sid;
      });
    }
    try {
      _response = await this.http.get(_url).toPromise();
      if (this.validate(_response)){
        _response = _response['subsonic-response'];
      } else {
        _response = {
          status: _response['subsonic-response'].status,
          error:  _response['subsonic-response'].error
        };
      }
    }
    catch (e) {
      _response = this.formatError(e);
    }
    finally {
      return _response;
    }
  }

  // Deletes a saved playlist.
  // @Param id (required)
  // playlist ID to delete
  // Returns an empty <subsonic-response> element on success.
  async deletePlaylist(id: string|number): Promise<any> {
    let _response;
    const _url = this.urlBuilder('deletePlaylist') + '&id=' + id.toString();
    try {
      _response = await this.http.get(_url).toPromise();
      if (this.validate(_response)){
        _response = _response['subsonic-response'];
      } else {
        _response = {
          status: _response['subsonic-response'].status,
          error:  _response['subsonic-response'].error
        };
      }
    }
    catch (e) {
      _response = this.formatError(e);
    }
    finally {
      return _response;
    }
  }

  // Streams a given media file.
  // @Param id (required)
  // item ID to stream
  // @Param maxBitRate (optional)
  // attempt to limit stream bandwidth to this value (0 = no limit)
  // @Param format (optional)
  // mp3, flv, raw
  // @Param estimateContentLength (optional)
  // set content-length http header to estimated value for transcoded media
  // @Param timeOffset (optional video only)
  // start playback from the offset in seconds
  // @Param size (optional video only)
  // resolution as WxH string (ie 640x480)
  // @Param converted (optional video only)
  // if a converted "optimized" video exists already use it instead of original
  // Returns binary data on success, or an XML document on error
  stream(id: string|number, maxBitRate?: number, format?: string, estimateContentLength?: boolean,
         timeOffset?: number, size?: string, converted?: boolean): string {
    let _url = this.urlBuilder('stream') + '&id=' + id;

    if (maxBitRate !== undefined){
      _url = _url + '&maxBitRate=' + encodeURIComponent(maxBitRate);
    } else if ((this.store.userState.streamMaxBitrate === 0) && (this.store.userState.streamFormat === 'mp3')) {
      _url = _url + '&maxBitRate=320';
    } else if ((this.store.userState.streamMaxBitrate === 0) && (this.store.userState.streamFormat === 'opus')) {
      _url = _url + '&maxBitRate=128';
    } else {
      _url = _url + '&maxBitRate=' + encodeURIComponent(this.store.userState.streamMaxBitrate);
    }
    if (format !== undefined){
      _url = _url + '&format=' + encodeURIComponent(format);
    } else {
      _url = _url + '&format=' + encodeURIComponent(this.store.userState.streamFormat);
    }
    if (timeOffset !== undefined){
      _url = _url + '&timeOffset=' + timeOffset;
    }
    if (size !== undefined){
      _url = _url + '&size=' + size;
    }
    if (estimateContentLength !== undefined){
      _url = _url + '&estimateContentLength=' + estimateContentLength;
    } else {
      if(this.store.appState.isIOS){
        // iOS needs this enabled so that it gets the range 0-1 response it expects
        _url = _url + '&estimateContentLength=true';
      } else {
        // android and chrome sometimes will not fire playback complete with estimate enabled
        // shows content length mismatch warnings in console
        _url = _url + '&estimateContentLength=false';
      }
    }
    return _url;
  }

  // Downloads a given media file. Similar to stream, but this method returns the original media data
  // @Param id (required)
  // item id to download
  // Returns binary data on success, or an XML document on error
  download(id: string|number): string {
    const _url = this.urlBuilder('download') + '&id=' + id.toString();
    return _url;
  }

  // Creates an HLS (HTTP Live Streaming) playlist used for streaming video or audio (VIDEO ONLY)
  // @Param id (required)
  // item id to stream
  // @Param bitRate (optional, multiple ok)
  // array of bitrates, attempt to limit stream to this rate
  // if multiple rates are provied then adaptive streaming can be enabled
  // @Param audioTrack (optional)
  // select an audiotrack for video playback (use getVideoInfo to get track IDs)
  // Returns an M3U8 playlist on success (content type "application/vnd.apple.mpegurl"), or an XML document on error
  async hls(id: string|number, bitRate?: Array<any>, audioTrack?: string|number): Promise<any> {
    let _response;
    let _url = this.urlBuilder('hls.m3u8') + '&id=' + id;

    if (bitRate !== undefined){
      bitRate.forEach((rate, index) => {
        _url = _url + '&bitRate=' + rate;
      });
    }
    if (audioTrack !== undefined){
      _url = _url + '&audioTrack=' + audioTrack;
    }
    try {
      _response = await this.http.get(_url).toPromise();
      if (this.validate(_response)){
        _response = _response['subsonic-response'];
      } else {
        _response = {
          status: _response['subsonic-response'].status,
          error:  _response['subsonic-response'].error
        };
      }
    }
    catch (e) {
      _response = this.formatError(e);
    }
    finally {
      return _response;
    }
  }

  // Returns captions (subtitles) for a video. Use getVideoInfo to get a list of available captions.
  // @Param id (required)
  // id of caption stream
  // @Param format (optional)
  // caption format srt or vtt
  // Returns an empty <subsonic-response> element on success.
  async getCaptions(id: string|number, format?: string): Promise<any> {
    let _response;
    let _url = this.urlBuilder('getCaptions') + '&id=' + id;

    if (format !== undefined){
      _url = _url + '&format=' + format;
    }
    try {
      _response = await this.http.get(_url).toPromise();
      if (this.validate(_response)){
        _response = _response['subsonic-response'];
      } else {
        _response = {
          status: _response['subsonic-response'].status,
          error:  _response['subsonic-response'].error
        };
      }
    }
    catch (e) {
      _response = this.formatError(e);
    }
    finally {
      return _response;
    }
  }

  // Returns a cover art image.
  // @Param id (required)
  // id of media item to get cover art for
  // @Param size (optional)
  // size for the cover image, images are square so only specify 1 dimension
  // Returns the cover art image in binary form.
  getCoverArt(id: string|number, size?: number): string {
    let _url = this.urlBuilder('getCoverArt') + '&id=' + id;
    if (size !== undefined){
      _url = _url + '&size=' + size;
    } else {
      _url = _url + '&size=500';
    }
    return _url;
  }

  // Searches for and returns lyrics for a given song.
  // @Param artist (required)
  // string of artist name
  // @Param song (required)
  // string of song name
  // Returns a <subsonic-response> element with a nested <lyrics> element on success
  // The <lyrics> element is empty if no matching lyrics was found.
  async getLyrics(artist: string, title: string): Promise<any> {
    let _response;
    const _url = this.urlBuilder('getLyrics') + '&artist=' + encodeURIComponent(artist) + '&title=' + encodeURIComponent(title);

    try {
      _response = await this.http.get(_url).toPromise();
      if (this.validate(_response)){
        _response = _response['subsonic-response'];
      } else {
        _response = {
          status: _response['subsonic-response'].status,
          error:  _response['subsonic-response'].error
        };
      }
    }
    catch (e) {
      _response = this.formatError(e);
    }
    finally {
      return _response;
    }
  }

  // Returns the avatar (personal image) for a user.
  // @Param username (required)
  // username to get the avatar for
  // Returns the avatar image in binary form.
  getAvatar(username: string): string {
    const _url = this.urlBuilder('getAvatar') + '&username=' + encodeURIComponent(this.store.userState.username);
    return _url;
  }

  // Attaches a star to a song, album or artist.
  // Must select 1
  // @Param id (optional)
  // id of song to star
  // @Param albumId (optional)
  // id of album to star
  // @Param artistId (optional)
  // id of artist to star
  // Returns an empty <subsonic-response> element on success.
  async star(id?: Array<string|number>, albumId?: Array<string|number>, artistId?: Array<string|number>): Promise<any> {
    let _response;
    let _url = this.urlBuilder('star');

    if (id !== undefined){
      id.forEach((itemId, index) => {
        _url = _url + '&id=' + itemId;
      });
    }
    if (albumId !== undefined){
      albumId.forEach((itemId, index) => {
        _url = _url + '&albumId=' + itemId;
      });
    }
    if (artistId !== undefined){
      artistId.forEach((itemId, index) => {
        _url = _url + '&artistId=' + itemId;
      });
    }
    try {
      _response = await this.http.get(_url).toPromise();
      if (this.validate(_response)){
        _response = _response['subsonic-response'];
      } else {
        _response = {
          status: _response['subsonic-response'].status,
          error:  _response['subsonic-response'].error
        };
      }
    }
    catch (e) {
      _response = this.formatError(e);
    }
    finally {
      return _response;
    }
  }

  // Removes a star to a song, album or artist.
  // Must select 1
  // @Param id (optional)
  // id of song to unstar
  // @Param albumId (optional)
  // id of album to unstar
  // @Param artistId (optional)
  // id of artist to unstar
  // Returns an empty <subsonic-response> element on success.
  async unstar(id?: Array<string|number>, albumId?: Array<string|number>, artistId?: Array<string|number>): Promise<any> {
    let _response;
    let _url = this.urlBuilder('unstar');

    if (id !== undefined){
      id.forEach((itemId) => {
        _url = _url + '&id=' + itemId;
      });
    }
    if (albumId !== undefined){
      albumId.forEach((itemId) => {
        _url = _url + '&albumId=' + itemId;
      });
    }
    if (artistId !== undefined){
      artistId.forEach((itemId) => {
        _url = _url + '&artistId=' + itemId;
      });
    }
    try {
      _response = await this.http.get(_url).toPromise();
      if (this.validate(_response)){
        _response = _response['subsonic-response'];
      } else {
        _response = {
          status: _response['subsonic-response'].status,
          error:  _response['subsonic-response'].error
        };
      }
    }
    catch (e) {
      _response = this.formatError(e);
    }
    finally {
      return _response;
    }
  }

  // Attaches a rating out of 5 to a song, album or artist.
  // @Param id (required)
  // id of song, album, artist to rate
  // @Param rating (required)
  // rating 0 - 5 (0 is remove rating)
  // Returns an empty <subsonic-response> element on success.
  async setRating(id: string|number, rating: number): Promise<any> {
    let _response;
    const _url = this.urlBuilder('setRating') + '&id=' + id + '&rating=' + rating;
    try {
      _response = await this.http.get(_url).toPromise();
      if (this.validate(_response)){
        _response = _response['subsonic-response'];
      } else {
        _response = {
          status: _response['subsonic-response'].status,
          error:  _response['subsonic-response'].error
        };
      }
    }
    catch (e) {
      _response = this.formatError(e);
    }
    finally {
      return _response;
    }
  }

  // Registers the local playback of one or more media files. Typically used
  // when playing media that is cached on the client. This operation includes:
  // scrobbles the media on last.fm if account is configured on server
  // updates the play count
  // makes the media file appear in nowPlaying.
  // @Param id (required)
  // id of song to scrobble
  // @Param time (optional)
  // time since 1970 when the media was played
  // @Param submission (optional)
  // defaults true, true scrobbles, false just sets nowPlaying
  // Returns an empty <subsonic-response> element on success.
  async scrobble(id: string|number, time?: number, submission?: boolean): Promise<any> {
    let _response;
    let _url = this.urlBuilder('scrobble') + '&id=' + id;
    if (time !== undefined){
      _url = _url + '&time=' + time;
    }
    if (submission !== undefined){
      _url = _url + '&submission=' + submission;
    }
    try {
      _response = await this.http.get(_url).toPromise();
      if (this.validate(_response)){
        _response = _response['subsonic-response'];
      } else {
        _response = {
          status: _response['subsonic-response'].status,
          error:  _response['subsonic-response'].error
        };
      }
    }
    catch (e) {
      _response = this.formatError(e);
    }
    finally {
      return _response;
    }
  }

  // Returns all Podcast channels the server subscribes to, and (optionally) their episodes.
  // This method can also be used to return details for only one channel - refer to the id parameter.
  // A typical use case for this method would be to first retrieve all channels without episodes, and
  // then retrieve all episodes for the single channel the user selects.
  // @Param id (optional)
  // id of a specific podcast channel to retrieve
  // @Param includeEpisodes (optional)
  // whether to include episodes for channels in results, default true
  // Returns a <subsonic-response> element with a nested <podcasts> element on success.
  async getPodcasts(id?: string|number, includeEpisodes?: boolean): Promise<any> {
    let _response;
    let _url = this.urlBuilder('getPodcasts');
    if (id !== undefined){
      _url = _url + '&id=' + id;
    }
    if (includeEpisodes !== undefined){
      _url = _url + '&includeEpisodes=' + includeEpisodes;
    }
    try {
      _response = await this.http.get(_url).toPromise();
      if (this.validate(_response)){
        _response = _response['subsonic-response'];
      } else {
        _response = {
          status: _response['subsonic-response'].status,
          error:  _response['subsonic-response'].error
        };
      }
    }
    catch (e) {
      _response = this.formatError(e);
    }
    finally {
      return _response;
    }
  }

  // Returns the most recently published Podcast episodes.
  // @Param count (optional)
  // max episodes to return, default 20
  // Returns a <subsonic-response> element with a nested <newestPodcasts> element on success.
  async getNewestPodcasts(count?: number): Promise<any> {
    let _response;
    let _url = this.urlBuilder('getNewestPodcasts');
    if (count !== undefined){
      _url = _url + '&count=' + count;
    }
    try {
      _response = await this.http.get(_url).toPromise();
      if (this.validate(_response)){
        _response = _response['subsonic-response'];
      } else {
        _response = {
          status: _response['subsonic-response'].status,
          error:  _response['subsonic-response'].error
        };
      }
    }
    catch (e) {
      _response = this.formatError(e);
    }
    finally {
      return _response;
    }
  }

  // Requests the server to check for new Podcast episodes. Note: The user must be authorized
  // for Podcast administration
  // Returns an empty <subsonic-response> element on success.
  async refreshPodcasts(): Promise<any> {
    let _response;
    const _url = this.urlBuilder('refreshPodcasts');
    try {
      _response = await this.http.get(_url).toPromise();
      if (this.validate(_response)){
        _response = _response['subsonic-response'];
      } else {
        _response = {
          status: _response['subsonic-response'].status,
          error:  _response['subsonic-response'].error
        };
      }
    }
    catch (e) {
      _response = this.formatError(e);
    }
    finally {
      return _response;
    }
  }

  // Adds a new Podcast channel. Note: The user must be authorized for Podcast administration
  // @Param url (required)
  // url to the podcast to add
  // Returns an empty <subsonic-response> element on success.
  async createPodcastChannel(url: string): Promise<any> {
    let _response;
    const _encodedUri = encodeURI(url);
    const _url = this.urlBuilder('createPodcastChannel') + '&url=' + _encodedUri;

    try {
      _response = await this.http.get(_url).toPromise();
      if (this.validate(_response)){
        _response = _response['subsonic-response'];
      } else {
        _response = {
          status: _response['subsonic-response'].status,
          error:  _response['subsonic-response'].error
        };
      }
    }
    catch (e) {
      _response = this.formatError(e);
    }
    finally {
      return _response;
    }
  }

  // Deletes a Podcast channel. Note: The user must be authorized for Podcast administration
  // @Param id (required)
  // podcast channel ID to delete
  // Returns an empty <subsonic-response> element on success.
  async deletePodcastChannel(id: number|string): Promise<any> {
    let _response;
    const _url = this.urlBuilder('deletePodcastChannel') + '&id=' + id;
    try {
      _response = await this.http.get(_url).toPromise();
      if (this.validate(_response)){
        _response = _response['subsonic-response'];
      } else {
        _response = {
          status: _response['subsonic-response'].status,
          error:  _response['subsonic-response'].error
        };
      }
    }
    catch (e) {
      _response = this.formatError(e);
    }
    finally {
      return _response;
    }
  }

  // Deletes a Podcast episode. Note: The user must be authorized for Podcast administration
  // @Param id (required)
  // podcast episode ID to delete
  // Returns an empty <subsonic-response> element on success.
  async deletePodcastEpisode(id: number|string): Promise<any> {
    let _response;
    const _url = this.urlBuilder('deletePodcastEpisode') + '&id=' + id;
    try {
      _response = await this.http.get(_url).toPromise();
      if (this.validate(_response)){
        _response = _response['subsonic-response'];
      } else {
        _response = {
          status: _response['subsonic-response'].status,
          error:  _response['subsonic-response'].error
        };
      }
    }
    catch (e) {
      _response = this.formatError(e);
    }
    finally {
      return _response;
    }
  }

  // Request the server to start downloading a given Podcast episode. Note: The user
  // must be authorized for Podcast administration
  // @Param id (required)
  // podcast episode ID to download
  // Returns an empty <subsonic-response> element on success.
  async downloadPodcastEpisode(id: number|string): Promise<any> {
    let _response;
    const _url = this.urlBuilder('downloadPodcastEpisode') + '&id=' + id;
    try {
      _response = await this.http.get(_url).toPromise();
      if (this.validate(_response)){
        _response = _response['subsonic-response'];
      } else {
        _response = {
          status: _response['subsonic-response'].status,
          error:  _response['subsonic-response'].error
        };
      }
    }
    catch (e) {
      _response = this.formatError(e);
    }
    finally {
      return _response;
    }
  }

  // Controls the jukebox, i.e., playback directly on the server's audio hardware.
  // Note: The user must be authorized to control the jukebox
  // @Param action (required)
  // The operation to perform. Must be one of: get, status (since 1.7.0), set (since 1.7.0),
  // start, stop, skip, add, clear, remove, shuffle, setGain
  // add (adds to current playlist)
  // set (clears current playlist and then adds)
  // @Param index (optional)
  // Used by skip and remove (zero based index of the song to skip or remove)
  // @Param offset (optional)
  // Used by skip.  Start playing this many seconds into the track
  // @Param id (optional)
  // used by add & set. array of IDs of songs to add to the playlist. Use multiple entries in the array to add
  // multiple songs
  // @Param gain (optional)
  // used by setGain method to control the playback volume.  Float between 0.0 and 1.0
  // Returns a <jukeboxStatus> element on success, unless the get action is used,
  // in which case a nested <jukeboxPlaylist> element is returned.
  async jukeboxControl(action: string, index?: number, offset?: number, id?: Array<number|string>, gain?: number): Promise<any> {
    let _response;
    let _url = this.urlBuilder('jukeboxControl') + '&action=' + action;
    if (action === 'set'){
      id.forEach((sid, _index) => {
        _url = _url + '&id=' + sid;
      });
    }
    if (action === 'add' || action === 'set'){
      id.forEach((sid, _index) => {
        _url = _url + '&id=' + sid;
      });
    }
    if (action === 'skip' && index !== undefined){
      _url = _url + '&index=' + index;
    }
    if (action === 'remove' && index !== undefined){
      _url = _url + '&index=' + index;
    }
    if (action === 'skip' && offset !== undefined){
      _url = _url + '&offset=' + offset;
    }
    if (action === 'setGain' && gain !== undefined){
      _url = _url + '&gain=' + gain;
    }

    try {
      _response = await this.http.get(_url).toPromise();
      if (this.validate(_response)){
        _response = _response['subsonic-response'];
      } else {
        _response = {
          status: _response['subsonic-response'].status,
          error:  _response['subsonic-response'].error
        };
      }
    }
    catch (e) {
      _response = this.formatError(e);
    }
    finally {
      return _response;
    }
  }

  // Returns all internet radio stations. Takes no extra parameters.
  // Returns a <subsonic-response> element with a nested <internetRadioStations>
  // element on success
  async getInternetRadioStations(): Promise<any> {
    let _response;
    const _url = this.urlBuilder('getInternetRadioStations');
    try {
      _response = await this.http.get(_url).toPromise();
      if (this.validate(_response)){
        _response = _response['subsonic-response'];
      } else {
        _response = {
          status: _response['subsonic-response'].status,
          error:  _response['subsonic-response'].error
        };
      }
    }
    catch (e) {
      _response = this.formatError(e);
    }
    finally {
      return _response;
    }
  }

  // Adds a new internet radio station. Only users with admin privileges are allowed to call this method.
  // @Param streamUrl (required)
  // the stream url for the station
  // @Param name (required)
  // user defined name for the station
  // @Param homepageUrl (optional)
  // the home page URL for the station
  // Returns an empty <subsonic-response> element on success.
  async createInternetRadioStation(streamUrl: string, name: string, homepageUrl?: string): Promise<any> {
    let _response;
    const _encodedUrl = encodeURI(streamUrl);
    const _encodedName = encodeURIComponent(name);
    let _encodedHPUrl;
    let _url = this.urlBuilder('createInternetRadioStation') + '&streamUrl=' + _encodedUrl + '&name=' + _encodedName;

    if (homepageUrl !== undefined){
      _encodedHPUrl = encodeURI(homepageUrl);
      _url = _url + '&homepageUrl=' + _encodedHPUrl;
    }
    try {
      _response = await this.http.get(_url).toPromise();
      if (this.validate(_response)){
        _response = _response['subsonic-response'];
      } else {
        _response = {
          status: _response['subsonic-response'].status,
          error:  _response['subsonic-response'].error
        };
      }
    }
    catch (e) {
      _response = this.formatError(e);
    }
    finally {
      return _response;
    }
  }

  // Adds a new internet radio station. Only users with admin privileges are allowed to call this method.
  // @Param id (required)
  // id of the station
  // @Param streamUrl (required)
  // the stream url for the station
  // @Param name (required)
  // user defined name for the station
  // @Param homepageUrl (optional)
  // the home page URL for the station
  // Returns an empty <subsonic-response> element on success.
  async updateInternetRadioStation(id: number|string, streamUrl: string, name: string, homepageUrl?: string): Promise<any> {
    let _response;
    const _encodedUrl = encodeURI(streamUrl);
    const _encodedName = encodeURIComponent(name);
    let _encodedHPUrl;
    let _url = this.urlBuilder('updateInternetRadioStation') + '&id=' + id + '&streamUrl=' + _encodedUrl + '&name=' + _encodedName;

    if (homepageUrl !== undefined){
      _encodedHPUrl = encodeURI(homepageUrl);
      _url = _url + '&homepageUrl=' + _encodedHPUrl;
    }
    try {
      _response = await this.http.get(_url).toPromise();
      if (this.validate(_response)){
        _response = _response['subsonic-response'];
      } else {
        _response = {
          status: _response['subsonic-response'].status,
          error:  _response['subsonic-response'].error
        };
      }
    }
    catch (e) {
      _response = this.formatError(e);
    }
    finally {
      return _response;
    }
  }

  // Deletes an existing internet radio station. Only users with admin privileges are
  // allowed to call this method.
  // @Param id (required)
  // id of the station
  // Returns an empty <subsonic-response> element on success.
  async deleteInternetRadioStation(id: number|string): Promise<any> {
    let _response;
    const _url = this.urlBuilder('deleteInternetRadioStation') + '&id=' + id;

    try {
      _response = await this.http.get(_url).toPromise();
      if (this.validate(_response)){
        _response = _response['subsonic-response'];
      } else {
        _response = {
          status: _response['subsonic-response'].status,
          error:  _response['subsonic-response'].error
        };
      }
    }
    catch (e) {
      _response = this.formatError(e);
    }
    finally {
      return _response;
    }
  }

  // Returns all bookmarks for this user. A bookmark is a position within a certain media file.
  // Returns a <subsonic-response> element with a nested <bookmarks> element on success
  async getBookmarks(): Promise<any> {
    let _response;
    const _url = this.urlBuilder('getBookmarks');

    try {
      _response = await this.http.get(_url).toPromise();
      if (this.validate(_response)){
        _response = _response['subsonic-response'];
      } else {
        _response = {
          status: _response['subsonic-response'].status,
          error:  _response['subsonic-response'].error
        };
      }
    }
    catch (e) {
      _response = this.formatError(e);
    }
    finally {
      return _response;
    }
  }

  // Creates or updates a bookmark (a position within a media file). Bookmarks are personal
  // and not visible to other users
  // @Param id (required)
  // id of media file to bookmark
  // @Param position (required)
  // position in ms within the media file
  // @Param comment (optional)
  // user defined comment for this bookmark
  // Returns an empty <subsonic-response> element on success.
  async createBookmark(id: number|string, position: number, comment?: string): Promise<any> {
    let _response;
    let _url = this.urlBuilder('createBookmark') + '&id=' + id + '&position=' + position;

    if (comment !== undefined){
      _url = _url + '&comment=' + encodeURIComponent(comment);
    }
    try {
      _response = await this.http.get(_url).toPromise();
      if (this.validate(_response)){
        _response = _response['subsonic-response'];
      } else {
        _response = {
          status: _response['subsonic-response'].status,
          error:  _response['subsonic-response'].error
        };
      }
    }
    catch (e) {
      _response = this.formatError(e);
    }
    finally {
      return _response;
    }
  }

  // Deletes bookmark for a given file
  // allowed to call this method.
  // @Param id (required)
  // id of media file to delete the bookmark from
  // Returns an empty <subsonic-response> element on success.
  async deleteBookmark(id: number|string): Promise<any> {
    let _response;
    const _url = this.urlBuilder('deleteBookmark') + '&id=' + id;

    try {
      _response = await this.http.get(_url).toPromise();
      if (this.validate(_response)){
        _response = _response['subsonic-response'];
      } else {
        _response = {
          status: _response['subsonic-response'].status,
          error:  _response['subsonic-response'].error
        };
      }
    }
    catch (e) {
      _response = this.formatError(e);
    }
    finally {
      return _response;
    }
  }

  // Returns the state of the play queue for this user (as set by savePlayQueue).
  // This includes the tracks in the play queue, the currently playing track, and
  // the position within this track. Typically used to allow a user to move between
  // different clients/apps while retaining the same play queue (for instance when
  // listening to an audio book).
  // Returns a <subsonic-response> element with a nested <playQueue> element on success,
  // or an empty <subsonic-response> if no play queue has been saved.
  async getPlayQueue(): Promise<any> {
    let _response;
    const _url = this.urlBuilder('getPlayQueue');

    try {
      _response = await this.http.get(_url).toPromise();
      if (this.validate(_response)){
        _response = _response['subsonic-response'];
      } else {
        _response = {
          status: _response['subsonic-response'].status,
          error:  _response['subsonic-response'].error
        };
      }
    }
    catch (e) {
      _response = this.formatError(e);
    }
    finally {
      return _response;
    }
  }

  // Saves the state of the play queue for this user. This includes the tracks in the play queue,
  // the currently playing track, and the position within this track. Typically used to allow a
  // user to move between different clients/apps while retaining the same play queue (for instance
  // when listening to an audio book).
  // @Param id (required)
  // array of id's of songs in the playqueue
  // @Param current (optional)
  // id of the currently playing media file
  // @Param position (optional)
  // position in ms within the currently playing media file
  // Returns an empty <subsonic-response> element on success.
  async savePlayQueue(id: Array<number|string>, current?: number|string, position?: number): Promise<any> {
    let _response;
    let _url = this.urlBuilder('savePlayQueue');

    id.forEach((sid, index) => {
      _url = _url + '&id=' + sid;
    });

    if (current !== undefined){
      _url = _url + '&current=' + current;
    }
    if (position !== undefined){
      _url = _url + '&position=' + position;
    }

    try {
      _response = await this.http.get(_url).toPromise();
      if (this.validate(_response)){
        _response = _response['subsonic-response'];
      } else {
        _response = {
          status: _response['subsonic-response'].status,
          error:  _response['subsonic-response'].error
        };
      }
    }
    catch (e) {
      _response = this.formatError(e);
    }
    finally {
      return _response;
    }
  }

  //
  // TO BE IMPLEMENTED IF NEEDED
  //

  getChatMessages() {

  }

  addChatMessage() {

  }

  getUser() {

  }

  getUsers() {

  }

  createUser() {

  }

  updateUser() {

  }

  deleteUser() {

  }

  changePassword() {

  }

}
