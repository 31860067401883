import { Component, OnInit, OnChanges, Input, SecurityContext, ChangeDetectionStrategy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ImageCacheService } from '../../services/image-cache.service';
import { StoreService } from '../../services/store.service';
import { UtilService } from 'src/app/services/util.service';
import { ThemeService } from 'src/app/services/theme.service';
import * as _ from 'lodash-es';

@Component({
  selector: 'app-cachedimage',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './cachedimage.component.html',
  styleUrls: ['./cachedimage.component.scss'],
})
export class CachedimageComponent implements OnInit, OnChanges {

  @Input() url: string;
  @Input() detailImage: boolean;
  @Input() cssClass: string;

  newUrl: any;
  id: string;

  constructor(private imageCache: ImageCacheService,
              private store: StoreService,
              private themeSvc: ThemeService,
              private util: UtilService,
              private domSanitizer: DomSanitizer) { }

  ngOnInit() {}

  ngOnChanges() {
    this.init();
  }

  init(){
    if (!this.cssClass){this.cssClass = 'no-class'; }

    // can't cache on web platform
    if (this.store.appState.isWEB){
      console.log('no cache on web');
      this.newUrl = this.url;
      return;
    }

    // to get the ID for internal resources
    const urlType = this.checkUrlType();

    if (urlType === 'internal'){
      console.log('Internal URL');
      const paramsString = this.url;
      const searchParams = new URLSearchParams(paramsString);
      this.id = searchParams.get('id');
    } else if (urlType === 'external') {
      console.log('External URL');
      this.id = this.url;
    } else if (urlType === 'local') {
      console.log('Local Asset Url');
      this.id = 'local';
    }
    this.handleUrl();
  }

  // check if this url comes from our subsonic server or
  // an external source
  checkUrlType(){
    if (((this.url.indexOf(this.store.userState.internalServername) !== -1)|| (this.url.indexOf(this.store.userState.externalServername) !== -1))
        && (this.url.indexOf('/share/img/') === -1)){
      console.log('internal image URL');
      return 'internal';
    } else if (this.url.indexOf('assets/imgs') !== -1) {
      console.log('asset image URL');
      return 'local';
    } else {
      console.log('external image URL');
      return 'external';
    }
  }

  // handle whether the new url will be the original
  // or the cached result
  handleUrl(){
    console.log('TEST this.url '+this.url);
    let tempUrl: string;
    // don't try to cache local assets
    if (this.id === 'local') {
      return;
    }
    // check if image exists in cache (will add to cache if not)
    if (this.imageCache.check(this.id)){
      console.log('cached image found');
      tempUrl = this.imageCache.get(this.id);
    } else {
      console.log('no cached image found');
      tempUrl = this.url;
    }
    console.log('TEST!!! tempurl ' + tempUrl);
    this.newUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(
      tempUrl
    );
  }

  // generate a UUID for the image element ID
  getElementUID(){
    return this.util.getUUID();
  }

  // catch the load event for images and call the theme service
  // to create colour swatches. Only emits if detailImage is true
  emitImgLoadEvent(event: any){
      this.themeSvc.createSwatches(event);
  }

}
