import { ModalController } from '@ionic/angular';
import { PlayeractionsService } from 'src/app/services/playeractions.service';
import { MoreOptionsServiceService } from 'src/app/services/more-options-service.service';
import { StoreService } from 'src/app/services/store.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-play-queue-viewer',
  templateUrl: './play-queue-viewer.component.html',
  styleUrls: ['./play-queue-viewer.component.scss'],
})
export class PlayQueueViewerComponent implements OnInit {

  public infinitePlayQueue = [];

  constructor(public store: StoreService,
              public moreOptions: MoreOptionsServiceService,
              private modalCtrl: ModalController,
              private playerActions: PlayeractionsService) { }

  ngOnInit() {this.loadInitialData();}

  loadInitialData() {
    this.infinitePlayQueue = this.store.playerState.playQueue.slice(0,21);
  }

  loadData(event) {
    if(this.infinitePlayQueue.length < this.store.playerState.playQueue.length){
      this.infinitePlayQueue = this.infinitePlayQueue.concat(this.store.playerState.playQueue.slice(this.infinitePlayQueue.length,this.infinitePlayQueue.length+20));
      event.target.complete();
    }
    if(this.infinitePlayQueue.length === this.store.playerState.playQueue.length){
      event.target.disabled = true;
    }
  }

  async doReorder(event: any) {
    await this.moveArrayItem(this.getCurrentArray(), event.detail.from, event.detail.to);
    await this.moveArrayItem(this.infinitePlayQueue, event.detail.from, event.detail.to);
    event.detail.complete();
  }

  getCurrentArray(){
    return this.store.playerState.playQueue;
  }

  async moveArrayItem(array: any[], from: number, to: number): Promise<any> {
    const modified = array;
    modified.splice(to, 0, modified.splice(from, 1)[0]);
    Promise.resolve(modified);
  }

  // When the more button is tapped on a track
  async moreOptionsTrack(event: Event, trackItem: any, index: number){
    this.moreOptions.moreOptionsTrack(event, trackItem, 'player', index);
  }

  playItem(event: Event, track: any, index: number){
    this.playerActions.setCurrentTrackByUser(index);
  }

  close(event){
    this.modalCtrl.dismiss();
  }

}
