import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { EventsService } from './events.service';
import { StoreService } from './store.service';
import { SubsonicService } from './subsonic.service';
import { PersistenceService } from './persistence.service';
import { ImageCacheService } from './image-cache.service';
import { UtilService } from './util.service';
import { AppactionsService } from './appactions.service';
import { FileTransfer, FileTransferObject } from '@awesome-cordova-plugins/file-transfer/ngx';
import { File, FileEntry } from '@awesome-cordova-plugins/file/ngx';

@Injectable({
  providedIn: 'root'
})
export class MusicCacheService {

  public canDownload = true;
  public musicCacheQueue: any = [];
  public songCacheQueue: any = [];
  public downloadActive = false;
  public songDownloadActive = false;
  public currentDownloadItem: any = {};
  public currentDownloadItemTotal = 0;
  public currentDownloadItemProgress = 0;
  public currentDownloadItemProgressPcnt = 0;
  private tx: FileTransferObject;
  private queueWatcher: any;
  private queueWatcherActive = false;
  private songQueueWatcher: any;
  private songQueueWatcherActive = false;
  private resetRequested = false;

  constructor(private store: StoreService,
              private subsonic: SubsonicService,
              private appActions: AppactionsService,
              private platform: Platform,
              private events: EventsService,
              private persist: PersistenceService,
              private util: UtilService,
              private imageCache: ImageCacheService,
              private fileTransfer: FileTransfer,
              private file: File)
  {
    this.init();
  }

  init() {
    this.platform.ready().then(() => {
      if (this.platform.is('cordova')){
        this.tx = this.fileTransfer.create();
        // Progress events don't work on iOS and on Android they need to be debounced
        // this.tx.onProgress((event) => {console.log("TEST "+event)});
      }
    });
  }

  // process the actual download
  async doDownload(songItem: any, url: string): Promise<any>{
    let _uniqueFilename = songItem.id;
    let _cachedFileFormat;

    // Need to check the original file suffix for non-transcoded content
    if (this.store.userState.cacheFormat === 'raw') {
      _cachedFileFormat = songItem.suffix;
      _uniqueFilename = _uniqueFilename + '.' + _cachedFileFormat;
    } else {
      _cachedFileFormat = this.store.userState.cacheFormat;
      _uniqueFilename = _uniqueFilename + '.' + _cachedFileFormat;
    }
    let _returnItem: any;

    // will overwrite existing files by default
    await this.tx.download(url, this.persist.getMusicCacheDir() + _uniqueFilename, true)
      .then(async (result: FileEntry) => {
        console.log('download song success result');
        songItem.cachedFilename = _uniqueFilename;
        songItem.cachedFormat = _cachedFileFormat.toUpperCase();
        songItem.originalSize = songItem.size;
        //get the actual size on disk after transcoding/rate limit if any
        songItem.size = await this.getDownloadedSize(result);
        _returnItem = {status: 'success', data: songItem};
      })
      .catch((err) => {
        console.log('download song error result');
        console.log(err);
        _returnItem = {status: 'error', data: err};
      });
    return _returnItem;
  }

  async getDownloadedSize(fileEntry: FileEntry){
    return new Promise((resolve, reject) => {
      fileEntry.getMetadata(
        (success) => {resolve(success.size);},
        (err) => {reject(err);});
    });
  }

  // set when starting a collection download
  setDownloadActive(){
    this.downloadActive = true;
  }

  // set when finishing a collection download
  setDownloadNotActive(){
    this.downloadActive = false;
    this.currentDownloadItem = {};
  }

  // reset the download progress counters
  resetDownloadProgress(){
    this.currentDownloadItemTotal = 0;
    this.currentDownloadItemProgress = 0;
    this.currentDownloadItemProgressPcnt = 0;
  }

  // register completion of file download
  updateDownloadProgress(){
    this.currentDownloadItemProgress++;
    this.currentDownloadItemProgressPcnt = Math.floor((this.currentDownloadItemProgress / this.currentDownloadItemTotal * 100));
  }

  // set the total items for the current download
  setDownloadItemLength(length: number){
    this.currentDownloadItemTotal = length;
  }

  // check if an item is in the download queue or is the current
  // downloading item
  isDownloading(id: string|number){
    const _id = id.toString();
    if (this.currentDownloadItem.id === _id){
      return true;
    }
    for (const queueItem of this.musicCacheQueue){
      if (queueItem.item.id === _id){
        return true;
      }
    }
    return false;
  }

  // check if anything is downloading or queued
  updateHasPendingDownloads(){
    if (this.musicCacheQueue.length || this.currentDownloadItem.hasOwnProperty('id')){
      this.store.appState.hasPendingDownloads = true;
    } else {
      this.store.appState.hasPendingDownloads = false;
    }
  }

  // delete an item from the cache queue
  deleteQueuedItem(index: number){
    // grab the item id
    const _itemID = this.musicCacheQueue[index].item.id;
    // public the download cancelled event
    this.events.publish('cacheCancel', _itemID);
    if (this.store.appState.hasPendingDownloads){
      this.musicCacheQueue.splice(index, 1);
    }
  }

  // adhoc song cache requests that are not part of collections
  // used by the autocache service based on users settings
  watchSongQueue() {
    // if a song queue watcher is already active do nothing
    if (this.songQueueWatcherActive){
      return;
    }
    // create a song queue watcher
    else {
      console.log('starting song queue watcher');
      this.songQueueWatcherActive = true;
      this.songQueueWatcher = setInterval(() => {
        console.log('songCacheQueue has ' + this.songCacheQueue.length + ' items waiting');
        console.log(this.songCacheQueue);
        // if there are items waiting and nothing running process them
        if (this.songCacheQueue.length){
          // make sure there is enough space to download
          this.checkCanDownload(this.songCacheQueue[0].size);
          // no active download, proceed to process
          if (!this.songDownloadActive && this.canDownload){
            console.log('song cache queue processing next item');
            this.songDownloadActive = true;
            this.setCachedSong(this.songCacheQueue.shift(), true);
          }
          // active download in progress, wait
          else if (this.songDownloadActive) {
            console.log('song cache items waiting but download in progress');
          }
          // cache is full, silently cancel adhoc downloads
          else if (!this.canDownload){
            console.log('cache is full, discarding songCacheQueue');
            this.songCacheQueue = [];
          }
        }
        // there are no items waiting kill the queue watcher to save resources
        else {
          console.log('song cache queue is empty');
          this.stopWatchingSongQueue();
        }
      }, 10000);
    }
  }

  // stop watching the song queue
  stopWatchingSongQueue(){
    console.log('stopping song queue watcher');
    this.songQueueWatcherActive = false;
    clearInterval(this.songQueueWatcher);
  }

  // force restart of the song queue watcher
  resetSongQueueWatcher(){
    this.stopWatchingSongQueue();
    setTimeout(() => {
      this.watchSongQueue();
    }, 6000);
  }

  // watch and process the download queue
  // all downloads are queued through reqCacheItem
  watchQueue() {
    // if a watcher is already active do nothing
    if (this.queueWatcherActive){
      return;
    }
    // else create a watcher
    else {
      console.log('starting music cache queue watcher');
      this.queueWatcherActive = true;
      // stop sleep while downloads in progress
      this.appActions.setEnableScreenAlive();
      // create queue watcher
      this.queueWatcher = setInterval(() => {
        this.updateHasPendingDownloads();
        console.log('musicCacheQueue has ' + this.musicCacheQueue.length + ' items waiting');
        console.log(this.musicCacheQueue);
        // if there are items waiting and nothing running process them
        if (this.musicCacheQueue.length){
          // make sure there is enough space to download
          this.checkCanDownload(this.musicCacheQueue[0].item.size);
          // no active download, proceed to process
          if (!this.downloadActive && this.canDownload){
            console.log('musicCacheQueue processing next item');
            this.setDownloadActive();
            const _nextItem = this.musicCacheQueue.shift();
            if (_nextItem.type === 'album'){
              console.log('processing album item ' + _nextItem.item.id);
              // store a reference to the type in case we have to push it back to the queue
              _nextItem.item.type = 'album';
              this.setCachedAlbum(_nextItem.item);
              // this.currentDownloadItem = _nextItem.item;
            }
            else if (_nextItem.type === 'playlist'){
              console.log('processing playlist item ' + _nextItem.item.id);
              // store a reference to the type in case we have to push it back to the queue
              _nextItem.item.type = 'playlist';
              this.setCachedPlaylist(_nextItem.item);
              // this.currentDownloadItem = _nextItem.item;
            }
            else if (_nextItem.type === 'folder'){
              console.log('processing folder item ' + _nextItem.item.id);
              // store a reference to the type in case we have to push it back to the queue
              _nextItem.item.type = 'folder';
              this.setCachedFolder(_nextItem.item);
              // this.currentDownloadItem = _nextItem.item;
            }
            if (_nextItem.type === 'starred'){
              console.log('processing starred songs ' + _nextItem.item.id);
              // store a reference to the type in case we have to push it back to the queue
              _nextItem.item.type = 'starred';
              this.setCachedStarred(_nextItem.item);
              // this.currentDownloadItem = _nextItem.item;
            }
            console.log(_nextItem);
          }
          // there are items waiting but something is running then wait
          else if (this.downloadActive){
            console.log('download currently active, waiting');
          }
          // there are items waiting but cache is full
          else if (!this.canDownload){
            console.log('cache is full, waiting');
          }
        }
        // there are no items waiting kill the queue watcher to save resources
        else {
          console.log('music cache queue empty');
          this.stopWatchingQueue();
        }
      }, 1000);
    }
  }

  // stop watching the download queue
  stopWatchingQueue(){
    console.log('stopping music cache queue watcher');
    // disable screen keep alive now queue is empty
    this.appActions.setDisableScreenAlive();
    // disable the queue watcher
    this.queueWatcherActive = false;
    clearInterval(this.queueWatcher);
  }

  // force restart of the queue watcher
  resetQueueWatcher(){
    // make sure watcher is stopped
    this.stopWatchingQueue();
    // stop any running item download loops
    this.resetRequested = true;
    // push any current item back onto the queue
    if (this.currentDownloadItem.id){
      const _oldItem = this.util.copy(this.currentDownloadItem);
      const _newItem = {item: _oldItem, type: _oldItem.type};
      this.musicCacheQueue.unshift(_newItem);
    }
    // clean up the current status
    this.currentDownloadItem = {};
    this.resetDownloadProgress();
    this.setDownloadNotActive();
    this.updateHasPendingDownloads();

    // restart the queue watcher
    setTimeout(() => {
      this.resetRequested = false;
      this.watchQueue();
    }, 2000);
  }

  // check if the item we want to download will fit in the cache
  async checkCanDownload(itemSize: number){
    console.log('checkCanDownload');
    const _projectedSpace: number = this.store.musicCacheState.usedSpace + itemSize;
    if (_projectedSpace > this.store.userState.cacheMaxSize){
      console.log('cannot download');
      console.log(_projectedSpace + ' / ' + this.store.userState.cacheMaxSize);
      this.setCannotDownload();
      return false;
    }
    else {
      console.log('can download');
      console.log(_projectedSpace + ' / ' + this.store.userState.cacheMaxSize);
      this.setCanDownload();
      return true;
    }
  }

  // enable normal downloading
  setCanDownload(){
    this.canDownload = true;
  }

  // disable downloading
  setCannotDownload(){
    this.canDownload = false;
  }

  // check if there is sufficient free space to cache space
  // for the item size (size is in b)
  checkFreeCacheSpace(sizeInB: number){
    console.log('check free cache space');
    console.log('check free cache percentage used: ' +
    Math.floor(this.store.musicCacheState.usedSpace / this.store.userState.cacheMaxSize * 100));

    if ((this.store.musicCacheState.usedSpace + this.store.podcastCacheState.usedSpace + sizeInB) > (this.store.userState.cacheMaxSize - 10000000)) {
      console.log('check free cache space: no space available');
      return true;
    } else {
      console.log('check free cache space: space available');
      return false;
    }
  }

  // update used space
  updateUsedSpace(size: number, type: string){
    if (type === 'plus'){
      this.store.musicCacheState.usedSpace = this.store.musicCacheState.usedSpace + size;
    }
    if (type === 'minus'){
      this.store.musicCacheState.usedSpace = this.store.musicCacheState.usedSpace - size;
    }
    // update the percentage calculation for used cache space on change.
    this.store.updateCacheUsedPercentage();
    // persist
    this.store.saveMusicCacheState();
  }

  // set a cached song as used in an album/folder/playlist
  // adds usage tracking to the store.musicCache.cachedSongUse hash
  async setCachedSongUse(id: string|number, useID: string|number){
    console.log('setCachedSongUse called for: ' + id + ' in ' + useID);
    const _id = id.toString();
    const _useID = useID.toString();
    const _isUsed = await this.checkCachedSongUse(_id);
    if (_isUsed){
      if (this.store.musicCacheState.cachedSongUse[_id].indexOf(_useID) === -1){
        this.store.musicCacheState.cachedSongUse[_id].push(_useID);
        console.log('setCachedSongUse useID ' + useID + 'tracking added to existing list');
      } else {
        console.log('setCachedSongUse useID ' + useID + ' already tracked');
      }
    }
    else {
      this.store.musicCacheState.cachedSongUse[_id] = [_useID];
      console.log('setCachedSongUse useID ' + useID + 'tracking added to new list');
    }
    this.store.saveMusicCacheState();
    return;
  }

  // check if the cached song is used in an album/folder/playlist
  // checks for tracking in store.musicCache.cachedSongUse hash
  async checkCachedSongUse(id: string|number){
    console.log('checkCachedSongUse called for: ' + id);
    const _id = id.toString();
    if (this.store.musicCacheState.cachedSongUse.hasOwnProperty(_id)){
      console.log('checkCachedSongUse for ' + id + ' true');
      return true;
    }
    else {
      console.log('checkCachedSongUse for ' + id + ' false');
      return false;
    }
  }

  // delete a usage of a cached song in an album/folder/playlist
  // removes a tracking ref in store.musicCache.cachedSongUse hash
  // if that is the last tracking item, remove the key from the hash
  async delCachedSongUse(id: string|number, useID: string|number){
    console.log('delCachedSongUse called for: ' + id + ' in ' + useID);
    const _id = id.toString();
    const _useID = useID.toString();
    const isUsed = await this.checkCachedSongUse(_id);

    return new Promise((resolve) => {
      if (isUsed){
        this.store.musicCacheState.cachedSongUse[_id].splice(this.store.musicCacheState.cachedSongUse[_id].indexOf(_useID), 1);
        console.log('delCachedSongUse for ' + id + ' in useID ' + useID + ' removed tracking from list');
        if (this.store.musicCacheState.cachedSongUse[_id].length === 0){
          delete this.store.musicCacheState.cachedSongUse[_id];
          console.log('delCachedSongUse for ' + id + ' no more uses to track, removing list');
        }
      }
      this.store.saveMusicCacheState();
      resolve(true);
    });
  }

  // if a song exists in the cache get it's indexe for songs
  // return the index position in store.musicCache.songs
  async checkCachedSongIndex(songID: string|number){
    console.log('checkCachedSongIndex called for ' + songID);
    const _songID = songID.toString();
    const _length2 = this.store.musicCacheState.songs.length;
    let _returnIndex2: any;

    // check songs array
    if (_length2 === 0){
      console.log('checkCachedSongIndex ' + songID + ' songs cache is empty, not found -1');
      _returnIndex2 = -1;
    }
    for (let i = 0; i < _length2; i++){
      if (this.store.musicCacheState.songs[i].id === _songID){
        console.log('checkCachedSongIndex ' + songID + ' songs found at ' + i);
        _returnIndex2 = i;
        break;
      }
      else if (i === _length2 - 1){
        console.log('checkCachedSongIndex ' + songID + ' songs not found in cache -1');
        _returnIndex2 = -1;
      }
    }
    return {songsIndex: _returnIndex2};
  }

  // if an item (album / playlist / folder) exists in the cache
  // return it's index position in the respective store.musicCache arrays
  async checkCachedItemIndex(itemID: string|number, type: string){
    const _itemID = itemID.toString();
    let _returnIndex: number;
    let _array: any;

    if (type === 'album'){
      _array = this.store.musicCacheState.albums;
    }
    if (type === 'playlist'){
      _array = this.store.musicCacheState.playlists;
    }
    if (type === 'folder'){
      _array = this.store.musicCacheState.folders;
    }
    if (type === 'starred'){
      _array = this.store.musicCacheState.starredSongs;
    }
    const _length = _array.length;

    // no items in the list
    if (_length === 0){
      _returnIndex = -1;
    }

    // at least 1 item in the list
    for (let i = 0; i < _length; i++){
      if (_array[i].id === _itemID){
        _returnIndex = i;
        break;
      } else if (i === _length - 1){
        _returnIndex = -1;
      }
    }

    return {index: _returnIndex};
  }

  async checkNewStarredItemIndex(itemID: string|number, array: any){
    const _itemID = itemID.toString();
    const _array = array;
    const _length = this.store.subsonicState.starred2.song.length;
    let _returnIndex;

    // no items in the list
    if (_length === 0){
      _returnIndex = -1;
    }

    // at least 1 item in the list
    for (let i = 0; i < _length; i++){
      if (_array[i].id === _itemID){
        _returnIndex = i;
        break;
      } else if (i === _length - 1){
        _returnIndex = -1;
      }
    }

    return {index: _returnIndex};
  }

  // return an object with status success and data cached URL if item is found in cache
  // else return status error
  getCachedSongUrl(songID: number|string){
    const _songID = songID.toString();
    let _cachedUrl: string;

    if (this.store.musicCacheState.allFiles[_songID] !== undefined){
      _cachedUrl = this.persist.getMusicCacheDir() + this.store.musicCacheState.allFiles[_songID].cachedFilename;
      if (this.store.appState.isIOS && this.store.appState.isWKWEBVIEW){
        _cachedUrl = _cachedUrl.slice(7);
      }
      if (this.store.appState.isANDROID){
        _cachedUrl = this.persist.getMusicCacheDir() + this.store.musicCacheState.allFiles[_songID].cachedFilename;
      }
      return {status: 'success', data: _cachedUrl};
    }
    else {
      return {status: 'error', data: ''};
    }
  }

  // check if a song exists in the cache
  // all files in the cache are recorded in
  // store.musicCache.allFiles hashmap
  checkCachedSong(songID: string|number){
    console.log('checkCachedSong called for ' + songID);
    const _songID = songID.toString();

    if (this.store.musicCacheState.allFiles[_songID] !== undefined){
      return true;
    }
    else {
      return false;
    }
  }

  // check if an album exists in the cache
  // checks the store.musicCache.albums array
  checkCachedAlbum(albumID: string|number){
    console.log('checkCachedAlbum called for ' + albumID);
    const _albumID = albumID.toString();
    const _length = this.store.musicCacheState.albums.length;

    if (_length === 0){
      console.log('checkCachedAlbum ' + albumID + ' cache is empty, not found');
      return false;
    }
    for (let i = 0; i < _length; i++){
      if (this.store.musicCacheState.albums[i].id === _albumID){
        console.log('checkCachedAlbum ' + albumID + ' found in cache');
        return true;
      }
      else if (i === _length - 1){
        console.log('checkCachedAlbum ' + albumID + ' not found in cache');
        return false;
      }
    }
  }

  // check if an album exists in the cache
  // checks the store.musicCache.playlists array
  checkCachedPlaylist(playlistID: string|number){
    console.log('checkCachedPlaylist called for ' + playlistID);
    const _playlistID = playlistID.toString();
    const _length = this.store.musicCacheState.playlists.length;

    if (_length === 0){
      console.log('checkCachedPlaylist ' + playlistID + ' cache is empty, not found');
      return false;
    }
    for (let i = 0; i < _length; i++){
      if (this.store.musicCacheState.playlists[i].id === _playlistID){
        console.log('checkCachedPlaylist ' + _playlistID + ' found in cache');
        return true;
      }
      else if (i === _length - 1){
        console.log('checkCachedPlaylist ' + _playlistID + ' not found in cache');
        return false;
      }
    }
  }

  // check if an album exists in the cache
  // checks the store.musicCache.folders array
  checkCachedFolder(folderID: string|number){
    console.log('checkCachedFolder called for ' + folderID);
    const _folderID = folderID.toString();
    const _length = this.store.musicCacheState.folders.length;

    if (_length === 0){
      console.log('checkCachedFolder ' + _folderID + ' cache is empty, not found');
      return false;
    }
    for (let i = 0; i < _length; i++){
      if (this.store.musicCacheState.albums[i].id === _folderID){
        console.log('checkCachedFolder ' + _folderID + ' found in cache');
        return true;
      }
      else if (i === _length - 1){
        console.log('checkCachedFolder ' + _folderID + ' not found in cache');
        return false;
      }
    }
  }

  // check if the starred songs are already cached
  // checks the store.musicCache.starredCacheEnabled value
  // check if an album exists in the cache
  // checks the store.musicCache.albums array
  checkCachedStarred(starredID: string|number){
    console.log('checkCachedStarred called for ' + starredID);
    const _starredID = starredID.toString();
    const _length = this.store.musicCacheState.starredSongs.length;

    if (_length === 0){
      console.log('checkCachedStarred ' + starredID + ' cache is empty, not found');
      return false;
    }
    for (let i = 0; i < _length; i++){
      if (this.store.musicCacheState.starredSongs[i].id === _starredID){
        console.log('checkCachedStarred ' + starredID + ' found in cache');
        return true;
      }
      else if (i === _length - 1){
        console.log('checkCachedStarred ' + starredID + ' not found in cache');
        return false;
      }
    }
  }

  // request to add an item to the cache
  // adds the item to the download queue
  // accepts an album / playlist / folder item
  // requires the type of collection to be specified
  reqCacheItem(item: any, type: string){
    let _length: number;
    let _itemSize = 0;
    // check that params are valid
    if (item === undefined || type === undefined){
      console.log('reqCacheItem: error missing arguments');
      return;
    }
    // check item is not currently downloading
    if (this.currentDownloadItem.id === item.id){
      console.log('reqCacheItem: already downloading this item');
      return;
    }
    // check item is not already queued
    for (let i = 0; i < this.musicCacheQueue.length; i++){
      if (this.musicCacheQueue[i].item.id === item.id){
        console.log('reqCacheItem: refusing to add duplicate item to queue');
        return;
      }
    }

    if (type === 'album'){
      _length = item.song.length;
      for (let i = 0; i < _length; i++){
        _itemSize = _itemSize + item.song[i].size;
      }
    }
    if (type === 'playlist'){
      _length = item.entry.length;
      for (let i = 0; i < _length; i++){
        _itemSize = _itemSize + item.entry[i].size;
      }
    }
    if (type === 'folder'){
      _length = item.child.length;
      for (let i = 0; i < _length; i++){
        _itemSize = _itemSize + item.child[i].size;
      }
    }
    if (type === 'starred'){
      _length = item.song.length;
      for (let i = 0; i < _length; i++){
        _itemSize = _itemSize + item.song[i].size;
      }
    }

    console.log('reqCacheItem: item ' + item.id + 'added to musicCacheQueue');
    item.type = type;
    item.size = _itemSize;
    this.musicCacheQueue.push({item, type});
    this.watchQueue();
    this.updateHasPendingDownloads();
    return;
  }

  // request to cache an individual song
  // used by the autocache mechanism to cache
  // ahead during adhoc playback
  reqCacheSong(songItem: any){
    if (songItem === undefined){
      console.log('bad song item');
      return;
    }
    console.log('reqCacheSong ' + songItem.id);
    this.songCacheQueue.push(songItem);
    this.watchSongQueue();
    return;
  }

  // add item to cached list, checking for duplicates
  async addItemToCachedList(item: any, type: string){
    let _array: any;
    let _itemFound = false;
    if (type === 'album'){
      _array = this.store.musicCacheState.albums;
    }
    if (type === 'playlist'){
      _array = this.store.musicCacheState.playlists;
    }
    if (type === 'folder'){
      _array = this.store.musicCacheState.folders;
    }
    if (type === 'starred'){
      _array = this.store.musicCacheState.starredSongs;
    }
    const _length = _array.length;

    // first item in the array!
    if (_length === 0){
      console.log('adding first item in cached list');
      _array.push(item);
      return;
    }

    // if there is already at least 1 item in the array
    for (let i = 0; i < _length; i++){
      // replace existing item
      if (item.id === _array[i].id){
        console.log('replacing existing item in cached list');
        _array.splice(i, 1, item);
        _itemFound = true;
        this.store.saveMusicCacheState();
        return;
      }
      // add new item
      if (i === _length - 1 && !_itemFound){
        console.log('adding new item to cached List');
        _array.push(item);
        this.store.saveMusicCacheState();
        return;
      }
    }
  }

  // add a song file to the cache
  // returns a response with status success or error and
  // data songItem or data error object
  // A successful response will include the cachedFilename key in the songItem
  // indicating where the file is saved.
  // this is used for individual song caching and by the collection caching calls.
  // if this is an adhoc cache request from autocache or similar set autocache to true
  async setCachedSong(songItem: any, autocache?: boolean){
    const _songID = songItem.id.toString();
    let _maxBitRate = 0;
    let _cachedSongItem: any;
    let _cachedSongItemIndex: any;
    const _isUsed = await this.checkCachedSongUse(_songID);

    // Set sane defaults when there is no user limit
    //
    if(this.store.userState.cacheFormat === 'raw' && (this.store.userState.streamMaxBitrate === 0)){
      _maxBitRate = 0;
    }

    if(this.store.userState.cacheFormat === 'opus' && (this.store.userState.streamMaxBitrate === 0)){
      _maxBitRate = 128;
    }

    if(this.store.userState.cacheFormat === 'mp3' && (this.store.userState.streamMaxBitrate === 0)){
      _maxBitRate = 320;
    }

    if (this.checkCachedSong(_songID)){
      console.log('setCachedSong ' + songItem.id + ' file already in cache');
      _cachedSongItemIndex = await this.checkCachedSongIndex(_songID);
      // as it is now used in a collection remove from the individual songs array
      if (_isUsed && _cachedSongItemIndex.songsIndex > -1){
        console.log('setCachedSong removing ' + songItem.id + ' from songs list as it is now used in a collection');
        this.store.musicCacheState.songs.splice(_cachedSongItemIndex.songsIndex, 1);
      }
      // compile return item
      _cachedSongItem = {status: 'success', data: this.util.copy(this.store.musicCacheState.allFiles[_songID])};
      this.store.saveMusicCacheState();

      // if these are autocache items update the songDownloadActive state
      if (autocache){
        this.songDownloadActive = false;
      }
      return _cachedSongItem;
    }
    else {
      console.log('setCachedSong ' + songItem.id + ' start to cache');
      // make sure we have offline coverart
      await this.imageCache.set(songItem.coverArt, false);
      // then proceed to download
      _cachedSongItem = await this.doDownload(songItem, this.subsonic.stream(_songID, _maxBitRate, this.store.userState.cacheFormat));
      // if download success
      if (_cachedSongItem.status === 'success'){
        console.log('setCachedSong ' + songItem.id + 'file added to cache');

        // update used space tracking
        this.updateUsedSpace(songItem.size, 'plus');

        // if the song is used in a collection add to allFiles only
        if (_isUsed){
          console.log('setCachedSong ' + _songID + ' is used in an album/folder/playlist only add to allFiles list');
          this.store.musicCacheState.allFiles[_songID] = _cachedSongItem.data;
        }
        // if the song is NOT used in a collection add to allFiles & individual songs
        else {
          console.log('setCachedSong ' + _songID + ' is not used in an album/folder/playlist add to allFiles & Songs lists');
          this.store.musicCacheState.allFiles[_songID] = _cachedSongItem.data;
          this.store.musicCacheState.songs.push(_cachedSongItem.data);
        }
        // persist and return
        this.store.saveMusicCacheState();

        // if these are autocache items update the songDownloadActive state
        if (autocache){
          this.songDownloadActive = false;
        }

        return this.util.copy(_cachedSongItem);
      }
      // if download failed
      else {
        console.log('setCachedSong ' + _songID + ' could not be added to cache');
        this.store.saveMusicCacheState();

        // if these are autocache items update the songDownloadActive state
        if (autocache){
          this.songDownloadActive = false;
        }
        return this.util.copy(_cachedSongItem);
      }
    }
  }

  // add an album to the cache
  async setCachedAlbum(albumItem: any){
    console.log('setCahedAlbum called');
    console.log(albumItem);
    // get the coverart for the album
    this.imageCache.set(albumItem.coverArt, false);
    // set this as the current download item
    this.currentDownloadItem = this.util.copy(albumItem);
    this.resetDownloadProgress();
    this.setDownloadItemLength(albumItem.song.length);
    // temporary array to hold our cached songItems
    const _cachedArray: any = [];

    // cache each song
    for (const item of albumItem.song){
      // break out and stop if reset is requested
      if (this.resetRequested){
        return;
      }
      await this.setCachedSongUse(item.id, albumItem.id);
      const _cachedItem = await this.setCachedSong(item);
      if (_cachedItem.status === 'success'){
        _cachedArray.push(_cachedItem.data);
      }
      else{
        console.log('error caching file for album, clean up');
        // the file wasn't cached so remove the usage reference
        await this.delCachedSongUse(item.id, albumItem.id);
      }
      // slight delay as very rapid disk access can cause issues
      await this.util.addDelay(10);
      // update the download progress tracker
      this.updateDownloadProgress();
    }
    // potentially handle partially cached result in future
    if (_cachedArray.length < albumItem.song.length){
      console.log('some tracks failed to cache');
    }
    // all items failed to cache
    if (_cachedArray.length === 0){
      this.events.publish('cacheFailure', albumItem);
    }
    // some or all items cached proceed
    else {
      // replace the original song array with an updated one with cached filenames
      albumItem.song = _cachedArray;
      // set the cachedFormat of the files onto the album item for reference
      // handle old cached files that do not have cachedFormat property
      if(albumItem.song[0].cachedFormat){
        albumItem.cachedFormat = albumItem.song[0].cachedFormat;
      } else {
        albumItem.cachedFormat = 'MP3';
      }

      // add the album to the cached albums list
      await this.addItemToCachedList(this.util.copy(albumItem), 'album');
      // publish the download complete event in case someone is waiting
      this.events.publish('cacheComplete', albumItem.id);
    }
    // set the download as inactive
    this.setDownloadNotActive();
    // update the pending download status;
    this.updateHasPendingDownloads();
    // persist
    this.store.saveMusicCacheState();
    // we're done here
    return;
  }

  // add a playlist to the cache
  async setCachedPlaylist(playlistItem: any){
    console.log('setCachedPlaylist called');
    console.log(playlistItem);
    // get the coverart for the playlist itself
    this.imageCache.set(playlistItem.coverArt, false);
    // set this as the current download item
    this.currentDownloadItem = playlistItem;
    this.resetDownloadProgress();
    this.setDownloadItemLength(playlistItem.entry.length);
    // temporary array to hold our cached songItems
    const _cachedArray: any = [];

    // cache each song
    for (const item of playlistItem.entry){
      // break out and stop if reset is requested
      if (this.resetRequested){
        return;
      }
      await this.setCachedSongUse(item.id, playlistItem.id);
      const _cachedItem = await this.setCachedSong(item);
      if (_cachedItem.status === 'success'){
        _cachedArray.push(_cachedItem.data);
      }
      else{
        console.log('error caching file for playlist, clean up');
        // the file wasn't cached so remove the usage reference
        await this.delCachedSongUse(item.id, playlistItem.id);
      }
      // update the download progress tracker
      this.updateDownloadProgress();
    }
    // potentially handle partially cached result in future
    if (_cachedArray.length < playlistItem.entry.length){
      console.log('some tracks failed to cache');
    }
    // all items failed to cache
    if (_cachedArray.length === 0){
      this.events.publish('cacheFailure', playlistItem);
    }
    // some or all items cached proceed
    else {
      // replace the original song array with an updated one with cached filenames
      playlistItem.entry = _cachedArray;

      // set the cachedFormat of the files onto the album item for reference
      // handle old cached files that do not have cachedFormat property
      if(playlistItem.entry[0].cachedFormat){
        playlistItem.cachedFormat = playlistItem.entry[0].cachedFormat;
      } else {
        playlistItem.cachedFormat = 'MP3';
      }

      // add the album to the cached albums list
      await this.addItemToCachedList(this.util.copy(playlistItem), 'playlist');
      // publish the download complete event in case someone is waiting
      this.events.publish('cacheComplete', playlistItem.id);
    }
    // set the download as inactive
    this.setDownloadNotActive();
    // update the pending download status;
    this.updateHasPendingDownloads();
    // persist
    this.store.saveMusicCacheState();
    // we're done here
    return;
  }

  // add a folder to the cache
  async setCachedFolder(folderItem: any){
    console.log('setCachedFolder called');
    console.log(folderItem);
    // get the coverart for the folder
    this.imageCache.set(folderItem.id, false);
    // set this as the current download item
    this.currentDownloadItem = folderItem;
    this.resetDownloadProgress();
    this.setDownloadItemLength(folderItem.child.length);
    // temporary array to hold our cached songItems
    const _cachedArray: any = [];

    // cache each song
    for (const item of folderItem.child){
      // break out and stop if reset is requested
      if (this.resetRequested){
        return;
      }
      await this.setCachedSongUse(item.id, folderItem.id);
      const _cachedItem = await this.setCachedSong(item);
      if (_cachedItem.status === 'success'){
        _cachedArray.push(_cachedItem.data);
      }
      else{
        console.log('error caching file for folder, clean up');
        // the file wasn't cached so remove the usage reference
        await this.delCachedSongUse(item.id, folderItem.id);
      }
      // currently not handling failed downloads TODO: clean up failed items
      this.updateDownloadProgress();
    }
    // potentially handle partially cached result in future
    if (_cachedArray.length < folderItem.child.length){
      console.log('some tracks failed to cache');
    }
    // all items failed to cache
    if (_cachedArray.length === 0){
      this.events.publish('cacheFailure', folderItem);
    }
    // some or all items cached proceed
    else {
      // replace the original song array with an updated one with cached filenames
      folderItem.child = _cachedArray;
      // set the cachedFormat of the files onto the album item for reference
      // handle old cached files that do not have cachedFormat property
      if(folderItem.child[0].cachedFormat){
        folderItem.cachedFormat = folderItem.child[0].cachedFormat;
      } else {
        folderItem.cachedFormat = 'MP3';
      }

      // add the album to the cached albums list
      await this.addItemToCachedList(this.util.copy(folderItem), 'folder');
      // publish the download complete event in case someone is waiting
      this.events.publish('cacheComplete', folderItem.id);
    }
    // set the download as inactive
    this.setDownloadNotActive();
    // update the pending download status;
    this.updateHasPendingDownloads();
    // persist
    this.store.saveMusicCacheState();
    // we're done here
    return;
  }

    // add an album to the cache
    async setCachedStarred(starredItem: any){
      console.log('setCahedStarred called');
      console.log(starredItem);

      // set this as the current download item
      this.currentDownloadItem = this.util.copy(starredItem);
      this.resetDownloadProgress();
      this.setDownloadItemLength(starredItem.song.length);
      // temporary array to hold our cached songItems
      const _cachedArray: any = [];

      // cache each song
      for (const item of starredItem.song){
        // break out and stop if reset is requested
        if (this.resetRequested){
          return;
        }
        await this.setCachedSongUse(item.id, starredItem.id);
        const _cachedItem = await this.setCachedSong(item);
        if (_cachedItem.status === 'success'){
          _cachedArray.push(_cachedItem.data);
        }
        else{
          console.log('error caching file for album, clean up');
          // the file wasn't cached so remove the usage reference
          await this.delCachedSongUse(item.id, starredItem.id);
        }
        // slight delay as very rapid disk access can cause issues
        await this.util.addDelay(10);
        // update the download progress tracker
        this.updateDownloadProgress();
      }
      // potentially handle partially cached result in future
      if (_cachedArray.length < starredItem.song.length){
        console.log('some tracks failed to cache');
      }
      // all items failed to cache
      if (_cachedArray.length === 0){
        this.events.publish('cacheFailure', starredItem);
      }
      // some or all items cached proceed
      else {
        // replace the original song array with an updated one with cached filenames
        starredItem.song = _cachedArray;

        // add the album to the cached albums list
        await this.addItemToCachedList(this.util.copy(starredItem), 'starred');
        // publish the download complete event in case someone is waiting
        this.events.publish('cacheComplete', starredItem.id);
      }
      // set the download as inactive
      this.setDownloadNotActive();
      // update the pending download status;
      this.updateHasPendingDownloads();
      // persist
      this.store.saveMusicCacheState();
      // we're done here
      return;
    }

  // Updated cached starredSongs as we keep it in sync
  async updateCachedStarred(): Promise<any> {
    // starred songs are not cached, do nothing
    if(this.store.musicCacheState.starredSongs.length < 1) {
      return;
    }

    // get a copy of the cached starred songs array
    const array1 = this.store.musicCacheState.starredSongs[0].song;
    // get a cooy of the latest starred songs array
    const array2 = this.util.copy(this.store.subsonicState.starred2.song);

    // function to get list of items in array1 but not array2
    // TODO move to util service
    const exclude = (arr1, arr2) => arr1.filter(o1 => arr2.map(o2 => o2.id).indexOf(o1.id) === -1);

    const songsToRemove = exclude(array1, array2);
    const songsToAdd = exclude(array2, array1);

    // Process removal if any
    if (songsToRemove.length) {
      for (let i=0; i< songsToRemove.length; i++){
        await this.delCachedSongUse(songsToRemove[i].id, 'starred');
        await this.delCachedSong(songsToRemove[i]);
        const index = await this.checkNewStarredItemIndex(songsToRemove[i].id, array1);
        if(index.index !== -1){
          this.store.musicCacheState.starredSongs[0].size = this.store.musicCacheState.starredSongs[0].size - songsToRemove[i].size;
          this.updateUsedSpace(songsToRemove[i].size, 'minus');
          array1.splice(index.index, 1);
        }
      }
    }

    // Process addition if any
    if(songsToAdd.length) {
      for (let i=0; i< songsToAdd.length; i++){
        await this.setCachedSongUse(songsToAdd[i].id, 'starred');
        const _cachedItem = await this.setCachedSong(songsToAdd[i]);
        if (_cachedItem.status === 'success'){
          const index = await this.checkNewStarredItemIndex(songsToAdd[i].id, array2);
          if(index.index !== -1){
            this.store.musicCacheState.starredSongs[0].size = this.store.musicCacheState.starredSongs[0].size + songsToAdd[i].size;
            this.updateUsedSpace(songsToAdd[i].size, 'plus');
            array1.splice(index.index, 0, _cachedItem.data);
          }
        }
      }
    }

    //this.store.musicCacheState.starredSongs[0] = array1;
    this.store.saveMusicCacheState();
  }

  // delete a cached song from the cache
  async delCachedSong(songItem: any){
    console.log('delCachedSong called for id: ' + songItem.id);
    let _returnItem: any;
    const _isUsed = await this.checkCachedSongUse(songItem.id);

    // if the file is still used in another collection don't delete
    if (_isUsed){
      console.log('delCachedSong item ' + songItem.id + ' still in use do not remove file');
      _returnItem = {status: 'success', data: {}};
      return _returnItem;
    }

    // otherwise let's delete the file and remove it's references
    const _cacheIndexes = await this.checkCachedSongIndex(songItem.id);
    const _result = await this.persist.delMusicFile(songItem.cachedFilename);

    // if the file was successfully removed
    if (_result.status === 'success'){
      // update used space tracking
      this.updateUsedSpace(songItem.size, 'minus');

      console.log('delCachedSong file ' + songItem.cachedFilename + ' deleted for ID: ' + songItem.id);
      // remove it from the allFiles hashmap
      if (this.store.musicCacheState.allFiles[songItem.id] !== undefined){
        console.log('delCachedSong removed reference to id: ' + songItem.id + 'from allFiles list');
        delete this.store.musicCacheState.allFiles[songItem.id];
      }
      // remove it from the songs index
      if (_cacheIndexes.songsIndex > -1){
        console.log('delCachedSong removed reference to id: ' + songItem.id + 'from songs list');
        this.store.musicCacheState.songs.splice(_cacheIndexes.songsIndex, 1);
      }
      // return success with deleted file reference as data
      _returnItem = {status: 'success', data: _result.data};
    }
    // the file was not successfully removed so we should not remove it's references
    else {
      console.log('delCachedSong file ' + songItem.cachedFilename + ' could not be deleted for ID: ' + songItem.id);
      // return error with delete operation error as data
      _returnItem = {status: 'error', data: _result.data};
    }
    // persist the state
    this.store.saveMusicCacheState();
    return _returnItem;
  }

  // delete a cached collection item from the cache
  async delCachedItem(itemID: string|number, type: string){
    console.log('delCachedItem ' + type + ' called for: ' + itemID);
    console.log(itemID);
    const _itemIndex = await this.checkCachedItemIndex(itemID, type);
    console.log(_itemIndex);
    let _array: any;
    let _cacheArray: any;
    // thats a mess it's not in the index
    if (_itemIndex.index === -1){
      console.log('item not found index');
      return;
    }
    // handle type specifics
    if (type === 'album'){
      _array = this.util.copy(this.store.musicCacheState.albums[_itemIndex.index].song);
      _cacheArray = this.store.musicCacheState.albums;
    }
    if (type === 'playlist'){
      _array = this.util.copy(this.store.musicCacheState.playlists[_itemIndex.index].entry);
      _cacheArray = this.store.musicCacheState.playlists;
    }
    if (type === 'folder'){
      _array = this.util.copy(this.store.musicCacheState.folders[_itemIndex.index].child);
      _cacheArray = this.store.musicCacheState.folders;
    }
    if (type === 'starred'){
      _array = this.util.copy(this.store.musicCacheState.starredSongs[_itemIndex.index].song);
      _cacheArray = this.store.musicCacheState.starredSongs;
    }

    // delete each song
    for (const item of _array){
      // clear the cached song use
      await this.delCachedSongUse(item.id, item.id);
      // process delete of the cached file
      const _cachedItem = await this.delCachedSong(item);
      if (_cachedItem.status === 'success'){
        console.log('delCachedItem ' + type + ' item successs');
      }
      else{
        console.log('delCachedItem ' + type + ' item error');
        // TODO take some failure action and clean up
      }
    }
    // remove the album from the cached albums list
    _cacheArray.splice(_itemIndex.index, 1);
    // save state
    this.store.saveMusicCacheState();
    // we're done here
    return;
  }

  // completely clear the music cache
  async clearCache(){
    return this.store.clearMusicCacheState();
  }

  // clear all items of type album, playlist, folder, song
  async clearItemList(type: string){
    let _array: any;

    // make a copy of the array we will be emptying
    if (type === 'album'){
      _array = this.util.copy(this.store.musicCacheState.albums);
    }
    if (type === 'playlist'){
      _array = this.util.copy(this.store.musicCacheState.playlists);
    }
    if (type === 'folder'){
      _array = this.util.copy(this.store.musicCacheState.folders);
    }
    if (type === 'song'){
      _array = this.util.copy(this.store.musicCacheState.songs);
    }
    if (type === 'starred'){
      _array = this.util.copy(this.store.musicCacheState.starredSongs);
    }
    const _length = _array.length;

    // nothing to do if list is empty
    if (_length === 0){
      return;
    }

    // display loader
    this.appActions.showLoading('clearing offline ' + type + 's.  Please wait...');
    // keep the display alive during long delete operations
    this.appActions.setEnableScreenAlive();
    // process songs
    if (type === 'song'){
      for (let i = 0; i < _length; i++){
        await this.delCachedSong(_array[i]);
        // slight delay as very rapid disk access can cause issues
        await this.util.addDelay(100);
        if (i === _length - 1){
          this.appActions.hideLoading();
          this.appActions.setDisableScreenAlive();
          return;
        }
      }
    }
    // process collections
    else {
      for (let i = 0; i < _length; i++){
        await this.delCachedItem(_array[i].id, type);
        // slight delay as very rapid disk access can cause issues
        await this.util.addDelay(100);
        if (i === _length - 1){
          this.appActions.hideLoading();
          this.appActions.setDisableScreenAlive();
          return;
        }
      }
    }
  }
}
