import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MusicbrainzService {

  private apiKey = '';
  private apiBaseUrl = 'https://musicbrainz.org/ws/2/artist/';
  private webBaseUrl = 'https://musicbrainz.org/arist/';
  private apiFormat = 'json';

  constructor(private http: HttpClient) { }

  // Returns the best matching artist as the only entry in the array of artists
  async getBestMatchArtistIDByName(artistName: string): Promise<any> {
    const url = encodeURI(this.apiBaseUrl + '?query=artist:' + artistName + '&fmt=' + this.apiFormat + '&limit=1');
    let response = {};

    try {
      response = await this.http.get(url).toPromise();
    }
    catch (e) {
      console.log(e);
      response = {};
    }
    finally {
      console.log('*** MUSICBRAINZ ***');
      console.log(response);
      return Promise.resolve(response);
    }
  }

  // Returns array of matching artists
  // TODO clean and format the results to only what is needed
  async getAllMatchingArtistsByName(artistName: string) {
    const url = encodeURI(this.apiBaseUrl + '?query=artist:' + artistName + '&fmt=' + this.apiFormat + '&limit=100');
    let response = {};

    try {
      response = await this.http.get(url).toPromise();
    }
    catch (e) {
      console.log(e);
      response = {};
    }
    finally {
      console.log('*** MUSICBRAINZ ***');
      console.log(response);
      return Promise.resolve(response);
    }
  }

  async getArtistBioByID(artistMBID: string) {
    const url = encodeURI(this.webBaseUrl + artistMBID + '/wikipedia-extract');
    let response = {};

    try {
      response = await this.http.get(url).toPromise();
    }
    catch (e) {
      console.log(e);
      response = {};
    }
    finally {
      console.log('*** MUSICBRAINZ BIO ***');
      console.log(response);
      return Promise.resolve(response);
    }
  }
}
