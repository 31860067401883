import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { EventsService } from './events.service';
import { UtilService } from './util.service';
import { Media, MediaObject } from '@awesome-cordova-plugins/media/ngx';

declare global {
  interface Window {
    audioContext: typeof AudioContext;
    webkitAudioContext: typeof AudioContext;
  }
}

@Injectable({
  providedIn: 'root'
})
export class MediaplayerService {

  // media type = native or html
  public mediaType = 'native';
  // HTML5 audio tag object
  public audioTag: HTMLAudioElement;
  // HTML5 audio tag needs user interaction to play
  public audioTagCanPlay = false;
  // Native Media Plugin object
  public nativeMedia: MediaObject;
  // if we want to use web audio api
  // private audioCtx = new (window.AudioContext || window.webkitAudioContext)();
  // private elementSrcNode;
  // private gainNode;
  // private analyserNode;

  constructor(private media: Media,
              private events: EventsService,
              private util: UtilService,
              private platform: Platform)
  {
    this.init();
  }

  async init() {
    const ready = await this.platform.ready();
    // use native audio if available else use html audio
    if (this.platform.is('cordova')){
      console.log('using native audio');
      this.mediaType = 'native';
    } else {
      console.log('using html audio');
      this.mediaType = 'html';
    }
    if (this.mediaType === 'html'){
      this.audioTag = new Audio();
      this.audioTag.crossOrigin = 'anonymous';
      this.audioTag.controls = true;
      this.audioTag.autoplay = false;
      this.audioTag.preload = 'none'; // auto | metadata | none

      // this.elementSrcNode = this.audioCtx.createMediaElementSource(this.audioTag);
      // this.gainNode = this.audioCtx.createGain();
      // this.elementSrcNode.connect(this.gainNode);
      // this.gainNode.connect(this.audioCtx.destination);
    }
    if (this.mediaType === 'native'){
      // perform any native specific init actions
    }
    // make sure all is ready before returning
    return this.util.addDelay(1000);
  }

  canHTMLAudioPlay() {
    return this.audioTagCanPlay;
  }

  async setSource(sourceUrl: string) {
    if (this.mediaType === 'html'){
      this.audioTag.src = sourceUrl;
      this.audioTag.title = 'SubStreamer';
      return;
    }
    if (this.mediaType === 'native'){
      if (this.nativeMedia !== undefined){
        await this.nativeMedia.release();
      }
      this.nativeMedia = await this.media.create(sourceUrl);
      this.nativeMedia.onStatusUpdate.subscribe((status) => {this.broadcastNativeMediaEvents({type: 'status', value: status}); });
      this.nativeMedia.onSuccess.subscribe(() => {this.broadcastNativeMediaEvents({type: 'complete', value: 'playbackComplete'}); });
      this.nativeMedia.onError.subscribe((err) => {this.broadcastNativeMediaEvents({type: 'error', value: err}); });
      console.log(this.nativeMedia);
      return;
    }
  }

  // take native events from observer and broadcast
  // keeps behaviour similar between html and native
  broadcastNativeMediaEvents(event){
    console.log(event);
    if (event.type === 'complete'){
      console.log('complete: playback complete');
      this.events.publish('ended', {});
    }
    if (event.type === 'error'){
      console.log('error: media error');
      console.log(event.value);
      this.events.publish('error', event.value);
    }
    if (event.type === 'status'){
      if (event.value === 1){
        console.log('status: 1 unknown');
        this.events.publish('unknown', {});
      }
      if (event.value === 2){
        console.log('status: 2 playing');
        this.events.publish('playing', {});
      }
      if (event.value === 3){
        console.log('status: 3 paused');
        this.events.publish('pause', {});
      }
      if (event.value === 4){
        console.log('status: 4 stopped');
        this.events.publish('stopped', {});
      }
    }
  }

  play() {
    if (this.mediaType === 'html'){
      this.audioTag.play();
    }
    if (this.mediaType === 'native'){
      this.nativeMedia.play();
    }
  }

  pause() {
    if (this.mediaType === 'html'){
      this.audioTag.pause();
    }
    if (this.mediaType === 'native'){
      this.nativeMedia.pause();
    }
  }

  togglePlayPause() {
    if (this.mediaType === 'html'){
      if (this.audioTag.paused){
        this.audioTag.play();
      } else if (this.audioTag.playbackRate > 0){
        this.audioTag.pause();
      }
    }
    if (this.mediaType === 'native'){
      // need to add get playbackrate or similar for native
    }
  }

  async getCurrentPosition() {
    if (this.mediaType === 'html'){
      return this.audioTag.currentTime;
    }
    if (this.mediaType === 'native'){
      return await this.nativeMedia.getCurrentPosition();
    }
  }

  async seekTo(positionInS: number) {
    if (this.mediaType === 'html'){
      this.audioTag.currentTime = positionInS;
      return this.audioTag.currentTime;
    }
    if (this.mediaType === 'native'){
      this.nativeMedia.seekTo(positionInS * 1000);
      return await this.nativeMedia.getCurrentPosition();
    }
  }

  async getDuration() {
    if (this.mediaType === 'html'){
      if (isNaN(this.audioTag.duration)){
        return 0;
      } else {
        return this.audioTag.duration;
      }
    }
    if (this.mediaType === 'native'){
      return await this.nativeMedia.getDuration();
    }

  }

  getRate() {
    if (this.mediaType === 'html'){
      return this.audioTag.playbackRate;
    }
    if (this.mediaType === 'native'){
      // native get rate function
    }
  }

  setRate(rateAsNumber: number) {
    if (this.mediaType === 'html'){
      this.audioTag.playbackRate = rateAsNumber;
      this.audioTag.defaultPlaybackRate = rateAsNumber;
      return this.audioTag.playbackRate;
    }
    if (this.mediaType === 'native'){
      this.nativeMedia.setRate(rateAsNumber);
    }
  }

  setVolume(vol: number) {
    if (this.mediaType === 'html') {
      this.audioTag.volume = vol;
      // this.gainNode.gain.setValueAtTime(vol, this.audioCtx.currentTime);
    }
    if (this.mediaType === 'native') {
      this.nativeMedia.setVolume(vol);
    }
  }

}
