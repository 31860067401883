import { PlayQueueViewerComponent } from './../play-queue-viewer/play-queue-viewer.component';
import { MoreOptionsServiceService } from 'src/app/services/more-options-service.service';
import { SubsonicactionsService } from './../../services/subsonicactions.service';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { StoreService } from '../../services/store.service';
import { PlayeractionsService } from '../../services/playeractions.service';
import { PlayerPage } from '../../pages/player/player.page';
import { ThemeService } from 'src/app/services/theme.service';

@Component({
  selector: 'app-miniplayer',
  templateUrl: './miniplayer.component.html',
  styleUrls: ['./miniplayer.component.scss'],
})
export class MiniplayerComponent implements OnInit, AfterViewInit {

  constructor(private modalCtrl: ModalController,
              private moreOptions: MoreOptionsServiceService,
              public subsonicActions: SubsonicactionsService,
              public themeSvc: ThemeService,
              public store: StoreService,
              public playerActions: PlayeractionsService) { }

  ngOnInit() {}

  ngAfterViewInit() {}

  play(event: Event) {
    event.stopPropagation();
    this.playerActions.play();
  }

  pause(event: Event) {
    event.stopPropagation();
    this.playerActions.pause();
  }

  async openPlayer(event): Promise<any>{
    event.stopPropagation();

    const playerModal = await this.modalCtrl.create({
      component: PlayerPage,
      canDismiss: true,
      componentProps: {},
      breakpoints: [0, 0.99],
      initialBreakpoint: 0.99
    });

    return await playerModal.present();
  }

  async openQueueViewer(event): Promise<any>{
    event.stopPropagation();

    const queueViewerModal = await this.modalCtrl.create({
      component: PlayQueueViewerComponent,
      canDismiss: true,
      componentProps: {},
      id: 'queueViewer',
    });

    return await queueViewerModal.present();
  }

  showCastOptions(event: Event){
    event.stopPropagation();
    //this.playerActions.showNativeCastOptions();
    this.playerActions.showCastOptions();
  }

  async next(){
    if (this.playerActions.getCanPlayNext()){
      this.playerActions.next(true);
    } else {
      this.playerActions.next(true);
    }
  }

  async prev(){
    if (this.playerActions.getCanPlayPrev()){
      this.playerActions.prev(true);
    } else {
      this.playerActions.prev(true);
    }
  }

  fwdInterval(){
    this.playerActions.skipFwd();
  }

  bckInterval(){
    this.playerActions.skipBck();
  }

  openPlayQueue(event) {
    event.stopPropagation();
  }

  onPositionChangeStart(event){
    this.playerActions.stopWatchPosition();
  }

  onPositionChangeEnd(event){
    this.playerActions.seekTo(event);
    this.playerActions.watchPosition();
  }

  async starTrack(event, track){
    console.log('star track');
    await this.subsonicActions.starTrack(track.id);
  }

  async unstarTrack(event, track){
    console.log('unstar track');
    await this.subsonicActions.unstarTrack(track.id);
  }

  isSongStarred(songID): boolean {
    return this.subsonicActions.isSongStarred(songID);
  }

  // When the more button is tapped on a track
  async moreOptionsTrack(event: Event, trackItem: any, index: number){
    this.moreOptions.moreOptionsTrack(event, trackItem, 'player', index);
  }
}
