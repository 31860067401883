/* eslint-disable @typescript-eslint/naming-convention */
/// <reference types="cordova-plugin-statusbar" />
import { Injectable, NgZone } from '@angular/core';
import { StoreService } from './store.service';
import Vibrant from 'node-vibrant';

declare global {
  interface Window {
    StatusBar: StatusBar;
  }
}

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  public themeCss;
  private prefersDark;

  constructor(private ngzone: NgZone,
              private store: StoreService) { }

  init(): void {
    this.createDefaultSwatches();
    this.handleSystemThemePref();
    this.setPreferredTheme();
  }

  handleSystemThemePref() {
    // Use matchMedia to check the user preference
    this.prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
    this.autoToggleDarkTheme(this.prefersDark.matches);
    this.registerEventListeners();
  }

  toggleUseSystemTheme(useSystem: boolean) {
    if (useSystem) {
      this.autoToggleDarkTheme(this.prefersDark.matches);
    }
    if (!useSystem) {
      this.setPreferredTheme();
    }
  }

  // Manually set the preferred theme
  setPreferredTheme() {
    this.ngzone.run(() => {
      if (!this.store.userState.useSystemTheme){
        if (this.store.userState.preferredTheme === 'dark') {
          this.themeCss = 'dark-theme';
          document.body.classList.toggle('dark', true);
          if(!this.store.appState.isWEB){
            window.StatusBar.styleLightContent();
            window.StatusBar.backgroundColorByName('black');
          }

        }
        if (this.store.userState.preferredTheme === 'light') {
          this.themeCss = 'light-theme';
          document.body.classList.toggle('dark', false);
          if(!this.store.appState.isWEB){
            window.StatusBar.styleDarkContent();
            window.StatusBar.backgroundColorByName('white');
          }
        }
      }
    });
  }

  // Auto switch themes based on system prefs
  autoToggleDarkTheme(shouldEnableDark) {
    this.ngzone.run(() => {
      if (this.store.userState.useSystemTheme) {
        if(shouldEnableDark){
          this.themeCss = 'dark-theme';
          if(!this.store.appState.isWEB){
            window.StatusBar.styleLightContent();
            window.StatusBar.backgroundColorByName('black');
          }
        } else {
          this.themeCss = 'light-theme';
          if(!this.store.appState.isWEB){
            window.StatusBar.styleDarkContent();
            window.StatusBar.backgroundColorByName('white');
          }
        }
        //shouldEnableDark ? this.themeCss = 'dark-theme' : this.themeCss = 'light-theme';
        document.body.classList.toggle('dark', shouldEnableDark);
      }
    });
  }

  registerEventListeners() {
    this.prefersDark.addListener(mediaQuery => this.autoToggleDarkTheme(mediaQuery.matches));
  }

  // needs an element ID, accepts an event object or other object with .target.id property
  async createSwatches(event: any) {
    const elementID = event.target.id;
    const img = document.getElementById(elementID)?.getAttribute('src') || '';
    console.log(img);
    const v = new Vibrant(img);
    const palette = await v.getPalette();
    console.log(palette);
    this.setSwatchesToThemeVariables(palette);
  }

  // default color swatches
  createDefaultSwatches(){
    const defaultPalette = {
      DarkMuted: {hex: '#29323c'},
      DarkVibrant: {hex: '#29323c'},
      LightMuted: {hex: '#f5f7fa'},
      LightVibrant: {hex: '#f5f7fa'},
    };
    this.setSwatchesToThemeVariables(defaultPalette, true);
  }

  // update theme CSS variables with new values for color swatches
  setSwatchesToThemeVariables(palette, isDefault?: boolean){
    if(this.store.userState.enableReactiveColors || isDefault){
      document.documentElement.style.setProperty('--darkmuted', palette.DarkMuted.hex);
      document.documentElement.style.setProperty('--darkvibrant', palette.DarkVibrant.hex);
      document.documentElement.style.setProperty('--lightmuted', palette.LightMuted.hex);
      document.documentElement.style.setProperty('--lightvibrant', palette.LightVibrant.hex);
    }
  }
}
