import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toIso'
})
export class ToIsoPipe implements PipeTransform {

  /**
   * Takes date as a string in any format, returns as an ISO date string
   */
  transform(value: string, ...args) {
    return new Date(value).toISOString();
  }

}
