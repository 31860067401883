import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { EventsService } from './events.service';
import { StoreService } from './store.service';
import { SubsonicService } from './subsonic.service';
import { PersistenceService } from './persistence.service';
import { ImageCacheService } from './image-cache.service';
import { UtilService } from './util.service';
import { AppactionsService } from './appactions.service';
import { FileTransfer, FileTransferObject } from '@awesome-cordova-plugins/file-transfer/ngx';

@Injectable({
  providedIn: 'root'
})
export class PodcastCacheService {


  public canDownload = true;
  public podcastCacheQueue: any = [];
  public episodeCacheQueue: any = [];
  public downloadActive = false;
  public episodeDownloadActive = false;
  public currentDownloadItem: any = {};
  public currentDownloadItemTotal = 0;
  public currentDownloadItemProgress = 0;
  public currentDownloadItemProgressPcnt = 0;
  private queueWatcher: any;
  private queueWatcherActive = false;
  private episodeQueueWatcher: any;
  private episodeQueueWatcherActive = false;
  private resetRequested = false;
  private tx: FileTransferObject;

  constructor(private store: StoreService,
              private subsonic: SubsonicService,
              private appActions: AppactionsService,
              private platform: Platform,
              private events: EventsService,
              private persist: PersistenceService,
              private util: UtilService,
              private imageCache: ImageCacheService,
              private fileTransfer: FileTransfer)
  {
    this.init();
  }

  init(): void {
    this.platform.ready().then(() => {
      if (this.platform.is('cordova')){
        this.tx = this.fileTransfer.create();
        // Progress events don't work on iOS and on Android they need to be debounced
        // this.tx.onProgress((event) => {console.log("TEST "+event)});
      }
    });
  }


  // process the actual download
  async doDownload(episodeItem: any, url: string): Promise<any>{
    const _uniqueFilename = episodeItem.id + '.' + episodeItem.suffix;
    let _returnItem: any;

    // will overwrite existing files by default
    await this.tx.download(url, this.persist.getPodcastCacheDir() + _uniqueFilename, true)
      .then((result) => {
        console.log('download episode success result');
        console.log(result);
        episodeItem.cachedFilename = _uniqueFilename;
        _returnItem = {status: 'success', data: episodeItem};
      })
      .catch((err) => {
        console.log('download episode error result');
        console.log(err);
        _returnItem = {status: 'error', data: err};
      });
    return _returnItem;
  }

  // set when starting a collection download
  setDownloadActive(){
    this.downloadActive = true;
  }

  // set when finishing a collection download
  setDownloadNotActive(){
    this.downloadActive = false;
    this.currentDownloadItem = {};
  }

  // reset the download progress counters
  resetDownloadProgress(){
    this.currentDownloadItemTotal = 0;
    this.currentDownloadItemProgress = 0;
    this.currentDownloadItemProgressPcnt = 0;
  }

  // register completion of file download
  updateDownloadProgress(){
    this.currentDownloadItemProgress++;
    this.currentDownloadItemProgressPcnt = Math.floor((this.currentDownloadItemProgress / this.currentDownloadItemTotal * 100));
  }

  // set the total items for the current download
  setDownloadItemLength(length: number){
    this.currentDownloadItemTotal = length;
  }

  // check if an item is in the download queue or is the current
  // downloading item
  isDownloading(id: string|number){
    const _id = id.toString();
    if (this.currentDownloadItem.id === _id){
      return true;
    }
    for (const queueItem of this.podcastCacheQueue){
      if (queueItem.item.id === _id){
        return true;
      }
    }
    return false;
  }

  // check if anything is downloading or queued
  updateHasPendingDownloads(){
    if (this.podcastCacheQueue.length || this.currentDownloadItem.hasOwnProperty('id')){
      this.store.appState.hasPendingPodcastDownloads = true;
    } else {
      this.store.appState.hasPendingPodcastDownloads = false;
    }
  }

  // delete an item from the cache queue
  deleteQueuedItem(index: number){
    // grab the item id
    const _itemID = this.podcastCacheQueue[index].item.id;
    // public the download cancelled event
    this.events.publish('podcastcacheCancel', _itemID);
    if (this.store.appState.hasPendingPodcastDownloads){
      this.podcastCacheQueue.splice(index, 1);
    }
  }

  // adhoc episode cache requests that are not part of collections
  // used by the autocache service based on users settings
  watchEpisodeQueue() {
    // if a episode queue watcher is already active do nothing
    if (this.episodeQueueWatcherActive){
      return;
    }
    // create a episode queue watcher
    else {
      console.log('starting episode queue watcher');
      this.episodeQueueWatcherActive = true;
      this.episodeQueueWatcher = setInterval(() => {
        console.log('episodeCacheQueue has ' + this.episodeCacheQueue.length + ' items waiting');
        console.log(this.episodeCacheQueue);
        // if there are items waiting and nothing running process them
        if (this.episodeCacheQueue.length){
          // make sure there is enough space to download
          this.checkCanDownload(this.episodeCacheQueue[0].size);
          // no active download, proceed to process
          if (!this.episodeDownloadActive && this.canDownload){
            console.log('episode cache queue processing next item');
            this.episodeDownloadActive = true;
            this.setCachedEpisode(this.episodeCacheQueue.shift(), true);
          }
          // active download in progress, wait
          else if (this.episodeDownloadActive) {
            console.log('episode cache items waiting but download in progress');
          }
          // cache is full, silently cancel adhoc downloads
          else if (!this.canDownload){
            console.log('cache is full, discarding episodeCacheQueue');
            this.episodeCacheQueue = [];
          }
        }
        // there are no items waiting kill the queue watcher to save resources
        else {
          console.log('episode cache queue is empty');
          this.stopWatchingEpisodeQueue();
        }
      }, 10000);
    }
  }

  // stop watching the episode queue
  stopWatchingEpisodeQueue(){
    console.log('stopping episode queue watcher');
    this.episodeQueueWatcherActive = false;
    clearInterval(this.episodeQueueWatcher);
  }

  // force restart of the episode queue watcher
  resetEpisodeQueueWatcher(){
    this.stopWatchingEpisodeQueue();
    setTimeout(() => {
      this.watchEpisodeQueue();
    }, 6000);
  }

  // watch and process the download queue
  // all downloads are queued through reqCacheItem
  watchQueue() {
    // if a watcher is already active do nothing
    if (this.queueWatcherActive){
      return;
    }
    // else create a watcher
    else {
      console.log('starting podcast cache queue watcher');
      this.queueWatcherActive = true;
      // stop sleep while downloads in progress
      this.appActions.setEnableScreenAlive();
      // create queue watcher
      this.queueWatcher = setInterval(() => {
        this.updateHasPendingDownloads();
        console.log('podcastCacheQueue has ' + this.podcastCacheQueue.length + ' items waiting');
        console.log(this.podcastCacheQueue);
        // if there are items waiting and nothing running process them
        if (this.podcastCacheQueue.length){
          // make sure there is enough space to download
          this.checkCanDownload(this.podcastCacheQueue[0].item.size);
          // no active download, proceed to process
          if (!this.downloadActive && this.canDownload){
            console.log('podcastCacheQueue processing next item');
            this.setDownloadActive();
            const _nextItem = this.podcastCacheQueue.shift();
            if (_nextItem.type === 'podcast'){
              console.log('processing podcast item ' + _nextItem.item.id);
              // store a reference to the type in case we have to push it back to the queue
              _nextItem.item.type = 'podcast';
              this.setCachedPodcast(_nextItem.item);
             // this.currentDownloadItem = _nextItem.item;
            }
            else if (_nextItem.type === 'playlist'){
              console.log('processing playlist item ' + _nextItem.item.id);
              // store a reference to the type in case we have to push it back to the queue
              _nextItem.item.type = 'playlist';
              this.setCachedPlaylist(_nextItem.item);
             // this.currentDownloadItem = _nextItem.item;
            }
            else if (_nextItem.type === 'folder'){
              console.log('processing folder item ' + _nextItem.item.id);
              // store a reference to the type in case we have to push it back to the queue
              _nextItem.item.type = 'folder';
              this.setCachedFolder(_nextItem.item);
             // this.currentDownloadItem = _nextItem.item;
            }
            console.log(_nextItem);
          }
          // there are items waiting but something is running then wait
          else if (this.downloadActive){
            console.log('download currently active, waiting');
          }
          // there are items waiting but cache is full
          else if (!this.canDownload){
            console.log('cache is full, waiting');
          }
        }
        // there are no items waiting kill the queue watcher to save resources
        else {
          console.log('podcast cache queue empty');
          this.stopWatchingQueue();
        }
      }, 1000);
    }
  }

  // stop watching the download queue
  stopWatchingQueue(){
    console.log('stopping podcast cache queue watcher');
    // disable screen keep alive now queue is empty
    this.appActions.setDisableScreenAlive();
    // disable the queue watcher
    this.queueWatcherActive = false;
    clearInterval(this.queueWatcher);
  }

  // force restart of the queue watcher
  resetQueueWatcher(){
    // make sure watcher is stopped
    this.stopWatchingQueue();
    // stop any running item download loops
    this.resetRequested = true;
    // push any current item back onto the queue
    if (this.currentDownloadItem.id){
      const _oldItem = this.util.copy(this.currentDownloadItem);
      const _newItem = {item: _oldItem, type: _oldItem.type};
      this.podcastCacheQueue.unshift(_newItem);
    }
    // clean up the current status
    this.currentDownloadItem = {};
    this.resetDownloadProgress();
    this.setDownloadNotActive();
    this.updateHasPendingDownloads();

    // restart the queue watcher
    setTimeout(() => {
      this.resetRequested = false;
      this.watchQueue();
    }, 2000);
  }

  // check if the item we want to download will fit in the cache
  async checkCanDownload(itemSize: number){
    console.log('checkCanDownload');
    const _projectedSpace: number = this.store.podcastCacheState.usedSpace + itemSize;
    if (_projectedSpace > this.store.userState.cacheMaxSize){
      console.log('cannot download');
      console.log(_projectedSpace + ' / ' + this.store.userState.cacheMaxSize);
      this.setCannotDownload();
      return false;
    }
    else {
      console.log('can download');
      console.log(_projectedSpace + ' / ' + this.store.userState.cacheMaxSize);
      this.setCanDownload();
      return true;
    }
  }

  // enable normal downloading
  setCanDownload(){
    this.canDownload = true;
  }

  // disable downloading
  setCannotDownload(){
    this.canDownload = false;
  }

  // check if there is sufficient free space to cache space
  // for the item size (size is in b)
  checkFreeCacheSpace(sizeInB: number){
    console.log('check free cache space');
    console.log('check free cache percentage used: ' +
    Math.floor((this.store.podcastCacheState.usedSpace + this.store.musicCacheState.usedSpace)
    / this.store.userState.cacheMaxSize * 100));

    if ((this.store.podcastCacheState.usedSpace + this.store.musicCacheState.usedSpace + sizeInB) > (this.store.userState.cacheMaxSize - 10000000)) {
      console.log('check free cache space: no space available');
      return true;
    } else {
      console.log('check free cache space: space available');
      return false;
    }
  }

  // update used space
  updateUsedSpace(size: number, type: string){
    if (type === 'plus'){
      this.store.podcastCacheState.usedSpace = this.store.podcastCacheState.usedSpace + size;
    }
    if (type === 'minus'){
      this.store.podcastCacheState.usedSpace = this.store.podcastCacheState.usedSpace - size;
    }
    // update the percentage calculation for used cache space on change.
    this.store.updateCacheUsedPercentage();
    // persist
    this.store.savePodcastCacheState();
  }

  // set a cached episode as used in an podcast/folder/playlist
  // adds usage tracking to the store.podcastCache.cachedEpisodeUse hash
  async setCachedEpisodeUse(id: string|number, useID: string|number){
    console.log('setCachedEpisodeUse called for: ' + id + ' in ' + useID);
    const _id = id.toString();
    const _useID = useID.toString();
    const _isUsed = await this.checkCachedEpisodeUse(_id);
    if (_isUsed){
      if (this.store.podcastCacheState.cachedEpisodeUse[_id].indexOf(_useID) === -1){
        this.store.podcastCacheState.cachedEpisodeUse[_id].push(_useID);
        console.log('setCachedEpisodeUse useID ' + useID + 'tracking added to existing list');
      } else {
        console.log('setCachedEpisodeUse useID ' + useID + ' already tracked');
      }
    }
    else {
      this.store.podcastCacheState.cachedEpisodeUse[_id] = [_useID];
      console.log('setCachedEpisodeUse useID ' + useID + 'tracking added to new list');
    }
    this.store.savePodcastCacheState();
    return;
  }

  // check if the cached episode is used in an podcast/folder/playlist
  // checks for tracking in store.podcastCache.cachedEpisodeUse hash
  async checkCachedEpisodeUse(id: string|number){
    console.log('checkCachedEpisodeUse called for: ' + id);
    const _id = id.toString();
    if (this.store.podcastCacheState.cachedEpisodeUse.hasOwnProperty(_id)){
      console.log('checkCachedEpisodeUse for ' + id + ' true');
      return true;
    }
    else {
      console.log('checkCachedEpisodeUse for ' + id + ' false');
      return false;
    }
  }

  // delete a usage of a cached episode in an podcast/folder/playlist
  // removes a tracking ref in store.podcastCache.cachedEpisodeUse hash
  // if that is the last tracking item, remove the key from the hash
  async delCachedEpisodeUse(id: string|number, useID: string|number){
    console.log('delCachedEpisodeUse called for: ' + id + ' in ' + useID);
    const _id = id.toString();
    const _useID = useID.toString();
    const isUsed = await this.checkCachedEpisodeUse(_id);
    if (isUsed){
      this.store.podcastCacheState.cachedEpisodeUse[_id].splice(this.store.podcastCacheState.cachedEpisodeUse[_id].indexOf(_useID), 1);
      console.log('delCachedEpisodeUse for ' + id + ' in useID ' + useID + ' removed tracking from list');
      if (this.store.podcastCacheState.cachedEpisodeUse[_id].length === 0){
        delete this.store.podcastCacheState.cachedEpisodeUse[_id];
        console.log('delCachedEpisodeUse for ' + id + ' no more uses to track, removing list');
      }
    }
    this.store.savePodcastCacheState();
    return;
  }

  // if a episode exists in the cache get it's indexe for episodes
  // return the index position in store.podcastCache.episodes
  async checkCachedEpisodeIndex(episodeID: string|number){
    console.log('checkCachedEpisodeIndex called for ' + episodeID);
    const _episodeID = episodeID.toString();
    const _length2 = this.store.podcastCacheState.episodes.length;
    let _returnIndex2: any;

    // check episodes array
    if (_length2 === 0){
      console.log('checkCachedEpisodeIndex ' + episodeID + ' episodes cache is empty, not found -1');
      _returnIndex2 = -1;
    }
    for (let i = 0; i < _length2; i++){
      if (this.store.podcastCacheState.episodes[i].id === _episodeID){
        console.log('checkCachedEpisodeIndex ' + episodeID + ' episodes found at ' + i);
        _returnIndex2 = i;
        break;
      }
      else if (i === _length2 - 1){
        console.log('checkCachedEpisodeIndex ' + episodeID + ' episodes not found in cache -1');
        _returnIndex2 = -1;
      }
    }
    return {episodesIndex: _returnIndex2};
  }

  // if an item (podcast / playlist / folder) exists in the cache
  // return it's index position in the respective store.podcastCache arrays
  async checkCachedItemIndex(itemID: string|number, type: string){
    const _itemID = itemID.toString();
    let _array: any;
    let _returnIndex: number;

    if (type === 'podcast'){
      _array = this.store.podcastCacheState.podcasts;
    }
    if (type === 'playlist'){
      _array = this.store.podcastCacheState.playlists;
    }
    if (type === 'folder'){
      _array = this.store.podcastCacheState.folders;
    }
    const _length = _array.length;

    // no items in the list
    if (_length === 0){
      _returnIndex = -1;
    }

    // at least 1 item in the list
    for (let i = 0; i < _length; i++){
      if (_array[i].id === _itemID){
        _returnIndex = i;
        break;
      } else if (i === _length - 1){
        _returnIndex = -1;
      }
    }

    return {index: _returnIndex};
  }

  // return an object with status success and data cached URL if item is found in cache
  // else return status error
  getCachedEpisodeUrl(episodeID: number|string){
    const _episodeID = episodeID.toString();
    let _cachedUrl: string;

    if (this.store.podcastCacheState.allFiles[_episodeID] !== undefined){
      _cachedUrl = this.persist.getPodcastCacheDir() + this.store.podcastCacheState.allFiles[_episodeID].cachedFilename;
      if (this.store.appState.isIOS && this.store.appState.isWKWEBVIEW){
        _cachedUrl = _cachedUrl.slice(7);
      }
      if (this.store.appState.isANDROID){
        _cachedUrl = this.persist.getPodcastCacheDir() + this.store.podcastCacheState.allFiles[_episodeID].cachedFilename;
      }
      return {status: 'success', data: _cachedUrl};
    }
    else {
      return {status: 'error', data: ''};
    }
  }

  // check if a episode exists in the cache
  // all files in the cache are recorded in
  // store.podcastCache.allFiles hashmap
  checkCachedEpisode(episodeID: string|number){
    console.log('checkCachedEpisode called for ' + episodeID);
    const _episodeID = episodeID.toString();

    if (this.store.podcastCacheState.allFiles[_episodeID] !== undefined){
      return true;
    }
    else {
      return false;
    }
  }

  // check if an podcast exists in the cache
  // checks the store.podcastCache.podcasts array
  checkCachedPodcast(podcastID: string|number){
    console.log('checkCachedPodcast called for ' + podcastID);
    const _podcastID = podcastID.toString();
    const _length = this.store.podcastCacheState.podcasts.length;

    if (_length === 0){
      console.log('checkCachedPodcast ' + podcastID + ' cache is empty, not found');
      return false;
    }
    for (let i = 0; i < _length; i++){
      if (this.store.podcastCacheState.podcasts[i].id === _podcastID){
        console.log('checkCachedPodcast ' + podcastID + ' found in cache');
        return true;
      }
      else if (i === _length - 1){
        console.log('checkCachedPodcast ' + podcastID + ' not found in cache');
        return false;
      }
    }
  }

  // check if an podcast exists in the cache
  // checks the store.podcastCache.playlists array
  checkCachedPlaylist(playlistID: string|number){
    console.log('checkCachedPlaylist called for ' + playlistID);
    const _playlistID = playlistID.toString();
    const _length = this.store.podcastCacheState.playlists.length;

    if (_length === 0){
      console.log('checkCachedPlaylist ' + playlistID + ' cache is empty, not found');
      return false;
    }
    for (let i = 0; i < _length; i++){
      if (this.store.podcastCacheState.playlists[i].id === _playlistID){
        console.log('checkCachedPlaylist ' + _playlistID + ' found in cache');
        return true;
      }
      else if (i === _length - 1){
        console.log('checkCachedPlaylist ' + _playlistID + ' not found in cache');
        return false;
      }
    }
  }

  // check if an podcast exists in the cache
  // checks the store.podcastCache.folders array
  checkCachedFolder(folderID: string|number){
    console.log('checkCachedFolder called for ' + folderID);
    const _folderID = folderID.toString();
    const _length = this.store.podcastCacheState.folders.length;

    if (_length === 0){
      console.log('checkCachedFolder ' + _folderID + ' cache is empty, not found');
      return false;
    }
    for (let i = 0; i < _length; i++){
      if (this.store.podcastCacheState.podcasts[i].id === _folderID){
        console.log('checkCachedFolder ' + _folderID + ' found in cache');
        return true;
      }
      else if (i === _length - 1){
        console.log('checkCachedFolder ' + _folderID + ' not found in cache');
        return false;
      }
    }
  }

  // request to add an item to the cache
  // adds the item to the download queue
  // accepts an podcast / playlist / folder item
  // requires the type of collection to be specified
  reqCacheItem(item: any, type: string){
    let _length: number;
    let _itemSize = 0;
    // check that params are valid
    if (item === undefined || type === undefined){
      console.log('reqCacheItem: error missing arguments');
      return;
    }
    // check item is not currently downloading
    if (this.currentDownloadItem.id === item.id){
      console.log('reqCacheItem: already downloading this item');
      return;
    }
    // check item is not already queued
    for (let i = 0; i < this.podcastCacheQueue.length; i++){
      if (this.podcastCacheQueue[i].item.id === item.id){
        console.log('reqCacheItem: refusing to add duplicate item to queue');
        return;
      }
    }

    if (type === 'podcast'){
      _length = item.episode.length;
      for (let i = 0; i < _length; i++){
        _itemSize = _itemSize + item.episode[i].size;
      }
    }
    if (type === 'playlist'){
      _length = item.entry.length;
      for (let i = 0; i < _length; i++){
        _itemSize = _itemSize + item.entry[i].size;
      }
    }
    if (type === 'folder'){
      _length = item.child.length;
      for (let i = 0; i < _length; i++){
        _itemSize = _itemSize + item.child[i].size;
      }
    }

    console.log('reqCacheItem: item ' + item.id + 'added to podcastCacheQueue');
    item.type = type;
    item.size = _itemSize;
    this.podcastCacheQueue.push({item, type});
    this.watchQueue();
    this.updateHasPendingDownloads();
    return;
  }

  // request to cache an individual episode
  // used by the autocache mechanism to cache
  // ahead during adhoc playback
  reqCacheEpisode(episodeItem: any){
    if (episodeItem === undefined){
      console.log('bad episode item');
      return;
    }
    console.log('reqCacheEpisode ' + episodeItem.id);
    this.episodeCacheQueue.push(episodeItem);
    this.watchEpisodeQueue();
    return;
  }

  // add item to cached list, checking for duplicates
  async addItemToCachedList(item: any, type: string){
    let _array: any;
    let _itemFound = false;
    if (type === 'podcast'){
      _array = this.store.podcastCacheState.podcasts;
    }
    if (type === 'playlist'){
      _array = this.store.podcastCacheState.playlists;
    }
    if (type === 'folder'){
      _array = this.store.podcastCacheState.folders;
    }
    const _length = _array.length;

    // first item in the array!
    if (_length === 0){
      console.log('adding first item in cached list');
      _array.push(item);
      return;
    }

    // if there is already at least 1 item in the array
    for (let i = 0; i < _length; i++){
      // replace existing item
      if (item.id === _array[i].id){
        console.log('replacing existing item in cached list');
        _array.splice(i, 1, item);
        _itemFound = true;
        this.store.savePodcastCacheState();
        return;
      }
      // add new item
      if (i === _length - 1 && !_itemFound){
        console.log('adding new item to cached List');
        _array.push(item);
        this.store.savePodcastCacheState();
        return;
      }
    }
  }

  // add a episode file to the cache
  // returns a response with status success or error and
  // data episodeItem or data error object
  // A successful response will include the cachedFilename key in the episodeItem
  // indicating where the file is saved.
  // this is used for individual episode caching and by the collection caching calls.
  // if this is an adhoc cache request from autocache or similar set autocache to true
  async setCachedEpisode(episodeItem: any, autocache?: boolean){
    const _episodeID = episodeItem.id.toString();
    const _episodeStreamID = episodeItem.streamId.toString();
    let _cachedEpisodeItem: any;
    let _cachedEpisodeItemIndex: any;
    const _isUsed = await this.checkCachedEpisodeUse(_episodeID);

    if (this.checkCachedEpisode(_episodeID)){
      console.log('setCachedEpisode ' + episodeItem.id + ' file already in cache');
      _cachedEpisodeItemIndex = await this.checkCachedEpisodeIndex(_episodeID);
      // as it is now used in a collection remove from the individual episodes array
      if (_isUsed && _cachedEpisodeItemIndex.episodesIndex > -1){
        console.log('setCachedEpisode removing ' + episodeItem.id + ' from episodes list as it is now used in a collection');
        this.store.podcastCacheState.episodes.splice(_cachedEpisodeItemIndex.episodesIndex, 1);
      }
      // compile return item
      _cachedEpisodeItem = {status: 'success', data: this.util.copy(this.store.podcastCacheState.allFiles[_episodeID])};
      this.store.savePodcastCacheState();

      // if these are autocache items update the episodeDownloadActive state
      if (autocache){
        this.episodeDownloadActive = false;
      }
      return _cachedEpisodeItem;
    }
    else {
      console.log('setCachedEpisode ' + episodeItem.id + ' start to cache');
      // make sure we have offline coverart
      await this.imageCache.set(episodeItem.coverArt, false);
      // then proceed to download
      _cachedEpisodeItem = await this.doDownload(episodeItem, this.subsonic.download(_episodeStreamID));
      // if download success
      if (_cachedEpisodeItem.status === 'success'){
        console.log('setCachedEpisode ' + episodeItem.id + 'file added to cache');

        // update used space tracking
        this.updateUsedSpace(episodeItem.size, 'plus');

        // if the episode is used in a collection add to allFiles only
        if (_isUsed){
          console.log('setCachedEpisode ' + _episodeID + ' is used in an podcast/folder/playlist only add to allFiles list');
          this.store.podcastCacheState.allFiles[_episodeID] = _cachedEpisodeItem.data;
        }
        // if the episode is NOT used in a collection add to allFiles & individual episodes
        else {
          console.log('setCachedEpisode ' + _episodeID + ' is not used in an podcast/folder/playlist add to allFiles & Episodes lists');
          this.store.podcastCacheState.allFiles[_episodeID] = _cachedEpisodeItem.data;
          this.store.podcastCacheState.episodes.push(_cachedEpisodeItem.data);
        }
        // persist and return
        this.store.savePodcastCacheState();

        // if these are autocache items update the episodeDownloadActive state
        if (autocache){
          this.episodeDownloadActive = false;
        }

        return this.util.copy(_cachedEpisodeItem);
      }
      // if download failed
      else {
        console.log('setCachedEpisode ' + _episodeID + ' could not be added to cache');
        this.store.savePodcastCacheState();

        // if these are autocache items update the episodeDownloadActive state
        if (autocache){
          this.episodeDownloadActive = false;
        }
        return this.util.copy(_cachedEpisodeItem);
      }
    }
  }

  // add an podcast to the cache
  async setCachedPodcast(podcastItem: any){
    console.log('setCachedPodcast called');
    console.log(podcastItem);
    // get the coverart for the podcast
    this.imageCache.set(podcastItem.coverArt, false);
    // set this as the current download item
    this.currentDownloadItem = this.util.copy(podcastItem);
    this.resetDownloadProgress();
    this.setDownloadItemLength(podcastItem.episode.length);
    // temporary array to hold our cached episodeItems
    const _cachedArray: any = [];

    // cache each episode
    for (const item of podcastItem.episode){
      // break out and stop if reset is requested
      if (this.resetRequested){
        return;
      }
      await this.setCachedEpisodeUse(item.id, podcastItem.id);
      const _cachedItem = await this.setCachedEpisode(item);
      if (_cachedItem.status === 'success'){
        _cachedArray.push(_cachedItem.data);
      }
      else{
        console.log('error caching file for podcast, clean up');
        // the file wasn't cached so remove the usage reference
        await this.delCachedEpisodeUse(item.id, podcastItem.id);
      }
      // slight delay as very rapid disk access can cause issues
      await this.util.addDelay(10);
      // update the download progress tracker
      this.updateDownloadProgress();
    }
    // potentially handle partially cached result in future
    if (_cachedArray.length < podcastItem.episode.length){
      console.log('some tracks failed to cache');
    }
    // all items failed to cache
    if (_cachedArray.length === 0){
      this.events.publish('podcastcacheFailure', podcastItem);
    }
    // some or all items cached proceed
    else {
      // replace the original episode array with an updated one with cached filenames
      podcastItem.episode = _cachedArray;
      // add the podcast to the cached podcasts list
      await this.addItemToCachedList(this.util.copy(podcastItem), 'podcast');
      // publish the download complete event in case someone is waiting
      this.events.publish('podcastcacheComplete', podcastItem.id);
    }
    // set the download as inactive
    this.setDownloadNotActive();
    // update the pending download status;
    this.updateHasPendingDownloads();
    // persist
    this.store.savePodcastCacheState();
    // we're done here
    return;
  }

  // add a playlist to the cache
  async setCachedPlaylist(playlistItem: any){
    console.log('setCachedPlaylist called');
    console.log(playlistItem);
    // get the coverart for the playlist itself
    this.imageCache.set(playlistItem.coverArt, false);
    // set this as the current download item
    this.currentDownloadItem = playlistItem;
    this.resetDownloadProgress();
    this.setDownloadItemLength(playlistItem.entry.length);
    // temporary array to hold our cached episodeItems
    const _cachedArray: any = [];

    // cache each episode
    for (const item of playlistItem.entry){
      // break out and stop if reset is requested
      if (this.resetRequested){
        return;
      }
      await this.setCachedEpisodeUse(item.id, playlistItem.id);
      const _cachedItem = await this.setCachedEpisode(item);
      if (_cachedItem.status === 'success'){
        _cachedArray.push(_cachedItem.data);
      }
      else{
        console.log('error caching file for playlist, clean up');
        // the file wasn't cached so remove the usage reference
        await this.delCachedEpisodeUse(item.id, playlistItem.id);
      }
      // update the download progress tracker
      this.updateDownloadProgress();
    }
    // potentially handle partially cached result in future
    if (_cachedArray.length < playlistItem.entry.length){
      console.log('some tracks failed to cache');
    }
    // all items failed to cache
    if (_cachedArray.length === 0){
      this.events.publish('cacheFailure', playlistItem);
    }
    // some or all items cached proceed
    else {
      // replace the original episode array with an updated one with cached filenames
      playlistItem.entry = _cachedArray;
      // add the podcast to the cached podcasts list
      await this.addItemToCachedList(this.util.copy(playlistItem), 'playlist');
      // publish the download complete event in case someone is waiting
      this.events.publish('podcastcacheComplete', playlistItem.id);
    }
    // set the download as inactive
    this.setDownloadNotActive();
    // update the pending download status;
    this.updateHasPendingDownloads();
    // persist
    this.store.savePodcastCacheState();
    // we're done here
    return;
  }

  // add a folder to the cache
  async setCachedFolder(folderItem: any){
    console.log('setCachedFolder called');
    console.log(folderItem);
    // get the coverart for the folder
    this.imageCache.set(folderItem.id, false);
    // set this as the current download item
    this.currentDownloadItem = folderItem;
    this.resetDownloadProgress();
    this.setDownloadItemLength(folderItem.child.length);
    // temporary array to hold our cached episodeItems
    const _cachedArray: any = [];

    // cache each episode
    for (const item of folderItem.child){
      // break out and stop if reset is requested
      if (this.resetRequested){
        return;
      }
      await this.setCachedEpisodeUse(item.id, folderItem.id);
      const _cachedItem = await this.setCachedEpisode(item);
      if (_cachedItem.status === 'success'){
        _cachedArray.push(_cachedItem.data);
      }
      else{
        console.log('error caching file for folder, clean up');
        // the file wasn't cached so remove the usage reference
        await this.delCachedEpisodeUse(item.id, folderItem.id);
      }
      // currently not handling failed downloads TODO: clean up failed items
      this.updateDownloadProgress();
    }
    // potentially handle partially cached result in future
    if (_cachedArray.length < folderItem.child.length){
      console.log('some tracks failed to cache');
    }
    // all items failed to cache
    if (_cachedArray.length === 0){
      this.events.publish('cacheFailure', folderItem);
    }
    // some or all items cached proceed
    else {
      // replace the original episode array with an updated one with cached filenames
      folderItem.child = _cachedArray;
      // add the podcast to the cached podcasts list
      await this.addItemToCachedList(this.util.copy(folderItem), 'folder');
      // publish the download complete event in case someone is waiting
      this.events.publish('podcastcacheComplete', folderItem.id);
    }
    // set the download as inactive
    this.setDownloadNotActive();
    // update the pending download status;
    this.updateHasPendingDownloads();
    // persist
    this.store.savePodcastCacheState();
    // we're done here
    return;
  }

  // delete a cached episode from the cache
  async delCachedEpisode(episodeItem: any){
    console.log('delCachedEpisode called for id: ' + episodeItem.id);
    let _returnItem: any;
    const _isUsed = await this.checkCachedEpisodeUse(episodeItem.id);

    // if the file is still used in another collection don't delete
    if (_isUsed){
      console.log('delCachedEpisode item ' + episodeItem.id + ' still in use do not remove file');
      _returnItem = {status: 'success', data: {}};
      return _returnItem;
    }

    // otherwise let's delete the file and remove it's references
    const _cacheIndexes = await this.checkCachedEpisodeIndex(episodeItem.id);
    const _result = await this.persist.delPodcastFile(episodeItem.cachedFilename);

    // if the file was successfully removed
    if (_result.status === 'success'){
      // update used space tracking
      this.updateUsedSpace(episodeItem.size, 'minus');

      console.log('delCachedEpisode file ' + episodeItem.cachedFilename + ' deleted for ID: ' + episodeItem.id);
      // remove it from the allFiles hashmap
      if (this.store.podcastCacheState.allFiles[episodeItem.id] !== undefined){
        console.log('delCachedEpisode removed reference to id: ' + episodeItem.id + 'from allFiles list');
        delete this.store.podcastCacheState.allFiles[episodeItem.id];
      }
      // remove it from the episodes index
      if (_cacheIndexes.episodesIndex > -1){
        console.log('delCachedEpisode removed reference to id: ' + episodeItem.id + 'from episodes list');
        this.store.podcastCacheState.episodes.splice(_cacheIndexes.episodesIndex, 1);
      }
      // return success with deleted file reference as data
      _returnItem = {status: 'success', data: _result.data};
    }
    // the file was not successfully removed so we should not remove it's references
    else {
      console.log('delCachedEpisode file ' + episodeItem.cachedFilename + ' could not be deleted for ID: ' + episodeItem.id);
      // return error with delete operation error as data
      _returnItem = {status: 'error', data: _result.data};
    }
    // persist the state
    this.store.savePodcastCacheState();
    return _returnItem;
  }

  // delete a cached podcast from the cache
  async delCachedItem(itemID: string|number, type: string){
    console.log('delCachedItem ' + type + ' called for: ' + itemID);
    console.log(itemID);
    const _itemIndex = await this.checkCachedItemIndex(itemID, type);
    console.log(_itemIndex);
    let _array: any;
    let _cacheArray: any;
    // thats a mess it's not in the index
    if (_itemIndex.index === -1){
      console.log('item not found index');
      return;
    }
    // handle type specifics
    if (type === 'podcast'){
      _array = this.util.copy(this.store.podcastCacheState.podcasts[_itemIndex.index].episode);
      _cacheArray = this.store.podcastCacheState.podcasts;
    }
    if (type === 'playlist'){
      _array = this.util.copy(this.store.podcastCacheState.playlists[_itemIndex.index].entry);
      _cacheArray = this.store.podcastCacheState.playlists;
    }
    if (type === 'folder'){
      _array = this.util.copy(this.store.podcastCacheState.folders[_itemIndex.index].child);
      _cacheArray = this.store.podcastCacheState.folders;
    }

    // delete each episode
    for (const item of _array){
      // clear the cached episode use
      await this.delCachedEpisodeUse(item.id, item.id);
      // process delete of the cached file
      const _cachedItem = await this.delCachedEpisode(item);
      if (_cachedItem.status === 'success'){
        console.log('delCachedItem ' + type + ' item successs');
      }
      else{
        console.log('delCachedItem ' + type + ' item error');
        // TODO take some failure action and clean up
      }
    }
    // remove the podcast from the cached podcasts list
    _cacheArray.splice(_itemIndex.index, 1);
    // save state
    this.store.savePodcastCacheState();
    // we're done here
    return;
  }

  // completely clear the podcast cache
  async clearCache(){
    await this.store.clearPodcastCacheState();
  }

  // clear all items of type podcast, playlist, folder, episode
  async clearItemList(type: string){
    let _array: any;

    // make a copy of the array we will be emptying
    if (type === 'podcast'){
      _array = this.util.copy(this.store.podcastCacheState.podcasts);
    }
    if (type === 'playlist'){
      _array = this.util.copy(this.store.podcastCacheState.playlists);
    }
    if (type === 'folder'){
      _array = this.util.copy(this.store.podcastCacheState.folders);
    }
    if (type === 'episode'){
      _array = this.util.copy(this.store.podcastCacheState.episodes);
    }
    const _length = _array.length;

    // nothing to do if list is empty
    if (_length === 0){
      return;
    }

    // display loader
    this.appActions.showLoading('clearing offline ' + type + 's.  Please wait...');
    // keep the display alive during long delete operations
    this.appActions.setEnableScreenAlive();
    // process episodes
    if (type === 'episode'){
      for (let i = 0; i < _length; i++){
        await this.delCachedEpisode(_array[i]);
        // slight delay as very rapid disk access can cause issues
        await this.util.addDelay(100);
        if (i === _length - 1){
          this.appActions.hideLoading();
          this.appActions.setDisableScreenAlive();
          return;
        }
      }
    }
    // process collections
    else {
      for (let i = 0; i < _length; i++){
        await this.delCachedItem(_array[i].id, type);
        // slight delay as very rapid disk access can cause issues
        await this.util.addDelay(100);
        if (i === _length - 1){
          this.appActions.hideLoading();
          this.appActions.setDisableScreenAlive();
          return;
        }
      }
    }
  }


}
