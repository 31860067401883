import { MoreOptionsServiceService } from 'src/app/services/more-options-service.service';
import { Component, OnInit, ViewChild, NgZone } from '@angular/core';
import { ModalController, IonContent, NavController, ActionSheetController } from '@ionic/angular';
import { SubsonicactionsService } from 'src/app/services/subsonicactions.service';
import { AppactionsService } from 'src/app/services/appactions.service';
import { PlayeractionsService } from 'src/app/services/playeractions.service';
import { StoreService } from 'src/app/services/store.service';
import { NavigationExtras } from '@angular/router';
import { UtilService } from 'src/app/services/util.service';
import { UseractionsService } from 'src/app/services/useractions.service';
import { TouchHandlerService } from 'src/app/services/touch-handler.service';

@Component({
  selector: 'app-player',
  templateUrl: './player.page.html',
  styleUrls: ['./player.page.scss'],
})
export class PlayerPage implements OnInit {

  @ViewChild('content', {static: true}) private content: IonContent;
  public ready = false;
  public showPlayQueue = false;
  public animate = '';
  public animateShowQueue = '';
  public animateShowPlayer = '';
  public infinitePlayQueue = [];
  private swipeableClassName = 'swipeable';

  constructor(private modalCtrl: ModalController,
              private navCtrl: NavController,
              private ngzone: NgZone,
              private actionSheetCtrl: ActionSheetController,
              private subsonicActions: SubsonicactionsService,
              private appActions: AppactionsService,
              private touchHandler: TouchHandlerService,
              private moreOptions: MoreOptionsServiceService,
              private util: UtilService,
              private userActions: UseractionsService,
              public playerActions: PlayeractionsService,
              public store: StoreService) { }

  ngOnInit() {
  }

  ionViewWillEnter() {
    this.userActions.checkAppStatusAndReload();
  }

  ionViewDidEnter() {
    this.appActions.setModalOpen();
    this.loadInitialData();
  }

  ionViewWillLeave() {

  }

  ionViewDidLeave() {
    this.appActions.setModalClosed();
  }

  loadInitialData() {
    this.infinitePlayQueue = this.store.playerState.playQueue.slice(0,21);
  }

  loadData(event) {
    if(this.infinitePlayQueue.length < this.store.playerState.playQueue.length){
      this.infinitePlayQueue = this.infinitePlayQueue.concat(this.store.playerState.playQueue.slice(this.infinitePlayQueue.length,this.infinitePlayQueue.length+20));
      event.target.complete();
    }
    if(this.infinitePlayQueue.length === this.store.playerState.playQueue.length){
      event.target.disabled = true;
    }
  }

  touchMove(event: any){
    this.touchHandler.touchMoveStopPropagation(event);
  }

  touchStart(event: any) {
    this.touchHandler.touchStart(event, this.swipeableClassName);
  }

  touchEnd(event: any) {
    const touchResult = this.touchHandler.touchEnd(event, this.swipeableClassName);

    if(touchResult.swipeDirection === 'left' && touchResult.swipeEnabled){
      this.next();
    }

    if(touchResult.swipeDirection === 'right' && touchResult.swipeEnabled){
      this.prev();
    }
  }

  async dismiss() {
    return this.modalCtrl.dismiss();
  }

  async showCastOptions(event: Event){
    return this.playerActions.showCastOptions();
  }

  togglePlayQueue(){
    console.log('show play queue');
    this.animateShowQueue = '';
    this.animateShowPlayer = 'fade-in';
    if (this.showPlayQueue){
      this.showPlayQueue = false;
      this.loadInitialData();
    } else {
      this.showPlayQueue = true;
    }
  }

  playItem(event: Event, track: any, index: number){
    this.playerActions.setCurrentTrackByUser(index);
  }

  play(){
    this.playerActions.play();
  }

  pause(){
    this.playerActions.pause();
  }

  adjustRate(){
    this.playerActions.adjustRate();
  }

  async next(){
    if (this.playerActions.getCanPlayNext()){
      await this.animateFwdOut();
      this.playerActions.next(true);
      this.animateFwdIn();
    } else {
      this.playerActions.next(true);
    }
  }

  async prev(){
    if (this.playerActions.getCanPlayPrev()){
      await this.animateBckOut();
      this.playerActions.prev(true);
      this.animateBckIn();
    } else {
      this.playerActions.prev(true);
    }
  }

  fwdInterval(){
    this.playerActions.skipFwd();
  }

  bckInterval(){
    this.playerActions.skipBck();
  }

  shuffle(){
    this.playerActions.shufflePlay();
  }

  toggleRepeat(){
    this.playerActions.toggleRepeat();
  }

  toggleSleepTimer(){
    if (this.playerActions.isSleepTimerActive()){
      this.playerActions.disableSleepTimer();
    } else {
      this.playerActions.createSleepTimer();
    }
  }

  addBookmark(){
    this.playerActions.addBookmark();
  }

  isSongStarred(songID): boolean {
    return this.subsonicActions.isSongStarred(songID);
  }

  async starTrack(event, track){
    console.log('star track');
    await this.subsonicActions.starTrack(track.id);
  }

  async unstarTrack(event, track){
    console.log('unstar track');
    await this.subsonicActions.unstarTrack(track.id);
  }

  onPositionChangeStart(event){
    this.playerActions.stopWatchPosition();
  }

  onPositionChangeEnd(event){
    this.playerActions.seekTo(event);
    this.playerActions.watchPosition();
  }

  async doReorder(event: any) {
    await this.moveArrayItem(this.getCurrentArray(), event.detail.from, event.detail.to);
    await this.moveArrayItem(this.infinitePlayQueue, event.detail.from, event.detail.to);
    event.detail.complete();
  }

  getCurrentArray(){
    return this.store.playerState.playQueue;
  }

  async moveArrayItem(array: any[], from: number, to: number): Promise<any> {
    const modified = array;
    modified.splice(to, 0, modified.splice(from, 1)[0]);
    Promise.resolve(modified);
  }

  async animateFwdOut() {
    this.animate = 'slide-out-left';
    return this.util.addDelay(300);
  }

  async animateFwdIn() {
    this.animate = 'slide-in-left';
    return Promise.resolve();
  }

  async animateBckOut() {
    this.animate = 'slide-out-right';
    await this.util.addDelay(300);
  }

  async animateBckIn() {
    this.animate = 'slide-in-right';
    return Promise.resolve();
  }

  // When the more button is tapped on a track
  async moreOptionsTrack(event: Event, trackItem: any, index: number){
    this.moreOptions.moreOptionsTrack(event, trackItem, 'player', index);
  }

}
