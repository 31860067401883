import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'readableTime'
})
export class ReadableTimePipe implements PipeTransform {

  /*
    Takes a value in seconds and converts it to a string in MM:SS format
   */
  transform(value?: string, format?: string) {

    let returnVal;
    let seconds: any = value;
    const minute = 60;
    const hour = 60;
    let currentSeconds;
    let currentMinutes;
    let currentHours;

    seconds = parseInt(seconds, 10);


    if (isNaN(seconds)) {
      seconds = 0;
    }
    if (seconds > 59) {
    currentMinutes = Math.floor(seconds / minute);
    } else {
      currentMinutes = 0;
    }
    if (seconds > 59){
    currentSeconds = (seconds - (currentMinutes * 60));
    } else{
      currentSeconds = seconds;
    }
    if (currentMinutes > 59) {
      currentHours = Math.floor(currentMinutes / hour);
      currentMinutes = (currentMinutes - (currentHours * 60));
    }

    const formatPadded = (hr, min, sec) => {
      let paddedMin;
      let paddedSec;
      if (sec < 10){
        paddedSec = '0' + sec;
      } else{
        paddedSec = sec; }
      if (min < 10){
        paddedMin = '0' + min;
      } else{
        paddedMin = min;
      }
      return '' + paddedMin + ':' + paddedSec;
    };

    const formatFriendly = (hr, min, sec) => {
      let friendlyString = '';
      if (hr > 1) {
        friendlyString = friendlyString+hr+' hours ';
      }
      if (hr > 0 && hr < 2) {
        friendlyString = friendlyString+hr+' hour ';
      }
      if (min > 1) {
        friendlyString = friendlyString + min + ' minutes';
      }
      if (min > 0 && min < 2) {
        friendlyString = friendlyString + min + ' minute';
      }
      return friendlyString;
    };

    if (format === undefined) {
      returnVal = formatFriendly(currentHours, currentMinutes, currentSeconds);
    }

    if (format === 'padded') {
      returnVal = formatPadded(currentHours, currentMinutes, currentSeconds);
    }

    if (format === 'friendly') {
      returnVal = formatFriendly(currentHours, currentMinutes, currentSeconds);
    }

    return returnVal;
  }

}
