import { Component, OnInit } from '@angular/core';
import { ModalController, AlertController } from '@ionic/angular';
import { MusicCacheService } from 'src/app/services/music-cache.service';
import { PodcastCacheService } from 'src/app/services/podcast-cache.service';
import { AppactionsService } from 'src/app/services/appactions.service';
import { StoreService } from 'src/app/services/store.service';
import { UseractionsService } from 'src/app/services/useractions.service';
import { TouchHandlerService } from 'src/app/services/touch-handler.service';

@Component({
  selector: 'app-download-status',
  templateUrl: './download-status.page.html',
  styleUrls: ['./download-status.page.scss'],
})
export class DownloadStatusPage implements OnInit {

  public reorderEnabled = true;
  public segment = 'music';

  constructor(private modalCtrl: ModalController,
              private alertCtrl: AlertController,
              private appActions: AppactionsService,
              public musicCache: MusicCacheService,
              public podcastCache: PodcastCacheService,
              private touchHandler: TouchHandlerService,
              private userActions: UseractionsService,
              public store: StoreService) { }

  ngOnInit() {
  }

  ionViewWillEnter() {
    this.setInitialSegment();
    this.userActions.checkAppStatusAndReload();
  }

  ionViewDidEnter() {
    this.appActions.setModalOpen();
  }

  ionViewWillLeave() {

  }

  ionViewDidLeave() {
    this.appActions.setModalClosed();
  }

  async dismiss() {
    return this.modalCtrl.dismiss();
  }

  touchMove(event){
    this.touchHandler.touchMoveStopPropagation(event);
  }

  setInitialSegment() {
    if (this.store.appState.hasPendingPodcastDownloads && !this.store.appState.hasPendingDownloads){
      this.segment = 'podcasts';
    }
  }

  toggleReorder(){
    if (this.reorderEnabled){
      this.reorderEnabled = false;
    } else {
      this.reorderEnabled = true;
    }
  }

  async reset(){
    await this.appActions.showLoading('restarting download process...');
    this.musicCache.resetQueueWatcher();
    this.musicCache.resetSongQueueWatcher();
    this.podcastCache.resetQueueWatcher();
    this.podcastCache.resetEpisodeQueueWatcher();
    await this.appActions.hideLoading(2000);
  }

  deleteItem(event, item, index){
    this.musicCache.deleteQueuedItem(index);
  }
  deletePodcastItem(event, item, index){
    this.podcastCache.deleteQueuedItem(index);
  }

  async doReorder(event: any) {
    await this.moveArrayItem(this.getCurrentArray(), event.detail.from, event.detail.to);
    event.detail.complete();
  }

  getCurrentArray(){
    return this.segment === 'music' ? this.musicCache.musicCacheQueue : this.podcastCache.podcastCacheQueue;
  }

  async moveArrayItem(array: [any], from: number, to: number): Promise<any> {
    const modified = array;
    modified.splice(to, 0, modified.splice(from, 1)[0]);
    Promise.resolve(modified);
  }

  async resetTapped(){
    const _alert = await this.alertCtrl.create({
      header: 'Restart Downloads',
      message: 'This will force your downloads to restart if they have stalled due to being in the background too long or due to connectivity issues. Are you sure you want to restart?',
      mode: 'ios',
      translucent: true,
      buttons: [
        {
          text: 'cancel',
          handler: (data) => {
            console.log('restart downloads cancelled');
          }
        },
        {
          text: 'ok',
          handler: (data) => {
            console.log('restart downloads confirmed');
            this.reset();
          }
        }
      ]
    });
    return _alert.present();
  }

}
