/* eslint-disable @typescript-eslint/quotes */
import { Injectable } from '@angular/core';
import * as _ from 'lodash-es';
import * as uuid from "uuid";

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  private oldConsoleLog: any = null;

  constructor() { }

  // List all items in array1 excluding those that exist in array2
  // Matching based on the ID property of each item
  async excludeByID(array1, array2){
    return new Promise(resolve => {
      const exclude = (arr1, arr2) => arr1.filter(o1 => arr2.map(o2 => o2.id).indexOf(o1.id) === -1);
      resolve(exclude(array1, array2));
    });
  }

  // add a delay of ms before resolving
  async addDelay(timeMs: number): Promise<any> {
    console.log('adding delay');
    return new Promise(resolve => {
      if (!timeMs){
        resolve(true);
      }
      setTimeout(() => {
        console.log('delay complete');
        resolve(true);
      }, timeMs);
    });
  }

  // get array of trackIDs from an albumItem for adding to playlists
  async getAlbumTrackIDArray(albumItem: any): Promise<any>{
    const albumTrackArray = [];
    for (let i=0; i<albumItem.song.length; i++) {
      albumTrackArray.push(albumItem.song[i].id);
      if(i === albumItem.song.length-1){
        console.log("TEST LAST ITEM");
        console.log(albumTrackArray);
        return albumTrackArray;
      }
    }
  }

  // return today's date as an ISO8601 string
  getToday(): string {
    const todayDate = new Date().toISOString();
    return todayDate;
  }

  // return a nicely formatted current time for bookmark naming
  getNiceCurrentDateTime(t24?: boolean): string {
    const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const months = ["January", "February", "March", "April", "May",
                    "June", "July", "August", "September", "October",
                    "November", "December"];

    let formattedTime = '';
    let paddedMins;
    let paddedHours;

    const today = new Date(Date.now());
    const hours = today.getHours();
    const mins = today.getMinutes();

    if(mins < 10) {
      paddedMins = '0'+mins;
    } else {
      paddedMins = mins.toString();
    }
    if(hours < 10) {
      paddedHours = '0'+hours;
    } else {
      paddedHours = hours.toString();
    }

    const day = days[today.getDay()];
    const month = months[today.getMonth()];
    const time24 = paddedHours +':'+paddedMins+':00';
    const time12 = new Date('1970-01-01T' + time24 + 'Z')
                    .toLocaleTimeString('en-US',
                    {timeZone:'UTC',hour12:true,hour:'numeric',minute:'numeric'}
                  );
    if(t24) {
      formattedTime =  day + ' ' + time24;
    } else {
      formattedTime =  day + ' ' + time12;
    }
    return formattedTime;
  }

  // return a UUID
  getUUID(): string {
    return uuid.v4();
  }

  // return a copy of an object or array
  copy(sourceObject): any {
    const destinationObject = _.cloneDeep(sourceObject);
    return destinationObject;
  }

  getRandom(array1: Array<any>) {
    return _.sample(array1);
  }

  // return a randomised copy of the array
  async shuffle(array1: Array<any>): Promise<any>{
    let ctr: number = array1.length;
    let temp: any;
    let index: number;
    // while there are elements in the array
    while (ctr > 0){
      // pick a random index
      index = Math.floor(Math.random() * ctr);
      // decrease the counter by 1
      ctr--;
      // swap the last element
      temp = array1[ctr];
      array1[ctr] = array1[index];
      array1[index] = temp;
    }
    return array1;
  }

  // return a random query string (for cache busting)
  randomQueryString(): string {
    return '?' + Math.random() + '';
  }

  // return a debounced function
  debounce(funcToDebounce, time){
    return _.debounce(funcToDebounce, time, {leading: true, trailing: true});
  }

  enableLogger(){
    if (this.oldConsoleLog == null){return; }
    window.console.log = this.oldConsoleLog;
  }

  disableLogger(){
    this.oldConsoleLog = console.log;
    window.console.log = () => {};
  }

  // remove line breaks and new lines from a string, these break sqlite storage plugin
  sanitizeString(rawString: string){
    if (rawString === undefined) {
      return '';
    }
    if (rawString.length < 2 ){
      return '';
    }
    // remove leading and trailing spaces and new lines
    const trimmedString = rawString.trim();
    // remove new lines and line breaks
    let response = trimmedString.replace(/(\r\n\t|\n|\r\t)/gm, ' ');
    // remove non-ASCII characters
    response = response.replace(/[^A-Za-z 0-9 \.,\?""!@#\$%\^&\*\(\)-_=\+;:<>\/\\\|\}\{\[\]`~]*/g, '') ;
    return response;
  }
}
