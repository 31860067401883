import { Injectable, NgZone } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { EventsService } from './events.service';
import { StoreService } from './store.service';
import { SubsonicService } from './subsonic.service';
import { ImageCacheService } from './image-cache.service';
import { MusicCacheService } from './music-cache.service';
import { PodcastCacheService } from './podcast-cache.service';
import { UtilService } from './util.service';
import { UseractionsService } from './useractions.service';
import { MusicControls} from '@awesome-cordova-plugins/music-controls/ngx';
import { CcMediaplayerService } from '../services/cc-mediaplayer.service';
import { LocalMediaplayerService } from '../services/local-mediaplayer.service';
import { ScrobbleService } from './scrobble.service';


@Injectable({
  providedIn: 'root'
})
export class PlayeractionsService {

  public sleepTimerActive = false;
  public isDoingFirstLoad = false;
  public mediaPlayer: any;
  private isFirstPlay = true;
  private isPreload = false;
  private isBookmarkLoad = false;
  private bookmarkLoadSeek = 0;
  private progressWatcher: any;
  private progressWatcherActive = false;
  private queueWatcher: any;
  private queueWatcherActive = false;
  private sleepTimer: any;
  private playerChangeInProgress = false;
  private playerChangeSeek = 0;
  private isDisconnect = false;

  constructor(private events: EventsService,
              private alertCtrl: AlertController,
              private ngzone: NgZone,
              private musicControls: MusicControls,
              private store: StoreService,
              private subsonic: SubsonicService,
              private userActions: UseractionsService,
              private musicCache: MusicCacheService,
              private podcastCache: PodcastCacheService,
              private scrobbleSvc: ScrobbleService,
              private imageCache: ImageCacheService,
              private util: UtilService,
              private ccMediaplayer: CcMediaplayerService,
              private localMediaplayer: LocalMediaplayerService)
  {
    this.init();
  }

  async init(): Promise<any> {
    // Always start with a local media player
    this.mediaPlayer = this.localMediaplayer;
    return;
  }

  startSleepTimer(timeInMin){
    this.ngzone.run(() => {
      console.log('setting sleep timer for ' + timeInMin);
      if (this.sleepTimerActive){
        this.stopSleepTimer();
      }
      this.sleepTimerActive = true;
    });

    const _time = parseInt(timeInMin,10) * 60 * 1000;

    this.sleepTimer = setTimeout(() => {
      console.log('sleep timer expired');
      this.sleepTimerActive = false;
      this.pause();
    }, _time);
  }

  stopSleepTimer(){
    this.ngzone.run(() => {
      clearTimeout(this.sleepTimer);
      this.sleepTimerActive = false;
    });
  }

  setPlayerChangeInProgress(changeHappening: boolean) {
    if(changeHappening){
      this.store.appState.playerChangeInProgress = true;
    } else {
      this.store.appState.playerChangeInProgress = false;
      this.playerChangeSeek = 0;
    }
  }

  getPlayerChangeInProgress() {
    return this.store.appState.playerChangeInProgress;
  }

  async showNativeCastOptions() {
    return this.useCC();
  }

  async showCastOptions() {

    const inputsArray = [];
    let listeningOn = '';

    if(this.store.playerState.currentMediaplayer === 'local'){
      listeningOn = 'Now listening on device';

      inputsArray.push({
        type: 'radio',
        label: 'This Device',
        value: 'local',
        checked: true
      });

      inputsArray.push({
        type: 'radio',
        label: 'Chromecast',
        value: 'chromecast',
        checked: false
      });
    }
    if(this.store.playerState.currentMediaplayer === 'chromecast'){
      listeningOn = 'Now listening on chromecast';

      inputsArray.push({
        type: 'radio',
        label: 'This Device',
        value: 'local',
        checked: false
      });

      inputsArray.push({
        type: 'radio',
        label: 'Chromecast',
        value: 'chromecast',
        checked: true
      });
    }

    const alert = await this.alertCtrl.create({
      header: 'Select Output',
      mode: 'ios',
      translucent: true,
      inputs: inputsArray,
      buttons: [
        {
          text: 'cancel',
          handler: (data) => {
            console.log('showCastOptions cancel clicked');
          }
        },
        {
          text: 'ok',
          handler: (data) => {
            console.log('showCastOptions ok clicked');
            console.log(data);
            if (data){
              if(data === 'local') {
                this.useLocal();
              }
              if(data === 'chromecast'){
                this.useCC();
              }
            } else {
              console.log('no option chosen');
            }
          }
        }
      ]
    });
    return alert.present();
  }

  async useLocal() {
    // if there is already a change in progress do nothing
    if(this.getPlayerChangeInProgress()){
      console.log('playerActions: exit change already in progress');
      return;
    }
    // set change in progress
    this.setPlayerChangeInProgress(true);

    let res;
    try {
      res = await this.ccMediaplayer.ccLeaveSession();
    }
    catch(err) {
      res = err;
    }

    if(res.status === 'ok') {
      // Catch the current position
      this.playerChangeSeek = this.store.playerState.currentTrackTime;
      // Stop the old player
      if(this.store.playerState.isReady){
        await this.stop();
      }
      this.store.playerState.currentMediaplayer = 'local';
      this.mediaPlayer = this.localMediaplayer;
      await this.setPlayQueue(this.store.playerState.playQueue, this.store.playerState.currentTrackIndex, false);
    } else {
      this.setPlayerChangeInProgress(false);
    }
  }

  async useCC() {
    // if there is already a change in progress do nothing
    if(this.getPlayerChangeInProgress()){
      console.log('playerActions: exit change already in progress');
      return;
    }
    // set change in progress
    this.setPlayerChangeInProgress(true);

    let res;
    try {
      res = await this.ccMediaplayer.ccReqSession();
    }
    catch(err) {
      res = err;
    }

    if(res.status === 'ok'){
      // Catch the current position
      this.playerChangeSeek = this.store.playerState.currentTrackTime;
      // Stop the old player
      if(this.store.playerState.isReady){
        this.setUserAction(true);
        await this.stop();
      }
      this.store.playerState.currentMediaplayer = 'chromecast';
      this.mediaPlayer = this.ccMediaplayer;
      await this.setPlayQueue(this.store.playerState.playQueue, this.store.playerState.currentTrackIndex, false);
    } else {
      this.setPlayerChangeInProgress(false);
    }
  }

  createBookmark(bookmarkName){
    const _playerState = this.util.copy(this.store.playerState);
    const _bookmark: any = {};
    _bookmark.playerState = _playerState;

    _bookmark.bookmarkCreated = this.util.getToday();
    _bookmark.bookmarkName = bookmarkName;
    _bookmark.id = this.util.getUUID();

    this.store.bookmarkState.bookmarkList.push(_bookmark);
    console.log(this.store.bookmarkState);
    this.store.saveBookmarkState();

    this.showCreateBookmarkSuccess(bookmarkName);
  }

  async deleteBookmark(bookmarkID){
    this.ngzone.run(() => {
      const _index = this.getBookmarkIndex(bookmarkID);
      this.store.bookmarkState.bookmarkList.splice(_index, 1);
      this.store.saveBookmarkState();
    });
  }

  async getBookmarkIndex(bookmarkID){
    for (let i = 0; i < this.store.bookmarkState.bookmarkList.length; i++){
      if (this.store.bookmarkState.bookmarkList[i].id === bookmarkID){
        return i;
      }
    }
  }

  // load the saved bookmark player state
  loadBookmark(bookmarkItem){
    this.setIsBookmarkLoad();
    this.bookmarkLoadSeek = bookmarkItem.playerState.currentTrackTime;
    this.setPlayQueue(bookmarkItem.playerState.playQueue, bookmarkItem.playerState.currentTrackIndex);
  }

  // set the bookmarkLoad status
  setIsBookmarkLoad(){
    this.isBookmarkLoad = true;
  }

  // clear the bookmarkLoad status
  clearIsBookmarkload(){
    this.isBookmarkLoad = false;
    this.bookmarkLoadSeek = 0;
  }

  // seek to the saved bookmark track position
  async doBookmarkLoadSeek(){
    if (this.isBookmarkLoad){
      this.mediaPlayer.setVolume(0);
      setTimeout(() => {
        this.seekTo(this.bookmarkLoadSeek);
        this.clearIsBookmarkload();
        this.mediaPlayer.setVolume(1);
      },500);
    }
  }

  watchQueue(){
    if (this.queueWatcherActive){
      this.stopWatchQueue();
    }

    this.queueWatcherActive = true;
    this.queueWatcher = setInterval(() => {
      if (this.store.playerState.currentMediaplayer === 'local'){
        if ((this.mediaPlayer.audioTag !== undefined || this.mediaPlayer.nativeMedia !== undefined) && this.store.playerState.isReady){
          this.saveQueueState();
          console.log(this.store.playerState);
        }
      }
      if (this.store.playerState.currentMediaplayer === 'chromecast'){
        console.log(this.mediaPlayer.ccMedia);
        console.log(this.ccMediaplayer.isConnected());
        console.log(this.store.playerState.isReady);
        if ((this.mediaPlayer.ccMedia !== undefined) && this.ccMediaplayer.isConnected() && this.store.playerState.isReady){
          this.saveQueueState();
          console.log(this.store.playerState);
        }
      }
    }, 10000);
  }

  stopWatchQueue(){
    this.queueWatcherActive = false;
    clearInterval(this.queueWatcher);
  }

  saveQueueState(){
    this.store.queueState.savedPlayerState = this.store.playerState;
    this.store.queueState.savedQueueAvl = true;
    this.store.saveQueueState();
  }

  // watch and update the current playback position for progress bars
  watchPosition(){
    if (this.progressWatcherActive){
      this.stopWatchPosition();
    }

    this.progressWatcherActive = true;
    this.progressWatcher = setInterval(() => {
      if (this.store.playerState.currentMediaplayer === 'local' && this.store.playerState.isReady){
        if(this.mediaPlayer.audioTag !== undefined || this.mediaPlayer.nativeMedia !== undefined){
          this.setCurrentTrackTime();
        }
      }
      if (this.store.playerState.currentMediaplayer === 'chromecast' && this.store.playerState.isReady){
        if(this.mediaPlayer.ccMedia !== undefined) {
          this.setCurrentTrackTime();
        }
      }
    }, 1000);
  }

  // stop watching the current position
  stopWatchPosition(){
    this.progressWatcherActive = false;
    clearInterval(this.progressWatcher);
  }

  // register event listeners for async updates from playing media
  async registerAudioEventListeners() {
    // native media events
    if (this.mediaPlayer.mediaType === 'native' || this.store.playerState.currentMediaplayer === 'chromecast'){
      this.events.subscribe('ended', () => {
        console.log('ended');
        // If this is triggered while we are changing audio source ignore it.
        // iOS triggers complete callback on stop command, android doesn't.
        if (this.getPlayerChangeInProgress()){
          return;
        }

        this.scrobblePlayed();

        // Android fires complete also on any stop event (like next track or destroy)
        if (!this.store.appState.isANDROID || this.store.playerState.currentMediaplayer === 'chromecast'){
          console.log('AudioEventListeners: Not Android play next');
          this.next();
        } else {
          console.log('AudioEventListenesr: Android handle completion');
          if (!this.getUserAction()){
            console.log('AudioEventListeners: playback complete, not a user action, play next');
            this.next();
          } else {
            console.log('AudioEventListeners: playback complete, user action, reset useraction state only');
            this.setUserAction(false);
          }
        }
      });
      this.events.subscribe('playing', () => {
        console.log('playing');
        this.doPreloadSeek();
        this.doBookmarkLoadSeek();
        this.doPlayerSwitchSeek();
        this.doPlayerDisconnect();
        this.updateRemoteControls();
        if(this.store.playerState.currentMediaplayer === 'chromecast' && this.store.playerState.isPaused && !this.getPlayerChangeInProgress()){
          this.play();
        }
      });
      this.events.subscribe('pause', () => {
        console.log('pause');
        if(this.store.playerState.currentMediaplayer === 'chromecast' && this.store.playerState.isPlaying && !this.getPlayerChangeInProgress()){
          this.pause();
        }
      });
      this.events.subscribe('stop', () => {
        console.log('stop');
      });
      this.events.subscribe('error', (err) => {
        console.log('error');
        console.log(err);
      });
      this.events.subscribe('disconnected', () => {
        if(this.store.playerState.currentMediaplayer === 'chromecast' && !this.getPlayerChangeInProgress()) {
          this.isDisconnect = true;
          this.useLocal();
        }
      });
    }
    // html media events
    if (this.mediaPlayer.mediaType === 'html'){
      this.mediaPlayer.audioTag.addEventListener('ended', (ev) => {
        console.log('ended');
        console.log(ev);
        this.scrobblePlayed();
        this.next();
      });
      this.mediaPlayer.audioTag.addEventListener('canplay', (ev) => {
        console.log('can play');
        console.log(ev);
        console.log(this.mediaPlayer.audioTag.duration);
      });
      this.mediaPlayer.audioTag.addEventListener('canplaythrough', (ev) => {
        console.log('can playthrough');
        console.log(ev);
      });
      this.mediaPlayer.audioTag.addEventListener('playing', (ev) => {
        console.log('playing');
        console.log(ev);
        this.doPreloadSeek();
        this.doBookmarkLoadSeek();
      });
      this.mediaPlayer.audioTag.addEventListener('pause', (ev) => {
        console.log('pause');
        console.log(ev);
      });
      this.mediaPlayer.audioTag.addEventListener('error', (ev) => {
        console.log('error');
        console.log(ev);
      });
      this.mediaPlayer.audioTag.addEventListener('stalled', (ev) => {
        console.log('stalled');
        console.log(ev);
      });
    }
    //await this.util.addDelay(1000);
    return this.util.addDelay(1000);
  }

  // Update the device native remote controls
  updateRemoteControls(){
    const _isPodCast = (this.store.playerState.currentTrack.type === 'podcast');
    const _isMusic = (this.store.playerState.currentTrack.type !== 'podcast');

    let _coverArt: string;

    // Browser remote controls
    if (this.store.appState.isWEB){

      // @ts-ignore
      if(!navigator.mediaSession){
        console.log('playerActions: browser does not supprot mediaSession');
        return;
      }

      console.log('Playeractions: browser supports media session');

      // No cache for web, always online coverart
      _coverArt = this.store.appState.coverArtBase500 + this.store.playerState.currentTrack.coverArt;
      console.log(_coverArt);

        // Set Metadata
        // @ts-ignore
        navigator.mediaSession.metadata = new MediaMetadata({
          title: this.store.playerState.currentTrack.title,
          artist: this.store.playerState.currentTrack.artist,
          album: this.store.playerState.currentTrack.album,
          artwork: [
            { src: _coverArt, sizes: '96x96',   type: 'image/jpeg' },
            { src: _coverArt, sizes: '128x128', type: 'image/jpeg' },
            { src: _coverArt, sizes: '192x192', type: 'image/jpeg' },
            { src: _coverArt, sizes: '256x256', type: 'image/jpeg' },
            { src: _coverArt, sizes: '384x384', type: 'image/jpeg' },
            { src: _coverArt, sizes: '512x512', type: 'image/jpeg' },
          ]
        });
        // @ts-ignore
        navigator.mediaSession.setActionHandler('play', (details) => {this.htmlMusicControlsEvents(details);});
        // @ts-ignore
        navigator.mediaSession.setActionHandler('pause', (details) => {this.htmlMusicControlsEvents(details);});
        // @ts-ignore
        navigator.mediaSession.setActionHandler('nexttrack', (details) => {this.htmlMusicControlsEvents(details);});
        // @ts-ignore
        navigator.mediaSession.setActionHandler('previoustrack', (details) => {this.htmlMusicControlsEvents(details);});
    }
    // Native remote controls
    if (!this.store.appState.isWEB) {
      // handle cached cover art
      if (this.imageCache.check(this.store.playerState.currentTrack.coverArt)){
        console.log('updateRemoteControls: using cached coverArt');
        // native music controls expects a protocol prefix
        _coverArt = this.imageCache.get(this.store.playerState.currentTrack.coverArt, true);
      } else {
        console.log('updateRemoteControls: using remote coverArt');
        _coverArt = this.store.appState.coverArtBase500 + this.store.playerState.currentTrack.coverArt;
      }
      console.log(_coverArt);

      this.musicControls.create({
        track       : this.store.playerState.currentTrack.title,  // optional, default : ''
        artist      : this.store.playerState.currentTrack.artist, // optional, default : ''
        cover       : _coverArt,  // optional, default : nothing
        // cover can be a local path (use fullpath 'file:///storage/emulated/...', or only 'my_image.jpg'
        // if my_image.jpg is in the www folder of your app)
        // or a remote url ('http://...', 'https://...', 'ftp://...')
        isPlaying   : this.store.playerState.isPlaying, // optional, default : true
        dismissable : true, // optional, default : false
        // hide previous/next/close buttons:
        hasPrev   : _isMusic,      // show previous button, optional, default: true
        hasNext   : _isMusic,      // show next button, optional, default: true
        hasClose  : false,       // show close button, optional, default: false
        // iOS only, optional
        album : this.store.playerState.currentTrack.album,  // optional, default: ''
        duration : this.store.playerState.currentTrack.duration, // optional, default: 0
        elapsed : 0, // optional, default: 0
        hasSkipForward : _isPodCast,  // show skip forward button, optional, default: false
        hasSkipBackward : _isPodCast, // show skip backward button, optional, default: false
        skipForwardInterval : this.store.userState.skipFwdInt, // display number for skip forward, optional, default: 0
        skipBackwardInterval : this.store.userState.skipBckInt, // display number for skip backward, optional, default: 0
        hasScrubbing : true, // enable scrubbing from the progress bar in control center or lock screen
        // Android only, optional
        // text displayed in the status bar when the notification (and the ticker) are updated
        ticker    : 'Now playing: ' + this.store.playerState.currentTrack.title + ''
      });
      this.musicControls.subscribe().subscribe((action) => {
        this.musicControlsEvents(action);
      });
      this.musicControls.listen();
    }
  }

  htmlMusicControlsEvents(details: any): void {
    switch(details.action) {
      case 'play':
        this.play();
        break;
      case 'pause':
        this.pause();
        break;
      case 'nexttrack':
        this.next(true);
        break;
      case 'previoustrack':
        this.prev(true);
        break;
    }
  }

  // listen for native remote control events
  musicControlsEvents(action: any): void {
    const _message = JSON.parse(action).message;
    // const _message = action;
    console.log('!! ACTION - ' + _message);
    this.ngzone.run(() => {
    switch (_message) {
      case 'music-controls-next':
        console.log('remote next');
        this.next(true);
        break;
      case 'music-controls-previous':
        console.log('remote prev');
        this.prev(true);
        break;
      case 'music-controls-pause':
        console.log('remote pause');
        this.pause();
        break;
      case 'music-controls-play':
        console.log('remote play');
        this.play();
        break;
      case 'music-controls-destroy':
        // Do something
        break;
      case 'music-controls-toggle-play-pause':
        console.log('toggle play pause');
        this.togglePlayPause();
        break;
      // Headset events (Android only)
      case 'music-controls-media-button' :
        console.log('media button');
        this.togglePlayPause();
        break;
      case 'music-controls-headset-unplugged':
        console.log('headset unplugged');
        this.pause();
        break;
      case 'music-controls-headset-plugged':
        console.log('headset plugged');
        break;
      case 'android.bluetooth.headset.profile.action.CONNECTION_STATE_CHANGED':
        console.log('remote bluetooth connections state change');
        this.pause();
        break;
      case 'music-controls-seek-to':
        const seekToInSeconds = JSON.parse(action).position;
        this.musicControls.updateElapsed({
          elapsed: seekToInSeconds,
          isPlaying: this.store.playerState.isPlaying
        });
        this.seekTo(seekToInSeconds);
        break;
      case 'music-controls-skip-forward':
        this.skipFwd();
        break;
      case 'music-controls-skip-backward':
        this.skipBck();
        break;

      default:
        break;
    }
    });
  }

  // set whether prev and next tracks are available
  async updatePrevNextAbility() {
    if (this.store.playerState.currentTrackIndex === this.store.playerState.playQueue.length - 1 &&
        (this.store.playerState.repeatEnabled === 0)){

      console.log('canPlayNext FALSE');
      this.store.playerState.canPlayNext = false;
    } else {
      console.log('canPlayNext TRUE');
      this.store.playerState.canPlayNext = true;
    }
    if (this.store.playerState.currentTrackIndex === 0) {
      console.log('canPlayPrev FALSE');
      this.store.playerState.canPlayPrev = false;
    } else {
      console.log('canPlayPrev TRUE');
      this.store.playerState.canPlayPrev = true;
    }
  }

  // When doing a re-order on the playqueue it may be necessary to reset
  // the current track index
  setCurrentTrackIndex(newIndex: number): void {
    this.store.playerState.currentTrackIndex = newIndex;
    this.store.saveQueueState();
  }

  getCanPlayNext(){
    return this.store.playerState.canPlayNext;
  }

  getCanPlayPrev(){
    return this.store.playerState.canPlayPrev;
  }

  // set whether an event was triggered by a user action
  setUserAction(bool): void {
    this.store.playerState.userAction = bool;
  }

  // get userAction state
  getUserAction(): boolean {
    return this.store.playerState.userAction;
  }

  // when preloading a saved play state but not starting playback
  setIsPreload(){
    this.isPreload = true;
  }

  // clear the preload status
  clearIsPreload(){
    this.isPreload = false;
  }

  // load the saved playqueue
  doPreload(){
    this.setIsPreload();
    this.setPlayQueue(this.store.queueState.savedPlayerState.playQueue, this.store.queueState.savedPlayerState.currentTrackIndex, true);
  }

  // seek to the saved preloaded position
  doPreloadSeek(){
    if (this.isPreload){
      this.clearIsPreload();
      this.mediaPlayer.setVolume(0);
      setTimeout(() => {
        this.seekTo(this.store.queueState.savedPlayerState.currentTrackTime);
        this.mediaPlayer.setVolume(1);
      },500);
    }
  }

  // do player switch seek
  doPlayerSwitchSeek(){
    if(this.getPlayerChangeInProgress() && !this.isDisconnect){
      this.mediaPlayer.setVolume(0);
      setTimeout(() => {
        this.seekTo(this.playerChangeSeek);
        this.mediaPlayer.setVolume(1);
        this.setPlayerChangeInProgress(false);
      },500);
    }
  }

  // do player disconnect fallback
  doPlayerDisconnect(){
    if(this.getPlayerChangeInProgress() && this.isDisconnect){
      this.mediaPlayer.setVolume(0);
      setTimeout(() => {
        this.seekTo(this.playerChangeSeek);
      },500);

      setTimeout(() => {
        this.isDisconnect = false;
        this.pause();
        this.mediaPlayer.setVolume(1);
        this.setPlayerChangeInProgress(false);
      },700);
    }
  }

  // add trackItem to the playqueue after the current track
  async playNext(trackItem: any) {
    if(this.store.playerState.playQueue.length === 0 || !this.store.playerState.isReady){
      await this.setPlayQueue([trackItem],0);
    } else {
      this.store.playerState.playQueue.splice(this.store.playerState.currentTrackIndex + 1, 0, trackItem);
    }

    this.updatePrevNextAbility();
    await this.saveQueueState();
    this.showPlayNextSuccess(trackItem);
  }

  // replace the entire playqueue with new and start from startingIndex
  async setPlayQueue(newPlayQueue: Array<any>, startingIndex: number, isPreload?: boolean): Promise<any> {
    if (!isPreload) {
      this.clearIsPreload();
    }
    if (!this.isFirstPlay){
      this.setUserAction(true);
    }
    this.store.playerState.playQueue = this.util.copy(newPlayQueue);
    await this.setCurrentTrack(startingIndex);

    this.events.publish('PlayQueueUpdated');
  }

  // append to the end of the current playqueue
  async addToPlayQueue(trackItem): Promise<any> {
    if (this.store.playerState.playQueue.length){
      this.store.playerState.playQueue.push(trackItem);
      this.updatePrevNextAbility();
    } else {
      this.setPlayQueue([trackItem], 0);
    }
  }

  async addMultipleToPlayQueue(trackItemArray): Promise<any> {
    if(this.store.playerState.playQueue.length){
      this.store.playerState.playQueue = this.store.playerState.playQueue.concat(trackItemArray);
    } else {
      this.setPlayQueue(trackItemArray, 0);
    }
  }

  // cache ahead based on users auto cache setting
  autoCache(){
    if (this.store.appState.isWEB){
      console.log('autocache disabled on web');
      return;
    }
    // podcast handling
    if (this.store.playerState.currentTrack.type === 'podcast'){
      if (this.store.userState.cacheAutoRate !== 0){
        for (let i = 0; i <= this.store.userState.cacheAutoRate; i++){
          if (this.store.playerState.playQueue[this.store.playerState.currentTrackIndex + i]){
            console.log('autocache episode ' + i + ' ' +
            this.store.playerState.playQueue[this.store.playerState.currentTrackIndex + i].title);
            this.podcastCache.reqCacheEpisode(this.util.copy(this.store.playerState.playQueue[this.store.playerState.currentTrackIndex + i]));
          }
          else {
            console.log('autocache episode no playqueue items left to cache');
          }
        }
      }
    }
    // all other media types handling
    else {
      if (this.store.userState.cacheAutoRate !== 0){
        for (let i = 0; i <= this.store.userState.cacheAutoRate; i++){
          if (this.store.playerState.playQueue[this.store.playerState.currentTrackIndex + i]){
            console.log('autocache track ' + i + ' ' + this.store.playerState.playQueue[this.store.playerState.currentTrackIndex + i].title);
            this.musicCache.reqCacheSong(this.util.copy(this.store.playerState.playQueue[this.store.playerState.currentTrackIndex + i]));
          }
          else {
            console.log('autocache track no playqueue items left to cache');
          }
        }
      }
    }
  }

  // set the current track to play by the UI
  async setCurrentTrackByUser(index: number): Promise<any> {
    this.setUserAction(true);
    this.clearIsPreload();
    await this.setCurrentTrack(index);
  }

  // set the current track to play internally
  async setCurrentTrack(index: number): Promise<any> {
    this.store.playerState.currentTrackTime = 0;
    this.store.playerState.currentTrackProg = 0;
    this.store.playerState.currentTrack = this.store.playerState.playQueue[index];
    this.store.playerState.currentTrackIndex = index;
    this.store.playerState.isReady = true;
    this.updatePrevNextAbility();
    let _cachedUrlAvailable: any;
    let _playbackUrl: string;
    // handle podcast specific logic
    if (this.store.playerState.currentTrack.type === 'podcast'){
      // check for a cached episode
      _cachedUrlAvailable = this.podcastCache.getCachedEpisodeUrl(this.store.playerState.currentTrack.id);
      // use cached track if available
      if (_cachedUrlAvailable.status === 'success' && this.store.playerState.currentMediaplayer === 'local'){
        _playbackUrl = _cachedUrlAvailable.data;
        console.log('playing cached resource: ' + _playbackUrl);
      }
      // else use streaming track
      else {
        _playbackUrl = this.subsonic.stream(this.store.playerState.currentTrack.streamId);
        console.log('playing streamed resource: ' + _playbackUrl);
      }
      // track plays locally for podcasts
      this.userActions.addPlaybackCount(this.store.playerState.currentTrack.id);
    }
    // handle all other media types
    else {
      // check for a cached track
      _cachedUrlAvailable = this.musicCache.getCachedSongUrl(this.store.playerState.currentTrack.id);
      // use cached track if available
      if (_cachedUrlAvailable.status === 'success' && this.store.playerState.currentMediaplayer === 'local'){
        _playbackUrl = _cachedUrlAvailable.data;
        console.log('playing cached resource: ' + _playbackUrl);
      }
      // else use streaming track
      else {
        _playbackUrl = this.subsonic.stream(this.store.playerState.currentTrack.id);
        console.log('playing streamed resource: ' + _playbackUrl);
      }
    }

    await this.mediaPlayer.setSource(_playbackUrl, this.store.playerState.currentTrack);

    if (!this.isPreload){
      this.play();
    }

    // don't autocache or scrobble on preload
    if (!this.isPreload){
      // don't cache when casting
      if(this.store.playerState.currentMediaplayer === 'local'){
        this.autoCache();
      }
      this.scrobbleNowPlaying();
    }
  }

  // Playback complete submission
  async scrobblePlayed(){
    this.scrobbleSvc.scrobblePlayed(this.store.playerState.currentTrack.id);
    // if (!this.store.userState.offlineMode && !(this.store.playerState.currentTrack.type === 'podcast')
    //     && !this.getPlayerChangeInProgress()){
    //   const test = await this.subsonic.scrobble(this.store.playerState.currentTrack.id, undefined, true);
    //   console.error(test);
    // }
  }

  // Now Playing submission
  scrobbleNowPlaying(){
    this.scrobbleSvc.scrobbleNowPlaying(this.store.playerState.currentTrack.id);

    // if (!this.store.userState.offlineMode && !(this.store.playerState.currentTrack.type === 'podcast')
    //     && !this.getPlayerChangeInProgress()){
    //   this.subsonic.scrobble(this.store.playerState.currentTrack.id, undefined, false);
    // }
  }

  // 0.0 stopped
  // 0.5 half speed
  // 1.0 normal
  // 2.0 double speed
  setRate(chosenRate){
    this.mediaPlayer.setRate(+chosenRate);
    this.store.appState.currentPlayRate = +chosenRate;
  }

  async adjustRate(){
    this.setRateDialog();
  }

  getRate(){
    return this.store.appState.currentPlayRate;
  }

  // toggle play / pause status
  togglePlayPause(){
    if (this.store.playerState.isPlaying) {
      this.pause();
    } else if (this.store.playerState.isPaused){
      this.play();
    }
  }

  async play(){
    if (this.isFirstPlay){
      this.isDoingFirstLoad = true;
      await this.registerAudioEventListeners();
      this.watchPosition();
      this.watchQueue();
      this.isFirstPlay = false;
    }
    this.store.playerState.isPlaying = true;
    this.store.playerState.isPaused = false;
    this.store.playerState.isStopped = false;
    this.mediaPlayer.play();
    this.updateRemoteControls();
    this.isDoingFirstLoad = false;
  }

  pause(){
    this.store.playerState.isPlaying = false;
    this.store.playerState.isPaused = true;
    this.store.playerState.isStopped = false;
    this.mediaPlayer.pause();
    this.updateRemoteControls();
  }

  stop(){
    this.store.playerState.isPlaying = false;
    this.store.playerState.isPaused = true;
    this.store.playerState.isStopped = true;
    this.mediaPlayer.stop();
    this.updateRemoteControls();
  }

  next(userAction?: boolean){
    if (this.store.playerState.canPlayNext){
      if (userAction){
        this.setUserAction(true);
      }
      // repeat 1 enabled
      if (this.store.playerState.repeatEnabled === 1){
        this.setCurrentTrack(this.store.playerState.currentTrackIndex);
      }
      // last track with repeat enabled
      else if (this.store.playerState.currentTrackIndex === this.store.playerState.playQueue.length - 1){
        this.setCurrentTrack(0);
      }
      // no repeat go to next track
      else {
        this.setCurrentTrack(this.store.playerState.currentTrackIndex + 1);
      }
    } else {
      this.pause();
      // TODO HANDLE STOP
    }
  }

  prev(userAction?: boolean){
    // If more than 5 seconds into track and playing go to start of current track not prev track
    if (this.store.playerState.isPlaying && this.store.playerState.currentTrackTime > 5) {
      this.seekTo(1);
      return;
    }

    // Else perform default previous track action
    if (this.store.playerState.canPlayPrev){
      if (userAction){
        this.setUserAction(true);
      }
      this.setCurrentTrack(this.store.playerState.currentTrackIndex - 1);
      return;
    }
  }

  skipFwd(){
    if ((this.store.playerState.currentTrackTime + this.store.userState.skipFwdInt) < this.store.playerState.currentTrack.duration){
      this.seekTo(this.store.playerState.currentTrackTime + this.store.userState.skipFwdInt);
    } else {
      this.next(true);
    }
    this.musicControls.updateElapsed({
      elapsed: this.store.playerState.currentTrackTime.toString(),
      isPlaying: this.store.playerState.isPlaying
    });
  }

  skipBck(){
    if ((this.store.playerState.currentTrackTime - this.store.userState.skipBckInt) > 0){
      this.seekTo(this.store.playerState.currentTrackTime - this.store.userState.skipBckInt);
    } else {
      this.seekTo(0);
    }
    this.musicControls.updateElapsed({
      elapsed: this.store.playerState.currentTrackTime.toString(),
      isPlaying: this.store.playerState.isPlaying
    });
  }

  isSleepTimerActive(){
    return this.sleepTimerActive;
  }

  disableSleepTimer(){
    if (this.sleepTimerActive){
      this.stopSleepTimer();
    }
  }

  createSleepTimer(){
    this.newSleepTimerDialog();
  }

  addBookmark(){
    if (this.store.userState.autonameBookmarks) {
      this.createBookmark(this.util.getNiceCurrentDateTime());
    } else {
      this.newBookmarkDialog();
    }
  }

  // shuffle the current play queue and start playing from start
  async shufflePlay(): Promise<any>{
    const _shuffledQueue = await this.util.shuffle(this.store.playerState.playQueue);
    return this.setPlayQueue(_shuffledQueue, 0);
  }

  // toggle repeat
  // 0 no repeat
  // 1 repeat 1
  // 2 repeat all
  toggleRepeat(){
    if (this.store.playerState.repeatEnabled < 2){
      this.store.playerState.repeatEnabled++;
    }
    else {
      this.store.playerState.repeatEnabled = 0;
    }
    this.updatePrevNextAbility();
    return;
  }

  seekTo(secs: number){
    // android will not seek to 0 or < 0
    if (secs <= 0) {
      secs = 1;
    }
    this.mediaPlayer.seekTo(secs);
  }

  resetCurrentTrackTime(){
    this.store.resetPlayerProgress();
  }

  async setCurrentTrackTime(){
    const _currentTime = await this.mediaPlayer.getCurrentPosition();
    if (_currentTime !== -1){
      const currentTrackTime = _currentTime;
      const currentTrackProg = Math.floor(_currentTime / this.store.playerState.currentTrack.duration * 100);
      this.store.updatePlayerProgress(currentTrackTime, currentTrackProg);
      if (!this.store.appState.isWEB){
        this.musicControls.updateElapsed({
          elapsed: this.store.playerState.currentTrackTime.toString(),
          isPlaying: this.store.playerState.isPlaying
        });
      }
    } else {
      // do nothing
    }
  }

  getCurrentTrackTime(){
    return this.store.playerState.getCurrentTrackTime;
  }

  // get progress in current track as %
  getCurrentTrackProg(){
    return this.store.playerState.getCurrentTrackProg;
  }

  // new sleep timer interval dialog
  async newSleepTimerDialog(){

    const inputsArray = [];

    inputsArray.push({
      type: 'radio',
      label: '10 minutes',
      value: '10'
    });
    inputsArray.push({
      type: 'radio',
      label: '20 minutes',
      value: '20'
    });
    inputsArray.push({
      type: 'radio',
      label: '30 minutes',
      value: '30',
      checked: true
    });
    inputsArray.push({
      type: 'radio',
      label: '1 hour',
      value: '60'
    });

    const _alert = await this.alertCtrl.create({
      header: 'Set Sleep Timer',
      message: 'Playback will automatically pause when your sleep timer expires.',
      mode: 'ios',
      translucent: true,
      inputs: inputsArray,
      buttons: [
        {
          text: 'cancel',
          handler: (data) => {
            console.log('newSleepTimerDialog cancel clicked');
          }
        },
        {
          text: 'ok',
          handler: (data) => {
            console.log('newSleepTimerDialog ok clicked');
            console.log(data);
            if (data){
              console.log('newSleepTimerDialog value: ' + data);
              this.startSleepTimer(data);
            } else {
              console.log('no option chosen');
            }
          }
        }
      ]
    });
    return _alert.present();
  }

    // set playback rate dialog
    async setRateDialog(){
      const currentRate = this.store.appState.currentPlayRate.toString();

      const inputsArray = [
      ];

      inputsArray.push({
        type: 'radio',
        label: '0.50x',
        value: '0.5',
        checked: false
      });
      inputsArray.push({
        type: 'radio',
        label: '0.75x',
        value: '0.75',
        checked: false
      });
      inputsArray.push({
        type: 'radio',
        label: 'normal',
        value: '1',
        checked: false
      });
      inputsArray.push({
        type: 'radio',
        label: '1.25x',
        value: '1.25',
        checked: false
      });
      inputsArray.push({
        type: 'radio',
        label: '1.5x',
        value: '1.5',
        checked: false
      });
      inputsArray.push({
        type: 'radio',
        label: '1.75x',
        value: '1.75',
        checked: false
      });
      inputsArray.push({
        type: 'radio',
        label: '2x',
        value: '2',
        checked: false
      });

      inputsArray.forEach((input) => {
        if (currentRate === input.value){
          input.checked = true;
        }
      });

      const _alert = await this.alertCtrl.create({
        header: 'Playback Rate',
        message: 'Adjust the audio playback rate.',
        mode: 'ios',
        translucent: true,
        inputs: inputsArray,
        buttons: [
          {
            text: 'cancel',
            handler: (data) => {
              console.log('setRateDialog cancel clicked');
            }
          },
          {
            text: 'ok',
            handler: (data) => {
              console.log('setRateDialog ok clicked');
              console.log(data);
              if (data){
                console.log('setRateDialog value: ' + data);
                this.setRate(data);
              } else {
                console.log('no option chosen');
              }
            }
          }
        ]
      });
      return _alert.present();
    }

  async newBookmarkDialog(){

    const _alert = await this.alertCtrl.create({
      header: 'New Bookmark',
      message: 'Enter a name for this bookmark, your play queue and current position will be saved.',
      mode: 'ios',
      translucent: true,
      inputs: [
        {name: 'name', placeholder: 'name'}
      ],
      buttons: [
        {
          text: 'cancel',
          handler: (data) => {
            console.log('newBookmarkDialog cancel clicked');
          }
        },
        {
          text: 'save',
          handler: (data) => {
            console.log('newBookmarkDialog save clicked');
            if (!data.name.length){
              return false;
            } else {
              console.log('newBookmarkDialog create');
              this.createBookmark(data.name);
            }
          }
        }
      ]
    });
    return _alert.present();
  }

  // If track add to playlist success
  async showCreateBookmarkSuccess(bookmarkName: string){
    const alert = await this.alertCtrl.create({
      header: 'Bookmark Created',
      message: 'New bookmark ' + bookmarkName + ' created, your bookmarks are availeble in the menu when you want to resume playback.',
      mode: 'ios',
      translucent: true,
      buttons: ['OK']
    });
    return alert.present();
  }

  // If playNext success
  async showPlayNextSuccess(trackItem: any) {
    const _alert = await this.alertCtrl.create({
      header: 'Track Added',
      message: trackItem.artist + ' - ' +trackItem.title + ' will play next.',
      mode: 'ios',
      translucent: true
    });

    await _alert.present();

    setTimeout(() => {
      _alert.dismiss();
    },1200);
  }
}
