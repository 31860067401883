import { Injectable, NgZone, ApplicationRef } from '@angular/core';
import { MenuController, ToastController, LoadingController, AlertController, Platform, IonContent, NavController } from '@ionic/angular';
import { EventsService } from '../services/events.service';
import { StoreService } from '../services/store.service';
import { PersistenceService } from '../services/persistence.service';
import { SubsonicService} from '../services/subsonic.service';
import { buildInfo } from '../buildinfo';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { Insomnia } from '@awesome-cordova-plugins/insomnia/ngx';
import { LocalNotifications } from '@awesome-cordova-plugins/local-notifications/ngx';
import { BackgroundMode } from '@awesome-cordova-plugins/background-mode/ngx';
import { Keyboard } from '@awesome-cordova-plugins/keyboard/ngx';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { ThemeService } from './theme.service';
import { AndroidPermissionsService } from './android-permissions.service';

declare global {
  interface Navigator {
    app: any;
  }
}

@Injectable({
  providedIn: 'root'
})
export class AppactionsService {

  private _errorToast: any;
  private _loader: HTMLIonLoadingElement;
  private idleWatcher: any;
  private idleTime = 0;
  private idleWatcherActive = false;

  constructor(private menuCtrl: MenuController,
              private alertCtrl: AlertController,
              private toastCtrl: ToastController,
              private loadingCtrl: LoadingController,
              private store: StoreService,
              private navCtrl: NavController,
              private persist: PersistenceService,
              private subsonic: SubsonicService,
              private themeSvc: ThemeService,
              private network: Network,
              private nativeSplashscreen: SplashScreen,
              private insomnia: Insomnia,
              private background: BackgroundMode,
              private keyboard: Keyboard,
              private screenOrientation: ScreenOrientation,
              private localNote: LocalNotifications,
              private androidPerms: AndroidPermissionsService,
              private platform: Platform,
              private ngzone: NgZone,
              private appref: ApplicationRef,
              private events: EventsService) { }

  // Early settings on app launch (called in app.component.ts)
  async preInit(){
    // detect the platforms
    this.setPlatform();
    // setup the keyboard defaults
    this.initKeyboard();
    // set allowed Screen orientations
    this.setAllowedScreenOrientations();
    // get initial orientation
    this.updateScreenOrientation();
    // register network state listeners
    this.registerNetworkStateListeners();
    // set initial network state
    this.setNetworkState(true);
    // init Android Permissions service
    this.androidPerms.init();
  }

  // Settings that depend on some user data
  async init(){
    // set coverart & cached audio base URL's for this session
    this.setCoverArtBase200();
    this.setCoverArtBase500();
    this.setCachedMusicBase();
    // register cache error event handler
    this.registerCacheFailureHandler();

    // set app version
    this.store.appState.appVersion = buildInfo.buildVersion;

    // start the theme service
    this.themeSvc.init();

    if(this.store.appState.isANDROID) {
      // init background mode config
      this.background.setDefaults({ silent: true });
      this.background.disableWebViewOptimizations();
      if(this.store.appState.isBackgroundModeEnabled){
        this.background.disableBatteryOptimizations();
      }
    }
  }

  //
  // Screen Orientation Handler
  //

  // allow landscape only on tablets on real devices
  setAllowedScreenOrientations(){
    // locks for web
    if (this.store.appState.iSWEB){
      return;
    }
    // locks for ios
    if (this.store.appState.isIOS && !this.store.appState.isTABLET) {
      console.log('IOS PHONE: Portrait only');
      this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);
    }
    if (this.store.appState.isIOS && this.store.appState.isTABLET) {
      console.log('IOS TABLET: Portrait or Landscape');
      this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.ANY);
    }
    // locks for android
    if (this.store.appState.isANDROID && !this.store.appState.isTABLET) {
      console.log('ANDROID PHONE: Portrait only');
      this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);
    }
    if (this.store.appState.isANDROID && this.store.appState.isTABLET) {
      console.log('ANDROID TABLET: Portrait or Landscape');
      this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.ANY);
    }
  }

  // listen for orientation change events
  registerScreenOrientationListener(){
    this.screenOrientation.onChange().subscribe(() => {
      console.log('orientation changed');
      this.updateScreenOrientation();
    });
  }

  // update the current orientation
  updateScreenOrientation(){
    if (this.store.appState.isWEB) {
      return;
    }
    const orient = this.screenOrientation.type;
    if (orient.indexOf('landscape') > -1 ){
      this.store.appState.deviceOrientation = 'landscape';
    }
    if (orient.indexOf('portrait') > -1 ){
      this.store.appState.deviceOrientation = 'portrait';
    }
  }

  //
  // Cache failure handler
  //

  // display error to user when caching an item completely fails
  registerCacheFailureHandler(){
    this.events.subscribe('cacheFailure', (item) => {
      console.log('appActions registered failure');
      this.showError('Download failed for ' + item.name);
    });
  }

  //
  // Network status
  //

  // listen for network state events
  registerNetworkStateListeners(){
    this.network.onDisconnect().subscribe(() => {
      console.log('network disconnected');
      // new connection type isn't available immediately
      setTimeout(() => {
        this.setNetworkState();
      }, 3000);
    });

    this.network.onConnect().subscribe(() => {
      console.log('network connected');
      // net connection type isn't available immediately
      setTimeout(() => {
        this.setNetworkState();
      }, 3000);
    });

    this.network.onChange().subscribe(() => {
      console.log('network state change');
      // extra delay so it doesn't conflict with connect/disconnect
      setTimeout(() => {
        this.setNetworkState(true);
      }, 6000);
    });
  }

  // update the connection availability and type
  // if silent do not trigger visible alerts
  setNetworkState(silent?: boolean) {
    const type = this.network.type;
    console.log('networktype: ' + type);
    // there is no change from previous state
    if (type === this.store.appState.connectionType) {
      console.log('network state not changed');
      return;
    }

    // there is a non cellular connection
    if (type === 'wifi' || type === 'ethernet') {
      this.store.appState.connectionAvailable = true;
      this.store.appState.connectionType = type;
      if (!silent) {
        this.showError('internet access restored. Connected to ' + this.store.appState.conectionType);
      }
    }
    // there is a cellular connection
    if (type === '2g' || type === '3g' || type === '4g'
    || type === '5g' || type === 'cellular' || type === 'cell'){
      this.store.appState.connectionAvailable = true;
      this.store.appState.connectionType = 'mobile';
      if (!silent){
        this.showError('internet access restored. Connected to ' + this.store.appState.conectionType);
      }
    }
    // connection state is unknown
    if (type === 'unknown'){
      this.store.appState.connectionAvailable = true;
      this.store.appState.connectionType = type;
      if (!silent){
        this.showError('internet access state unknown. That\'s weird.');
      }
    }
    // there is no connection available
    if (type === 'none'){
      this.store.appState.connectionAvailable = false;
      this.store.appState.connectionType = 'none';
      if (!silent){
        this.showError('no internet access');
      }
    }
    console.log('connectionAvailable: ' + this.store.appState.connectionAvailable);
  }

  async getSubsonicAPIState(){
    return await this.subsonic.ping();
  }

  //
  // Scroll Helpers
  //

  // listen for scroll start event from ion-content
  scrollStartListener(){
    console.log('appActions: scroll start event received');
    this.ngzone.run(() => {
      // enable the scroll top fab
      this.store.appState.showScrollTop = true;
      // if the keyboard is open hide it when we start to scroll
      this.hideKB();
    });

  }

  // listen for scroll end event from ion-content
  // pass in a reference to the ion content on the page in question
  async scrollEndListener(content?: IonContent){
    console.log('appActions: scroll end event received');
    this.ngzone.run(async () => {
      if (content !== undefined){
        const scrollEle = await content.getScrollElement();
        if (scrollEle.scrollTop < 100) {
          console.log('appActions: scroll end and scroll top < 100');
          // back at the top disable the scroll top fab
          this.store.appState.showScrollTop = false;
        }
      }
    });
  }


  //
  // Keyboard helpers
  //
  initKeyboard(){
    console.log('appActions: keyboard init');
    this.registerKBStateListeners();
  }

  registerKBStateListeners(){
    this.keyboard.onKeyboardHide()
      .subscribe((data) => {
        console.log('appActions: keyboard hide event received');
        this.store.appState.keyboardVisible = false;
      });
    this.keyboard.onKeyboardShow()
      .subscribe((data) => {
        console.log('appActions: keyboard show event received');
        this.store.appState.keyboardVisible = true;
      });
  }

  hideKB(){
    if (this.store.appState.keyboardVisible) {
      this.store.appState.keyboardVisible = false;
      this.keyboard.hide();
    }
  }

  showKB(){
    if (!this.store.appState.keyboardVisible) {
      this.store.appState.keyboardVisible = true;
      this.keyboard.show();
    }
  }

  //
  // First Load tracking
  //

  // Refresh the starred lists on first load
  setStarredUpdated(){
    this.store.appState.starredUpdated = true;
  }

  async showAndroidPermsNotice() {
    if(!this.store.appState.isBackgroundModeEnabled && !this.store.userState.doNotShowPermsNotice && this.store.appState.isANDROID) {

      const alertButtons = [
        {
          text: 'Don\'t show again',
          role: 'cancel',
          handler: () => {this.doNotShowPermsNotice();}
        },
        {
          text: 'Go To Settings',
          role: 'confirm',
          handler: () => {
            this.ngzone.run(() => {
              this.navCtrl.navigateForward('settings');
            });
          }
        }
      ];

      const alert = await this.alertCtrl.create({
        header: 'Missing Permissions',
        message: 'substreamer needs some extra permissions to run smoothly in the background, please enable the ignore battery optimization option in the setting screen to avoid issues during playback.',
        buttons: alertButtons,
      });

      await alert.present();
    }
  }

  doNotShowPermsNotice(){
    this.store.userState.doNotShowPermsNotice = true;
    this.store.saveUserState();
  }

  setResizeForMiniPlayerNeeded(){
    this.store.appState.resizeNeeded = false;
  }

  // resize content if miniplayer has just been spawned
  resizeIfNeeded(content: IonContent){
    if (this.store.appState.resizeNeeded) {
      setTimeout(() => {
        // TODO V4
        // content.resize();
        this.store.appState.resizeNeeded = false;
      }, 500);
    }
  }

  //
  // Platform Tracking
  //

  // set platform and webview on start
  setPlatform(){
    if (this.platform.is('ios') && this.platform.is('cordova')){
      console.log('platform is IOS');
      this.store.appState.isIOS = true;
    }
    if (this.platform.is('android') && this.platform.is('cordova')){
      console.log('platform is ANDROID');
      this.store.appState.isANDROID = true;
    }
    if (!this.platform.is('cordova')){
      console.log('platform is WEB');
      this.store.appState.isWEB = true;
    }
    if (this.platform.is('ios')) {
      console.log('webview is WKWEBVIEW');
      this.store.appState.isWKWEBVIEW = true;
    }
    if (this.platform.is('tablet') || this.platform.is('ipad')){
      this.store.appState.isTABLET = true;
    }
    console.log('platforms: ' + this.platform.platforms());
    this.registerPauseResumeListeners();
  }

  getPlatform(){
    const appPlatform = {isWeb: this.store.appState.isWEB,
                     isIos: this.store.appState.isIOS,
                     isAndroid: this.store.appState.isANDROID,
                     isTablet: this.store.appState.isTABLET,
                     isPhone: (!this.store.appState.isTABLET && !this.store.appState.isWEB)
                    };
    return appPlatform;
  }

  // catch the pause and resume events from platform
  registerPauseResumeListeners(): void {
    // handle when app is sent to the background
    document.addEventListener('pause', () => {
        console.log('App sent to background');
        // disable screen keep alive when in background
        if (this.store.appState.enableScreenAlive){
          this.setDisableScreenAlive();
        }
        // if downloads are still pending when sent to background
        if (this.store.appState.hasPendingDownloads || this.store.appState.hasPendingPodcastDownloads){
          console.log('sent to background with pending downloads');
          // generate a local notification to inform the user to return
          this.localNote.schedule({
            id: 1007,
            text: 'Return to app within 2 minutes to avoid interruption',
            title: 'Downloads in Progress',
            launch: true
          });
        }
        // enable background idle watcher
        if (this.platform.is('android')) {
          this.background.enable();
          this.startIdleWatcher();
        }

        if (this.platform.is('ios')) {
          //this.background.enable();
        }
    });

    // handle when app returns to the foreground
    document.addEventListener('resume', () => {
        console.log('App sent to foreground');
        // clear all notifications when user returns to the app
        this.localNote.clear(1007);
        // re-enable screen keep alive so downloads don't stop in background
        if (this.store.appState.hasPendingDownloads || this.store.appState.hasPendingPodcastDownloads){
          this.setEnableScreenAlive();
        }
        // force change detection run on resume from background
        // recovers from broken sockets and other weirdness if app was suspended
        this.appref.tick();

        // enable background mode
        if (this.platform.is('android')) {
          this.background.disable();
          this.stopIdleWatcher();
          this.androidPerms.checkSensitivePermissions();
        }

        if (this.platform.is('ios')) {
         // this.background.disable();
        }

        // broadcast resume from background for any other interested listeners
        setTimeout(() => {
          this.events.publish('resumeFromBackground');
        }, 2000);
    });
  }

  async isBackgroundModeEnabled() {
    return await this.androidPerms.checkBatteryPermission();
  }

  async enableBackgroundMode() {
    return await this.androidPerms.requestBatteryPermission();
  }

  configureBackgroundMode() {
    this.background.setDefaults({ silent: true });
    this.background.disableWebViewOptimizations();
    this.background.disableBatteryOptimizations();
  }

  disableBackgroundMode() {
    this.background.disable();
  }

  async isLocalNotificationEnabled(): Promise<void> {
    this.androidPerms.checkNotificationPermission();
  }

  async enableLocalNotification() {
    if(this.store.appState.isANDROID) {
      await this.androidPerms.requestNotificationPermission();
      this.localNote.schedule({
        id: 1008,
        text: 'Used to notify you if you have download tasks running when app is backgrounded to avoid interruption.',
        title: 'substreamer notifications enabled',
        launch: true
      });
      setTimeout(() => {this.localNote.clear(1008);},5000);
    }
  }

  async isStorageEnabled() {
    return await this.androidPerms.checkStoragePermission();
  }

  async enableStorage() {
    return await this.androidPerms.requestStoragePermission();
  }

  // Ensure that app doesn't run forever without playback
  // Only applicable for Android
  startIdleWatcher(){
    if(this.store.appState.isIOS) {
      return;
    }

    if(this.idleWatcherActive){
      return;
    }
    this.idleWatcherActive = true;
    this.idleWatcher = setInterval(() => {
      if(!this.store.playerState.isPlaying){
        this.idleTime += 10;
        console.log('idle time: '+this.idleTime);
      } else {
        this.idleTime = 0;
      }

      if(this.idleTime >= this.store.userState.suspendTimer){
        console.log('timer expired exit');
        this.background.disable();
        this.exitApp();
      }
    }, 10000);

  }

  // Stop the Idle watcher when the app is resumed
  stopIdleWatcher(){
    if(this.store.appState.isIOS) {
      return;
    }

    if(this.idleWatcherActive){
      this.idleWatcherActive = false;
      this.idleTime = 0;
      clearInterval(this.idleWatcher);
    }
  }

  // kill the app
  exitApp() {
    navigator.app.exitApp();
  }

  //
  // Screen keep alive
  //

  setEnableScreenAlive(){
    this.insomnia.keepAwake()
      .then(
        () => {
          console.log('keepAwake enable success');
          this.store.appState.enableScreenAlive = true;
        },
        () => {
          console.log('keepAwake enable error');
        }
      );
  }

  setDisableScreenAlive(){
    this.insomnia.allowSleepAgain()
      .then(
        () => {
          console.log('keepAwake disable success');
          this.store.appState.enableScreenAlive = false;
        },
        () => {
          console.log('keepAwake enable error');
        }
      );
  }

  //
  // Modal Tracking
  //

  setModalOpen(){
    this.store.appState.modalOpen = true;
  }

  setModalClosed(){
    this.store.appState.modalOpen = false;
  }

  isModalOpen(){
    return this.store.appState.modalOpen;
  }

  //
  // Cover Art Helper
  //

  setCoverArtBase200(){
    this.store.appState.coverArtBase200 = this.subsonic.getCoverArtBaseUrl(200);
  }

  setCoverArtBase500(){
    this.store.appState.coverArtBase500 = this.subsonic.getCoverArtBaseUrl(500);
  }

  setCachedMusicBase(){
    this.store.appState.cachedMusicBase = this.persist.getMusicCacheDir();
  }

  //
  // SPLASHSCREEN
  //

  hideSplashScreen(){
    if (this.isSplashScreenEnabled()){
      console.log('hide splashscreen');
      this.nativeSplashscreen.hide();
      this.store.appState.splashEnabled = false;
    }
  }

  showSplashScreen(){
    if (!this.isSplashScreenEnabled()){
      console.log('show splashscreen');
      this.nativeSplashscreen.show();
      this.store.appState.splashEnabled = true;
    }
  }

  isSplashScreenEnabled(){
    return this.store.appState.splashEnabled;
  }

  //
  // MENU
  //

  setSplitPaneStatus(bool: boolean): void {
    this.store.appState.splitPaneStatus = bool;
  }

  getSplitPaneStatus(): boolean {
    return this.store.appState.splitPaneStatus;
  }

  enableMenu(){
    if (!this.isMenuEnabled()){
      this.menuCtrl.enable(true, 'start');
      this.store.appState.menuEnabled = true;
    }
  }

  disableMenu(){
    if (this.isMenuEnabled()){
      this.menuCtrl.enable(false, 'start');
      this.menuCtrl.enable(false, 'end');
      this.store.appState.menuEnabled = false;
    }
  }

  isMenuEnabled(){
    return this.store.appState.menuEnabled;
  }

  //
  // ERROR / SUCCESS Toast
  //

  async showError(errorMessage: string, duration?: number){
    let _duration = 1000;

    // clear any existing visible messages
    if (this.isErrorShown()){
      await this._errorToast.dismiss();
    }

    if (duration) {
      _duration = duration;
    }

    this._errorToast = await this.toastCtrl.create({
      message: errorMessage,
      position: 'top',
      duration: _duration,
      cssClass: 'error-toast',
    });
    await this._errorToast.present();

    // set errorShown status true
    this.store.appState.errorShown = true;
  }

  async hideError(){
    // check if an error is currently visible
    if (this.isErrorShown()){
      // dismiss the toast
      await this._errorToast.dismiss();
      // set the errorShown status
      this.store.appState.errorShown = false;
    }
  }

  isErrorShown(){
    return this.store.appState.errorShown;
  }

  //
  // LOADING
  //

  async showLoading(loadingMessage: string): Promise<any>{
    // clear any existing visible messages
    if (this.isLoadingShown()){
      await this._loader.dismiss();
    }
    // create error toast
    this._loader = await this.loadingCtrl.create({
      message: loadingMessage,
      spinner: 'crescent',
      translucent: true,
      backdropDismiss: true,
      cssClass: 'loader'
    });

    // present the toast
    await this._loader.present();
    // set errorShown status true
    this.store.appState.loadingShown = true;
  }

  async hideLoading(delay?: number){
    console.log('appActions: hide loading called with delay: ' + delay);
    if (delay === undefined) {
      delay = 100;
    }
    setTimeout(async () => {
      if (this.isLoadingShown()){
        // dismiss the loader
        await this._loader.dismiss();
        // set the loadingShown status
        this.store.appState.loadingShown = false;
      }
    }, delay);
  }

  isLoadingShown(){
    return this.store.appState.loadingShown;
  }


}

