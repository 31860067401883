import { Injectable } from '@angular/core';
import { StoreService } from './store.service';
import { UtilService } from './util.service';
import { TranslateService } from '@ngx-translate/core';
import { SubsonicactionsService } from './subsonicactions.service';

@Injectable({
  providedIn: 'root'
})

export class RecommendationService {

  public shortRecommendationsList = [];
  public artistList = [];
  public genreAndYearList = [];
  public listLength = 6;
  private artistListTypes = ['Top Songs From', 'Artists Like'];
  private genreAndYearListTypes = ['Play More', 'Play More From'];
  private dateRanges = [
    {start: '2020', end: '2030', periodString: 'The 2020s'},
    {start: '2015', end: '2020', periodString: 'Late 2010s'},
    {start: '2010', end: '2015', periodString: 'Early 2010s'},
    {start: '2005', end: '2010', periodString: 'Late 2000s'},
    {start: '2000', end: '2005', periodString: 'Early 2000s'},
    {start: '1995', end: '2000', periodString: 'Late 90s'},
    {start: '1990', end: '1995', periodString: 'Early 90s'},
    {start: '1985', end: '1990', periodString: 'Late 80s'},
    {start: '1980', end: '1985', periodString: 'Early 80s'},
    {start: '1975', end: '1980', periodString: 'Late 70s'},
    {start: '1970', end: '1975', periodString: 'Early 70s'},
    {start: '1965', end: '1970', periodString: 'Late 60s'},
    {start: '1960', end: '1965', periodString: 'Early 60s'},
    {start: '1950', end: '1960', periodString: 'The 50s'},
    {start: '1940', end: '1950', periodString: 'The 40s'},
    {start: '1930', end: '1940', periodString: 'The 30s'},
    {start: '1920', end: '1930', periodString: 'The 20s'},
    {start: '1900', end: '1920', periodString: 'Before the 20s'},
    {start: '0001', end: '1900', periodString: 'Classical Music'},
  ];

  constructor(public translate: TranslateService,
              private store: StoreService,
              private subsonicActions: SubsonicactionsService,
              private util: UtilService)
              {}

  async generatePlaylists() {
    setTimeout(() => {this.addRandomItem();},5000);
    await this.generateRecentlists();
    await this.generateStarredlists();
    await this.generateShortRecommendations();
  }

  // Adds the random from all music item to the start of the recommendation list
  async addRandomItem() {
    const randomItem = {name: await this.translate.get('HOME_REC_MY_MUSIC').toPromise(), listType: 'Random Songs From', color: this.getRandomColor()};
    this.shortRecommendationsList.push(randomItem);
  }

  // Creates a short list of mixed recommendations (for embedding in homepage etc)
  async generateShortRecommendations() {
    this.shortRecommendationsList = this.shortRecommendationsList.concat(this.genreAndYearList);
  }

  // Creates a list of recommendations based on recently played items
  async generateRecentlists() {
    let albumList = [];

    albumList = this.util.copy(this.store.subsonicState.albumListRecent2.album);

    // get a list of recently played artists
    for(let i=0;i<this.listLength;i++){
      const item = this.util.getRandom(albumList);

      // add the artist to the artistList if it does not already exist
      if(!item.artist.toLowerCase().includes('various') && item.artistId && !this.isArtistUsed(item.artistId)){
        this.addArtistToList(item.artistId, item.artist);
      }

      // add genre and year to the genreAndPeriodList, if it does not already exist
      this.addGenreAndYearToList(item.genre, item.year);
    }
  }

  // Creates a list of recommendations based on starred items
  async generateStarredlists() {
    let albumList = [];

    albumList = this.util.copy(this.store.subsonicState.starred2.song);

    // get a list of recently played artists
    for(let i=0;i<this.listLength;i++){
      const item = this.util.getRandom(albumList);

      // add the artist to the artistList if it does not already exist
      if(!item.artist.toLowerCase().includes('various') && item.artistId && !this.isArtistUsed(item.artistId)){
        this.addArtistToList(item.artistId, item.artist);
      }

      // add genre and year to the genreAndPeriodList, if it does not already exist
      this.addGenreAndYearToList(item.genre, item.year);
    }
  }

  async getSimilarArtistsMix() {

  }

  async getTopSongsMix() {

  }

  async getSimilarGenreAndTimeMix() {

  }

  async getRandomMix() {
    // Random Songs From, Tops Songs By, Artists Like, Songs Like
    const randomList = await this.subsonicActions.getRandomPlaylist(this.store.userState.randomListLength);

    const randomItem = {name: await this.translate.get('HOME_REC_MY_MUSIC').toPromise(), listType: 'Random Songs From', color: this.getRandomColor(), trackList: randomList.randomSongs.song};
    //TODO WTF? this.suggestedList.push(randomItem);
  }

  // - HELPERS - //

  // empty the artist list
  clearArtistList(): void {
    this.artistList = [];
  }

  clearGenreAndYearList(): void {
    this.genreAndYearList = [];
  }

  // create an object with artistId & artistName and add to the list
  addArtistToList(artistId: string, artistName: string): void {
    this.artistList.push({artistId,
                          artistName,
                          color: this.getRandomColor(),
                          listType: this.util.getRandom(this.artistListTypes)});
  }

  // create an object with genre and year and add to the list
  addGenreAndYearToList(genre: string, year: string){
    this.genreAndYearList.push({genre,
                                year,
                                color: this.getRandomColor(),
                                listType: this.util.getRandom(this.genreAndYearListTypes),
                                timePeriod: this.checkDateRange(year)});
  }

  // check if artist is already in the suggested list
  isArtistUsed(artistId: string): boolean {
    const len = this.artistList.length;
    for(let i=0;i<len;i++){
      if(artistId === this.artistList[i].artistId){
        return true;
      }
      if(i === len-1){
        return false;
      }
    }
  }

  // return true is folder browsing is preferred
  shouldUseFolders(): boolean {
    if(this.store.userState.modeFoldersOnly){
      return true;
    } else {
      return false;
    }
  }

  // return true if tag browsing is preferred or no preference set
  shouldUseTags(): boolean {
    if(this.store.userState.modeTagsOnly){
      return true;
    }
    else if(!this.store.userState.modeTagsOnly && !this.store.userState.modeFoldersOnly){
      return true;
    }
    else {
      return false;
    }
  }

  // return a random color from colorList
  getRandomColor() {
    const colorList = ['primary', 'secondary', 'tertiary', 'success', 'warning', 'danger'];
    const color = colorList[Math.floor(Math.random()*colorList.length)];
    return color;
  }

  // check the date range a given date falls in
  checkDateRange(dateString: string): any {
    const length = this.dateRanges.length;
    const selectedDate = new Date(dateString + '-01-01');

    for(let i=0;i<length;i++){
      const startDate = new Date(this.dateRanges[i].start);
      const endDate = new Date(this.dateRanges[i].end);

      if (selectedDate >= startDate && selectedDate <= endDate) {
        console.log('✅ date ' + selectedDate.toString() + ' is between ' + startDate.toString() + ' & ' + endDate.toString());
        return this.dateRanges[i];
      } else {
        console.log('⛔️ date ' + selectedDate.toString() + ' is NOT between ' + startDate.toString() + ' & ' + endDate.toString());
      }
      if(i === length-1) {
        console.log('⛔️ last item, date not found');
        return {start: '', end: '', periodString: ''};
      }
    }
  }

}
