import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { EventsService } from './events.service';
import { StoreService } from './store.service';
import { PersistenceService } from './persistence.service';
import { UtilService } from './util.service';
import { FileTransfer, FileTransferObject } from '@awesome-cordova-plugins/file-transfer/ngx';
import { WebView } from '@awesome-cordova-plugins/ionic-webview/ngx';

@Injectable({
  providedIn: 'root'
})
export class ImageCacheService {

  private tx: FileTransferObject;
  private imageCacheQueue = [];
  private queueWatcher: any;
  private queueWatcherActive = false;

  constructor(private store: StoreService,
              private platform: Platform,
              private persist: PersistenceService,
              private util: UtilService,
              private events: EventsService,
              private webview: WebView,
              private fileTransfer: FileTransfer)
  {
    this.init();
  }

  init() {
    this.platform.ready().then(() => {
      if (this.platform.is('cordova')){
        this.tx = this.fileTransfer.create();
        this.resgisterResumeListener();
      }
    });
  }

  resgisterResumeListener(){
    // reset the queue watcher in case downloads died in the background
    this.events.subscribe('resumeFromBackground',  () => {
      this.resetQueueWatcher();
    });
  }

  // watch and process the download queue
  watchQueue() {
    // if a watcher is already active do nothing
    if (this.queueWatcherActive){
      return;
    } else {
      console.log('starting image cache queue watcher');
    // else create a watcher
      this.queueWatcherActive = true;
      this.queueWatcher = setInterval(() => {
        // if there are queued items process them
        if (this.imageCacheQueue.length){
          console.log('image cache queue has ' + this.imageCacheQueue.length + ' items wating');
          console.log(this.imageCacheQueue);
          this.set(this.imageCacheQueue.shift(), false);
        } else {
        // if there are no more queued items stop watcher
          console.log('image cache queue empty');
          this.stopWatchingQueue();
        }
      }, 500);
    }
  }

  // stop watching the download queue
  stopWatchingQueue(){
    console.log('stopping image cache queue watcher');
    this.queueWatcherActive = false;
    clearInterval(this.queueWatcher);
  }

  // force restart of the queue watcher
  resetQueueWatcher(){
    this.stopWatchingQueue();
    setTimeout(() => {
      this.watchQueue();
    }, 2000);
  }

  // check cache
  // id will be an id for subsonic content
  // id will be the url as string for external content
  check(id: string|number) {
    const _id = id.toString();
    // if the image is in our cache return true
    if (this.store.imageCacheState[_id] !== undefined){
      return true;
    // else queue it for download and return false
    } else {
      // check if the image is already in the queue
      // to prevent duplicates
      if (this.imageCacheQueue.indexOf(_id) === -1){
        // add to the queue
        this.imageCacheQueue.push(_id);
        // make sure the queue watcher is started
        this.watchQueue();
      } else {
        console.log('refusing to add duplicate item to the queue');
      }
      return false;
    }
  }

  // set cached image (if refresh then delete first)
  async set(id: string|number, refresh: boolean) {
    if ( id === undefined) {
      return;
    }
    const _id = id.toString();

    // no forced refresh for web
    if (this.store.appState.isWEB){
      console.log('no forced image refresh for web');
      return;
    }

    const _downloadType = this.checkDownloadType(_id);
    let _downloadUrl;

    // if it's a forced refresh clear any existing image
    if (refresh){
      await this.del(_id);
    }

    if (_downloadType === 'internal'){
      _downloadUrl = this.store.appState.coverArtBase500 + _id;
    } else {
      _downloadUrl = _id;
    }

    await this.doDownload(_downloadUrl, _id);

    // This probably needs to be debounced
    this.store.saveImageCacheState();

    return;
  }

  // get cached image
  get(id: string|number, raw?: boolean) {
    const _id = id.toString();
    // Return urls to access through the built in web browser with ionic webview
    if (!raw){
      return this.webview.convertFileSrc(this.persist.getImageCacheDir() + this.store.imageCacheState[_id].filename);
    // Return the raw path for access by native plugins
    } else {
      return this.persist.getImageCacheDir() + this.store.imageCacheState[_id].filename;
    }
  }

  // del cached image
  async del(id: string| number) {
    const _id = id.toString();

    // no forced refresh for web
    if (this.store.appState.isWEB){
      console.log('no forced image refresh for web');
      return;
    }

    if (this.store.imageCacheState[_id] === undefined){
      console.log('nothing to delete');
      return;
    }

    // fetch a copy of the item we will delete
    const _itemToDelete = this.util.copy(this.store.imageCacheState[_id]);
    console.log('-------------------');
    console.log(_id);
    console.log(_itemToDelete);
    console.log(this.store.imageCacheState[id]);
    console.log('-------------------');
    // delete the file from disk
    await this.persist.delImageFile(_itemToDelete.filename);

    // remove the file from the database
    delete this.store.imageCacheState[_id];

    // complete
    return;
  }

  //
  // Helper Methods
  //

  // check if this is an internal subsonic or external item to cache
  checkDownloadType(id) {
    if (id.substring(0, 4).toLowerCase() === 'http'){
      return 'external';
    } else {
      return 'internal';
    }
  }

  // process the actual download
  async doDownload(url: string, id: string|number){
    const _id = id.toString();
    let _returnItem: any;
    const _uniqueFilename = this.util.getUUID();

    await this.tx.download(url, this.persist.getImageCacheDir() + _uniqueFilename, true)
      .then((result) => {
        console.log('download success result');
        console.log(result);
        this.store.imageCacheState[_id] = {id: _id, filename: _uniqueFilename, updated: this.util.getToday()};
        _returnItem = {status: 'success', data: {}};
      })
      .catch((err) => {
        console.log('download error result');
        console.log(err);
        _returnItem = {status: 'error', data: err};
      });
    return _returnItem;
  }

}
