import { ImageCacheService } from './image-cache.service';
import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { StoreService } from './store.service';
import { SubsonicService } from './subsonic.service';
import { FanarttvService } from './fanarttv.service';
import { MusicbrainzService } from './musicbrainz.service';
import { UtilService } from './util.service';
import { EventsService } from './events.service';

import { MusicCacheService } from './music-cache.service';

@Injectable({
  providedIn: 'root'
})
export class SubsonicactionsService {

  public  currentArtistDL = '';
  public  artistDLQueue = [];
  private artistDLQueueWatcher: any;
  private artistDLQueueRefresh = false;
  private artistDLQueueWatcherActive = false;
  private artistDLQueueDelay = 2000;
  private refreshWatcher: any;
  private refreshWatcherActive = false;
  private refreshChannelWatcher: any;
  private refreshChannelWatcherActive = false;

  constructor(private alertCtrl: AlertController,
              private store: StoreService,
              private subsonic: SubsonicService,
              private fanarttv: FanarttvService,
              private musicbrainz: MusicbrainzService,
              private util: UtilService,
              private musicCache: MusicCacheService,
              private events: EventsService,
              private imageCache: ImageCacheService
              ) {this.init();}

  async init() {
    this.resgisterArtistDLResumeListener();
  }
  //
  // Handle bulk artist detail download
  //

  resgisterArtistDLResumeListener(){
    // reset the queue watcher in case downloads died in the background
    this.events.subscribe('resumeFromBackground',  () => {
      this.resetArtistDLQueueWatcher();
    });
  }

  // watch and process the download queue
  watchArtistDLQueue() {
    // if a watcher is already active do nothing
    if (this.artistDLQueueWatcherActive){
      return;
    } else {
      console.log('starting artistsDL queue watcher');
    // else create a watcher
      this.artistDLQueueWatcherActive = true;
      this.artistDLQueueWatcher = setInterval(() => {
        // if there are queued items process them
        if (this.artistDLQueue.length){
          console.log('artistsDL queue has ' + this.artistDLQueue.length + ' items wating');
          console.log(this.artistDLQueue);
          this.doArtistDL(this.artistDLQueue.shift());
          console.log('ARTISTDL: ' +this.artistDLQueue.length + ' items remaining');
        } else {
        // if there are no more queued items stop watcher
          console.log('ARTISTDL: queue empty');
          this.stopWatchingArtistDLQueue();
        }
      }, this.artistDLQueueDelay);
    }
  }

  // stop watching the download queue
  stopWatchingArtistDLQueue(){
    console.log('stopping artistDL queue watcher');
    this.artistDLQueueWatcherActive = false;
    this.artistDLQueueRefresh = false;
    clearInterval(this.artistDLQueueWatcher);
  }

  // force restart of the queue watcher
  resetArtistDLQueueWatcher(){
    this.stopWatchingArtistDLQueue();
    setTimeout(() => {
      this.watchArtistDLQueue();
    }, 2000);
  }

  setArtistDLQueue(artistItemArray: any, refresh: boolean) {
    if(refresh) {
      this.artistDLQueueRefresh = true;
    }

    if(artistItemArray.length) {
      this.artistDLQueue = this.util.copy(artistItemArray);
    }

    this.watchArtistDLQueue();
  }

  addItemToArtistDLQueue(artistItem: any, refresh: boolean) {
    if(refresh) {
      this.artistDLQueueRefresh = true;
    }

    if(artistItem.id) {
      this.artistDLQueue.push(artistItem);
    }

    this.watchArtistDLQueue();
  }

  delArtistDLQueue() {
    this.stopWatchingArtistDLQueue();
    this.artistDLQueue = [];
  }

  async doArtistDL(artist: any): Promise<any> {
    this.currentArtistDL = artist.name;
    if(!this.artistDLQueueRefresh) {
      if (this.store.subsonicState.artists[artist.id] &&
        this.store.subsonicState.artistInfo2[artist.id] &&
        this.store.subsonicState.artistTopSongs[artist.id]) {
        return Promise.resolve();
      }
    }
      await this.setArtist(artist.id);
      await this.setArtistInfo2(artist.id, artist.name);
      await this.setArtistTopSongs(artist.name, artist.id);
      const artistImgUrl = this.store.subsonicState.artistInfo2[artist.id].largeImageUrl.toString();
      this.imageCache.check(artistImgUrl);
      return Promise.resolve();
  }

  // monitor podcast / episode download & refresh status
  async watchPodcastRefreshProgress(){
    if (this.refreshWatcherActive){
      console.log('already monitoring');
    }
    this.refreshWatcher = setInterval(async () => {
      await this.setPodcasts();
      for (let i = 0; i < this.store.subsonicState.podcastList.channel.length; i++){
        if (this.store.subsonicState.podcastList.channel[i].status !== 'completed'){
          console.log('Downloads still in progress');
          break;
        }
        if (i === this.store.subsonicState.podcastList.channel.length - 1){
          console.log('All completed');
          clearInterval(this.refreshWatcher);
          this.refreshWatcherActive = false;
        }
      }
    }, 3000);
  }

  // monitor podcast / episode download & refresh status
  async watchPodcastChannelRefreshProgress(podcastID: string){
    if (this.refreshChannelWatcherActive){
      console.log('already monitoring');
    }
    this.refreshChannelWatcher = setInterval(async () => {
      await this.setPodcast(podcastID);
      for (let i = 0; i < this.store.subsonicState.podcasts[podcastID].episode.length; i++){
        if (this.store.subsonicState.podcasts[podcastID].episode[i].status !== 'completed'){
          console.log('Channel Episode Downloads still in progress');
          break;
        }
        if (i === this.store.subsonicState.podcasts[podcastID].episode.length - 1){
          console.log('All Channel Episodes completed');
          clearInterval(this.refreshChannelWatcher);
          this.refreshChannelWatcherActive = false;
        }
      }
    }, 3000);
  }

  // get a random playlist
  async getRandomPlaylist(size: number){
    if(size === undefined){
      size = 50;
    }
    return this.subsonic.getRandomSongs(size);
  }

  // get a generated playlist optionally by genre and year range
  async getRadioPlaylist(genreArg: string, yearItem: any){
    console.log('getRadioPlaylist called');
    let genre = genreArg;
    if (genreArg === 'Mix It Up!'){
      genre = undefined;
    }

    const result = await this.subsonic.getRandomSongs(this.store.userState.randomListLength, yearItem.fromYear, yearItem.toYear, genre);

    if (result.status === 'ok'){
      // handle when server returns no results
      if (!result.randomSongs.song){
        result.randomSongs.song = [];
        this.showRadioPlaylistError();
      } else {
        // else we have results so all good
        this.showRadioPlaylistSuccess(genreArg, yearItem.name);
      }
      // return the array of songs
      return Promise.resolve(result.randomSongs.song);
    } else {
      // handle generating the error alert
      this.showRadioPlaylistError('' + result.error.code + ' ' + result.error.message);
      // still return an empty array if somethign went wrong
      return Promise.resolve([]);
    }
  }

  async delTrackFromPlaylist(playlistID: string|number, indexToRemove: number){
    const res = await this.subsonic.updatePlaylist(playlistID, undefined, undefined, undefined, undefined, [indexToRemove]);
    console.log(res);
    if (res.status !== 'failed') {
      this.store.subsonicState.playlists[playlistID].entry.splice(indexToRemove, 1);
      this.store.saveSubsonicState();
    } else {
      this.showDelTrackFromPlaylistError('' + res.error.code + ' ' + res.error.message);
    }
    return Promise.resolve();

  }

  // get a generated playlist of similar tracks
  async getSimilarTracks(id: string|number){
    return this.subsonic.getSimilarSongs2(id);
  }

  // get a generated playlist of similar tracks
  async getSimilarFolderTracks(id: string|number){
    return this.subsonic.getSimilarSongs(id);
  }

  // get a copy of an album object
  async getAlbum(id: string|number): Promise<any> {
    return this.util.copy(this.store.subsonicState.albums[id]);
  }

  // get a copy of a folder object
  async getFolder(id: string|number): Promise<any> {
    return this.util.copy(this.store.subsonicState.musicDirectories[id]);
  }

  // get a copy of a playlist object
  async getPlaylist(id: string|number): Promise<any> {
    return this.util.copy(this.store.subsonicState.playlists[id]);
  }

  // get a cyopf of the starred2 object
  async getStarred(): Promise<any> {
    return this.util.copy(this.store.subsonicState.starred2);
  }

  async starTrack(id: string|number){
    await this.subsonic.star([id], undefined, undefined);
    await this.setStarred2();
    await this.setStarred();
    await this.util.addDelay(500);
    await this.musicCache.updateCachedStarred();
  }

  async starAlbum(id: string|number){
    await this.subsonic.star(undefined, [id], undefined);
    await this.setStarred2();
  }

  async starArtist(id: string|number){
    await this.subsonic.star(undefined, undefined, [id]);
    await this.setStarred2();
  }

  async starFolder(id: string|number){
    await this.subsonic.star([id], undefined, undefined);
    await this.setStarred();
  }

  async unstarTrack(id: string|number){
    await this.subsonic.unstar([id], undefined, undefined);
    await this.setStarred2();
    await this.setStarred();
    await this.util.addDelay(500);
    await this.musicCache.updateCachedStarred();
  }

  async unstarAlbum(id: string|number){
    await this.subsonic.unstar(undefined, [id], undefined);
    await this.setStarred2();
  }

  async unstarArtist(id: string|number){
    await this.subsonic.unstar(undefined, undefined, [id]);
    await this.setStarred2();
  }

  async unstarFolder(id: string|number){
    await this.subsonic.unstar([id], undefined, undefined);
    await this.setStarred();
  }

  async getSearchResults(searchString: string, resultSize?: number, offset?: number){
    let rSize = 100;
    let oSize = 0;
    if(resultSize) {
      rSize = resultSize;
    }
    if(offset) {
      oSize = offset;
    }
    const results: any = {};
    const folderResults = await this.subsonic.search2(searchString, rSize, oSize, rSize, oSize, rSize, oSize);
    const tagResults = await this.subsonic.search3(searchString, rSize, oSize, rSize, oSize, rSize, oSize);

    if (folderResults.status === 'ok' && tagResults.status === 'ok'){
      results.folder = (folderResults.searchResult2.album ? folderResults.searchResult2.album : []);
      results.song = (tagResults.searchResult3.song ? tagResults.searchResult3.song : []);
      results.album = (tagResults.searchResult3.album ? tagResults.searchResult3.album : []);
      results.artist = (tagResults.searchResult3.artist ? tagResults.searchResult3.artist : []);
    } else {
      results.song = [];
      results.artist = [];
      results.album = [];
      results.folder = [];
    }
    return Promise.resolve(results);
  }

  // Scan Server, store progress, then complete
  async scanServer(){
    await this.setScanStatus();
    const oldItemCount = this.store.subsonicState.itemCount.count;

    await this.subsonic.startScan();
    await this.setScanStatus();

    while (this.store.subsonicState.scanStatus){
      await this.setScanStatus();
      await this.util.addDelay(1000);
    }
    if (!this.store.subsonicState.scanStatus){
      if (oldItemCount !== this.store.subsonicState.itemCount.count){
        console.log('items on server changed');
        // refresh newest lists to show up new items
        this.setAlbumListNewest2();
        this.setAlbumListNewest();
      } else {
        console.log('no items changed on server');
      }
    }
  }

  async saveTopSongsPlaylist(arrayOfSongID: any, playlistName: string){
    this.subsonic.createPlaylist(playlistName, undefined, arrayOfSongID)
      .then((result) => {
        if (result.status === 'ok'){
          console.log('save top songs playlist success');
          this.showSaveTopSongsPlaylistSuccess(playlistName);
          this.setPlaylists();
        } else {
          console.log('save top songs playlist error');
          this.showSaveTopSongsPlaylistError(playlistName);
        }
      });
  }

  async addTracksToPlaylist(arrayOfTrackId: any){

    const inputsArray = [];

    // we don't know when playlists were last refreshed if ever
    await this.setPlaylists();

    const playlistsLength = this.store.subsonicState.playlistsList.playlist.length;

    for (let i = 0; i < playlistsLength; i++){
      inputsArray.push({
        type: 'radio',
        label: this.store.subsonicState.playlistsList.playlist[i].name,
        value: this.store.subsonicState.playlistsList.playlist[i]
      });
    }

    const alert = await this.alertCtrl.create({
      header: 'Add to Playlist',
      message: 'Select playlist to add to',
      mode: 'ios',
      translucent: true,
      inputs: inputsArray,
      buttons: [
        {
          text: 'cancel',
          handler: (data) => {
            console.log('addTrackToPlaylist cancel clicked');
          }
        },
        {
          text: 'ok',
          handler: (data) => {
            console.log('addTrackToPlaylist ok clicked');
            console.log(data);
            if (data){
              this.subsonic.updatePlaylist(data.id, undefined, undefined, undefined, arrayOfTrackId)
                .then((response) => {
                  if (response.status === 'ok'){
                    console.log('addTrackToPlaylist: success');
                    this.showAddSongToPlaylistSuccess(data.name);
                    this.setPlaylist(data.id);
                  } else {
                    console.log('addTrackToPlaylist: error');
                    this.showAddSongToPlaylistError(data.name);
                  }
                });
            } else {
              console.log('no option chosen');
            }
          }
        }
      ]
    });
    return alert.present();
  }

  async newPlaylistWithTracks(arrayOfTrackId: any){

    const alert = await this.alertCtrl.create({
      header: 'New Playlist',
      message: 'Enter a name for the playlist',
      mode: 'ios',
      translucent: true,
      inputs: [
        {name: 'name', placeholder: 'name'}
      ],
      buttons: [
        {
          text: 'cancel',
          handler: (data) => {
            console.log('newPlaylistWithTracks cancel clicked');
          }
        },
        {
          text: 'save',
          handler: (data) => {
            console.log('new playlist save clicked');
            if (!data.name.length){
              return false;
            } else {
              this.subsonic.createPlaylist(data.name, undefined, arrayOfTrackId)
                .then((result) => {
                  if (result.status === 'ok'){
                    console.log('newPlaylistWithTracks: succcess');
                    this.showCreatePlaylistSuccess(data.name);
                    this.setPlaylists();
                    console.log(result);
                  } else {
                    console.log('newPlaylistWithTracks: error');
                    this.showCreatePlaylistError(data.name);
                    console.log(result);
                  }
                });
            }
          }
        }
      ]
    });
    return alert.present();
  }

  async renamePlaylist(playlistItem: any){

    const alert = await this.alertCtrl.create({
      header: 'Rename Playlist',
      message: 'Enter a new name for the playlist',
      mode: 'ios',
      translucent: true,
      inputs: [
        {name: 'name', placeholder: playlistItem.name}
      ],
      buttons: [
        {
          text: 'cancel',
          handler: (data) => {
            console.log('renamePlaylist cancel clicked');
          }
        },
        {
          text: 'save',
          handler: (data) => {
            console.log('rename playlist save clicked');
            if (!data.name.length){
              return false;
            } else {
              this.subsonic.updatePlaylist(playlistItem.id, data.name)
                .then((result) => {
                  if (result.status === 'ok'){
                    console.log('renamePlaylist: succcess');
                    this.showRenamePlaylistSuccess(data.name);
                    this.setPlaylists();
                    console.log(result);
                  } else {
                    console.log('renamePlaylist: error');
                    this.showRenamePlaylistError(data.name);
                    console.log(result);
                  }
                });
            }
          }
        }
      ]
    });
    return alert.present();
  }

  async deletePlaylist(playlistItem: any){

    const alert = await this.alertCtrl.create({
      header: 'Delete Playlist',
      message: 'Are you sure you want to delete: ' + playlistItem.name + '?',
      mode: 'ios',
      translucent: true,
      buttons: [
        {
          text: 'cancel',
          handler: (data) => {
            console.log('deletePlaylist cancel clicked');
          }
        },
        {
          text: 'ok',
          handler: (data) => {
            console.log('deletePlaylist ok clicked');

            this.subsonic.deletePlaylist(playlistItem.id)
              .then((result) => {
                if (result.status === 'ok'){
                  console.log('deletePlaylist: succcess');
                  this.showDeletePlaylistSuccess(playlistItem.name);
                  this.setPlaylists();
                  console.log(result);
                } else {
                  console.log('deletePlaylist: error');
                  this.showDeletePlaylistError(playlistItem.name);
                  console.log(result);
                }
              });
          }
        }
      ]
    });
    return alert.present();
  }

  // check server is reachable and responding correctly
  async checkPing(): Promise<any> {
    console.log('checkPing called');
    const result = await this.subsonic.ping();
    if (result.status === 'ok'){
      console.log('checkPing: success');
      this.store.subsonicState.pingOk = true;
    } else {
      console.log('checkPing: error');
      this.store.subsonicState.pingOk = false;
    }
  }

  // get license from server and set in store
  async setLicense(): Promise<any> {
    console.log('setLicense called');
    const result = await this.subsonic.getLicense();
    console.log(result);
    if (result.status === 'ok'){
      this.store.subsonicState.license = result.license;
      this.store.saveSubsonicState();
      console.log('setLicense: success');
    } else {
      console.log('setLicense: error');
    }
  }

  // get nowPlaying from server and set in store
  async setNowPlaying(): Promise<any> {
    console.log('setNowPlaying called');
    const result = await this.subsonic.getNowPlaying();
    console.log(result);
    if (result.status === 'ok'){
      this.store.subsonicState.nowPlaying = result.nowPlaying;
      this.store.saveSubsonicState();
      console.log('setNowPlaying: success');
    } else {
      console.log('setNowPlaying: error');
    }
  }

  // get scanStatus from server and set in store
  // update itemCount and newItemsAvailable based on count
  async setScanStatus(): Promise<any> {
    console.log('setScanStatus called');
    const result = await this.subsonic.getScanStatus();
    console.log(result);
    if (result.status === 'ok'){
      this.store.subsonicState.scanStatus = result.scanStatus.scanning;
      this.store.subsonicState.itemCount = {count: result.scanStatus.count, updated: this.util.getToday()};
      // only persist status when scan is complete
      if (!result.scanStatus.scanning){
        await this.store.saveSubsonicState();
      }
      console.log('setScanStatus: success');
      return Promise.resolve();
    } else {
      console.log('setScanStatus: error');
      return Promise.reject();
    }
  }

  // get starred from server and set in store
  async setStarred(): Promise<any> {
    console.log('setStarred called');
    const result = await this.subsonic.getStarred();
    console.log(result);
    if (result.status === 'ok'){
      result.starred.updated = this.util.getToday();
      if (result.starred.song === undefined){
        result.starred.song = [];
      }
      if (result.starred.album === undefined){
        result.starred.album = [];
      }
      this.store.subsonicState.starred = result.starred;
      this.store.saveSubsonicState();
      console.log('setStarred: success');
    } else {
      console.log('setStarred: error');
    }
  }

  // get starred2 from server and set in store
  async setStarred2(): Promise<any> {
    console.log('setStarred2 called');
    const result = await this.subsonic.getStarred2();
    console.log(result);
    if (result.status === 'ok'){
      result.starred2.updated = this.util.getToday();
      if (result.starred2.song === undefined){
        result.starred2.song = [];
      }
      if (result.starred2.album === undefined){
        result.starred2.album = [];
      }
      if (result.starred2.artist === undefined){
        result.starred2.artist = [];
      }
      this.store.subsonicState.starred2 = result.starred2;
      this.store.saveSubsonicState();
      console.log('setStarred2: success');
    } else {
      console.log('setStarred2: error');
    }
  }

  // get music folders from server and set in store
  async setMusicFolders(): Promise<any> {
    console.log('setMusicFolders called');
    const result = await this.subsonic.getMusicFolders();
    console.log(result);
    if (result.status === 'ok'){
      this.store.subsonicState.musicFolders = result.musicFolders;
      this.store.saveSubsonicState();
      console.log('setMusicFolders: success');
    } else {
      console.log('setMusicFolders: error');
    }
  }

  // get indexes from server and set in store
  async setIndexes(): Promise<any> {
    console.log('setIndexes called');
    const result = await this.subsonic.getIndexes();
    console.log(result);
    if (result.status === 'ok'){
      this.store.subsonicState.indexes = result.indexes;
      this.store.saveSubsonicState();
      console.log('setIndexes: success');
    } else {
      console.log('setIndexes: error');
    }
  }

  // get indexes from server and set in store
  async setIndexesFlat(): Promise<any> {
    console.log('setIndexesFlat called');
    const compiledResult = {lastModified: 0, ignoredArticles: '', index: []};
    const result = await this.subsonic.getIndexes();
    console.log(result);
    if (result.status === 'ok'){
      compiledResult.lastModified = result.indexes.lastModified;
      compiledResult.ignoredArticles = result.indexes.ignoredArticles;

      result.indexes.index.forEach((index) => {
        compiledResult.index = compiledResult.index.concat(index.artist);
      });
      this.store.subsonicState.indexesFlat = compiledResult;
      this.store.saveSubsonicState();
      console.log('setIndexesFlat: success');
    } else {
      console.log('setIndexesFlat: error');
    }
  }

  // get musicDirectory by id from server and set in store
  // musicDirectories[id]
  async setMusicDirectory(id: string | number): Promise<any> {
    console.log('setMusicDirectory called');
    const result = await this.subsonic.getMusicDirectory(id);
    console.log(result);
    if (result.status === 'ok'){
      this.store.subsonicState.musicDirectories[id] = result.directory;
      this.store.saveSubsonicState();
      console.log('setMusicDirectory: success');
    } else {
      console.log('setMusicDirectory: error');
    }
  }

  // get genres from server and set in store
  async setGenres(): Promise<any> {
    console.log('setGenres called');
    const result = await this.subsonic.getGenres();
    console.log(result);
    if (result.status === 'ok'){
      // deal with empty returns
      if (!result.genres.genre){
        result.genres.genre = [];
      }
      // count total songs and albums
      let albumCount = 0;
      let songCount = 0;
      const genreLength = result.genres.genre.length;

      for (let i = 0; i < genreLength; i++){
        albumCount = albumCount + result.genres.genre[i].albumCount;
        songCount = songCount + result.genres.genre[i].songCount;
      }
      // inject mix it up
      result.genres.genre.unshift({value: 'Mix It Up!',
                                    albumCount,
                                    songCount,
                                    description: 'I\'ll Take a Bit of Everything'});
      // save the genre list
      this.store.subsonicState.genres = result.genres;
      this.store.saveSubsonicState();
      console.log('setGenres: success');
    } else {
      console.log('setGenres: error');
    }
  }

  // get artists from server and set in store (tag)
  async setArtists(): Promise<any> {
    console.log('setArtists called');
    const result = await this.subsonic.getArtists();
    console.log(result);
    if (result.status === 'ok'){
      this.store.subsonicState.artistsList = result.artists;
      this.store.saveSubsonicState();
      console.log('setArtists: success');
    } else {
      console.log('setArtists: error');
    }
  }

  // get artists from server and set in store (tag)
  async setArtistsFlat(): Promise<any> {
    console.log('setArtistsFlat called');
    const compiledResult = {ignoredArticles: '', index: []};
    const result = await this.subsonic.getArtists();
    console.log(result);
    if (result.status === 'ok'){
      compiledResult.ignoredArticles = result.artists.ignoredArticles;
      result.artists.index.forEach((index) => {
        compiledResult.index = compiledResult.index.concat(index.artist);
      });
      this.store.subsonicState.artistsListFlat = compiledResult;
      this.store.saveSubsonicState();
      console.log(compiledResult);
      console.log('setArtistsFlat: success');
    } else {
      console.log('setArtistsFlat: error');
    }
  }

  // get artist by id from server and set in store artist[id]
  async setArtist(id: string | number): Promise<any> {
    console.log('setArtist called');
    const result = await this.subsonic.getArtist(id);
    console.log(result);
    if (result.status === 'ok'){
      this.store.subsonicState.artists[id] = result.artist;
      this.store.saveSubsonicState();
      console.log('setArtist: success');
    } else {
      console.log('setArtist: error');
    }
  }

  // For a given artistName and artistID get similar artist
  // get top 10 songs for each artist
  // return the shuffled list
  async getSimilarArtistsPlaylist(artistName: string, artistId: string): Promise<any> {
    console.log('getSimilarArtistsPlaylist called');
    console.log('TEST '+artistName + ' ' + artistId);
    // Store similar artists array
    let similarArtistsSongs = [];

    // Refresh the artist Info
    await this.setArtistInfo2(artistId, artistName);

    // Add the current artist top tracks
    const result = await this.subsonic.getTopSongs(artistName, 30);
    if (result.status === 'ok' && result.topSongs){
      if(result.topSongs.song) {
        similarArtistsSongs = similarArtistsSongs.concat(this.util.copy(result.topSongs.song));
      }
    }

    // If we don't have the artist details for some reason return and break out
    if(!this.store.subsonicState.artistInfo2[artistId]){
      return similarArtistsSongs;
    }
    // If there are no similar artists return and break out
    if(!this.store.subsonicState.artistInfo2[artistId].similarArtist){
      return similarArtistsSongs;
    }

    // Get top tracks for each of the similar artists
    for(let i=0;i<this.store.subsonicState.artistInfo2[artistId].similarArtist.length;i++){
      // Get the top songs for the artist
      const result2 = await this.subsonic.getTopSongs(this.store.subsonicState.artistInfo2[artistId].similarArtist[i].name, 10);

      if (result2.status === 'ok' && result2.topSongs){
        if(result2.topSongs.song){
          similarArtistsSongs = similarArtistsSongs.concat(this.util.copy(result2.topSongs.song));
        }
      }
      // On the last item return a shuffled list
      if(i === this.store.subsonicState.artistInfo2[artistId].similarArtist.length-1){
        await this.util.shuffle(similarArtistsSongs);
        return similarArtistsSongs;
      }
    }
  }

  // get artist by id from server and set in store artist[id]
  async setArtistTopSongs(artistName: string, artistId: string | number): Promise<any> {
    console.log('setArtistTopSongs called');
    const result = await this.subsonic.getTopSongs(artistName, 50);
    console.log(result);
    if (result.status === 'ok'){
      this.store.subsonicState.artistTopSongs[artistId] = result.topSongs;
      this.store.saveSubsonicState();
      console.log('setArtistTopSongs: success');
    } else {
      console.log('setArtistTopSongs: error');
    }
  }

  // get artistInfo by id from server and set in store artistInfo2[id]
  async setArtistInfo2(id: string | number, artistName: string): Promise<any> {
    console.log('setArtistInfo2 called');
    const result = await this.subsonic.getArtistInfo2(id, 20, false);
    console.log(result);
    if (result.status === 'ok'){
      const htmlTagRef = /<\/?[\w\s="/.':;#-\/\?]+>/gi;
      const lastFMRef = ' Read more on Last.fm';
      let plaintext;

      if (result.artistInfo2.biography !== undefined){
        plaintext = result.artistInfo2.biography.replace(htmlTagRef, '');
        plaintext = plaintext.replace(lastFMRef, '...');
        result.artistInfo2.biography = plaintext;
      }
      if (result.artistInfo2.biography === 'Read more on Last.fm'){
        result.artistInfo2.biography = 'Looks like Last.fm doesn\'t know much about that artist or had trouble matching them.  If there is a read more link below you can see what last.fm thinks, if not then you might want to check your ID3 tags as the artist was not found.';
      }

      // enable direct fetching of artist images from fanart.tv API
      // only run if user has enabled the setting
      if(this.store.userState.enableFanartTV) {
        // fetch artist image from fanart.tv as lastfm removed images from their API
        let result2 = await this.fanarttv.getArtistImage(result.artistInfo2.musicBrainzId);

        // artistInfo2 sometimes has bogus mbid's which cause fanarttv fetch to fail
        // look up the artist using musicbrainz API to catch most of these
        // TODO expose musicbrainz results to UI so user can correct matches
        // Probably clean this up and push the secondary metadata retrieval to a seperate function
        if(!Object.keys(result2).length){
          const mbapiresponse = await this.musicbrainz.getBestMatchArtistIDByName(artistName);
          if (mbapiresponse.artists) {
            result.artistInfo2.musicBrainzId = mbapiresponse.artists[0].id;
            result2 = await this.fanarttv.getArtistImage(result.artistInfo2.musicBrainzId);
          }
        }
        console.log(result2);
        // try artist thumb first
        if (result2.hasOwnProperty('artistthumb')){
          result.artistInfo2.largeImageUrl = result2.artistthumb[0].url;
        }
        // try for an album cover
        else if (result2.hasOwnProperty('albums')){
          const key = Object.keys(result2.albums)[0];
          if (result2.albums[key].hasOwnProperty('albumcover')){
            result.artistInfo2.largeImageUrl = result2.albums[key].albumcover[0].url;
          }
        }
        else {
          result.artistInfo2.largeImageUrl = '';
          // use static image placeholder if there is no alternative
          // need to resolve issue with sanitizing local url in imagecache provider
          // _result.artistInfo2.largeImageUrl = 'assets/imgs/artistImage.jpg';
        }
      }

      this.store.subsonicState.artistInfo2[id] = result.artistInfo2;
      this.store.saveSubsonicState();
      console.log('setArtistInfo2: success');
    } else {
      console.log('setArtistInfo2: error');
    }
  }

  // get all albums from server and set in store in flat format (tag)
  async setAlbumsFlat(): Promise<any> {
    console.log('setAlbumsFlat called');
    let _offset = 0;
    let _end = false;
    let _error = false;
    const _compiledResult = {updated: this.util.getToday(), index: []};

    // Get a compiled list of all albums
    // Make multiple requests until we have all albums
    // Store _results in _compiledResult
    do {
      const _result = await this.subsonic.getAlbumList2('alphabeticalByArtist', 500, _offset);
      console.log(_result);
      if (_result.status === 'ok'){
        if (_result.albumList2.album !== undefined){
          _offset = _offset + _result.albumList2.album.length;
          _compiledResult.index = _compiledResult.index.concat(_result.albumList2.album);
        }
        else {
          // handle when total album count is multiple of 500
          console.log('setlAlbums: Caught end loop');
          _end = true;
        }
        console.log('setAlbumsFlat: offset ' + _offset + ' success');
      } else {
        _error = true;
        console.log('setAlbumsFlat: offset  ' + _offset + ' error');
      }
    }
    while (_offset % 500 === 0 && !_end && !_error);

    if (!_error){
      // Now update the store
      this.store.subsonicState.albumsListFlat = _compiledResult;
      this.store.saveSubsonicState();
      console.log('setAlbumsFlat: success');
      return(true);
    } else {
      console.log('setAlbumsFlat: error');
      return(false);
    }
  }

  // get all albums from server and set in store in indexed format (tag)
  async setAlbums(): Promise<any> {
    console.log('setAlbums called');
    let _offset = 0;
    let _end = false;
    let _error = false;
    let _compiledResult = [];
    let _compiledResultLength = 0;
    const _indexedResult = {
      ignoredArticles: 'the el la los las le les ',
      index: [
        {name: 'A', album: []},
        {name: 'B', album: []},
        {name: 'C', album: []},
        {name: 'D', album: []},
        {name: 'E', album: []},
        {name: 'F', album: []},
        {name: 'G', album: []},
        {name: 'H', album: []},
        {name: 'I', album: []},
        {name: 'J', album: []},
        {name: 'K', album: []},
        {name: 'L', album: []},
        {name: 'M', album: []},
        {name: 'N', album: []},
        {name: 'O', album: []},
        {name: 'P', album: []},
        {name: 'Q', album: []},
        {name: 'R', album: []},
        {name: 'S', album: []},
        {name: 'T', album: []},
        {name: 'U', album: []},
        {name: 'V', album: []},
        {name: 'W', album: []},
        {name: 'X-Z', album: []},
        {name: '#', album: []}
      ]
    };

    // Get a compiled list of all albums
    // Make multiple requests until we have all albums
    // Store _results in _compiledResult
    do {
      const _result = await this.subsonic.getAlbumList2('alphabeticalByArtist', 500, _offset);
      console.log(_result);
      if (_result.status === 'ok'){
        if (_result.albumList2.album !== undefined){
          _offset = _offset + _result.albumList2.album.length;
          _compiledResult = _compiledResult.concat(_result.albumList2.album);
        }
        else {
          // handle when total album count is multiple of 500
          console.log('setlAlbums: Caught end loop');
          _end = true;
        }
        console.log('setAlbums: ' + _offset + ' success');
      } else {
        _error = true;
        console.log('setAlbums: ' + _offset + ' error');
      }
    }
    while (_offset % 500 === 0 && !_end && !_error);

    if (!_error){
      // grab the length of _compiledResult array of all albums
      _compiledResultLength = _compiledResult.length;

      for (let i = 0; i < _compiledResultLength; i++){
        const album = _compiledResult[i];
        const _lowerCaseString = album.artist.toLowerCase();
        const _firstWord = _lowerCaseString.substring(0, _lowerCaseString.indexOf(' ') + 1);
        let _indexTarget = '';

        // first check for and handle ignored keywords
        // if there are no ignored keywords then set index target to first letter
        // of the string
        if (_firstWord.length > 2 && _indexedResult.ignoredArticles.indexOf(_firstWord) > -1){
          _indexTarget = _lowerCaseString.substring(_firstWord.length, _firstWord.length + 1);
          console.log('removed first word: ' + _firstWord);
          console.log('new index target: ' + _indexTarget);
        }
        else {
          _indexTarget = _lowerCaseString.substring(0, 1);
        }

        // now add the albums under the correct index
        if (_indexTarget === 'a'){
          _indexedResult.index[0].album.push(album);
          console.log('check a');
        }
        else if (_indexTarget === 'b'){
          _indexedResult.index[1].album.push(album);
          console.log('check b');
        }
        else if (_indexTarget === 'c'){
          _indexedResult.index[2].album.push(album);
          console.log('check c');
        }
        else if (_indexTarget === 'd'){
          _indexedResult.index[3].album.push(album);
          console.log('check d');
        }
        else if (_indexTarget === 'e'){
          _indexedResult.index[4].album.push(album);
          console.log('check e');
        }
        else if (_indexTarget === 'f'){
          _indexedResult.index[5].album.push(album);
          console.log('check f');
        }
        else if (_indexTarget === 'g'){
          _indexedResult.index[6].album.push(album);
          console.log('check g');
        }
        else if (_indexTarget === 'h'){
          _indexedResult.index[7].album.push(album);
          console.log('check h');
        }
        else if (_indexTarget === 'i'){
          _indexedResult.index[8].album.push(album);
          console.log('check i');
        }
        else if (_indexTarget === 'j'){
          _indexedResult.index[9].album.push(album);
          console.log('check j');
        }
        else if (_indexTarget === 'k'){
          _indexedResult.index[10].album.push(album);
          console.log('check k');
        }
        else if (_indexTarget === 'l'){
          _indexedResult.index[11].album.push(album);
          console.log('check l');
        }
        else if (_indexTarget === 'm'){
          _indexedResult.index[12].album.push(album);
          console.log('check m');
        }
        else if (_indexTarget === 'n'){
          _indexedResult.index[13].album.push(album);
          console.log('check n');
        }
        else if (_indexTarget === 'o'){
          _indexedResult.index[14].album.push(album);
          console.log('check o');
        }
        else if (_indexTarget === 'p'){
          _indexedResult.index[15].album.push(album);
          console.log('check p');
        }
        else if (_indexTarget === 'q'){
          _indexedResult.index[16].album.push(album);
          console.log('check q');
        }
        else if (_indexTarget === 'r'){
          _indexedResult.index[17].album.push(album);
          console.log('check r');
        }
        else if (_indexTarget === 's'){
          _indexedResult.index[18].album.push(album);
          console.log('check s');
        }
        else if (_indexTarget === 't'){
          _indexedResult.index[19].album.push(album);
          console.log('check t');
        }
        else if (_indexTarget === 'u'){
          _indexedResult.index[20].album.push(album);
          console.log('check u');
        }
        else if (_indexTarget === 'v'){
          _indexedResult.index[21].album.push(album);
          console.log('check v');
        }
        else if (_indexTarget === 'w'){
          _indexedResult.index[22].album.push(album);
          console.log('check w');
        }
        else if (_indexTarget === 'x' || _indexTarget === 'y' || _indexTarget === 'z'){
          _indexedResult.index[23].album.push(album);
          console.log('check x-z');
        }
        else {
          _indexedResult.index[24].album.push(album);
          console.log('check all others');
        }
      }
      // Now update the store
      this.store.subsonicState.albumsList = _indexedResult;
      this.store.saveSubsonicState();
      console.log('setAlbums: success');
      return(true);
    } else {
      console.log('setAlbums: error');
      return(false);
    }
  }

  // get album by id from server and set in store albums[id]
  async setAlbum(id: string | number): Promise<any> {
    console.log('setAlbum called');
    const _result = await this.subsonic.getAlbum(id);
    console.log(_result);
    if (_result.status === 'ok'){
      this.store.subsonicState.albums[id] = _result.album;
      this.store.saveSubsonicState();
      console.log('setAlbum: success');
    } else {
      console.log('setAlbum: error');
    }
  }

  // get albuminfo by id from server and set in store albumInfo2[id] (tag)
  async setAlbumInfo2(id: string | number): Promise<any> {
    console.log('setAlbumInfo2 called');
    const _result = await this.subsonic.getAlbumInfo2(id);
    console.log(_result);
    if (_result.status === 'ok'){
      this.store.subsonicState.albumInfo2[id] = _result.albumInfo;
      this.store.saveSubsonicState();
      console.log('setAlbumInfo2: success');
    } else {
      console.log('setAlbumInfo2: error');
    }
  }

  // get newest albums from server and set in store
  async setAlbumListNewest(size?: number): Promise<any> {
    console.log('setAlbumListNewest called');
    let _size = 20;
    if (size !== undefined){
      _size = size;
    }
    const _result = await this.subsonic.getAlbumList('newest', _size);
    console.log(_result);
    if (_result.status === 'ok'){
      this.store.subsonicState.albumListNewest = _result.albumList;
      this.store.saveSubsonicState();
      console.log('setAlbumListNewest: success');
    } else {
      console.log('setAlbumListNewest: error');
    }
  }

  // get recent albums from server and set in store
  async setAlbumListRecent(size?: number): Promise<any> {
    console.log('setAlbumListRecent called');
    let _size = 20;
    if (size !== undefined){
      _size = size;
    }
    const _result = await this.subsonic.getAlbumList('recent', _size);
    console.log(_result);
    if (_result.status === 'ok'){
      this.store.subsonicState.albumListRecent = _result.albumList;
      this.store.saveSubsonicState();
      console.log('setAlbumListRecent: success');
    } else {
      console.log('setAlbumListRecent: error');
    }
  }

  // get random albums from server and set in store
  async setAlbumListRandom(size?: number): Promise<any> {
    console.log('setAlbumListRandom called');
    let _size = 20;
    if (size !== undefined){
      _size = size;
    }
    const _result = await this.subsonic.getAlbumList('random', _size);
    console.log(_result);
    if (_result.status === 'ok'){
      this.store.subsonicState.albumListRandom = _result.albumList;
      this.store.saveSubsonicState();
      console.log('setAlbumListRandom: success');
    } else {
      console.log('setAlbumListRandom: error');
    }
  }

  // get frequen albums from server and set in store
  async setAlbumListFrequent(size?: number): Promise<any> {
    console.log('setAlbumListFrequent called');
    let _size = 20;
    if (size !== undefined){
      _size = size;
    }
    const _result = await this.subsonic.getAlbumList('frequent', _size);
    console.log(_result);
    if (_result.status === 'ok'){
      this.store.subsonicState.albumListFrequent = _result.albumList;
      this.store.saveSubsonicState();
      console.log('setAlbumListFrequent: success');
    } else {
      console.log('setAlbumListFrequent: error');
    }
  }

  // get newest albums from server and set in store (tag)
  async setAlbumListNewest2(size?: number): Promise<any> {
    console.log('setAlbumListNewest2 called');
    let _size = 20;
    if (size !== undefined){
      _size = size;
    }
    const _result = await this.subsonic.getAlbumList2('newest', _size);
    console.log(_result);
    if (_result.status === 'ok'){
      this.store.subsonicState.albumListNewest2 = _result.albumList2;
      this.store.saveSubsonicState();
      console.log('setAlbumListNewes2t: success');
    } else {
      console.log('setAlbumListNewest2: error');
    }
  }

  // get recently played albums from server and set in store (tag)
  async setAlbumListRecent2(size?: number): Promise<any> {
    console.log('setAlbumListRecent2 called');
    let _size = 20;
    if (size !== undefined){
      _size = size;
    }
    const _result = await this.subsonic.getAlbumList2('recent', _size);
    console.log(_result);
    if (_result.status === 'ok'){
      this.store.subsonicState.albumListRecent2 = _result.albumList2;
      this.store.saveSubsonicState();
      console.log('setAlbumListRecent2: success');
    } else {
      console.log('setAlbumListRecent2: error');
    }
  }

  // get random albums from server and set in store (tag)
  async setAlbumListRandom2(size?: number): Promise<any> {
    console.log('setAlbumListRandom2 called');
    let _size = 20;
    if (size !== undefined){
      _size = size;
    }
    const _result = await this.subsonic.getAlbumList2('random', _size);
    console.log(_result);
    if (_result.status === 'ok'){
      this.store.subsonicState.albumListRandom2 = _result.albumList2;
      this.store.saveSubsonicState();
      console.log('setAlbumListRandom2: success');
    } else {
      console.log('setAlbumListRandom2: error');
    }
  }

  // get frequently played albums from server and set in store (tag)
  async setAlbumListFrequent2(size?: number): Promise<any> {
    console.log('setAlbumListFrequent2 called');
    let _size = 20;
    if (size !== undefined){
      _size = size;
    }
    const _result = await this.subsonic.getAlbumList2('frequent', _size);
    console.log(_result);
    if (_result.status === 'ok'){
      this.store.subsonicState.albumListFrequent2 = _result.albumList2;
      this.store.saveSubsonicState();
      console.log('setAlbumListFrequent2: success');
    } else {
      console.log('setAlbumListFrequent2: error');
    }
  }

  // get song from server and set in store songs[id]
  async setSong(id: string | number): Promise<any> {
    console.log('setSong called');
    const _result = await this.subsonic.getSong(id);
    console.log(_result);
    if (_result.status === 'ok'){
      this.store.subsonicState.songs[id] = _result.song;
      this.store.saveSubsonicState();
      console.log('setSong: success');
    } else {
      console.log('setSong: error');
    }
  }

  // get playlists from server and set in store
  async setPlaylists(): Promise<any> {
    console.log('setPlaylists called');
    const _result = await this.subsonic.getPlaylists();
    console.log(_result);
    if (_result.status === 'ok'){
      _result.playlists.updated = this.util.getToday();
      this.store.subsonicState.playlistsList = _result.playlists;
      this.store.saveSubsonicState();
      console.log('setPlaylists: success');
    } else {
      console.log('setPlaylists: error');
    }
  }

  // get playlist by id from server and set in store playlists[id]
  async setPlaylist(id: string | number): Promise<any> {
    console.log('setPlaylist called');
    const _result = await this.subsonic.getPlaylist(id);
    console.log(_result);
    if (_result.status === 'ok'){
      this.store.subsonicState.playlists[id] = _result.playlist;
      this.store.saveSubsonicState();
      console.log('setPlaylist: success');
    } else {
      console.log('setPlaylist: error');
    }
  }

  // get bookmarks from server and set in store
  async setBookmarks(): Promise<any> {
    console.log('setBookmarks called');
    const _result = await this.subsonic.getBookmarks();
    console.log(_result);
    if (_result.status === 'ok'){
      this.store.subsonicState.bookmarks = _result.bookmarks;
      this.store.saveSubsonicState();
      console.log('setBookmarks: success');
    } else {
      console.log('setBookmarks: error');
    }
  }

  async deletePodcastEpisode(episodeItem: any){
    if (typeof episodeItem === undefined){
      return(false);
    }
    const _id = episodeItem.id.toString();
    const _result = await this.subsonic.deletePodcastEpisode(_id);
    if (_result.status === 'ok'){
      await this.setPodcasts();
      console.log('deletePodcastEpisde: success');
      this.showDeletePodcastEpSuccess(episodeItem.title);
      return(true);
    } else {
      this.showDeletePodcastEpError(episodeItem.title);
      console.log('deletePodcastEpisode: error');
      return(false);
    }
  }

  async downloadPodcastEpisode(episodeItem: any){
    if (typeof episodeItem === undefined){
      return(false);
    }
    const _id = episodeItem.id.toString();
    const _result = await this.subsonic.downloadPodcastEpisode(_id);
    if (_result.status === 'ok'){
      await this.setPodcasts();
      console.log('deletePodcastEpisde: success');
      this.showDownloadPodcastEpSuccess(episodeItem.title);
      return(true);
    } else {
      this.showDownloadPodcastEpError(episodeItem.title);
      console.log('deletePodcastEpisode: error');
      return(false);
    }
  }


  async refreshPodcasts(): Promise<any> {
    console.log('refreshPodcasts called');
    const _result = await this.subsonic.refreshPodcasts();
    console.log(_result);
    if (_result.status === 'ok'){
      console.log('refreshPodcasts: success');
    } else {
      console.log('refreshPodcasts: error');
    }
  }

  async deletePodcast(podcastItem: any){
    if (typeof podcastItem === undefined){
      return(false);
    }
    const _id = podcastItem.id.toString();
    const _result = await this.subsonic.deletePodcastChannel(_id);
    if (_result.status === 'ok'){
      await this.setPodcasts();
      console.log('deletePodcast: success');
      this.showDeletePodcastSuccess(podcastItem.title);
      return(true);
    } else {
      this.showDeletePodcastError(podcastItem.title);
      console.log('deletePodcast: error');
      return(false);
    }
  }

  // subscribe to new podcast
  async subscribeToPodcast(podcastItem: any){
    console.log(podcastItem);
    try {
      const _result = await this.subsonic.createPodcastChannel(podcastItem.rss);
      if (_result.status === 'ok'){
        await this.util.addDelay(4000);
        await this.setPodcasts();
        console.log('subscribeToPodcast: success');
        this.showSubscribePodcastSuccess(podcastItem.title);
        return Promise.resolve(true);
      } else {
        this.showSubscribePodcastError(podcastItem.title);
        console.log('subscribeToPodcast: error');
        return Promise.resolve(false);
      }
    }
    catch (e) {
      console.log('subscribe podcast http error');
      console.log(e);
      return Promise.resolve(false);
    }
  }

  // get podcast list from the server and set in store
  async setPodcasts(): Promise<any> {
    console.log('setPodcasts called');
    const _result = await this.subsonic.getPodcasts();
    console.log(_result);
    if (_result.status === 'ok'){
      _result.podcasts.updated = this.util.getToday();
      // if there are no podcasts the channel array might be missing
      if (_result.podcasts.channel === undefined){
        _result.podcasts.channel = [];
      }

      // sanitize the description of channels and episodes
      // removes special line ends/breaks that kill sqlite
      _result.podcasts.channel.forEach((channel) => {
        console.log('sanitize channel: ' + channel.title);
        channel.description = this.util.sanitizeString(channel.description);
        channel.title = this.util.sanitizeString(channel.title);
        // when first added the episode array might not be present
        if (channel.episode === undefined){
          channel.episode = [];
        }
        channel.episode.forEach((episode) => {
          console.log('sanitize episode: ' + episode.title);
          episode.description = this.util.sanitizeString(episode.description);
          episode.title = this.util.sanitizeString(episode.title);
        });
      });

      this.store.subsonicState.podcastList = _result.podcasts;
      this.store.saveSubsonicState();
      console.log('setPodcasts: success');
      return Promise.resolve(true);
    } else {
      console.log('setPodcasts: error');
      return Promise.resolve(false);
    }
  }

  // get podcast by id from server and set in store podcasts[id]
  async setPodcast(id: string | number): Promise<any> {
    console.log('setPodcast called');
    const _result = await this.subsonic.getPodcasts(id);
    console.log(_result);
    if (_result.status === 'ok'){
      _result.podcasts.channel[0].title = this.util.sanitizeString(_result.podcasts.channel[0].title);
      _result.podcasts.channel[0].description = this.util.sanitizeString(_result.podcasts.channel[0].description);
      _result.podcasts.channel[0].episode.forEach((item) => {
        item.title = this.util.sanitizeString(item.title);
        item.description = this.util.sanitizeString(item.description);
      });
      this.store.subsonicState.podcasts[id] = _result.podcasts.channel[0];
      this.store.saveSubsonicState();
      console.log('setPodcast: success');
    } else {
      console.log('setPodcast: error');
    }
  }

  // check if song is starred
  isSongStarred(id: string| number): boolean {
    // console.log("isSongStarred called for id: "+id);

    if(!this.store.subsonicState.starred2.song) {
      this.setStarred2();
    }

    const _starredSongLength = this.store.subsonicState.starred2.song.length;
    let _foundItem = false;

    for (let i = 0; i < _starredSongLength; i++){
      if (this.store.subsonicState.starred2.song[i].id === id){
        _foundItem = true;
        return true;
      } else if (i === _starredSongLength - 1 && !_foundItem){
        return false;
      }
    }
  }

  // check if album is starred
  isAlbumStarred(id: string|number): boolean {
    console.log('isAlbumStarred called');

    const _starredAlbumLength = this.store.subsonicState.starred2.album.length;
    let _foundItem = false;

    for (let i = 0; i < _starredAlbumLength; i++){
      if (this.store.subsonicState.starred2.album[i].id === id){
        _foundItem = true;
        return true;
      } else if (i === _starredAlbumLength - 1 && !_foundItem){
        return false;
      }
    }
  }

  // check if album is starred
  isArtistStarred(id: string|number): boolean {
    console.log('isArtistStarred called');

    const _starredArtistLength = this.store.subsonicState.starred2.artist.length;
    let _foundItem = false;

    for (let i = 0; i < _starredArtistLength; i++){
      if (this.store.subsonicState.starred2.artist[i].id === id){
        _foundItem = true;
        return true;
      } else if (i === _starredArtistLength - 1 && !_foundItem){
        return false;
      }
    }
  }

  // check if album is starred
  isFolderStarred(id: string|number): boolean {
    console.log('isFolderStarred called');

    const _starredFolderLength = this.store.subsonicState.starred.album.length;
    let _foundItem = false;

    for (let i = 0; i < _starredFolderLength; i++){
      if (this.store.subsonicState.starred.album[i].id === id){
        _foundItem = true;
        return true;
      } else if (i === _starredFolderLength - 1 && !_foundItem){
        return false;
      }
    }
  }

  //
  // -- Success & Error alerts
  //

  // if podcast delete success
  async showDeletePodcastSuccess(podcastName: string){
    const alert = await this.alertCtrl.create({
      header: 'Podcast Deleted',
      message: 'Podcast ' + podcastName + ' deleted successfully',
      mode: 'ios',
      translucent: true,
      buttons: ['OK']
    });
    return alert.present();
  }

  // if podcast delete error
  async showDeletePodcastError(podcastName: string){
    const alert = await this.alertCtrl.create({
      header: 'Error Deleting Podcast',
      message: 'An error occurred deleting podcast: ' + podcastName,
      mode: 'ios',
      translucent: true,
      buttons: ['OK']
    });
    return alert.present();
  }

  // if podcast subscribe success
  async showSubscribePodcastSuccess(podcastName: string){
    const alert = await this.alertCtrl.create({
      header: 'Podcast Subscribed',
      message: 'Podcast ' + podcastName + ' successfully added to your server, it will take a few minutes to start syncing',
      mode: 'ios',
      translucent: true,
      buttons: ['OK']
    });
    return alert.present();
  }

  // if podcast subscribe error
  async showSubscribePodcastError(podcastName: string){
    const alert = await this.alertCtrl.create({
      header: 'Error Subscribing to Podcast',
      message: 'An error occurred adding podcast: ' + podcastName + 'to your server',
      mode: 'ios',
      translucent: true,
      buttons: ['OK']
    });
    return alert.present();
  }

  // if podcast episode delete success
  async showDeletePodcastEpSuccess(episodeName: string){
    const alert = await this.alertCtrl.create({
      header: 'Episode Deleted',
      message: 'Episode ' + episodeName + ' deleted successfully',
      mode: 'ios',
      translucent: true,
      buttons: ['OK']
    });
    return alert.present();
  }

  // if podcast episode delete error
  async showDeletePodcastEpError(episodeName: string){
    const alert = await this.alertCtrl.create({
      header: 'Error Deleting Episode',
      message: 'An error occurred deleting episode: ' + episodeName,
      mode: 'ios',
      translucent: true,
      buttons: ['OK']
    });
    return alert.present();
  }

  // if podcast episode download success
  async showDownloadPodcastEpSuccess(episodeName: string){
    const alert = await this.alertCtrl.create({
      header: 'Download Started',
      message: 'Episode ' + episodeName + ' download started',
      mode: 'ios',
      translucent: true,
      buttons: ['OK']
    });
    return alert.present();
  }

  // if podcast episode download error
  async showDownloadPodcastEpError(episodeName: string){
    const alert = await this.alertCtrl.create({
      header: 'Error Starting Download',
      message: 'An error occurred starting download of episode: ' + episodeName,
      mode: 'ios',
      translucent: true,
      buttons: ['OK']
    });
    return alert.present();
  }

  // if playlist creation success
  async showCreatePlaylistSuccess(playlistName: string){
    const alert = await this.alertCtrl.create({
      header: 'Playlist Created',
      message: 'New playlist ' + playlistName + ' created successfully',
      mode: 'ios',
      translucent: true,
      buttons: ['OK']
    });
    return alert.present();
  }

  // if playlist creation error
  async showCreatePlaylistError(playlistName: string){
    const alert = await this.alertCtrl.create({
      header: 'Error Creating Playlist',
      message: 'An error occurred creating new playlist: ' + playlistName,
      mode: 'ios',
      translucent: true,
      buttons: ['OK']
    });
    return alert.present();
  }

  // if playlist delete success
  async showDeletePlaylistSuccess(playlistName: string){
    const alert = await this.alertCtrl.create({
      header: 'Playlist Deleted',
      message: 'Playlist ' + playlistName + ' deleted successfully',
      mode: 'ios',
      translucent: true,
      buttons: ['OK']
    });
    return alert.present();
  }

  // if playlist delete error
  async showDeletePlaylistError(playlistName: string){
    const alert = await this.alertCtrl.create({
      header: 'Error Deleting Playlist',
      message: 'An error occurred deleting playlist: ' + playlistName,
      mode: 'ios',
      translucent: true,
      buttons: ['OK']
    });
    return alert.present();
  }

  // if playlist rename success
  async showRenamePlaylistSuccess(playlistName: string){
    const alert = await this.alertCtrl.create({
      header: 'Playlist Name Updated',
      message: 'New playlist name is: ' + playlistName,
      mode: 'ios',
      translucent: true,
      buttons: ['OK']
    });
    return alert.present();
  }

  // if playlist rename error
  async showRenamePlaylistError(playlistName: string){
    const alert = await this.alertCtrl.create({
      header: 'Error Updating Playlist',
      message: 'An error occurred updating playlist: ' + playlistName,
      mode: 'ios',
      translucent: true,
      buttons: ['OK']
    });
    return alert.present();
  }

  // If track add to playlist success
  async showAddSongToPlaylistSuccess(playlistName: string){
    const alert = await this.alertCtrl.create({
      header: 'Playlist Updated',
      message: 'Track added to playlist ' + playlistName,
      mode: 'ios',
      translucent: true,
      buttons: ['OK']
    });
    return alert.present();
  }

  // if track add to playlist error
  async showAddSongToPlaylistError(playlistName: string){
    const alert = await this.alertCtrl.create({
      header: 'Error Updating Playlist',
      message: 'An error occurred updating playlist: ' + playlistName,
      mode: 'ios',
      translucent: true,
      buttons: ['OK']
    });
    return alert.present();
  }

  // If top songs playlist created successfully
  async showSaveTopSongsPlaylistSuccess(playlistName: string){
    const alert = await this.alertCtrl.create({
      header: 'Playlist Saved',
      message: playlistName + ' saved',
      mode: 'ios',
      translucent: true,
      buttons: ['OK']
    });
    return alert.present();
  }

  // if top songs playlist not created
  async showSaveTopSongsPlaylistError(playlistName: string){
    const alert = await this.alertCtrl.create({
      header: 'Error Saving Playlist',
      message: 'An error occurred saving playlist: ' + playlistName,
      mode: 'ios',
      translucent: true,
      buttons: ['OK']
    });
    return alert.present();
  }

  // If radioplaylist generation success
  async showRadioPlaylistSuccess(genre: string, period: string){
    const alert = await this.alertCtrl.create({
      header: 'Radio Playlist',
      message: genre + ' tracks from ' + period + ' station is now playing!',
      mode: 'ios',
      translucent: true,
      buttons: ['OK']
    });
    return alert.present();
  }

  // if radioplaylist generation failed or empty
  async showRadioPlaylistError(err?: string){
    let alert;
    if (err){
      alert = await this.alertCtrl.create({
        header: 'Radio Playlist Error',
        message: 'Sorry something went wrong there (' + err + ')',
        mode: 'ios',
        translucent: true,
        buttons: ['OK']
      });
    }else{
      alert = await this.alertCtrl.create({
        header: 'No Match Found',
        message: 'No songs match your genre & year selection, try changing your selection or get more music!',
        mode: 'ios',
        translucent: true,
        buttons: ['OK']
      });
    }
    return alert.present();
  }

  // if radioplaylist generation failed or empty
  async showDelTrackFromPlaylistError(err?: string){
    const alert = await this.alertCtrl.create({
      header: 'Could not delete track',
      message: 'Sorry something went wrong there (' + err + ')',
      mode: 'ios',
      translucent: true,
      buttons: ['OK']
    });
    return alert.present();
  }
}
