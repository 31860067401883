import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncateString'
})
export class TruncateStringPipe implements PipeTransform {

  /**
   * Takes date as a string in any format, returns as an ISO date string
   */
  transform(value: string, length: number) {
    const ending = '...';

    if (!value) {
      return '';
    };

    if (length === undefined) {
      length = 50;
    };

    if (value.length > length) {
      return value.substring(0, length - ending.length) + ending;
    } else {
      return value;
    };
  }

}
