import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterByDateRange'
})
export class FilterByDateRangePipe implements PipeTransform {

  /**
   * return array only containing items with date between start and end
   */
  transform(array: Array <any>, start: string, end: string, key: string) {
    let startDate;
    let endDate;

    if (typeof start === undefined && typeof end === undefined){
      return array;
    }
    if (start.length === 0 && end.length === 0){
      return array;
    }
    if (array.length === 0){
      return array;
    }
    if (start.length){
      startDate = Number(new Date(start.slice(0, 7)));
    } else {
      startDate = Number(new Date('0001-01'));
    }
    if (end.length){
      endDate = Number(new Date(end.slice(0, 7)));
    } else {
      endDate = Number(new Date('3001-01'));
    }

    return array.filter((item) =>
      startDate <= Number(new Date(new Date(item[key]).toISOString().slice(0, 7))) &&
      Number(new Date(item[key].slice(0, 7))) <= endDate
    );
  }

}
