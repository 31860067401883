/* eslint-disable max-len */
import { Injectable, NgZone } from '@angular/core';
import { NavigationExtras } from '@angular/router';
import { ActionSheetController, NavController, ModalController} from '@ionic/angular';
import { SubsonicactionsService } from '../services/subsonicactions.service';
import { PlayeractionsService } from '../services/playeractions.service';
import { AppactionsService } from '../services/appactions.service';
import { UtilService } from '../services/util.service';
import { StoreService } from '../services/store.service';
import { MusicCacheService } from '../services/music-cache.service';
import { PodcastCacheService } from './podcast-cache.service';

@Injectable({
  providedIn: 'root'
})
export class MoreOptionsServiceService {

  constructor(private subsonicActions: SubsonicactionsService,
              private playerActions: PlayeractionsService,
              private appActions: AppactionsService,
              private util: UtilService,
              private musicCache: MusicCacheService,
              private podcastCache: PodcastCacheService,
              private store: StoreService,
              private ngzone: NgZone,
              private navCtrl: NavController,
              private modalCtrl: ModalController,
              private actionSheetCtrl: ActionSheetController) { }

  //
  // Helpers
  //
  async handleModalDismiss(context: string) {
    if(context === 'player'){
      try {
        await this.modalCtrl.dismiss();
      }
      catch {
        console.log('no modal to dismiss');
      }
    }
  }

  //
  // When the more options button is tapped on a track
  //
  async moreOptionsTrack(event: Event, trackItem: any, context?: string, trackItemIndex?: number){
    event.stopPropagation();
    console.log(trackItem);

    let _buttons = [];

    const _starTrackButton = {
      text: 'Star this Track',
      icon: 'star',
      handler: () => {
        console.log('star track clicked');
        this.subsonicActions.starTrack(trackItem.id);
      }
    };

    const _unstarTrackButton = {
      text: 'Unstar this Track',
      icon: 'star-outline',
      handler: () => {
        console.log('unstar track clicked');
        this.subsonicActions.unstarTrack(trackItem.id);
      }
    };

    const _playNextButton = {
      text: 'Play Next',
      icon: 'play-forward',
      handler: () => {
        console.log('play next clicked');
        this.playerActions.playNext(trackItem);
      }
    };

    const _addToPlaylistButton = {
      text: 'Add to Playlist',
      icon: 'list',
      handler: () => {
        console.log('add to playlist clicked');
        this.subsonicActions.addTracksToPlaylist([trackItem.id]);
      }
    };

    const _startNewPlaylistButton = {
      text: 'Start New Playlist',
      icon: 'add-circle',
      handler: () => {
        console.log('start new playlist clicked');
        this.subsonicActions.newPlaylistWithTracks([trackItem.id]);
      }
    };

    const _removeFromPlaylistButton = {
      text: 'Remove from Playlist',
      icon: 'list',
      handler: () => {
        console.log('remove from playlist clicked');
        this.subsonicActions.delTrackFromPlaylist(trackItem.id, trackItemIndex);
        // TODO cut from displayed list on success or show error
      }
    };

    const _queueTrackButton = {
      text: 'Add to Now Playing',
      icon: 'play',
      handler: () => {
        console.log('queue track track clicked');
        this.playerActions.addToPlayQueue(trackItem);
      }
    };

    const _setOfflineTrackButton = {
      text: 'Make Track Available Offline',
      icon: 'download',
      handler: () => {
        console.log('make track available offline clicked');
        this.musicCache.reqCacheSong(this.util.copy(trackItem));
      }
    };

    const _goToArtistButton = {
      text: 'Go To Artist',
      icon: 'person',
      handler: async () => {
        await this.handleModalDismiss(context);
        console.log('goto artist clicked');
        const navExtras: NavigationExtras = {state: {id: trackItem.artistId, name: trackItem.artist, coverArt: 'ar-' + trackItem.artistId}};
        this.ngzone.run(() => {
          this.navCtrl.navigateForward(`artist-detail/${trackItem.id}`, navExtras);
        });
      }
    };

    const _goToAlbumButton = {
      text: 'Go To Album',
      icon: 'disc',
      handler: async () => {
        console.log('goto album clicked');
        await this.handleModalDismiss(context);
        const navExtras: NavigationExtras = {state: {id: trackItem.albumId, name: trackItem.album, coverArt: 'al-' + trackItem.albumId}};
        this.ngzone.run(() => {
          this.navCtrl.navigateForward(`album-detail/${trackItem.id}`, navExtras);
        });
      }
    };

    const _playSimilarButton = {
      text: 'Play Similar Songs',
      icon: 'musical-notes',
      handler: () => {
        console.log('play similar clicked');
        this.subsonicActions.getSimilarTracks(trackItem.artistId)
          .then((result) => {
            if (result.similarSongs2.song !== undefined){
              this.playerActions.setPlayQueue(result.similarSongs2.song, 0);
            }
          });
      }
    };

    const _playSimilarFoldersButton = {
      text: 'Play Similar Songs',
      icon: 'musical-notes',
      handler: () => {
        console.log('play similar clicked');
        this.subsonicActions.getSimilarFolderTracks(trackItem.id)
          .then((result) => {
            if (result.similarSongs.song !== undefined){
              this.playerActions.setPlayQueue(result.similarSongs.song, 0);
            }
          });
      }
    };

    const _delTrackButton = {
      text: 'Remove Offline File',
      icon: 'trash',
      handler: () => {
        console.log('del track clicked');
        this.musicCache.delCachedSong(trackItem);
      }
    };

    const _saveQueueButton = {
      text: 'Save Queue as Playlist',
      icon: 'add-circle',
      handler: () => {
        console.log('save queue clicked');
        const _arrayOfID = [];
        const _length = this.store.playerState.playQueue.length;
        for (let i = 0; i < _length; i++){
          _arrayOfID.push(this.store.playerState.playQueue[i].id);
        }
        this.subsonicActions.newPlaylistWithTracks(_arrayOfID);
      }
    };

    const _playSimilarArtists = {
      text: 'Play Mix of Similar Artists',
      icon: 'people-circle',
      handler: async () => {
        console.log('play similar artists clicked');
        await this.appActions.showLoading('Building a list of songs from similar artist, this might take a moment...');
        await this.playerActions.setPlayQueue(await this.subsonicActions.getSimilarArtistsPlaylist(trackItem.artist, trackItem.artistId), 0);
      }
    };

    const _delTrackFromQueueButton = {
      text: 'Remove Track From Queue',
      icon: 'trash',
      handler: () => {
        console.log('delete track clicked');
        this.store.playerState.playQueue.splice(trackItemIndex, 1);
        this.store.saveQueueState();
      }
    };

    const _cancelButton = {
      text: 'Cancel',
      role: 'destructive',
      icon: 'close',
      handler: () => {
        console.log('Cancel clicked');
      }
    };

    // add main function buttons
    if (this.subsonicActions.isSongStarred(trackItem.id)){
      _buttons = _buttons.concat([_unstarTrackButton,
                                  _addToPlaylistButton,
                                  _startNewPlaylistButton]
                                );
    } else {
      _buttons = _buttons.concat([_starTrackButton,
                                  _addToPlaylistButton,
                                  _startNewPlaylistButton]
                                );
    }

    // only add set offline track option if on a native platform
    if(!this.store.appState.isWEB) {
      _buttons = _buttons.concat([_setOfflineTrackButton]);
    }

    // add to play queue only on non-player views
    if(context !== 'player'){
      _buttons.splice(1,0,_playNextButton);
      _buttons = _buttons.concat([_queueTrackButton]);
    }

    // add goto album button except on album view where it is not relevant
    if(context !== 'albumDetail') {
      _buttons = _buttons.concat([_goToAlbumButton]);
    }

    // artstInfo only works with an artist ID, compilation albums don't have it
    // also add play similar tracks for ID3 mode
    if (trackItem.artistId && !this.store.userState.modeFoldersOnly){
      _buttons = _buttons.concat([_goToArtistButton, _playSimilarButton]);
    } else {
      // play similar tracks for folder mode
      _buttons = _buttons.concat([_playSimilarFoldersButton]);
    }

    // add the del from cache button for offline music page
    if (context === 'offlineMusic') {
      _buttons = _buttons.concat([_delTrackButton]);
    }

    // add the del from playlist button for the playlist detail page
    if (context === 'playlistDetail') {
      _buttons = _buttons.concat([_removeFromPlaylistButton]);
    }

    // Add player view specific items
    if (context === 'player') {
      _buttons = _buttons.concat([_saveQueueButton, _playSimilarArtists]);

      // don't show remove track button on current playing track
      if(trackItem.id !== this.store.playerState.currentTrack.id){
        _buttons = _buttons.concat([_delTrackFromQueueButton]);
      }
    }

    // add the cancel button
    _buttons = _buttons.concat([_cancelButton]);

    const actionSheet = await this.actionSheetCtrl.create({
      header: trackItem.title + ' - ' + trackItem.artist,
      cssClass: 'black-action-sheet',
      mode: 'md',
      buttons: _buttons
    });

    return actionSheet.present();
  }

  //
  // WHen the more options button is tapped on the album in ID3 Mode
  //
  async moreOptionsAlbum(event: Event, albumItem: any, context?: string){
    event.stopPropagation();

    // We don't always have the full album item with song array for tracks
    // grab it to make sure we have the required details
    if (!this.store.subsonicState.albums[albumItem.id]){
      console.log('need data');
      await this.subsonicActions.setAlbum(albumItem.id);
    }
    const albumDetailItem = await this.util.copy(this.store.subsonicState.albums[albumItem.id]);

    let _buttons = [];

    const _starAlbumButton = {
      text: 'Star this Album',
      icon: 'star',
      handler: () => {
        console.log('star album clicked');
        this.subsonicActions.starAlbum(albumItem.id);
      }
    };

    const _unstarAlbumButton = {
      text: 'Unstar this Album',
      icon: 'star-outline',
      handler: () => {
        console.log('unstar album clicked');
        this.subsonicActions.unstarAlbum(albumItem.id);
      }
    };

    const _goToArtistButton = {
      text: 'Go To Artist',
      icon: 'person',
      handler: async () => {
        await this.handleModalDismiss(context);
        console.log('goto artist clicked');
        const navExtras: NavigationExtras = {state: {id: albumItem.artistId, name: albumItem.artist, coverArt: 'ar-' + albumItem.artistId}};
        this.ngzone.run(() => {
          this.navCtrl.navigateForward(`artist-detail/${albumItem.artistId}`, navExtras);
        });
      }
    };

    const _playSimilarArtists = {
      text: 'Play Mix of Similar Artists',
      icon: 'people-circle',
      handler: async () => {
        console.log('play similar artists clicked');
        await this.appActions.showLoading('Building a list of songs from similar artist, this might take a moment...');
        await this.playerActions.setPlayQueue(await this.subsonicActions.getSimilarArtistsPlaylist(albumItem.artist, albumItem.artistId), 0);
        this.appActions.hideLoading();
      }
    };

    const _addToPlaylistButton = {
      text: 'Add to Playlist',
      icon: 'list',
      handler: async () => {
        console.log('add to playlist clicked');
        this.subsonicActions.addTracksToPlaylist(await this.util.getAlbumTrackIDArray(this.util.copy(albumDetailItem)));
      }
    };

    const _startNewPlaylistButton = {
      text: 'Start New Playlist',
      icon: 'add-circle',
      handler: async () => {
        console.log('start new playlist clicked');
        this.subsonicActions.newPlaylistWithTracks(await this.util.getAlbumTrackIDArray(this.util.copy(albumDetailItem)));
      }
    };

    const _queueAlbumButton = {
      text: 'Add to Now Playing',
      icon: 'play',
      handler: () => {
        console.log('queue album clicked');
        this.playerActions.addMultipleToPlayQueue(this.util.copy(albumDetailItem.song));
      }
    };

    const _delAlbumButton = {
      text: 'Remove Offline Album',
      icon: 'trash',
      handler: () => {
        console.log('del album clicked');
        this.musicCache.delCachedItem(albumItem.id, 'album');
      }
    };

    const _cancelButton = {
      text: 'Cancel',
      role: 'destructive',
      icon: 'close',
      handler: () => {
        console.log('Cancel clicked');
      }
    };

    if (this.subsonicActions.isAlbumStarred(albumItem.id)){
      _buttons = _buttons.concat([_unstarAlbumButton,
                                  _addToPlaylistButton,
                                  _startNewPlaylistButton,
                                  _queueAlbumButton]
                                );
    } else {
      _buttons = _buttons.concat([_starAlbumButton,
                                  _addToPlaylistButton,
                                  _startNewPlaylistButton,
                                  _queueAlbumButton]
                                );
    }

    // artstInfo only works with an artist ID, compilation albums don't have it
    if (albumItem.artistId){
      _buttons = _buttons.concat([_goToArtistButton, _playSimilarArtists]);
    }

    // add delete offline album for offline music page
    if(context === 'offlineMusic') {
      _buttons = _buttons.concat([_delAlbumButton]);
    }

    // add the cancel button
    _buttons = _buttons.concat([_cancelButton]);

    const actionSheet = await this.actionSheetCtrl.create({
      header: albumItem.name + ' - ' + albumItem.artist,
      cssClass: 'black-action-sheet',
      mode: 'md',
      buttons: _buttons
    });

    return actionSheet.present();
  }

  //
  // When the more button is tapped on an artist
  //
  async moreOptionsArtist(event, artistItem){
    event.stopPropagation();

    let _buttons = [];

    const _starArtistButton = {
      text: 'Star this Artist',
      icon: 'star',
      handler: () => {
        console.log('star artist clicked');
        this.subsonicActions.starArtist(artistItem.id);
      }
    };

    const _unstarArtistButton = {
      text: 'Unstar this Artist',
      icon: 'star-outline',
      handler: () => {
        console.log('unstar artist clicked');
        this.subsonicActions.unstarArtist(artistItem.id);
      }
    };

    const _saveTopSongsPlaylist = {
      text: 'Save Top Songs Playlist',
      icon: 'add-circle',
      handler: () => {
        console.log('save top songs playlist clicked');
        const _arrayOfTopSongID = [];
        const _length = this.store.subsonicState.artistTopSongs[artistItem.id].song.length;
        for (let i = 0; i < _length; i++){
          _arrayOfTopSongID.push(this.store.subsonicState.artistTopSongs[artistItem.id].song[i].id);
        }
        this.subsonicActions.saveTopSongsPlaylist(_arrayOfTopSongID, artistItem.name + ' Top Songs');
      }
    };

    const _playSimilarArtists = {
      text: 'Play Mix of Similar Artists',
      icon: 'people-circle',
      handler: async () => {
        console.log('play similar artists clicked');
        await this.appActions.showLoading('Building a list of songs from similar artist, this might take a moment...');
        await this.playerActions.setPlayQueue(await this.subsonicActions.getSimilarArtistsPlaylist(artistItem.name, artistItem.id), 0);
        this.appActions.hideLoading();
      }
    };

    const _cancelButton = {
      text: 'Cancel',
      role: 'destructive',
      icon: 'close',
      handler: () => {
        console.log('Cancel clicked');
      }
    };

    if (this.subsonicActions.isArtistStarred(artistItem.id)){
      _buttons = _buttons.concat([_unstarArtistButton,
                                  _saveTopSongsPlaylist,
                                  _playSimilarArtists,
                                  _cancelButton]
                                );
    } else {
      _buttons = _buttons.concat([_starArtistButton,
                                  _saveTopSongsPlaylist,
                                  _playSimilarArtists,
                                  _cancelButton]);
    }

    const actionSheet = await this.actionSheetCtrl.create({
      header: artistItem.name + '',
      cssClass: 'black-action-sheet',
      mode: 'md',
      buttons: _buttons
    });

    return actionSheet.present();
  }

  //
  // WHen the more button is tapped on a folder
  //
  async moreOptionsFolder(event: Event, folderItem: any, context?: string){
    event.stopPropagation();

    let _buttons = [];

    const _starFolderButton = {
      text: 'Star this Folder',
      icon: 'star',
      handler: () => {
        console.log('star folder clicked');
        this.subsonicActions.starFolder(folderItem.id);
      }
    };

    const _unstarFolderButton = {
      text: 'Unstar this Folder',
      icon: 'star-outline',
      handler: () => {
        console.log('unstar folder clicked');
        this.subsonicActions.unstarFolder(folderItem.id);
      }
    };

    const _delFolderButton = {
      text: 'Remove Offline Folder',
      icon: 'trash',
      handler: () => {
        console.log('del folder clicked');
        this.musicCache.delCachedItem(folderItem.id, 'folder');
      }
    };

    const _cancelButton = {
      text: 'Cancel',
      role: 'destructive',
      icon: 'close',
      handler: () => {
        console.log('Cancel clicked');
      }
    };

    // handle starred and standard items
    if (this.subsonicActions.isFolderStarred(folderItem.id)){
      _buttons = _buttons.concat([_unstarFolderButton]);
    } else {
      _buttons = _buttons.concat([_starFolderButton]);
    }

    // enable del folder for offline music
    if (context === 'offlineMusic') {
      _buttons = _buttons.concat([_delFolderButton]);
    }

    // add the cancel buttom
    _buttons = _buttons.concat([_cancelButton]);

    const actionSheet = await this.actionSheetCtrl.create({
      header: folderItem.name + '',
      cssClass: 'black-action-sheet',
      mode: 'md',
      buttons: _buttons
    });

    return actionSheet.present();
  }

  //
  // When the more button is tapped on the playlist
  //
  async moreOptionsPlaylist(event: Event, playlistItem: any, context?: string){
    event.stopPropagation();

    let _buttons = [];

    const _deletePlaylistButton = {
      text: 'Delete Playlist',
      icon: 'trash',
      handler: () => {
        console.log('delete playlist clicked');
        this.subsonicActions.deletePlaylist(playlistItem);
      }
    };

    const _renamePlaylistButton = {
      text: 'Rename Playlist',
      icon: 'create',
      handler: () => {
        console.log('rename playlist clicked');
        this.subsonicActions.renamePlaylist(playlistItem);
      }
    };

    const _delCachedPlaylistButton = {
      text: 'Remove Offline Playlist',
      icon: 'trash',
      handler: () => {
        console.log('del playlist clicked');
        this.musicCache.delCachedItem(playlistItem.id, 'playlist');
      }
    };

    const _cancelButton = {
      text: 'Cancel',
      role: 'destructive',
      icon: 'close',
      handler: () => {
        console.log('Cancel clicked');
      }
    };

    // add standard playlist actions
    if (!context) {
      _buttons = _buttons.concat([_renamePlaylistButton, _deletePlaylistButton]);
    }

    // add offline playlist actions
    if (context === 'offlineMusic') {
      _buttons = _buttons.concat([_delCachedPlaylistButton]);
    }

    // add the cancel button
    _buttons = _buttons.concat([_cancelButton]);

    const actionSheet = await this.actionSheetCtrl.create({
      header: playlistItem.name + '',
      cssClass: 'black-action-sheet',
      mode: 'md',
      buttons: _buttons
    });
    return actionSheet.present();
  }

}
