import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { IonRouterOutlet, Platform, NavController } from '@ionic/angular';
import { HTTP } from '@awesome-cordova-plugins/http/ngx';
import { AppactionsService } from './services/appactions.service';
import { StoreService } from './services/store.service';
import { SubsonicactionsService } from './services/subsonicactions.service';
import { environment } from 'src/environments/environment';

declare global {
  interface Navigator {
    app: any;
  }
}

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {

  @ViewChild(IonRouterOutlet, { static : true }) routerOutlet: IonRouterOutlet;
  public appPages: Array<{title: string; icon: string; url: string}>;
  public appPagesFoldersOnly: Array<{title: string; icon: string; url: string}>;
  public appPagesTagsOnly: Array<{title: string; icon: string; url: string}>;
  public appPagesOffline: Array<{title: string; icon: string; url: string}>;
  public appPagesOfflineFoldersOnly: Array<{title: string; icon: string; url: string}>;
  public appPagesOfflineTagsOnly: Array<{title: string; icon: string; url: string}>;

  public appPagesNoPodcast: Array<{title: string; icon: string; url: string}>;
  public appPagesFoldersOnlyNoPodcast: Array<{title: string; icon: string; url: string}>;
  public appPagesTagsOnlyNoPodcast: Array<{title: string; icon: string; url: string}>;
  public appPagesOfflineNoPodcast: Array<{title: string; icon: string; url: string}>;
  public appPagesOfflineFoldersOnlyNoPodcast: Array<{title: string; icon: string; url: string}>;
  public appPagesOfflineTagsOnlyNoPodcast: Array<{title: string; icon: string; url: string}>;

  public selectedIndex = 0;

  constructor(
    private platform: Platform,
    private location: Location,
    private navCtrl: NavController,
    private nativeHTTP: HTTP,
    public subsonicActions: SubsonicactionsService,
    public appActions: AppactionsService,
    public store: StoreService
  ) {
    this.initApp();
    this.initMenus();
  }

  ngOnInit() {
    const path = window.location.pathname.split('folder/')[1];
    if (path !== undefined) {
      this.selectedIndex = this.appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    }
  }

  initApp() {
    this.platform.ready().then(() => {
      if (this.platform.is('android') && this.platform.is('cordova')) {
        // do android stuff
        this.registerBackButtonHandler();
      }
      if (this.platform.is('ios') && this.platform.is('cordova')) {
        // do ios stuff
      }
      // do non-platform specific stuff
      this.disableConsoleLogs();
      this.setNativeHTTPOptions();
      this.appActions.preInit();
      console.log('lang ' +navigator.language.slice(0,2));
    });
  }

  async setNativeHTTPOptions() {
    if(this.platform.is('cordova') && (this.platform.is('ios') || this.platform.is('android'))){
      await this.nativeHTTP.setServerTrustMode('nocheck');
    }
  }

  setMenuIndex(newIndex: number) {
    this.selectedIndex = newIndex;
  }

  splitPaneStatus(event: any) {
    console.log('splitpanestatus: ' + event.detail.visible);
    if(event.detail.visible) {
      this.appActions.setSplitPaneStatus(true);
    } else {
      this.appActions.setSplitPaneStatus(false);
    }

  }

  async registerBackButtonHandler() {
    // If possible go back
    this.platform.backButton.subscribeWithPriority(10, (processNextHandler) => {
      console.log('Backbutton priority 10');
      if(this.routerOutlet.canGoBack()){
        this.routerOutlet.pop();
      } else {
        processNextHandler();
      }
    });

    // If not on home screen go home
    this.platform.backButton.subscribeWithPriority(5, (processNextHandler) => {
      console.log('Backbutton priority 5');
      if (!this.location.isCurrentPathEqualTo('/home')) {
        console.log('Go to homescreen');
        this.navCtrl.navigateRoot('home');
        this.setMenuIndex(0);
      } else {
        processNextHandler();
      }
    });

    // Else do nothing
    this.platform.backButton.subscribeWithPriority(1, async (event) => {console.log('Backbutton priority 1');});
  }

  disableConsoleLogs(): void {
    if (environment.production) {
      window.console.log = () => { };
      window.console.warn = () => { };
      window.console.error = () => { };
    }
  }

  toggleOfflineMode() {
    if (this.store.userState.offlineMode) {
      this.store.setOnline();
    } else {
      this.store.setOffline();
    }
  }

  exitApp() {
    navigator.app.exitApp();
  }

  initMenus() {

    const isWeb = !this.platform.is('cordova');
    const offlinePodcasts = { title: 'Offline Podcasts', icon: 'cloud-done', url: '/offline-podcasts'};
    const offlineMusic = { title: 'Offline Music', icon: 'cloud-done', url: '/offline-music'};

    // Default side menu
    this.appPages = [
      { title: 'Home', icon: 'stats-chart', url: '/home'},
      { title: 'Artists', icon: 'people', url: '/artist-list' },
      { title: 'Albums', icon: 'disc', url: '/album-list'},
      { title: 'Folders', icon: 'folder', url: '/folder-list'},
      { title: 'Starred', icon: 'star', url: '/starred-list'},
      { title: 'Playlists', icon: 'list', url: '/playlist-list'},
      { title: 'Playlist Builder', icon: 'radio', url: '/radio'},
      { title: 'Search', icon: 'search', url: '/search'},
      { title: 'Bookmarks', icon: 'bookmark', url: '/bookmarks'},
      { title: 'Podcasts', icon: 'mic', url: '/podcast-list'},
      { title: 'Settings', icon: 'settings', url: '/settings'}
    ];

    // Folders only side menu
    this.appPagesFoldersOnly = [
      { title: 'Home', icon: 'stats-chart', url: '/home'},
      { title: 'Folders', icon: 'folder', url: '/folder-list'},
      { title: 'Starred', icon: 'star', url: '/starred-list'},
      { title: 'Playlists', icon: 'list', url: '/playlist-list'},
      { title: 'Playlist Builder', icon: 'radio', url: '/radio'},
      { title: 'Search', icon: 'search', url: '/search'},
      { title: 'Bookmarks', icon: 'bookmark', url: '/bookmarks'},
      { title: 'Podcasts', icon: 'mic', url: '/podcast-list'},
      { title: 'Settings', icon: 'settings', url: '/settings'}
    ];

    // Tags only side menu
    this.appPagesTagsOnly = [
      { title: 'Home', icon: 'stats-chart', url: '/home'},
      { title: 'Artists', icon: 'people', url: '/artist-list' },
      { title: 'Albums', icon: 'disc', url: '/album-list'},
      { title: 'Starred', icon: 'star', url: '/starred-list'},
      { title: 'Playlists', icon: 'list', url: '/playlist-list'},
      { title: 'Playlist Builder', icon: 'radio', url: '/radio'},
      { title: 'Search', icon: 'search', url: '/search'},
      { title: 'Bookmarks', icon: 'bookmark', url: '/bookmarks'},
      { title: 'Podcasts', icon: 'mic', url: '/podcast-list'},
      { title: 'Settings', icon: 'settings', url: '/settings'}
    ];

    // Default side menu no podcasts
    this.appPagesNoPodcast = [
      { title: 'Home', icon: 'stats-chart', url: '/home'},
      { title: 'Artists', icon: 'people', url: '/artist-list' },
      { title: 'Albums', icon: 'disc', url: '/album-list'},
      { title: 'Folders', icon: 'folder', url: '/folder-list'},
      { title: 'Starred', icon: 'star', url: '/starred-list'},
      { title: 'Playlists', icon: 'list', url: '/playlist-list'},
      { title: 'Playlist Builder', icon: 'radio', url: '/radio'},
      { title: 'Search', icon: 'search', url: '/search'},
      { title: 'Bookmarks', icon: 'bookmark', url: '/bookmarks'},
      { title: 'Settings', icon: 'settings', url: '/settings'}
    ];

    // Folders only side menu no podcasts
    this.appPagesFoldersOnlyNoPodcast = [
      { title: 'Home', icon: 'stats-chart', url: '/home'},
      { title: 'Folders', icon: 'folder', url: '/folder-list'},
      { title: 'Starred', icon: 'star', url: '/starred-list'},
      { title: 'Playlists', icon: 'list', url: '/playlist-list'},
      { title: 'Playlist Builder', icon: 'radio', url: '/radio'},
      { title: 'Search', icon: 'search', url: '/search'},
      { title: 'Bookmarks', icon: 'bookmark', url: '/bookmarks'},
      { title: 'Settings', icon: 'settings', url: '/settings'}
    ];

    // Tags only side menu no podcasts
    this.appPagesTagsOnlyNoPodcast = [
      { title: 'Home', icon: 'stats-chart', url: '/home'},
      { title: 'Artists', icon: 'people', url: '/artist-list' },
      { title: 'Albums', icon: 'disc', url: '/album-list'},
      { title: 'Starred', icon: 'star', url: '/starred-list'},
      { title: 'Playlists', icon: 'list', url: '/playlist-list'},
      { title: 'Playlist Builder', icon: 'radio', url: '/radio'},
      { title: 'Search', icon: 'search', url: '/search'},
      { title: 'Bookmarks', icon: 'bookmark', url: '/bookmarks'},
      { title: 'Settings', icon: 'settings', url: '/settings'}
    ];

    // Offline default side menu
    this.appPagesOffline = [
      { title: 'Home', icon: 'stats-chart', url: '/cached-home'},
      { title: 'Songs', icon: 'musical-notes', url: '/cached-song-list'},
      { title: 'Albums', icon: 'disc', url: '/cached-album-list'},
      { title: 'Folders', icon: 'folder', url: '/cached-folder-list'},
      { title: 'Starred', icon: 'stats-chart', url: '/cached-starred'},
      { title: 'Playlists', icon: 'list', url: '/cached-playlist-list'},
      { title: 'Episodes', icon: 'list', url: '/cached-episode-list'},
      { title: 'Podcasts', icon: 'mic', url: '/cached-podcast-list'},
      { title: 'Bookmarks', icon: 'bookmark', url: '/bookmarks'},
      { title: 'Settings', icon: 'settings', url: '/settings'}
    ];

    // Offline folders Only side menu
    this.appPagesOfflineFoldersOnly = [
      { title: 'Home', icon: 'stats-chart', url: '/cached-home'},
      { title: 'Songs', icon: 'musical-notes', url: '/cached-song-list'},
      { title: 'Folders', icon: 'folder', url: '/cached-folder-list'},
      { title: 'Starred', icon: 'stats-chart', url: '/cached-starred'},
      { title: 'Playlists', icon: 'list', url: '/cached-playlist-list'},
      { title: 'Episodes', icon: 'list', url: '/cached-episode-list'},
      { title: 'Podcasts', icon: 'mic', url: '/cached-podcast-list'},
      { title: 'Bookmarks', icon: 'bookmark', url: '/bookmarks'},
      { title: 'Settings', icon: 'settings', url: '/settings'}
    ];

    // Offline Tags Only side menu
    this.appPagesOfflineTagsOnly = [
      { title: 'Home', icon: 'stats-chart', url: '/cached-home'},
      { title: 'Songs', icon: 'musical-notes', url: '/cached-song-list'},
      { title: 'Albums', icon: 'disc', url: '/cached-album-list'},
      { title: 'Starred', icon: 'stats-chart', url: '/cached-starred'},
      { title: 'Playlists', icon: 'list', url: '/cached-playlist-list'},
      { title: 'Episodes', icon: 'list', url: '/cached-episode-list'},
      { title: 'Podcasts', icon: 'mic', url: '/cached-podcast-list'},
      { title: 'Bookmarks', icon: 'bookmark', url: '/bookmarks'},
      { title: 'Settings', icon: 'settings', url: '/settings'}
    ];

    // Offline default side menu no podcasts
    this.appPagesOfflineNoPodcast = [
      { title: 'Home', icon: 'stats-chart', url: '/cached-home'},
      { title: 'Songs', icon: 'musical-notes', url: '/cached-song-list'},
      { title: 'Albums', icon: 'disc', url: '/cached-album-list'},
      { title: 'Folders', icon: 'folder', url: '/cached-folder-list'},
      { title: 'Starred', icon: 'stats-chart', url: '/cached-starred'},
      { title: 'Playlists', icon: 'list', url: '/cached-playlist-list'},
      { title: 'Bookmarks', icon: 'bookmark', url: '/bookmarks'},
      { title: 'Settings', icon: 'settings', url: '/settings'}
    ];

    // Offline folders Only side menu no podcasts
    this.appPagesOfflineFoldersOnlyNoPodcast = [
      { title: 'Home', icon: 'stats-chart', url: '/cached-home'},
      { title: 'Songs', icon: 'musical-notes', url: '/cached-song-list'},
      { title: 'Folders', icon: 'folder', url: '/cached-folder-list'},
      { title: 'Starred', icon: 'stats-chart', url: '/cached-starred'},
      { title: 'Playlists', icon: 'list', url: '/cached-playlist-list'},
      { title: 'Bookmarks', icon: 'bookmark', url: '/bookmarks'},
      { title: 'Settings', icon: 'settings', url: '/settings'}
    ];

    // Offline Tags Only side menu no podcasts
    this.appPagesOfflineTagsOnlyNoPodcast = [
      { title: 'Home', icon: 'stats-chart', url: '/cached-home'},
      { title: 'Songs', icon: 'musical-notes', url: '/cached-song-list'},
      { title: 'Albums', icon: 'disc', url: '/cached-album-list'},
      { title: 'Starred', icon: 'stats-chart', url: '/cached-starred'},
      { title: 'Playlists', icon: 'list', url: '/cached-playlist-list'},
      { title: 'Bookmarks', icon: 'bookmark', url: '/bookmarks'},
      { title: 'Settings', icon: 'settings', url: '/settings'}
    ];

    // If running on a device enable offline content menu items
    if(!isWeb) {
      this.appPages.splice(this.appPages.length-1, 0, offlinePodcasts, offlineMusic);
      this.appPagesFoldersOnly.splice(this.appPagesFoldersOnly.length-1, 0, offlinePodcasts, offlineMusic);
      this.appPagesTagsOnly.splice(this.appPagesTagsOnly.length-1, 0, offlinePodcasts, offlineMusic);
      this.appPagesNoPodcast.splice(this.appPagesNoPodcast.length-1, 0, offlineMusic);
      this.appPagesFoldersOnlyNoPodcast.splice(this.appPagesFoldersOnlyNoPodcast.length-1, 0, offlineMusic);
      this.appPagesTagsOnlyNoPodcast.splice(this.appPagesTagsOnlyNoPodcast.length-1, 0, offlineMusic);
     }
  }

}
