import { Injectable } from '@angular/core';
import { SubsonicService } from './subsonic.service';
import { StoreService } from './store.service';
import { AppactionsService } from './appactions.service';

@Injectable({
  providedIn: 'root'
})
export class ScrobbleService {

  private queueWatcher: any;
  private queueWatcherActive = false;

  constructor(private subsonic: SubsonicService,
              private appActions: AppactionsService,
              private store: StoreService) { }

  init(){
    setTimeout(() => {
      this.watchQueue();
    },5000);
  }

  // Playback complete submission
  scrobblePlayed(trackID: string) {
    const timePlayed = Date.now();

    if (!(this.store.playerState.currentTrack.type === 'podcast')
        && !this.store.appState.playerChangeInProgress){

        this.store.scrobbleQueueState.scrobbleQueue.push({trackID, timePlayed});
        this.watchQueue();
    }
  }

  // Now Playing submission
  scrobbleNowPlaying(trackID: string) {
    if (!this.store.userState.offlineMode && !(this.store.playerState.currentTrack.type === 'podcast')
        && !this.store.appState.playerChangeInProgress){
      this.subsonic.scrobble(trackID, undefined, false);
    }
  }

  //
  // Private
  //

  // watch and process the scrobble queue
  watchQueue() {
    // if a watcher is already active do nothing
    if (this.queueWatcherActive){
      return;
    }

    // else create a watcher
    console.log('starting scrobble queue watcher');

    this.queueWatcherActive = true;
    this.queueWatcher = setInterval(() => {
      // if there are queued items process them
      if (this.store.scrobbleQueueState.scrobbleQueue.length && !this.store.userState.offlineMode){
        console.log('scrobble queue has ' + this.store.scrobbleQueueState.scrobbleQueue.length + ' items wating');
        console.log(this.store.scrobbleQueueState.scrobbleQueue);
        this.processScrobbles();
      } else {
      // if there are no more queued items stop watcher
        console.log('scrobble queue empty');
        this.stopWatchingQueue();
      }
    }, 1000);
  }

  // stop watching the download queue
  stopWatchingQueue(){
    console.log('stopping scrobble queue watcher');
    this.queueWatcherActive = false;
    clearInterval(this.queueWatcher);
    console.log('scrobbleQueueState pending items saved : ' + this.store.scrobbleQueueState.scrobbleQueue.length);
    this.store.saveScrobbleQueueState();
  }

  // force restart of the queue watcher
  resetQueueWatcher(){
    this.stopWatchingQueue();
    setTimeout(() => {
      this.watchQueue();
    }, 2000);
  }

  async processScrobbles() {
    if(!this.store.userState.offlineMode){
      const apiStatus = await this.appActions.getSubsonicAPIState();
      if (apiStatus.status === 'ok') {
        const scrobbleItem = this.store.scrobbleQueueState.scrobbleQueue.shift();
        const res = await this.subsonic.scrobble(scrobbleItem.trackID, scrobbleItem.timePlayed, true);
        if (res.status === 'failed') {
          this.store.scrobbleQueueState.scrobbleQueue.unshift(scrobbleItem);
        }
        this.store.saveScrobbleQueueState();
      }
    }
  }
}
