import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchFilter'
})
export class SearchFilterPipe implements PipeTransform {

   // items: the array to be filtered
   // term: the search term to be matched
   // listType: song, album, playlist etc determines matching criteria

   transform(items: any, term: string, listType: string): any {

    // Term of space character triggers crazy resource utilization so catch that
    if (term === ' '){
      term = '';
    }

    // If there is no term or term length is 0 or items array length = 0 then return unchanged list
    if (term === undefined || term === null || term.length === 0 || items.length === 0) {return items; }

    if (listType === 'song'){
       return items.filter(item =>
         item.title.toLowerCase().includes(term.toLowerCase()) ||
         item.artist.toLowerCase().includes(term.toLowerCase())
       );
     }

     else if (listType === 'album'){
       return items.filter(item =>
         item.name.toLowerCase().includes(term.toLowerCase()) ||
         item.artist.toLowerCase().includes(term.toLowerCase())
       );
     }

     else if (listType === 'folder'){
       // Folder list only has name field
       if (items[0].hasOwnProperty('name')){
         return items.filter(item =>
         item.name.toLowerCase().includes(term.toLowerCase())
         );
       // Starred list has title which = name or album / artist
       } else {
         return items.filter(item =>
         item.title.toLowerCase().includes(term.toLowerCase()) ||
         item.artist.toLowerCase().includes(term.toLowerCase())
         );
       }
     }

     else if (listType === 'playlist'){
       return items.filter(item =>
         item.name.toLowerCase().includes(term.toLowerCase())
       );
     }

     else if (listType === 'artist'){
       return items.filter(item =>
         item.name.toLowerCase().includes(term.toLowerCase())
       );
     }

     else if (listType === 'podcast'){
       return items.filter(item =>
         item.title.toLowerCase().includes(term.toLowerCase()) ||
         item.description.toLowerCase().includes(term.toLowerCase())
       );
     }

     else if (listType === 'podcast_ep'){
       return items.filter(item =>
         item.title.toLowerCase().includes(term.toLowerCase()) ||
         item.description.toLowerCase().includes(term.toLowerCase())
       );
     }

     else if (listType === 'podcast_search_ep'){
       return items.filter(item =>
         item.title.toLowerCase().includes(term.toLowerCase()) ||
         item.description.toLowerCase().includes(term.toLowerCase())
       );
     }

     else if (listType === 'bookmark'){
       return items.filter(item =>
         item.bookmarkName.toLowerCase().includes(term.toLowerCase())
       );
     }
  }

}
