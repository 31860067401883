import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ListennotesService {

  public apiAppId = 'musicstashv2';
  public apiAppKey = 'e38f0103368044e9a7f1e97d6ea2085c';
  public apiBaseUrl = 'https://listen-api.listennotes.com';
  public apiBaseUrlTEST = 'http://localhost:8100/LNapi';
  public apiActiveUrl: string;
  public apiHeaders: HttpHeaders;
  private apiV1BaseUrl = 'https://listennotes.p.rapidapi.com';
  private apiV1Headers: HttpHeaders;
  private apiV1AppID = 'musicstash_5c383b71e4b0a62b04251959';
  private apiV1AppKey = 'cyZept7sRgmsh9yx5RxIo6iOsligp1E3C4ujsnYYeiOKp6B7TB';

  constructor(private http: HttpClient)
  {
    this.init();
  }

  init() {
    console.log('Hello ListennotesProvider Provider');
    // enable or test or production baseURL
    this.apiActiveUrl = this.apiBaseUrl;
    this.setHeaders();
    this.setV1Headers();
  }

  setHeaders(){
    const _headers = new HttpHeaders()
      .set('X-ListenAPI-Key', this.apiAppKey);
    this.apiHeaders = _headers;
  }

  setV1Headers(){
    const _headers = new HttpHeaders()
      .set('x-rapidapi-host', 'listennotes.p.rapidapi.com')
      .set('RapidAPI-Project', this.apiV1AppID)
      .set('X-RapidAPI-Key', this.apiV1AppKey);
    this.apiV1Headers = _headers;
  }

  validate(){

  }

  formatError(errorObject): any{
    let _formattedError: any;

    if (errorObject === undefined) {
      _formattedError = {
        status: 'failed',
        error:  {code: 100, message: 'Unknown Error'}
      };
      return _formattedError;
    }
    if (errorObject.statusText === 'Unknown Error'){
      _formattedError = {
        status: 'failed',
        error:  {code: 111, message: 'Could not connect to server'}
      };
    } else if (errorObject.status !== undefined){
      _formattedError = {
        status: 'failed',
        error:  {code: errorObject.status, message: errorObject.statusText}
      };
    } else {
      _formattedError = {
        status: 'failed',
        error:  {code: 112, message: errorObject.statusText}
      };
    }
    return _formattedError;
  }

  encodeQueryString(queryString: string){
    const _replacedString = queryString.replace(/ /g, '+');
    return _replacedString;
  }

  encodeTypeaheadString(queryString: string){
    const _replacedString = encodeURI(queryString.toLowerCase());
    return _replacedString;
  }

  // search API

  async search(searchString: string, searchType?: string, sortByDate?: number, offset?: number){
    console.log(this.apiHeaders);
    let _response;
    let _url = this.apiActiveUrl + '/api/v2/search?q=' + this.encodeQueryString(searchString);
    if (searchType !== undefined){
      _url = _url + '&type=' + searchType;
    }
    if (sortByDate !== undefined){
      _url = _url + '&sort_by_date=' + sortByDate;
    }
    if (offset !== undefined){
      _url = _url + '&offset=' + offset;
    } else {
      _url = _url + '&offset=0';
    }

    try {
      _response = await this.http.get(_url, {headers: this.apiHeaders}).toPromise();
      console.log(_response);
      _response = {
        status: 'ok',
        data: _response
      };
    }
    catch (e) {
      console.log(e);
      _response = this.formatError(e);
    }
    finally {
      return _response;
    }
  }

  async typeAhead(searchString: string, showPodcasts?: number, showGenres?: number, safeMode?: number){
    console.log(this.apiHeaders);

    let _response;
    let _url = this.apiActiveUrl + '/api/v2/typeahead?q=' + this.encodeTypeaheadString(searchString);

    if (showPodcasts !== undefined){
      _url = _url + '&show_podcasts=' + showPodcasts.toString();
    }

    try {
      _response = await this.http.get(_url, {headers: this.apiHeaders}).toPromise();
      console.log(_response);
      _response = {
        status: 'ok',
        data: _response
      };
    }
    catch (e) {
      console.log(e);
      _response = this.formatError(e);
    }
    finally {
      return _response;
    }
  }

  async getRssFeed(podcastID: string): Promise<any> {
    console.log(this.apiV1Headers);
    if (!podcastID){
      return this.formatError({statusText: 'invalid podcastID'});
    }

    let _response;
    const _url = this.apiV1BaseUrl + '/api/v1/podcasts/' + podcastID;

    try {
      _response = await this.http.get(_url, {headers: this.apiV1Headers}).toPromise();
      console.log(_response);
      _response = {
        status: 'ok',
        data: _response
      };
    }
    catch (e) {
      console.log(e);
      _response = this.formatError(e);
    }
    finally {
      return _response;
    }
  }

  // directory API

  // Batch fetch basic meta data for episodes POST
  // Batch fetch basic meta data for up to 10 episodes. This endpoint could be used to
  // implement custom playlists for individual episodes. For detailed meta data of an
  // individual episode, you need to use /api/v1/episodes/{id}
  async episodes(){

  }

  // Batch fetch basic meta data for podcasts POST
  // Batch fetch basic meta data for up to 10 podcasts. This endpoint could be used to build
  // something like OPML import, allowing users to import a bunch of podcasts via rss urls. For
  // detailed meta data of an individual episode, you need to use /api/v1/podcasts/{id}
  async podcasts(){

  }

  // Fetch a list of best podcasts by genre GET
  // Get a list of curated best podcasts by genre. You can get the genre ids from /api/v1/genres endpoint
  async bestPodcasts(){

  }

  // Fetch a list of podcast genres GET
  // Get a list of podcast genres that are supported in Listen Notes. The genre id can be passed to Search
  // API and filter results by specific genres. You may want to cache this list of genres on the client side.
  async genres(){

  }


  // Fetch a list of supported countries/regions for best podcasts GET
  // It returns a dictionary of country codes (e.g., us, gb...) & country names (United States
  // United Kingdom...). The country code is used in the query parameter "region" of the /api/v1/best_podcasts endpoint.
  async regions(){

  }

  // Fetch a list of supported languages for podcasts GET
  // Get a list of languages that are supported in Listen Notes database. You can use the language
  // string as query parameter in the search API
  async languages(){

  }

  // Fetch meta data for a podcast GET
  // Fetch meta data for a specific podcast. You can get id from search api's response.
  async podcastByID(podcastID: string){
    console.log(this.apiHeaders);
    if (!podcastID){
      return this.formatError({statusText: 'invalid podcastID'});
    }

    let _response;
    const _url = this.apiActiveUrl + '/api/v2/podcasts/' + podcastID;

    try {
      _response = await this.http.get(_url, {headers: this.apiHeaders}).toPromise();
      console.log(_response);
      _response = {
        status: 'ok',
        data: _response
      };
    }
    catch (e) {
      console.log(e);
      _response = this.formatError(e);
    }
    finally {
      return _response;
    }
  }

  // Fetch meta data for an episode GET
  // Fetch meta data for a specific episode. You can get id from search api's response.
  async episodeByID(){

  }

  // Fetch recommendations for a podcast GET
  // Up to 8 podcast recommendations based on the given podcast id
  async recommendationsByPodcastID(){

  }

  // Fetch recommendations for an episode GET
  // Up to 8 episode recommendations based on the given episode id
  async recommendationsByEpisodeID(){

  }
}
