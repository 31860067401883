import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { Diagnostic } from '@awesome-cordova-plugins/diagnostic/ngx';
import { Platform } from '@ionic/angular';
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';

declare let window: any;

@Injectable({
  providedIn: 'root'
})
export class PersistenceService {

  //private internalFileSystem = '';
  private fileSystem = '';
  private musicCacheDir = 'musicCache';
  private imageCacheDir = 'imageCache';
  private podcastCacheDir = 'podcastCache';

  constructor(private storage: Storage,
              private file: File,
              private diagnostic: Diagnostic,
              private platform: Platform) { }

  // Check that Storage is Ready
  async init(): Promise<any> {
    if(this.platform.is('cordova')){
      await this.storage.defineDriver(CordovaSQLiteDriver);
    }
    return await this.storage.create();
  }

  async initFileStorage(location: string, fsPath?: string){
    if (this.platform.is('cordova')){
      if (location === 'internal'){
        this.fileSystem = this.file.dataDirectory;
      }
      if (location === 'external'){
        this.fileSystem = fsPath + '/';
      }
      console.log('fs ' + this.fileSystem);

      // window.resolveLocalFileSystemURL(this.fileSystem, (data) => {
      //   this.internalFileSystem = data.toInternalURL();
      //   console.warn("internal fs " + this.internalFileSystem);
      // });

      this.file.checkDir(this.fileSystem, this.imageCacheDir)
        .then(() => {
          console.log('imageCache folder exists');
        })
        .catch((err) => {
          console.log('imageCache folder does not exist, creating');
          this.file.createDir(this.fileSystem, this.imageCacheDir, false)
            .then((directoryEntry) => {
              console.log('imageCache folder created');
            });
      });

      this.file.checkDir(this.fileSystem, this.musicCacheDir)
        .then(() => {
          console.log('musicCache folder exists');
        })
        .catch((err) => {
          console.log('musicCache folder does not exist, creating');
          this.file.createDir(this.fileSystem, this.musicCacheDir, false)
            .then((directoryEntry) => {
              console.log('musicCache folder created');
            });
      });

      this.file.checkDir(this.fileSystem, this.podcastCacheDir)
      .then(() => {
        console.log('podcastCache folder exists');
      })
      .catch((err) => {
        console.log('podcastCache folder does not exist, creating');
        this.file.createDir(this.fileSystem, this.podcastCacheDir, false)
          .then((directoryEntry) => {
            console.log('podcastCache folder created');
          });
      });
    }
  }

  // Wipe Storage
  async clear(): Promise<any> {

    if (this.platform.is('cordova')){
      // remove all offline files
      await this.file.removeRecursively(this.fileSystem, this.imageCacheDir);
      await this.file.removeRecursively(this.fileSystem, this.musicCacheDir);
      await this.file.removeRecursively(this.fileSystem, this.podcastCacheDir);
      // recreate empty cache folders
      await this.file.createDir(this.fileSystem, this.imageCacheDir, true);
      await this.file.createDir(this.fileSystem, this.musicCacheDir, true);
      await this.file.createDir(this.fileSystem, this.podcastCacheDir, true);
    }

    // clear the database
    await this.storage.clear();
    return true;
  }

  async substreamerMigration() {

    if (!this.platform.is('cordova')) {
      return;
    }
    // clear the structured data store
    await this.storage.remove('cachedImages');
    await this.storage.remove('cacheOptimized');
    await this.storage.remove('cacheOptimizationDeclined');
    await this.storage.remove('currentCacheSize');
    await this.storage.remove('allCachedFiles');
    await this.storage.remove('cachedSongs');
    await this.storage.remove('cachedAlbums');
    await this.storage.remove('cachedFolders');
    await this.storage.remove('cachedPlaylists');
    await this.storage.remove('cachedSongUse');
    await this.storage.remove('cachedIndividualSongs');
    await this.storage.remove('credentials');
    await this.storage.remove('preferences');

    // clear the old music cache
    this.file.checkDir(this.fileSystem, 'music')
    .then(() => {
      console.log('substreamer legacy music cache found, clearing');
      this.file.removeRecursively(this.fileSystem, 'music');
    })
    .catch((err) => {
      console.log('substreamer legacy music cache not present');
      console.log(err);
    });

    // clear the old image cache
    this.file.checkDir(this.fileSystem, 'images')
    .then(() => {
      console.log('substreamer legacy image cache found, clearing');
      this.file.removeRecursively(this.fileSystem, 'images');
    })
    .catch((err) => {
      console.log('substreamer legacy image cache not present');
      console.log(err);
    });

    return Promise.resolve();
  }

  // Clear subsonic data only
  async clearSubsonic(): Promise<any> {
    return this.storage.remove('subsonicState');
  }

  // Clear Queue data only
  async clearQueueState(): Promise<any> {
    return this.storage.remove('queueState');
  }

  // Clear Queue data only
  async clearBookmarkState(): Promise<any> {
    return this.storage.remove('bookmarkState');
  }

  // Clear Queue data only
  async clearScrobbleQueueState(): Promise<any> {
    return this.storage.remove('scrobbleQueueState');
  }

  // Clear userdata only
  async clearUser(): Promise<any> {
    return this.storage.remove('userState');
  }

  // Clear imageCachedata only
  async clearImageCache(): Promise<any> {
    await this.delImageCacheDir();
    return this.storage.remove('imageCacheState');
  }

  // Clear imageCachedata only
  async clearMusicCache(): Promise<any> {
    await this.delMusicCacheDir();
    return this.storage.remove('musicCacheState');
  }

  // Clear imageCachedata only
  async clearPodcastCache(): Promise<any> {
    await this.delPodcastCacheDir();
    return this.storage.remove('podcastCacheState');
  }

  // store a single item
  async setItem(key: string, stringData: string): Promise<any> {
    return this.storage.set(key, stringData);
  }

  // restrive a single item
  async getItem(key: string): Promise<any> {
    return this.storage.get(key);
  }

  // store a json object
  async setObject(key: string, objectData: object): Promise<any> {
    return this.storage.set(key, JSON.stringify(objectData));
  }

  // retrieve a json object
  async getObject(key: string): Promise<any> {
    return this.storage.get(key).then((data) => JSON.parse(data));
  }

  // return the image cache path
  getImageCacheDir(): string {
    return this.fileSystem + this.imageCacheDir + '/';
  }

  // return the music cache path
  getMusicCacheDir(): string {
    return this.fileSystem + this.musicCacheDir + '/';
  }

  // return the podcast cache path
  getPodcastCacheDir(){
    return this.fileSystem + this.podcastCacheDir + '/';
  }

  // delete and recreate the musicCache dir
  async delMusicCacheDir(): Promise<any> {
    if (this.platform.is('cordova')){
      // remove all offline files
      await this.file.removeRecursively(this.fileSystem, this.musicCacheDir);
      // recreate empty cache folders
      await this.file.createDir(this.fileSystem, this.musicCacheDir, true);
    }
    return Promise.resolve();
  }

  // delete and recreate the musicCache dir
  async delPodcastCacheDir(): Promise<any> {
    if (this.platform.is('cordova')){
      // remove all offline files
      await this.file.removeRecursively(this.fileSystem, this.podcastCacheDir);
      // recreate empty cache folders
      await this.file.createDir(this.fileSystem, this.podcastCacheDir, true);
    }
    return Promise.resolve();
  }

  // delete and recreate the imageCache dir
  async delImageCacheDir(): Promise<any>{
    if (this.platform.is('cordova')){
      // remove all offline files
      await this.file.removeRecursively(this.fileSystem, this.imageCacheDir);
      // recreate empty cache folders
      await this.file.createDir(this.fileSystem, this.imageCacheDir, true);
    }
    return Promise.resolve();
  }

  // delete a cached image file
  async delImageFile(filename: string): Promise<any>{
    let _returnItem: any;

    await this.file.removeFile(this.getImageCacheDir(), filename)
      .then((result) => {
        console.log('file ' + filename + ' deleted');
        console.log(result);
        _returnItem = {status: 'success', data: result};
      })
      .catch((err) => {
        console.log('file ' + filename + ' could not be deleted');
        console.log(err);
        _returnItem = {status: 'error', data: err};
      });

    return Promise.resolve(_returnItem);
  }

  // delete a cached image file
  async delMusicFile(filename: string): Promise<any> {
    let _returnItem: any;

    await this.file.removeFile(this.getMusicCacheDir(), filename)
      .then((result) => {
        console.log('file ' + filename + ' deleted');
        console.log(result);
        _returnItem = {status: 'success', data: result};
      })
      .catch((err) => {
        console.log('file ' + filename + ' could not be deleted');
        console.log(err);
        _returnItem = {status: 'error', data: err};
      });

    return Promise.resolve(_returnItem);
  }

  // delete a cached image file
  async delPodcastFile(filename: string): Promise<any>{
    let _returnItem: any;

    await this.file.removeFile(this.getPodcastCacheDir(), filename)
      .then((result) => {
        console.log('file ' + filename + ' deleted');
        console.log(result);
        _returnItem = {status: 'success', data: result};
      })
      .catch((err) => {
        console.log('file ' + filename + ' could not be deleted');
        console.log(err);
        _returnItem = {status: 'error', data: err};
      });

    return Promise.resolve(_returnItem);
  }

  // convert the file plugin file.metadata from callback to promise
  async getFileMetadata(file): Promise<any> {
    return new Promise(resolve => {
      file.getMetadata((metadata) => {resolve(metadata); });
    });
  }


  // check disk space used by image and music cache
  async checkUsedSpace(){
    let _usedMusicCache = 0;
    let _usedMusicCacheFiles = 0;
    let _usedImageCache = 0;
    let _usedImageCacheFiles = 0;
    let _usedImageFileTotal = 0;
    let _usedMusicFileTotal = 0;

    const _musicFileList = await this.file.listDir(this.fileSystem, this.musicCacheDir);
    _usedMusicFileTotal = _musicFileList.length;
    const _imageFileList = await this.file.listDir(this.fileSystem, this.imageCacheDir);
    _usedImageFileTotal = _imageFileList.length;

    for (const file of _musicFileList){
      const _result: any = await this.getFileMetadata(file);
      _usedMusicCache = _usedMusicCache + _result.size;
      _usedMusicCacheFiles++;
      console.log('music file progress: ' + ((_usedMusicCacheFiles / _usedMusicFileTotal) * 100).toFixed(0));
    }

    for (const file of _imageFileList){
      const _result: any = await this.getFileMetadata(file);
      _usedImageCache = _usedImageCache + _result.size;
      _usedImageCacheFiles++;
      console.log('image file progress: ' + ((_usedImageCacheFiles / _usedImageFileTotal) * 100).toFixed(0));
    }

    console.log('music cache: ' + _usedMusicCache + 'b or ' + (_usedMusicCache / 1000000).toFixed(2) + 'mb' + ' with ' + _usedMusicCacheFiles + ' files');
    console.log('image cache: ' + _usedImageCache + 'b or ' + (_usedImageCache / 1000000).toFixed(2) + 'mb' + ' with ' + _usedImageCacheFiles + ' files');
    return;
  }

  checkFreeSpace(){

  }

  // External Storage for Android (removable SDCards)

  async isExternalStorageAuthorized(): Promise<any>{
    const _isAuthorized = await this.diagnostic.isExternalStorageAuthorized();
    console.log('isExternalStorageAuthorized: ' + _isAuthorized);
    return Promise.resolve(_isAuthorized);
  }

  async getExternalStorageAuthorizationStatus(): Promise<any>{
    const _externalStorageAuthStatus = await this.diagnostic.getExternalStorageAuthorizationStatus();
    console.log('getExternalStorageAuthorizationStatus: ' + _externalStorageAuthStatus);
    return Promise.resolve(_externalStorageAuthStatus);
  }

  async requestExternalStorageAuthorization(): Promise<any>{
    const _requestResult = await this.diagnostic.requestExternalStorageAuthorization();
    console.log('requestExternalStorageAuthorization: ' + _requestResult);
    return Promise.resolve(_requestResult);
  }

  // Returns sd card details object or an empty object
  async getExternalSdCardDetails(): Promise<any>{
    try {
      const _cardDetails = await this.diagnostic.getExternalSdCardDetails();
      console.log('getExternalSdCardDetails: ');
      console.log(_cardDetails);
      for (let i = 0; i < _cardDetails.length; i++){
        if (_cardDetails[i].type === 'application'){
          return Promise.resolve(_cardDetails[i]);
        }
      }
      return Promise.resolve({});
    }
    catch (error) {
      console.log('getExternalSdCardDetails error: ');
      console.log(error);
      return Promise.resolve({});
    }

  }

}
