import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'readableSize'
})
export class ReadableSizePipe implements PipeTransform {

  /*
    Takes a value in bytes and converts it to a friendly reable string value
   */
  transform(value?: string|number, args?: any): string {
    let bytes: any = value;

    if (isNaN(bytes)){
      bytes = 0;
    }
    if (bytes <= 0){
      bytes = 0;
    }

    const format = (bytesArg: number): string => {
      let bytesString = '';
      if (bytesArg >= 1000000000) {bytesString = (bytesArg / 1000000000).toFixed(2) + ' GB'; }
      else if (bytesArg >= 1000000)     {bytesString = (bytesArg / 1000000).toFixed(2) + ' MB'; }
      else if (bytesArg >= 1000)        {bytesString = (bytesArg / 1000).toFixed(2) + ' KB'; }
      else if (bytesArg > 1)            {bytesString = bytesArg + ' bytes'; }
      else if (bytesArg === 1)          {bytesString = bytesArg + ' byte'; }
      else                              {bytesString = '0 bytes'; }
      return bytesString;
    };

    return format(bytes);
}

}
