import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { StoreService } from '../../services/store.service';
import { DownloadStatusPage } from '../../pages/download-status/download-status.page';

@Component({
  selector: 'app-showdownloadstatus',
  templateUrl: './showdownloadstatus.component.html',
  styleUrls: ['./showdownloadstatus.component.scss'],
})
export class ShowdownloadstatusComponent implements OnInit {

  customButton = 'disable-hover bar-button bar-button-ios bar-button-default bar-button-default-ios';

  constructor(public store: StoreService,
              private modalCtrl: ModalController) { }

  ngOnInit() {
    // adjust the button styling for ANDROID, defaults to IOS
    if (this.store.appState.isANDROID) {
      this.customButton = 'disable-hover bar-button bar-button-md bar-button-default bar-button-default-md';
    }
  }

  async openDownloadStatus(event): Promise<any>{
    // const downloadStatusModal = await this.modalCtrl.create({
    //   component: DownloadStatusPage,
    //   cssClass: this.store.appState.isWEB ? 'modal-web' : 'modal-mobile',
    //   canDismiss: true,
    //   presentingElement: document.querySelector('ion-split-pane'),
    //   componentProps: {}
    // });

    const downloadStatusModal = await this.modalCtrl.create({
      component: DownloadStatusPage,
      canDismiss: true,
      componentProps: {},
      breakpoints: [0, 0.99],
      initialBreakpoint: 0.99
    });

    return await downloadStatusModal.present();
  }

}
