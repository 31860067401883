import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Media, MediaObject } from '@awesome-cordova-plugins/media/ngx';
import { UtilService } from './util.service';
import { EventsService } from './events.service';

@Injectable({
  providedIn: 'root'
})
export class LocalMediaplayerService {

  // COMON MEDIA CONTROL PROPERTIES

  // IMPLEMENTATION SPECIFIC PROPERTIES
  // media type = native or html
  public mediaType = 'native';
  // HTML5 audio tag object
  public audioTag: HTMLAudioElement;
  // HTML5 audio tag needs user interaction to play
  public audioTagCanPlay = false;

  private nativeMedia: MediaObject;
  private nativeMediaState = 'IDLE';

  constructor(private platform: Platform,
              private events: EventsService,
              private media: Media,
              private util: UtilService) {
    this.init();
  }

  // COMMON MEDIA CONTROL FUNCTIONS

  async init() {
    const ready = await this.platform.ready();
    console.log('localMedia: init');

    if (this.platform.is('cordova')){
      console.log('using native audio');
      this.mediaType = 'native';
    } else {
      console.log('using html audio');
      this.mediaType = 'html';
    }

    if (this.mediaType === 'html'){
      this.audioTag = new Audio();
      this.audioTag.crossOrigin = 'anonymous';
      this.audioTag.controls = true;
      this.audioTag.autoplay = false;
      this.audioTag.preload = 'none'; // auto | metadata | none
    }

    if (this.mediaType === 'native'){
      // perform any native specific init actions
    }

    // make sure all is ready before returning
    return this.util.addDelay(1000);
  }

  async setSource(sourceUrl: string, trackItem: any): Promise<any> {
    console.log('localMedia: setSource');
    if (this.mediaType === 'html'){
      this.audioTag.src = sourceUrl;
      this.audioTag.title = 'SubStreamer';
      return;
    }
    if (this.mediaType === 'native'){
      if (this.nativeMedia !== undefined){
        await this.nativeMedia.release();
      }
      this.nativeMedia = this.media.create(sourceUrl);
      this.nativeMedia.onStatusUpdate.subscribe((status) => {this.broadcastNativeMediaEvents({type: 'status', value: status}); });
      this.nativeMedia.onSuccess.subscribe(() => {this.broadcastNativeMediaEvents({type: 'complete', value: 'playbackComplete'}); });
      this.nativeMedia.onError.subscribe((err) => {this.broadcastNativeMediaEvents({type: 'error', value: err}); });
      return Promise.resolve({status: 'ok', data: {}});
    }
  }

  play() {
    console.log('localMedia: play');
    if (this.mediaType === 'html'){
      this.audioTag.play();
    }
    if (this.mediaType === 'native'){
      this.nativeMedia.play();
    }
  }

  pause() {
    console.log('localMedia: pause');
    if (this.mediaType === 'html'){
      this.audioTag.pause();
    }
    if (this.mediaType === 'native'){
      this.nativeMedia.pause();
    }
  }

  async stop() {
    console.log('localMedia: stop');
    if(this.mediaType === 'html'){
      this.audioTag.pause();
    }
    if(this.mediaType === 'native'){
      this.nativeMedia.release();
    }
    return Promise.resolve({status: 'ok', data: {}});
  }

  async seekTo(timeInSeconds) {
    console.log('localMedia: seekTo');
    if(timeInSeconds < 0) {
      timeInSeconds = 1;
    }
    if (this.mediaType === 'html'){
      this.audioTag.currentTime = timeInSeconds;
      return Promise.resolve(this.audioTag.currentTime);
    }
    if (this.mediaType === 'native'){
      this.nativeMedia.seekTo(Math.floor(timeInSeconds * 1000));
      //console.log('localMediaPlayer: ' + await this.nativeMedia.getCurrentPosition());
      if(await this.nativeMedia.getCurrentPosition() < 0){
        console.log('localMediaPlayer: seek error, starting from 0');
        this.play();
      }
      return await this.nativeMedia.getCurrentPosition();
    }
  }

  async getDuration() {
    console.log('localMedia: getDuration');

    if (this.mediaType === 'html'){
      if (isNaN(this.audioTag.duration)){
        return 0;
      } else {
        return Promise.resolve(this.audioTag.duration);
      }
    }

    if (this.mediaType === 'native'){
      if (!this.nativeMedia){
        return Promise.resolve(0);
      }
      return Promise.resolve(this.nativeMedia.getDuration());
    }
  }

  async getCurrentPosition() {
    console.log('localMedia: getCurrentPosition');

    if (this.mediaType === 'html'){
      return Promise.resolve(this.audioTag.currentTime);
    }
    if (this.mediaType === 'native'){
      if (!this.nativeMedia){
        return Promise.resolve(0);
      }

      return await this.nativeMedia.getCurrentPosition();
    }
  }

  setRate(rateAsNumber: number) {
    console.log('localMedia: setRate');
    if (this.mediaType === 'html'){
      this.audioTag.playbackRate = rateAsNumber;
      this.audioTag.defaultPlaybackRate = rateAsNumber;
      return this.audioTag.playbackRate;
    }
    if (this.mediaType === 'native'){
      this.nativeMedia.setRate(rateAsNumber);
    }
  }

  getRate() {
    console.log('localMedia: getRate');
    if (this.mediaType === 'html'){
      return this.audioTag.playbackRate;
    }
    if (this.mediaType === 'native'){
      // native get rate function
    }
  }

  setVolume(vol: number) {
    if (this.mediaType === 'html') {
      this.audioTag.volume = vol;
    }
    if (this.mediaType === 'native') {
      this.nativeMedia.setVolume(vol);
    }
  }

  getVolume() {

  }

  destroy() {
    console.log('localMedia: destroy');
  }

  // IMPLEMENTATION SPECIFIC HELPERS

  canHTMLAudioPlay() {
    return this.audioTagCanPlay;
  }

  // take native events from observer and broadcast
  // keeps behaviour similar between html and native
  broadcastNativeMediaEvents(event){
    console.log(event);
    if (event.type === 'complete'){
      console.log('complete: playback complete');
      this.events.publish('ended', {});
    }
    if (event.type === 'error'){
      console.log('error: media error');
      console.log(event.value);
      this.events.publish('error', event.value);
    }
    if (event.type === 'status'){
      if (event.value === 1){
        console.log('status: 1 unknown');
        this.events.publish('unknown', {});
      }
      if (event.value === 2){
        console.log('status: 2 playing');
        this.events.publish('playing', {});
      }
      if (event.value === 3){
        console.log('status: 3 paused');
        this.events.publish('pause', {});
      }
      if (event.value === 4){
        console.log('status: 4 stopped');
        this.events.publish('stopped', {});
      }
    }
  }
}
