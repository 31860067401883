import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { IonicModule, IonicRouteStrategy, Platform } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage-angular';
import { Drivers } from '@ionic/storage';
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { NativeHttpModule, NativeHttpBackend, NativeHttpFallback } from 'ionic-native-http-connection-backend';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { PlayerPageModule } from './pages/player/player.module';
import { DownloadStatusPageModule } from './pages/download-status/download-status.module';

import { AndroidPermissionsService } from './services/android-permissions.service';
import { AppactionsService } from './services/appactions.service';
import { EventsService } from './services/events.service';
import { FanarttvService } from './services/fanarttv.service';
import { MusicbrainzService } from './services/musicbrainz.service';
import { ImageCacheService } from './services/image-cache.service';
import { ListennotesService } from './services/listennotes.service';
import { MediaplayerService } from './services/mediaplayer.service';
import { CcMediaplayerService } from './services/cc-mediaplayer.service';
import { LocalMediaplayerService } from './services/local-mediaplayer.service';
import { MusicCacheService } from './services/music-cache.service';
import { PersistenceService } from './services/persistence.service';
import { PlayeractionsService } from './services/playeractions.service';
import { PodcastCacheService } from './services/podcast-cache.service';
import { RecommendationService } from './services/recommendation.service';
import { SearchService } from './services/search.service';
import { StoreService } from './services/store.service';
import { SubsonicService } from './services/subsonic.service';
import { SubsonicactionsService } from './services/subsonicactions.service';
import { UseractionsService } from './services/useractions.service';
import { UtilService } from './services/util.service';
import { MoreOptionsServiceService } from './services/more-options-service.service';
import { ScrobbleService } from './services/scrobble.service';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { Diagnostic } from '@awesome-cordova-plugins/diagnostic/ngx';
import { FileTransfer } from '@awesome-cordova-plugins/file-transfer/ngx';
import { HttpClientModule, HttpBackend, HttpXhrBackend, HttpClient } from '@angular/common/http';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { Insomnia } from '@awesome-cordova-plugins/insomnia/ngx';
import { HTTP } from '@awesome-cordova-plugins/http/ngx';
import { BackgroundMode } from '@awesome-cordova-plugins/background-mode/ngx';
import { Media } from '@awesome-cordova-plugins/media/ngx';
import { WebView } from '@awesome-cordova-plugins/ionic-webview/ngx';
import { LocalNotifications } from '@awesome-cordova-plugins/local-notifications/ngx';
import { Keyboard } from '@awesome-cordova-plugins/keyboard/ngx';
import { SafariViewController } from '@awesome-cordova-plugins/safari-view-controller/ngx';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { MusicControls } from '@awesome-cordova-plugins/music-controls/ngx';

import { ComponentsModule } from './components/components.module';
import { PipesModule } from './pipes/pipes.module';


// AoT requires an exported function for factories
// export function HttpLoaderFactory(http: HttpClient) {
//   return new TranslateHttpLoader(http, './assets/i18n/', '.json');
// };
export const httpLoaderFactory = ((http: HttpClient) => new TranslateHttpLoader(http, './assets/i18n/', '.json'));

// Set storage configuration options
// substreamer
const storageCustomConfig = {
  name: '__substreamer3',
  driverOrder: [CordovaSQLiteDriver._driver,Drivers.IndexedDB,Drivers.LocalStorage]
};

@NgModule({
  declarations: [
    AppComponent
  ],
  entryComponents: [],
  imports: [
    BrowserModule,
    ComponentsModule,
    PipesModule,
    HttpClientModule,
    NativeHttpModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (httpLoaderFactory),
        deps: [HttpClient]
      },
      defaultLanguage: 'en'
    }),
    IonicModule.forRoot(), //{_forceStatusbarPadding: true}
    IonicStorageModule.forRoot(storageCustomConfig),
    AppRoutingModule,
    PlayerPageModule,
    DownloadStatusPageModule
  ],
  providers: [
    {provide: HttpBackend, useClass: NativeHttpFallback, deps: [Platform, NativeHttpBackend, HttpXhrBackend]},
    AndroidPermissions,
    BackgroundMode,
    Device,
    Diagnostic,
    File,
    FileTransfer,
    HTTP,
    Keyboard,
    Insomnia,
    LocalNotifications,
    Media,
    MusicControls,
    Network,
    SafariViewController,
    ScreenOrientation,
    SplashScreen,
    WebView,
    AppactionsService,
    EventsService,
    FanarttvService,
    MusicbrainzService,
    ImageCacheService,
    ListennotesService,
    MediaplayerService,
    CcMediaplayerService,
    LocalMediaplayerService,
    MusicCacheService,
    MoreOptionsServiceService,
    ScrobbleService,
    PersistenceService,
    PlayeractionsService,
    PodcastCacheService,
    RecommendationService,
    StoreService,
    SearchService,
    SubsonicService,
    SubsonicactionsService,
    UseractionsService,
    UtilService,
    AndroidPermissionsService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
