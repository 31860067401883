import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { StoreService } from './store.service';
import { SubsonicService } from './subsonic.service';
import { PersistenceService } from './persistence.service';
import { ScrobbleService } from './scrobble.service';

@Injectable({
  providedIn: 'root'
})
export class UseractionsService {

  private extStoragePath = '';

  constructor(private store: StoreService,
              private persist: PersistenceService,
              private scrobbleSvc: ScrobbleService,
              private subsonic: SubsonicService,
              private alertCtrl: AlertController) { }

  setUsername(username: string): void {
    console.log('setUsername called');
    this.store.userState.username = username;
  }

  setPassword(password: string): void {
    console.log('setPassword called');
    this.store.userState.password = password;
  }

  setServername(servername: string): void {
    console.log('setServername called');
    this.store.userState.servername = servername;
  }

  setLegacyAuth(legacyAuth: boolean): void {
    console.log('setLegacyAuth called');
    this.store.userState.legacyAuth = legacyAuth;
  }

  async testCredentials() {
    return await this.subsonic.ping();
  }

  clearCreds() {
    this.subsonic.clearCreds();
  }

  // if the webview has reloaded then reload the app properly
  checkAppStatusAndReload() {
    if(!this.store.appState.loadComplete){
      window.location.href = '/';
    }
  }

  // track podcast episode plays
  addPlaybackCount(id: number|string) {
    const count = this.getPlaybackCount(id);
    this.store.userState.podcastPlayCounts[id] = count + 1;
    this.store.saveUserState();
  }

  // get plays for podcast episode by id
  getPlaybackCount(id: number|string){
    if (this.store.userState.podcastPlayCounts.hasOwnProperty(id)){
      return this.store.userState.podcastPlayCounts[id];
    } else {
      return 0;
    }
  }

  // reset podcast episode play tracking
  resetPlaybackCount(){
    this.store.userState.podcastPlayCounts = {};
    this.store.saveUserState();
  }

  setTagsOnly(bool: boolean) {
    if (bool){
      this.store.userState.modeTagsOnly = true;
      this.store.userState.modeFoldersOnly = false;
    } else {
      this.store.userState.modeTagsOnly = false;
    }
  }

  setFoldersOnly(bool: boolean) {
    if (bool){
      this.store.userState.modeFoldersOnly = true;
      this.store.userState.modeTagsOnly = false;
    } else {
      this.store.userState.modeFoldersOnly = false;
    }
  }

  enableOfflineMode(): void {
    this.store.userState.offlineMode = true;
    this.store.saveUserState();
  }

  disableOfflineMode(): void {
    this.store.userState.offlineMode = false;
    this.store.saveUserState();
    this.scrobbleSvc.watchQueue();
  }

  async verifyUserAccount(): Promise<any> {
    console.log('verifyUserAccount called');
    const result = await this.subsonic.ping();
    if (result.status === 'ok'){
      // mark account as verified
      this.store.userState.verified = true;
      // set alternate servernames to default
      this.store.userState.internalServername = this.store.userState.servername;
      this.store.userState.externalServername = this.store.userState.servername;
      this.store.userState.serverVersion = result.version.split('.')[1];
      this.store.saveUserState();
      console.log('verifyUserAccount: Success');
    } else {
      console.log('verifyUserAccount: Error');
    }
    return result;
  }

  // Grab the server API version and set it
  async verifyServerVersion(): Promise<any> {
    console.log('verifyServerVersion called');
    const result = await this.subsonic.ping();
    if (result.status === 'ok'){
      this.store.userState.serverVersion = result.version.split('.')[1];
      this.store.saveUserState();
    }
    return Promise.resolve();
  }

  // User Initiated switch of offline file storage location
  async chooseStorageLocation(){

    // check that we have permission to access storage
    const isAuthorised = await this.persist.isExternalStorageAuthorized();

    if (!isAuthorised){
      console.log('chooseStorageLocation: storage not authorised yet, requesting');
      const authResponse = await this.persist.requestExternalStorageAuthorization();
      console.log(authResponse);
      if (authResponse === 'DENIED'){
        console.log('chooseStorageLocation: permission to storage DENIED');
        this.showSetStorageError('No permission to access your external storage, please grant access to continue.');
        return;
      }
      if (authResponse === 'GRANTED'){
        console.log('chooseStorageLocation: permission to storage GRANTED');
        return;
      }
      if (authResponse === 'DENIED_ALWAYS'){
        console.log('chooseStorageLocation: permission to storage DENIED_ALWAYS API 33, or user set to not ask again');
      }
    }

    // now we have permission let's check that there is external storage available
    const extStorageDetails = await this.persist.getExternalSdCardDetails();
    const isAvailable = this.checkExtStorageAvailable(extStorageDetails);

    // sdcard storage was in use but card has been removed
    // ask user to reset to internal or reinsert card
    if (this.store.userState.cacheLocation === 'external' && !isAvailable){
      this.showExtStorageRemoved();
      return;
    }

    // sdcard storage was in use but storage has been changed
    // ask user to reset to internal
    if (this.store.userState.cacheLocation === 'external' &&
        isAvailable && (extStorageDetails.filePath !== this.store.userState.extStorageLocation)){
      this.showExtStorageChanged();
      return;
    }

    // permission is granted but an external card is not detected
    if (this.store.userState.cacheLocation === 'internal' && !isAvailable){
      this.showSetStorageError('no external storage device could be found. Please check your SD card status');
      return;
    }

    // permission is granted and an external card IS detected
    // present user with storage options
    if (isAvailable){
      this.extStoragePath = extStorageDetails.filePath;
      this.showStoragePicker();
    }
  }

  // if there is no SDCard available we get an empty object
  // check that we have a valid storage object
  checkExtStorageAvailable(storageObject: any){
    if (storageObject.hasOwnProperty('type')){
      return true;
    } else {
      return false;
    }
  }

  // set the storage location to internal or external
  async setStorageLocation(location: string){
    if (location === this.store.userState.cacheLocation){
      console.log('setStorageLocation: no change required');
      this.showSetStorageSuccess();
      return Promise.resolve();
    }
    if (location === 'external'){
      console.log('setStorageLocation: change to external');
      this.store.userState.extStorageLocation = this.extStoragePath;
      await this.store.clearMusicCacheState();
      await this.store.clearPodcastCacheState();
      await this.store.clearImageCacheState();
      await this.persist.initFileStorage('external', this.store.userState.extStorageLocation);
      this.store.userState.cacheLocation = 'external';
      this.store.saveUserState();
      this.showSetStorageSuccess();
      return Promise.resolve();
    }
    else if (location === 'internal'){
      console.log('setStorageLocation: change to internal');
      this.store.userState.extStorageLocation = '';
      await this.store.clearMusicCacheState();
      await this.store.clearPodcastCacheState();
      await this.store.clearImageCacheState();
      await this.persist.initFileStorage('internal');
      this.store.userState.cacheLocation = 'internal';
      this.store.saveUserState();
      this.showSetStorageSuccess();
      return Promise.resolve();
    }
  }

  // return a string representing the current storage location
  getStorageLocation(){
    if (this.store.userState.cacheLocation === 'internal'){
      return 'Phone Storage';
    }
    if (this.store.userState.cacheLocation === 'external'){
      return 'SD Card Storage';
    }
  }

  // If storage change was successful
  async showSetStorageSuccess(){

    const alert = await this.alertCtrl.create({
      header: 'Storage Location',
      message: 'Offline music will now use ' + this.getStorageLocation(),
      mode: 'ios',
      translucent: true,
      buttons: ['OK']
    });
    return alert.present();
  }

  // if storage change failed
  async showSetStorageError(err?: string){
    let error = err;
    if (!error){
      error = '0';
    }

    const alert = await this.alertCtrl.create({
      header: 'Storage Location',
      message: 'Sorry something went wrong there (' + err + '). Your storage location was not changed',
      mode: 'ios',
      translucent: true,
      buttons: ['OK']
    });
    return alert.present();
  }

  // user had external storage selected but it has been removed
  async showExtStorageRemoved(){
    const alert = await this.alertCtrl.create({
      header: 'Storage Location',
      message: 'It looks like your SD Card Storage has been removed do you want to switch to internal storage? Otherwise re-insert your SD Card and restart the app.',
      mode: 'ios',
      translucent: true,
      buttons: [
        {
          text: 'cancel',
          handler: (data) => {
            console.log('cancel clicked');
          }
        },
        {
          text: 'ok',
          handler: (data) => {
            this.setStorageLocation('internal');
          }
        }
      ]
    });
    return alert.present();
  }

    // user had external storage selected but it has been removed
    async showExtStorageChanged(){
      const alert = await this.alertCtrl.create({
        header: 'Storage Location',
        message: 'It looks like your SD Card Storage has changed do you want to switch to internal storage? If this is a new card you will be able to enable it after this step.',
        mode: 'ios',
        translucent: true,
        buttons: [
          {
            text: 'cancel',
            handler: (data) => {
              console.log('cancel clicked');
            }
          },
          {
            text: 'ok',
            handler: (data) => {
              this.setStorageLocation('internal');
            }
          }
        ]
      });
      return alert.present();
    }

  // present the user with a list of storage options
  async showStoragePicker(){

    const inputArray = [];
    inputArray.push(
      {
        type: 'radio',
        label: 'Phone Storage',
        value: 'internal',
        checked: (this.store.userState.cacheLocation === 'internal')
      }
    );
    inputArray.push(
      {
        type: 'radio',
        label: 'SD Card Storage',
        value: 'external',
        checked: (this.store.userState.cacheLocation === 'external')
      }
    );

    const alert = await this.alertCtrl.create({
      header: 'Storage Location',
      message: 'Where would you like to save your offline music?',
      mode: 'ios',
      translucent: true,
      inputs: inputArray,
      buttons: [
        {
          text: 'cancel',
          handler: (data) => {
            console.log('cancel clicked');
          }
        },
        {
          text: 'ok',
          handler: (data) => {
            console.log('ok clicked');
            console.log(data);
            if (data){
              console.log('option chosen: ' + data);
              this.setStorageLocation(data);
            } else {
              console.log('no option chosen');
            }
          }
        }
      ]
    });
    return alert.present();
  }


}
