import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { IonContent} from '@ionic/angular';
import { StoreService } from '../../services/store.service';

@Component({
  selector: 'app-scrolltopfab',
  templateUrl: './scrolltopfab.component.html',
  styleUrls: ['./scrolltopfab.component.scss'],
})
export class ScrolltopfabComponent implements OnInit, OnDestroy {

  @Input() content: IonContent;
  isScrollTopTapped = false;

  constructor(public store: StoreService) { }

  ngOnInit() {
    console.log('init showTopFab');
  }

  ngOnDestroy() {
    console.log('destroy showTopFab');
    this.store.appState.showScrollTop = false;
  }

  scrollTopTapped(){
    console.log('scroll top tapped');
    // this.isScrollTopTapped = true;
    this.content.scrollToTop(200);
  }

}
