import { Injectable} from '@angular/core';
import { NavigationExtras } from '@angular/router';
import { SubsonicactionsService } from 'src/app/services/subsonicactions.service';
import { PlayeractionsService } from 'src/app/services/playeractions.service';
import { NavController} from '@ionic/angular';
import { StoreService } from 'src/app/services/store.service';
import { MoreOptionsServiceService } from './more-options-service.service';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  public ready = true;
  public searchProcessing = false;
  public searchQuery = '';
  public searchResult: any = {
    song: [],
    album: [],
    folder: [],
    artist: []
  };

  constructor(private subsonicActions: SubsonicactionsService,
              private playerActions: PlayeractionsService,
              private moreOptions: MoreOptionsServiceService,
              private navCtrl: NavController,
              private store: StoreService) { }

  async doSearch(searchTerm: string, rSize?: number, oSize?: number): Promise<any> {
    console.log('search service: doSearch for term: ' + searchTerm);
    this.searchQuery = searchTerm;
    if(!searchTerm || searchTerm.length === 0){
      this.doClear();
      return;
    }
    this.setSearchProcessing(true);
    this.searchResult = await this.subsonicActions.getSearchResults(searchTerm, rSize, oSize);
    this.setSearchProcessing(false);
    console.log(this.searchResult);
    return this.searchResult;
  }

  doClear(): void {
    this.ready = false;
    this.searchProcessing = false;
    this.searchQuery = '';
    this.searchResult = {
      song: [],
      album: [],
      folder: [],
      artist: []
    };
    this.ready = true;
  }

  setSearchProcessing(bool: boolean): void {
    this.searchProcessing = bool;
  }

  showAlbumDetail(event, item){
    event.stopPropagation();
    const navExtras: NavigationExtras = {state: item};
    this.navCtrl.navigateForward(`album-detail/${item.id}`, navExtras);
  }

  showFolderDetail(event, item){
    event.stopPropagation();
    const navExtras: NavigationExtras = {state: item};
    this.navCtrl.navigateForward(`folder-detail/${item.id}`, navExtras);
  }

  showArtistDetail(event, item){
    if (item.albumCount){
      const navExtras: NavigationExtras = {state: item};
      this.navCtrl.navigateForward(`artist-detail/${item.id}`, navExtras);
    } else {
      console.log('cannot show detail for an artist with no albums');
    }
  }

  playSongs(event, item, index){
    this.playerActions.setPlayQueue([item], 0);
  }

    // When the more button is tapped on a track
    async moreOptionsTrack(event: Event, trackItem: any){
      this.moreOptions.moreOptionsTrack(event, trackItem);
    }

    // WHen the more button is tapped on the album
    async moreOptionsAlbum(event: Event, albumItem: any){
      this.moreOptions.moreOptionsAlbum(event, albumItem);
    }

    // When the more button is tapped on the main or similar artist
    async moreOptionsArtist(event, artistItem){
      this.moreOptions.moreOptionsArtist(event, artistItem);
    }

    // When the more button is tapped on a folder
    async moreOptionsFolder(event, folderItem){
      this.moreOptions.moreOptionsFolder(event, folderItem);
    }
}
