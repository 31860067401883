import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterByDateRangePipe } from './filter-by-date-range.pipe';
import { ReadableSizePipe } from './readable-size.pipe';
import { ReadableTimePipe } from './readable-time.pipe';
import { SearchFilterPipe } from './search-filter.pipe';
import { SortByDatePipe } from './sort-by-date.pipe';
import { ToIsoPipe } from './to-iso.pipe';
import { TruncateStringPipe } from './truncate-string.pipe';

@NgModule({
  declarations: [
    FilterByDateRangePipe,
    ReadableSizePipe,
    ReadableTimePipe,
    SearchFilterPipe,
    SortByDatePipe,
    ToIsoPipe,
    TruncateStringPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    FilterByDateRangePipe,
    ReadableSizePipe,
    ReadableTimePipe,
    SearchFilterPipe,
    SortByDatePipe,
    ToIsoPipe,
    TruncateStringPipe
  ]
})
export class PipesModule { }
