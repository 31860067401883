import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'splash',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'album-detail/:id',
    loadChildren: () => import('./pages/album-detail/album-detail.module').then( m => m.AlbumDetailPageModule)
  },
  {
    path: 'album-list',
    loadChildren: () => import('./pages/album-list/album-list.module').then( m => m.AlbumListPageModule)
  },
  {
    path: 'artist-detail/:id',
    loadChildren: () => import('./pages/artist-detail/artist-detail.module').then( m => m.ArtistDetailPageModule)
  },
  {
    path: 'artist-list',
    loadChildren: () => import('./pages/artist-list/artist-list.module').then( m => m.ArtistListPageModule)
  },
  {
    path: 'bookmarks',
    loadChildren: () => import('./pages/bookmarks/bookmarks.module').then( m => m.BookmarksPageModule)
  },
  {
    path: 'cached-album-detail/:id',
    loadChildren: () => import('./pages/cached-album-detail/cached-album-detail.module').then( m => m.CachedAlbumDetailPageModule)
  },
  {
    path: 'cached-album-list',
    loadChildren: () => import('./pages/cached-album-list/cached-album-list.module').then( m => m.CachedAlbumListPageModule)
  },
  {
    path: 'cached-episode-list',
    loadChildren: () => import('./pages/cached-episode-list/cached-episode-list.module').then( m => m.CachedEpisodeListPageModule)
  },
  {
    path: 'cached-folder-detail/:id',
    loadChildren: () => import('./pages/cached-folder-detail/cached-folder-detail.module').then( m => m.CachedFolderDetailPageModule)
  },
  {
    path: 'cached-folder-list',
    loadChildren: () => import('./pages/cached-folder-list/cached-folder-list.module').then( m => m.CachedFolderListPageModule)
  },
  {
    path: 'cached-home',
    loadChildren: () => import('./pages/cached-home/cached-home.module').then( m => m.CachedHomePageModule)
  },
  {
    path: 'cached-playlist-detail/:id',
    loadChildren: () => import('./pages/cached-playlist-detail/cached-playlist-detail.module').then( m => m.CachedPlaylistDetailPageModule)
  },
  {
    path: 'cached-playlist-list',
    loadChildren: () => import('./pages/cached-playlist-list/cached-playlist-list.module').then( m => m.CachedPlaylistListPageModule)
  },
  {
    path: 'cached-podcast-detail/:id',
    loadChildren: () => import('./pages/cached-podcast-detail/cached-podcast-detail.module').then( m => m.CachedPodcastDetailPageModule)
  },
  {
    path: 'cached-podcast-list',
    loadChildren: () => import('./pages/cached-podcast-list/cached-podcast-list.module').then( m => m.CachedPodcastListPageModule)
  },
  {
    path: 'cached-song-list',
    loadChildren: () => import('./pages/cached-song-list/cached-song-list.module').then( m => m.CachedSongListPageModule)
  },
  {
    path: 'download-status',
    loadChildren: () => import('./pages/download-status/download-status.module').then( m => m.DownloadStatusPageModule)
  },
  {
    path: 'folder-detail/:id',
    loadChildren: () => import('./pages/folder-detail/folder-detail.module').then( m => m.FolderDetailPageModule)
  },
  {
    path: 'folder-list',
    loadChildren: () => import('./pages/folder-list/folder-list.module').then( m => m.FolderListPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'offline-music',
    loadChildren: () => import('./pages/offline-music/offline-music.module').then( m => m.OfflineMusicPageModule)
  },
  {
    path: 'offline-podcasts',
    loadChildren: () => import('./pages/offline-podcasts/offline-podcasts.module').then( m => m.OfflinePodcastsPageModule)
  },
  {
    path: 'player',
    loadChildren: () => import('./pages/player/player.module').then( m => m.PlayerPageModule)
  },
  {
    path: 'playlist-detail/:id',
    loadChildren: () => import('./pages/playlist-detail/playlist-detail.module').then( m => m.PlaylistDetailPageModule)
  },
  {
    path: 'playlist-list',
    loadChildren: () => import('./pages/playlist-list/playlist-list.module').then( m => m.PlaylistListPageModule)
  },
  {
    path: 'podcast-detail/:id',
    loadChildren: () => import('./pages/podcast-detail/podcast-detail.module').then( m => m.PodcastDetailPageModule)
  },
  {
    path: 'podcast-list',
    loadChildren: () => import('./pages/podcast-list/podcast-list.module').then( m => m.PodcastListPageModule)
  },
  {
    path: 'podcast-search',
    loadChildren: () => import('./pages/podcast-search/podcast-search.module').then( m => m.PodcastSearchPageModule)
  },
  {
    path: 'podcast-search-detail/:id',
    loadChildren: () => import('./pages/podcast-search-detail/podcast-search-detail.module').then( m => m.PodcastSearchDetailPageModule)
  },
  {
    path: 'radio',
    loadChildren: () => import('./pages/radio/radio.module').then( m => m.RadioPageModule)
  },
  {
    path: 'radio-detail',
    loadChildren: () => import('./pages/radio-detail/radio-detail.module').then( m => m.RadioDetailPageModule)
  },
  {
    path: 'search',
    loadChildren: () => import('./pages/search/search.module').then( m => m.SearchPageModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./pages/settings/settings.module').then( m => m.SettingsPageModule)
  },
  {
    path: 'splash',
    loadChildren: () => import('./pages/splash/splash.module').then( m => m.SplashPageModule)
  },
  {
    path: 'starred-list',
    loadChildren: () => import('./pages/starred-list/starred-list.module').then( m => m.StarredListPageModule)
  },
  {
    path: 'cached-starred',
    loadChildren: () => import('./pages/cached-starred/cached-starred.module').then( m => m.CachedStarredPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
