import { Injectable, NgZone } from '@angular/core';
import { PersistenceService } from './persistence.service';

@Injectable({
  providedIn: 'root'
})
export class StoreService {

  public playerState: any = {
    isReady:            false,
    playQueue:          [],
    currentTrack:       {},
    currentTrackTime:   0,
    currentTrackProg:   0,
    currentTrackIndex:  -1,
    isPlaying:          false,
    isPaused:           false,
    isStopped:          true,
    canPlayNext:        false,
    canPlayPrev:        false,
    repeatEnabled:      0,
    userAction:         false,
    currentMediaplayer: 'local',
  };
  public queueState: any = {
    savedPlayerState:   {},
    savedQueueAvl:      false
  };
  public bookmarkState: any = {
    bookmarkList:       []
  };
  public scrobbleQueueState: any = {
    scrobbleQueue:      []
  };
  public userState: any = {
    servername:         '',
    username:           '',
    password:           '',
    legacyAuth:         false,
    verified:           false,
    serverVersion:      0,
    internalServername: '',
    externalServername: '',
    preferredServer:    'external',
    cacheAutoRate:      1,
    cacheMaxSize:       4000000000,
    streamMaxBitrate:   0,
    streamFormat:       'mp3',
    cacheFormat:        'mp3',
    suspendTimer:       300,
    offlineMode:        false,
    modeFoldersOnly:    false,
    modeTagsOnly:       false,
    cacheLocation:      'internal',
    extStorageLocation: '',
    podcastsEnabled:    true,
    podcastSortOrder:   'desc',
    podcastPlayCounts:  {},
    skipFwdInt:         30,
    skipBckInt:         10,
    useSystemTheme:     true,
    preferredTheme:     'light',
    substreamMigrated:  false,
    recommendedEnabled: true,
    improvedSearch:     false,
    randomListLength:   50,
    autonameBookmarks:  false,
    enableFanartTV:     false,
    doNotShowPermsNotice: false,
    enableReactiveColors: true

  };
  public appState: any = {
    appVersion:           '0.0.0',
    menuEnabled:          true,
    splitPaneStatus:      false,
    splashEnabled:        true,
    errorShown:           false,
    loadingShown:         false,
    starredUpdated:       false,
    resizeNeeded:         true,
    coverArtBase200:      '',
    coverArtBase500:      '',
    cachedMusicBase:      '',
    isIOS:                false,
    isANDROID:            false,
    isWEB:                false,
    isWKWEBVIEW:          false,
    isTABLET:             false,
    enableScreenAlive:    false,
    hasPendingDownloads:  false,
    hasPendingPodcastDownloads: false,
    cacheUsedPercent:     0,
    keyboardVisible:      false,
    showScrollTop:        false,
    connectionType:       '',
    connectionAvailable:  true,
    deviceOrientation:    'portrait',
    // deviceWidth:          -1,
    // deviceHeight:         -1,
    // contentWidth:         -1,
    // contentHeight:        -1,
    modalOpen:            false,
    exitAlertVisible:     false,
    currentPlayRate:      1.0,
    loadComplete:         false,
    isBackgroundModeEnabled:  false,
    isNotificationEnabled:  false,
    isStorageEnabled:     false,
    shouldDisableNt:      false,
    shouldDisableBg:      false,
    shouldDiesbleSt:      false,
    androidVersion:       0,
    playerChangeInProgress: false
  };
  public imageCacheState: any = {};

  public musicCacheState: any = {
    songs: [],          // array of songs not used in any collection
    albums: [],         // array of albums
    folders: [],        // array of folders
    playlists: [],      // array of playlists
    starredSongs: [],   // array of 1 item with id 'starred', contains object song with list of starred songs
    allFiles: {},       // hashmap of all files including those in collections
    cachedSongUse: {},  // hashmap of which collections songs are used in
    usedSpace: 0
  };

  public podcastCacheState: any = {
    episodes: [],
    podcasts: [],
    allFiles: {},
    cachedEpisodeUse: {},
    usedSpace: 0
  };

  public subsonicState: any = {
    itemCount:            {count: 0, updated: ''},
    newItemsAvailable:    false,
    pingOk:               false,
    nowPlaying:           {},
    license:              {},
    scanStatus:           false,
    starred:              {},
    starred2:             {},
    musicFolders:         {},
    indexes:              {},
    indexesFlat:          {},
    musicDirectories:     {},
    genres:               {},
    artistsList:          {},
    artistsListFlat:      {},
    artists:              {},
    artistInfo:           {},
    artistInfo2:          {},
    artistTopSongs:       {},
    albumsList:           {},
    albumsListFlat:       {},
    albums:               {},
    albumInfo:            {},
    albumInfo2:           {},
    albumListNewest:      {},
    albumListRandom:      {},
    albumListFrequent:    {},
    albumListRecent:      {},
    albumListNewest2:     {},
    albumListRandom2:     {},
    albumListFrequent2:   {},
    albumListRecent2:     {},
    songs:                {},
    videosList:           {},
    videos:               {},
    playlistsList:        {},
    playlists:            {},
    podcastList:          {},
    podcasts:             {},
    podcastNewestList:    {}
  };

  public yearList: any = [
    {name: 'All Time',
    fromYear: false,
    toYear: false,
    description: 'I\'ll take my chances'},
    {name: 'Recent',
    fromYear: '2016',
    toYear: '2025',
    description: 'Songs released in the last 2 years'},
    {name: '2010+',
    fromYear: '2010',
    toYear: '2025',
    description: 'Songs released after 2010'},
    {name: '2000s',
    fromYear: '2000',
    toYear: '2009',
    description: 'Songs from the 2000s'},
    {name: '1990s',
    fromYear: '1990',
    toYear: '1999',
    description: 'Songs from the 90s'},
    {name: '1980s',
    fromYear: '1980',
    toYear: '1989',
    description: 'Songs from the 80s'},
    {name: '1970s',
    fromYear: '1970',
    toYear: '1979',
    description: 'Songs from the 70s'},
    {name: '1960s',
    fromYear: '1960',
    toYear: '1969',
    description: 'Songs from the 60s'},
    {name: '1950s',
    fromYear: '1950',
    toYear: '1959',
    description: 'Songs from the 50s'},
    {name: 'early',
    fromYear: '1900',
    toYear: '1949',
    description: 'Anything earlier than 1950'}];

  public languageList: any = [
    {en: 'English'},
    {test: 'Test'}
  ];

  constructor(private persist: PersistenceService,
              private ngzone: NgZone) { }

  // Initialize app by retrieving stored data from storage.
  async init(): Promise<any> {

    // check storage for userState values
    const _userState = await this.persist.getObject('userState');
    if (_userState != null){
      this.userState = _userState;
      console.log('userState initialized. Stored values found');
    } else {
      console.log('userState initialized. No stored values');
    }

    // check storage for subsonicState values
    const _subsonicState = await this.persist.getObject('subsonicState');
    if (_subsonicState != null){
      this.subsonicState = _subsonicState;
      console.log('subsonicState initialized. Stored values found');
    } else {
      console.log('subsonicState initialized. No stored values');
    }

    // check storage for imageCacheState values
    const _imageCacheState = await this.persist.getObject('imageCacheState');
    if (_imageCacheState != null){
      this.imageCacheState = _imageCacheState;
      console.log('imageCacheState initialized. Stored values found');
    } else {
      console.log('imageCacheState initialized. No stored values');
    }

    // check storage for musicCacheState values
    const _musicCacheState = await this.persist.getObject('musicCacheState');
    if (_musicCacheState != null){
      this.musicCacheState = _musicCacheState;
      console.log('musicCacheState initialized. Stored values found');
    } else {
      console.log('musicCacheState initialized. No stored values');
    }

    // check storage for podcastCacheState values
    const _podcastCacheState = await this.persist.getObject('podcastCacheState');
    if (_podcastCacheState != null){
      this.podcastCacheState = _podcastCacheState;
      console.log('podcastCacheState initialized. Stored values found');
    } else {
      console.log('podcastCacheState initialized. No stored values');
    }

    // check storage for queueState values
    const _queueState = await this.persist.getObject('queueState');
    if (_queueState != null){
      this.queueState = _queueState;
      console.log('queueState initialized. Stored values found');
    } else {
      console.log('queueState initialized. No stored values');
    }

    // check storage for bookmark values
    const _bookmarkState = await this.persist.getObject('bookmarkState');
    if (_bookmarkState != null){
      this.bookmarkState = _bookmarkState;
      console.log('bookmarkState initialized. Stored values found');
    } else {
      console.log('bookmarkState initialized. No stored values');
    }

    // check storage for scrobbleQueueState values
    const _scrobbleQueueState = await this.persist.getObject('scrobbleQueueState');
    if (_scrobbleQueueState != null){
      this.scrobbleQueueState = _scrobbleQueueState;
      console.log('scrobbleQueueState initialized. Stored values found');
    } else {
      console.log('scrobbleQueueState initialized. No stored values');
    }

    await this.persist.initFileStorage(this.userState.cacheLocation, this.userState.extStorageLocation);

    await this.runMigrations();

    return Promise.resolve(true);
  }

  setLoadComplete(isLoadComplete: boolean): void{
    this.appState.loadComplete = isLoadComplete;
  }

  updatePlayerProgress(currentTime: number, currentProg: number): void {
    this.ngzone.run(() => {
      this.playerState.currentTrackTime = currentTime;
      this.playerState.currentTrackProg = currentProg;
    });
  }

  resetPlayerProgress(): void {
    this.ngzone.run(() => {
      this.playerState.currentTrackTime = 0;
      this.playerState.currentTrackProg = 0;
    });
  }

  setOnline() {
    this.userState.offlineMode = false;
  }

  setOffline() {
    this.userState.offlineMode = true;
  }

  getOfflineMode(){
    return this.userState.offlineMode;
  }

  async saveUserState(): Promise<any>{
    return this.persist.setObject('userState', this.userState);
  }

  async saveSubsonicState(): Promise<any>{
    return this.persist.setObject('subsonicState', this.subsonicState);
  }

  async saveImageCacheState(): Promise<any>{
    return this.persist.setObject('imageCacheState', this.imageCacheState);
  }

  async saveMusicCacheState(): Promise<any>{
    return this.persist.setObject('musicCacheState', this.musicCacheState);
  }

  async savePodcastCacheState(): Promise<any>{
    return this.persist.setObject('podcastCacheState', this.podcastCacheState);
  }

  async saveQueueState(): Promise<any>{
    return this.persist.setObject('queueState', this.queueState);
  }

  async saveBookmarkState(): Promise<any>{
    return this.persist.setObject('bookmarkState', this.bookmarkState);
  }

  async saveScrobbleQueueState(): Promise<any>{
    return this.persist.setObject('scrobbleQueueState', this.scrobbleQueueState);
  }

  async clearBookmarkState(): Promise<any>{
    await this.persist.clearBookmarkState();
    this.bookmarkState = {
      bookmarkList: []
    };
    await this.saveBookmarkState();
    return Promise.resolve();
  }

  async clearUserState(): Promise<any>{
    await this.persist.clearUser();
    this.userState = {
      servername:         '',
      username:           '',
      password:           '',
      legacyAuth:         false,
      verified:           false,
      serverVersion:      0,
      internalServername: '',
      externalServername: '',
      preferredServer:    'external',
      cacheAutoRate:      1,
      cacheMaxSize:       4000000000,
      streamMaxBitrate:   0,
      streamFormat:       'mp3',
      cacheFormat:        'mp3',
      suspendTimer:       300,
      offlineMode:        false,
      modeFoldersOnly:    false,
      modeTagsOnly:       false,
      cacheLocation:      'internal',
      extStorageLocation: '',
      podcastsEnabled:    true,
      podcastSortOrder:   'desc',
      podcastPlayCounts:  {},
      recommendedEnabled: true,
      improvedSearch:     false,
      randomListLength:   50,
      autonameBookmarks:  false,
      enableFanartTV:     false,
      doNotShowPermsNotice: false,
      enableReactiveColors: true
    };
    await this.saveUserState();
    return Promise.resolve();
  }

  async clearSubsonicState(): Promise<any>{
    await this.persist.clearSubsonic();
    this.subsonicState = {
      itemCount:            {count: 0, updated: ''},
      newItemsAvailable:    false,
      pingOk:               false,
      nowPlaying:           {},
      license:              {},
      scanStatus:           false,
      starred:              {},
      starred2:             {},
      musicFolders:         {},
      indexes:              {},
      indexesFlat:          {},
      musicDirectories:     {},
      genres:               {},
      artistsList:          {},
      artistsListFlat:      {},
      artists:              {},
      artistInfo:           {},
      artistInfo2:          {},
      artistTopSongs:       {},
      albumsList:           {},
      albumsListFlat:       {},
      albums:               {},
      albumInfo:            {},
      albumInfo2:           {},
      albumListNewest:      {},
      albumListRandom:      {},
      albumListFrequent:    {},
      albumListRecent:      {},
      albumListNewest2:     {},
      albumListRandom2:     {},
      albumListFrequent2:   {},
      albumListRecent2:     {},
      songs:                {},
      videosList:           {},
      videos:               {},
      playlistsList:        {},
      playlists:            {},
      podcastList:          {},
      podcasts:             {},
      podcastNewestList:    {}
    };
    return Promise.resolve();
  }

  async clearImageCacheState(): Promise<any>{
    await this.persist.clearImageCache();
    this.imageCacheState = {};
    this.saveImageCacheState();
    this.updateCacheUsedPercentage();
    return Promise.resolve();
  }

  async clearMusicCacheState(): Promise<any>{
    await this.persist.clearMusicCache();
    this.musicCacheState = {
      songs: [],
      albums: [],
      folders: [],
      playlists: [],
      starredSongs: [],
      allFiles: {},
      cachedSongUse: {},
      usedSpace: 0
    };
    this.saveMusicCacheState();
    this.updateCacheUsedPercentage();
    return Promise.resolve();
  }

  async clearPodcastCacheState(): Promise<any>{
    await this.persist.clearPodcastCache();
    this.podcastCacheState = {
      episodes: [],
      podcasts: [],
      allFiles: {},
      cachedEpisodeUse: {},
      usedSpace: 0
    };
    this.savePodcastCacheState();
    this.updateCacheUsedPercentage();
    return Promise.resolve();
  }

  async clearQueueState(): Promise<any>{
    await this.persist.clearQueueState();
    this.queueState = {
      savedPlayerState: {},
      savedQueueAvl:    false
    };
    return Promise.resolve();
  }

  async clearScrobbleQueueState(): Promise<any>{
    await this.persist.clearScrobbleQueueState();
    this.scrobbleQueueState = {
      scrobbleQueue: []
    };
    return Promise.resolve();
  }

  async clearStore(): Promise<any>{
    await this.clearImageCacheState();
    await this.clearMusicCacheState();
    await this.clearPodcastCacheState();
    await this.clearUserState();
    await this.clearSubsonicState();
    await this.clearQueueState();
    await this.clearScrobbleQueueState();
    await this.clearBookmarkState();
    this.updateCacheUsedPercentage();
    return Promise.resolve();
  }

  async clearDataCache(): Promise<any>{
    await this.clearPodcastCacheState();
    await this.clearQueueState();
    await this.clearSubsonicState();
  }

  //
  // Used Space Percentage Calc
  //

  // update the used space percentage calculation
  updateCacheUsedPercentage(){
    this.appState.cacheUsedPercent = Math.floor(
      (this.musicCacheState.usedSpace + this.podcastCacheState.usedSpace) / this.userState.cacheMaxSize * 100
    );
  }

  async runMigrations(): Promise<any>{
    // add podcasts to subsonic state
    if (this.subsonicState.podcasts === undefined){
      this.subsonicState.podcasts = {};
      this.saveSubsonicState();
    };

    // add skip fwd/bck intervals for podcasts
    if (this.userState.skipFwdInt === undefined){
      this.userState.skipFwdInt = 30;
      this.userState.skipBckInt = 10;
      this.saveUserState();
    };

    // add bookmarks
    if (this.bookmarkState === undefined){
      this.bookmarkState = {
        bookmarkList: []
      };
      this.saveBookmarkState();
    };

    // add theme preferences
    if (this.userState.useSystemTheme === undefined || this.userState.preferredTheme === undefined) {
      this.userState.useSystemTheme = true;
      this.userState.preferredTheme = 'light';
      this.saveUserState();
    };

    // remove legacy substreamer data if exists
    // only run once
    if (!this.userState.substreamMigrated) {
      await this.persist.substreamerMigration();
      this.userState.substreamMigrated = true;
      await this.saveUserState();
    };

    // clear bad artist images from cache
    // caused by lastfm changes to artist image
    // this.subsonicState.artistInfo2 = {};
    // this.saveSubsonicState();

    // add legacyAuth value
    if (this.userState.legacyAuth === undefined){
      this.userState.legacyAuth = false;
      this.saveUserState();
    };

    // add serverVersion
    if (this.userState.serverVersion === undefined){
      this.userState.serverVersion = 0;
      this.saveUserState();
    };

    // add starredSongs array to musicCacheState
    if (this.musicCacheState.starredSongs === undefined){
      this.musicCacheState.starredSongs = [];
      this.saveMusicCacheState();
    };

    // add recommendations Enabled
    if (this.userState.recommendedEnabled === undefined){
      this.userState.recommendedEnabled = true;
      this.saveUserState();
    };

    // add Ampache Compatible Search Option
    if (this.userState.improvedSearch === undefined){
      this.userState.improvedSearch = false;
      this.saveUserState();
    };

    // add randomListLength Setting
    if (this.userState.randomListLength === undefined){
      this.userState.randomListLength = 50;
      this.saveUserState();
    };

    // add cacheFormat Setting
    if (this.userState.cacheFormat === undefined){
      this.userState.cacheFormat = 'mp3';
      this.saveUserState();
    };

    // add suspendTimer Setting
    if (this.userState.suspendTimer === undefined){
      this.userState.suspendTimer = 300;
      this.saveUserState();
    };

    // add autoname bookmarks option
    if (this.userState.autonameBookmarks === undefined){
      this.userState.autonameBookmarks = false;
      this.saveUserState();
    };

    // make fanartTV local integration optional
    if (this.userState.enableFanartTV === undefined){
      this.userState.enableFanartTV = false;
      this.saveUserState();
    };

    // add android perms notice option
    if (this.userState.doNotShowPermsNotice === undefined){
      this.userState.doNotShowPermsNotice = false;
      this.saveUserState();
    };

    // add reactive colors setting
    if (this.userState.enableReactiveColors === undefined){
      this.userState.enableReactiveColors = true;
      this.saveUserState();
    };

    // add scrobble queue
    if (this.scrobbleQueueState === undefined){
      this.scrobbleQueueState = {
        scrobbleQueue: []
      };
      this.saveScrobbleQueueState();
    };

    return Promise.resolve();
  }

}
