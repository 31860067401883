import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortByDate'
})
export class SortByDatePipe implements PipeTransform {

  /**
   * Takes an array and orders the items by the date stored in the given key
   * array: the array to be sorted
   * order: either 'asc' or 'desc'
   * key: key inside each item that stores a date to sort by
   */
  transform(array: Array<any>, order: string, key: string) {
    if (typeof order === undefined || typeof key === undefined){
      return array;
    }
    if (array.length === 0){
      return array;
    }
    const direction = order;

    const sortedArray = array.sort((a: any, b: any) => {
      const left = Number(new Date(a[key]));
      const right = Number(new Date(b[key]));
      return(direction === 'desc') ? right - left : left - right;
    });

    return sortedArray.filter((item) =>
      item !== undefined
    );
  }

}
