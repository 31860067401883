import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { StoreService } from '../services/store.service';
import { UtilService } from './util.service';
import { EventsService } from './events.service';
import { Media, MediaObject } from '@awesome-cordova-plugins/media/ngx';
declare const chrome: any;

@Injectable({
  providedIn: 'root'
})
export class CcMediaplayerService {

  // COMON MEDIA CONTROL PROPERTIES

  // IMPLEMENTATION SPECIFIC PROPERTIES
  private ccSession;
  private ccMedia;
  private ccMediaState = 'IDLE';

  private keepAliveMedia: MediaObject;

  constructor(private platform: Platform,
              private events: EventsService,
              private media: Media,
              private util: UtilService,
              private store: StoreService) {
    this.init();
   }

  // COMMON MEDIA CONTROL FUNCTIONS

  async init() {
    console.log('ccMedia: init');
    const ready = await this.platform.ready();
    if(this.platform.is('cordova')){
      this.ccInit();
    }
  }

  getMediaObject() {
    return this.ccMedia;
  }

  async setSource(sourceUrl: string, trackItem: any): Promise<any> {
    console.log('ccMedia: setSource');
    return this.ccLoadMedia(sourceUrl, trackItem);
  }

  play() {
    console.log('ccMedia: play');
    this.playKeepAliveMedia();
    this.ccMedia.play();
  }

  pause() {
    console.log('ccMedia: pause');
    this.pauseKeepAliveMedia();
    this.ccMedia.pause();
  }

  async stop() {
    console.log('ccMedia: stop');
    this.destroyKeepAliveMedia();
    return this.ccLeaveSession();
  }

  seekTo(timeInSeconds: number) {
    console.log('ccMedia: seekTo');
    if(timeInSeconds < 1) {
      timeInSeconds = 1;
    }
    const request = new chrome.cast.media.SeekRequest();
    request.currentTime = timeInSeconds;
    this.ccMedia.seek(request);
  }

  async getDuration() {
    console.log('ccMedia: getDuration');
    if (!this.ccMedia){
      return Promise.resolve(0);
    }
    return Promise.resolve(Math.floor(this.ccMedia.media.duration));
  }

  async getCurrentPosition() {
    console.log('ccMedia: getCurrentPosition');
    if (!this.ccMedia) {
      return Promise.resolve(0);
    }
    return Promise.resolve(Math.floor(this.ccMedia.getEstimatedTime()));
  }

  setRate() {
    console.log('ccMedia: setRate');

  }

  getRate() {
    console.log('ccMedia: getRate');

  }

  setVolume() {
    console.log('ccMedia: setVolume');

  }

  getVolume() {
    console.log('ccMedia: getVolume');

  }

  destroy() {
    console.log('ccMedia: destroy');
  }

  // IMPLEMENTATION SPECIFIC HELPERS

  // Initializes the chromecast API
  // Returns status ok if the API is available
  // Returns status error if the API is not available
  ccInit(): Promise<any>{
    return new Promise((resolve, reject) => {
      const defaultAppId = chrome.cast.media.DEFAULT_MEDIA_RECEIVER_APP_ID;
      const substreamerAppId = '78A5BA3F';

      const apiConfig = new chrome.cast.ApiConfig(new chrome.cast.SessionRequest(substreamerAppId),
        (session) => {
          console.log(session);
        },
        (receiverAvailable) => {
          console.log(receiverAvailable);
        }
      );

      chrome.cast.initialize(apiConfig,
        () => {
          console.log('init success');
          resolve({status: 'ok', data: {}});
        },
        (err) => {
          console.log('init error');
          console.log(err);
          reject({status: 'error', data: err});
        }
      );
    });
  }

  // Displays the native chromecast output picker
  // Returns status ok if a valid receive is chosen
  // Returns status error if cancelled by the user or connect fails
  ccReqSession(): Promise<any> {
    return new Promise((resolve, reject) => {
      const reqSuccess = async (session) => {
        console.log('reqSession success');
        console.log(session);
        this.createKeepAliveMedia();
        this.ccSession = session;
        this.ccSession.addUpdateListener((listener) => this.ccSessionUpdateListener());
        resolve({status: 'ok', data: {}});
      };

      const reqError = async (err) => {
        console.log('reqSession error');
        console.log(err);
        reject({status: 'error', data: err});
      };

      chrome.cast.requestSession(reqSuccess, reqError);
    });
  }

  // Disconnect from chromecast device and stops playback
  // Returns status ok if successful
  // Returns status error if fails
  ccLeaveSession(): Promise<any> {
    return new Promise((resolve, reject) => {
      if(true){
        resolve({status: 'ok', data: {}});
      }
      this.ccSession.stop(
        () => {
          resolve({status: 'ok', data: {}});
        },
        (err) => {
          reject({status: 'error', data: err});
        });
    });
  }

  // Load media item into the chromecast
  // Returns status ok if media successfully loaded
  // Returns status error if the media cannot be loaded
  ccLoadMedia(sourceUrl, trackItem): Promise<any> {
    return new Promise((resolve, reject) => {
      const mediaInfo = this.ccGenerateMediaInfo(sourceUrl, trackItem);
      const loadReq = new chrome.cast.media.LoadRequest(mediaInfo);
      loadReq.autoplay = false;

      this.ccSession.loadMedia(loadReq,
      async (media) => {
        console.log('loadMedia success');
        console.log(media);
        this.ccMedia = media;
        this.ccMedia.addUpdateListener((listener) => this.ccUpdateListener());
        resolve({status: 'ok', data: {}});
      },
      (err) => {
        console.log('loadMedia error');
        console.log(err);
        reject({status: 'error', data: err});
      }
      );
    });
  }

  // Generates a mediaInfo object with metadata & media source details
  // Used to load media items into chromecast
  ccGenerateMediaInfo(sourceUrl, trackItem) {
    let contentType = '';
    //check if source URL contains format=mp3 or if using native contentType
    if (this.store.userState.streamFormat === 'raw'){
      contentType = trackItem.contentType;
    } else {
      contentType = 'audio/mp3';
    }
    const mediaInfo = new chrome.cast.media.MediaInfo(sourceUrl, contentType);
    const albumArt = new chrome.cast.Image(this.store.appState.coverArtBase500+trackItem.coverArt);

    mediaInfo.metadata = new chrome.cast.media.MusicTrackMediaMetadata();
    mediaInfo.metadata.albumName = trackItem.album;
    mediaInfo.metadata.albumArtist = trackItem.artist;
    mediaInfo.metadata.artist = trackItem.artist;
    mediaInfo.metadata.title = trackItem.title;
    //mediaInfo.metadata.trackNumber = trackItem.track;
    mediaInfo.metadata.images = [albumArt];
    return mediaInfo;
  }

  // take native events from chromecast and rebroadcast
  // keeps behaviour similar between html and native
  broadcastNativeMediaEvents(event){
    console.log(event);
    if (event.type === 'complete'){
      console.log('complete: playback complete');
      this.events.publish('ended', {});
    }
    if (event.type === 'error'){
      console.log('error: media error');
      console.log(event.value);
      this.events.publish('error', event.value);
    }
    if (event.type === 'disconnected'){
      console.log('error: chromecast disconnected');
      console.log(event.value);
      this.events.publish('disconnected', {});
    }
    if (event.type === 'status'){
      if (event.value === 1){
        console.log('status: 1 unknown');
        this.events.publish('unknown', {});
      }
      if (event.value === 2){
        console.log('status: 2 playing');
        this.events.publish('playing', {});
      }
      if (event.value === 3){
        console.log('status: 3 paused');
        this.events.publish('pause', {});
      }
      if (event.value === 4){
        console.log('status: 4 stopped');
        this.events.publish('stopped', {});
      }
    }
  }

  isConnected() {
    if(this.ccSession === undefined) {
      return false;
    }
    if(this.ccSession.status === 'stopped' || this.ccSession.status === 'disconnected'){
      return false;
    }
    return true;
  }

  // Listen for media Status Updates
  ccUpdateListener() {
    // Do something if the media state has changed
    if (this.ccMedia.playerState !== this.ccMediaState){
      this.ccMediaState = this.ccMedia.playerState;
      console.log(this.ccMediaState);
      if (this.ccMediaState === 'PLAYING'){
        this.broadcastNativeMediaEvents({type: 'status', value: 2});
      }
      if (this.ccMediaState === 'PAUSED'){
        this.broadcastNativeMediaEvents({type: 'status', value: 3});
      }
      if (this.ccMediaState === 'IDLE'){
        this.broadcastNativeMediaEvents({type: 'status', value: 4});
      }
    }
    // Do something if the if playback finished
    if (this.ccMedia.idleReason === 'FINISHED' && (this.ccMediaState !== this.ccMedia.idleReason)){
      this.ccMediaState = 'FINISHED';
      this.broadcastNativeMediaEvents({type: 'complete', value: 'playbackComplete'});
    }
    console.log(this.ccMediaState);
  }

  // Listen for session status updates
  ccSessionUpdateListener(){
    console.log('Session Update');
    console.log(this.ccSession);
    console.log(this.ccMedia);
    if(this.store.appState.isANDROID) {
      if((this.ccSession.status === 'stopped' || this.ccSession.status === 'disconnected') && this.ccSession.receiver === null){
        console.log('DISCONNECTED FROM CC');
        this.broadcastNativeMediaEvents({type: 'disconnected', value: 'cc disconnected'});
      }
    }
    if(this.store.appState.isIOS) {
      if(this.ccSession.status === 'stopped' || this.ccSession.status === 'disconnected'){
        console.log('DISCONNECTED FROM CC');
        this.broadcastNativeMediaEvents({type: 'disconnected', value: 'cc disconnected'});
      }
    }
  }

  // Chromecast times out in the background if no local media playing
  // Music Controls also remain bound to the local player and don't update correctly
  // without some local media.
  // FOR IOS ONLY
  // Volume set to 0 and all events ignored.
  createKeepAliveMedia() {
    this.keepAliveMedia = this.media.create('assets/sounds/appbeep.wav');
    this.keepAliveMedia.setVolume(0.0);
  }

  playKeepAliveMedia() {
    this.keepAliveMedia.play({
      numberOfLoops: -1,
      playAudioWhenScreenIsLocked: true
    });
  }

  pauseKeepAliveMedia() {
    this.keepAliveMedia.pause();
  }

  destroyKeepAliveMedia() {
    this.keepAliveMedia.release();
  }

}

