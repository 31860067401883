import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FanarttvService {

  private apiKey = '0612a41cfb983080578c24a236a3aaa8';
  private apiBaseUrl = 'https://webservice.fanart.tv/v3/music/';
  private apiFormat = 'json';

  constructor(private http: HttpClient) { }

  async getArtistImage(artistMbrainzID: string): Promise<any>{
    let response;

    if (!artistMbrainzID){
      response = {};
      return response;
    }

    const url = encodeURI(this.apiBaseUrl + artistMbrainzID + '?api_key=' + this.apiKey + '&format=' + this.apiFormat);

    try {
      response = await this.http.get(url).toPromise();
    }
    catch (e) {
      console.log(e);
      response = {};
    }
    finally {
      return Promise.resolve(response);
    }

  }
}
