//
// Pass in a class name that exists on the elements where swipes should be monitored
// you can assign an empty class like class="swipeable" for instance
// Swipe will only be marked as enabled if either the start or end element
// has this class
// attach (touchmove), (touchstart), (touchend) event listeners to your main content
//

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TouchHandlerService {

  private touchStartClientX = 0;
  private touchStartClientY = 0;
  private touchEndClientX = 0;
  private touchEndClientY = 0;
  private touchStartSwipeable = false;
  private touchEndSwipeable = false;

  constructor() { }

  // stops touchMove events propagating
  touchMoveStopPropagation(event: any){
    event.stopPropagation();
  }

  // set touch start values
  touchStart(event: any, swipeableClass: string): void {
    this.touchStartClientX = event.changedTouches[0].clientX;
    this.touchStartClientY = event.changedTouches[0].clientY;
    if(event.target.className.indexOf(swipeableClass) !== -1){
      this.touchStartSwipeable = true;
    }
  }

  // set touch end values
  touchEnd(event: any, swipeableClass: string): any {
    let swipeDirection = '';
    let swipeEnabled = false;
    const returnItem = {swipeDirection: '', swipeEnabled: false};
    this.touchEndClientX = event.changedTouches[0].clientX;
    this.touchEndClientY = event.changedTouches[0].clientY;

    if(event.target.className.indexOf(swipeableClass) !== -1){
      this.touchEndSwipeable = true;
    }

    if(this.touchStartSwipeable || this.touchEndSwipeable){
      swipeEnabled = true;
    }

    const xDiff = Math.abs(this.touchStartClientX - this.touchEndClientX);
    const yDiff = Math.abs(this.touchStartClientY - this.touchEndClientY);

    if (xDiff > yDiff) {
      if(this.touchStartClientX - this.touchEndClientX > 0){
        swipeDirection = 'left';
      }
      else {
        swipeDirection = 'right';
      }
    }

    if (yDiff > xDiff) {
      if(this.touchStartClientY - this.touchEndClientY > 0){
        swipeDirection = 'up';
      }
      else {
        swipeDirection = 'down';
      }
    }
    this.reset();
    returnItem.swipeDirection = swipeDirection;
    returnItem.swipeEnabled = swipeEnabled;
    return returnItem;
  }

  // reset all values
  reset() {
    this.touchEndClientX = 0;
    this.touchEndClientY = 0;
    this.touchStartClientX = 0;
    this.touchStartClientY = 0;
    this.touchEndSwipeable = false;
    this.touchStartSwipeable = false;
  }
}
