import { PodcastCacheService } from 'src/app/services/podcast-cache.service';
import { EventsService } from './../../services/events.service';
import { ImageCacheService } from './../../services/image-cache.service';
import { SubsonicactionsService } from './../../services/subsonicactions.service';
import { PlayeractionsService } from './../../services/playeractions.service';
import { MusicCacheService } from './../../services/music-cache.service';
import { UtilService } from './../../services/util.service';
import { StoreService } from './../../services/store.service';
import { Component, Input, OnInit, OnDestroy, AfterViewInit } from '@angular/core';

type Context = 'album' | 'folder' | 'playlist' | 'starred' | 'podcast';

@Component({
  selector: 'app-detail-action-bar',
  templateUrl: './detail-action-bar.component.html',
  styleUrls: ['./detail-action-bar.component.scss'],
})
export class DetailActionBarComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() selectedItem: any;
  @Input() context: Context;
  @Input() searchActive?: boolean;
  public cacheDisable = false;
  public cacheStatus: boolean;
  public iconStatusName = 'cloud-download';
  public ready = false;
  private _cacheFailureHandler: any;

  constructor(public store: StoreService,
              private util: UtilService,
              private events: EventsService,
              private musicCache: MusicCacheService,
              private podcastCache: PodcastCacheService,
              private subsonicActions: SubsonicactionsService,
              private imageCache: ImageCacheService,
              private playerActions: PlayeractionsService)
              {
                this._cacheFailureHandler = (item) => {
                  this.cacheFailureHandler(item);
                };
              }

  ngOnInit() {}

  ngAfterViewInit() {
    this.registerCacheEventListeners();
    this.init();
  }

  ngOnDestroy() {this.deregisterCacheEventListeners();}

  async init() {
    if (this.context === 'album'){
      this.cacheStatus = this.musicCache.checkCachedAlbum(this.selectedItem.id);
      this.cacheDisable = this.musicCache.isDownloading(this.selectedItem.id);
    }
    if (this.context === 'folder'){
      this.cacheStatus = this.musicCache.checkCachedFolder(this.selectedItem.id);
      this.cacheDisable = this.musicCache.isDownloading(this.selectedItem.id);
    }
    if (this.context === 'playlist'){
      this.cacheStatus = this.musicCache.checkCachedPlaylist(this.selectedItem.id);
      this.cacheDisable = this.musicCache.isDownloading(this.selectedItem.id);
    }
    if (this.context === 'starred'){
      this.cacheStatus = this.musicCache.checkCachedStarred(this.selectedItem.id);
      this.cacheDisable = this.musicCache.isDownloading(this.selectedItem.id);
    }
    if (this.context === 'podcast'){
      this.cacheStatus = this.podcastCache.checkCachedPodcast(this.selectedItem.id);
      this.cacheDisable = this.podcastCache.isDownloading(this.selectedItem.id);
    }

    // if we are downloading then set cached as true while disabled
    if (this.cacheDisable){
      this.cacheStatus = true;
    }
  }

  registerCacheEventListeners() {
    // all except podcasts
    if (this.context !== 'podcast'){
      // subscribe for cache complete events
      this.events.subscribe('cacheComplete', (itemID) => {
        console.log(this.context + ': received cache complete event');
        if (this.selectedItem.id === itemID){
          this.cacheStatus = true;
          this.cacheDisable = false;
        }
      });
      // subscribe for cache cancel events
      this.events.subscribe('cacheCancel', (itemID) => {
        console.log(this.context + ': received cache cancel event');
        if (this.selectedItem.id === itemID){
          this.cacheStatus = false;
          this.cacheDisable = false;
        }
      });
      // subscribe for cache failure events, wrap it so we can
      // cancel just this handler
      this.events.subscribe('cacheFailure', this._cacheFailureHandler);
    }

    // podcasts
    if (this.context === 'podcast') {
      this.events.subscribe('podcastcacheComplete', (itemID) => {
        console.log('podcastDetail: received cache complete event');
        if (this.selectedItem.id === itemID){
          this.cacheDisable = false;
        }
      });
      // subscribe for cache cancel events
      this.events.subscribe('podcastcacheCancel', (itemID) => {
        console.log('podcastDetail: received cache cancel event');
        if (this.selectedItem.id === itemID){
          this.cacheDisable = false;
          this.cacheStatus = false;
        }
      });
      // subscribe for cache failure events, wrap it so we can
      // cancel just this handler
      this.events.subscribe('podcastcacheFailure', this._cacheFailureHandler);
    }
  }

  deregisterCacheEventListeners() {
    if (this.context !== 'podcast') {
      // unsubscribe from cache complete events
      this.events.unsubscribe('cacheComplete');
      // unsubscribe from cache cancel events
      this.events.unsubscribe('cacheCancel');
      // unsubscribe from cache failure events
      this.events.unsubscribe('cacheFailure');
    }

    if (this.context === 'podcast') {
      // unsubscribe from cache complete events
      this.events.unsubscribe('podcastcacheComplete');
      // unsubscribe from cache cancel events
      this.events.unsubscribe('podcastcacheCanel');
      // unsubscribe from cache failure events
      this.events.unsubscribe('podcastcacheFailure');
    }
  }

  // must be wrapped in _cacheFailureHandler arrow function
  cacheFailureHandler(item){
    console.log(this.context + ': received cache failure event');
    if (this.selectedItem.id === item.id){
      this.cacheStatus = false;
      this.cacheDisable = false;
    }
  }

  async cacheToggled(event: any){
    console.log('cache toggled');
    console.log(event);
    let _item;
    if (this.context === 'album'){
      _item = this.util.copy(this.store.subsonicState.albums[this.selectedItem.id]);
    }
    if (this.context === 'folder'){
      _item = this.util.copy(this.store.subsonicState.musicDirectories[this.selectedItem.id]);
    }
    if (this.context === 'playlist'){
      _item = this.util.copy(this.store.subsonicState.playlists[this.selectedItem.id]);
    }
    if (this.context === 'starred'){
      _item = {song: this.util.copy(this.store.subsonicState.starred2.song),
        id: 'starred',
        name: 'Starred Songs'
        };
    }
    if (this.context === 'podcast'){
      _item = this.util.copy(this.selectedItem);
    }

    // if cacheStatus was just toggled to false
    if (!this.cacheStatus){
      this.cacheDisable = true;
      if (this.context !== 'podcast'){
        await this.musicCache.delCachedItem(_item.id, this.context);
      }
      if (this.context === 'podcast'){
        await this.podcastCache.delCachedItem(_item.id, this.context);
      }
      this.cacheStatus = false;
      this.cacheDisable = false;
    }
    // if cacheStatus was just toggled to true
    if (this.cacheStatus){
      if (this.context === 'album'){
        if (this.musicCache.checkCachedAlbum(this.selectedItem.id)) {
          return;
        }
      }
      if (this.context === 'folder'){
        if (this.musicCache.checkCachedFolder(this.selectedItem.id)) {
          return;
        }
      }
      if (this.context === 'playlist'){
        if (this.musicCache.checkCachedPlaylist(this.selectedItem.id)) {
          return;
        }
      }
      if (this.context === 'starred'){
        if (this.musicCache.checkCachedStarred(this.selectedItem.id)) {
          return;
        }
      }
      if (this.context === 'podcast'){
        if (this.podcastCache.checkCachedPodcast(this.selectedItem.id)) {
          return;
        }
      }

      this.cacheDisable = true;
      this.musicCache.reqCacheItem(_item, this.context);
    }
  }

  // play the track list starting from the track that was tapped
  async play(event: Event){
    event.stopPropagation();
    let list = [];
    if (this.context === 'album'){
      const _album = await this.subsonicActions.getAlbum(this.selectedItem.id);
      list = _album.song;
    }
    if (this.context === 'folder'){
      const _album = await this.subsonicActions.getFolder(this.selectedItem.id);
      list = _album.child;
    }
    if (this.context === 'playlist'){
      const _playlist = await this.subsonicActions.getPlaylist(this.selectedItem.id);
      list = _playlist.entry;
    }
    if (this.context === 'starred'){
      let _starredList = {song:[]};
      _starredList = await this.subsonicActions.getStarred();
      list = _starredList.song;
    }

    this.playerActions.setPlayQueue(list, 0);
  }

  // shuffle the track list and then start playing
  async shufflePlay(event: Event): Promise<any> {
    event.stopPropagation();
    let _shuffledList;
    if (this.context === 'album'){
      const _album = await this.subsonicActions.getAlbum(this.selectedItem.id);
      _shuffledList = await this.util.shuffle(_album.song);
    }
    if (this.context === 'folder'){
      const _album = await this.subsonicActions.getFolder(this.selectedItem.id);
      _shuffledList = await this.util.shuffle(_album.child);
    }
    if (this.context === 'playlist'){
      const _playlist = await this.subsonicActions.getPlaylist(this.selectedItem.id);
      _shuffledList = await this.util.shuffle(_playlist.entry);
    }
    if (this.context === 'starred'){
      const _album = this.util.copy(this.store.subsonicState.starred2.song);
      _shuffledList = await this.util.shuffle(_album);
    }

    this.playerActions.setPlayQueue(_shuffledList, 0);
  }

}
