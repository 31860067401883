import { StoreService } from 'src/app/services/store.service';
import { Platform } from '@ionic/angular';
import { Injectable, NgZone } from '@angular/core';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { BackgroundMode } from '@awesome-cordova-plugins/background-mode/ngx';

declare let cordova: any;

@Injectable({
  providedIn: 'root'
})
export class AndroidPermissionsService {

  constructor(private ap: AndroidPermissions,
              private platform: Platform,
              private store: StoreService,
              private background: BackgroundMode,
              private zone: NgZone,
              private device: Device) { }

  async init() {
    if(this.shouldAbortNonAndroid()) { return;};

    this.store.appState.androidVersion = this.getVersion();
    await this.requestAllNonSensitivePermission();
    await this.checkBatteryPermission();
    await this.checkNotificationPermission();
    await this.checkStoragePermission();
  }

  // Abort the function on non-android platforms
  shouldAbortNonAndroid() {
    this.platform.platforms().indexOf('android');
    if(!this.platform.is('android')) {
      if(!this.platform.is('cordova')) {
        console.log('not supported on non-android platform');
        return true;
      }
    }
  }

  // Get the android version, taking only first 2 digits
  getVersion() {
    if(this.shouldAbortNonAndroid()) { return;};

    const version = parseFloat(this.device.version.slice(0,2));
    return version;
  }

  async checkSensitivePermissions(){
    if(this.shouldAbortNonAndroid()) { return;};

    await this.checkBatteryPermission();
    await this.checkNotificationPermission();
    await this.checkStoragePermission();
  }

  // Check storage access permission
  async checkStoragePermission(){
    if(this.shouldAbortNonAndroid()) { return;};

    console.log('android permissions: check storage permission');
    const isEnabled = await this.ap.checkPermission('android.permission.WRITE_EXTERNAL_STORAGE');
    console.log('isStorageEnabled: '+ isEnabled.hasPermission);
    this.store.appState.isStorageEnabled = isEnabled.hasPermission;
    this.store.appState.shouldDisableSt = isEnabled.hasPermission;
  }

  // Check notification permission
  async checkNotificationPermission(){
    if(this.shouldAbortNonAndroid()) { return;};

    console.log('android permissions: check notification permission');
    let isEnabled;

    isEnabled = await this.ap.checkPermission('android.permission.ACCESS_NOTIFICATION_POLICY');

    if(this.getVersion() >= 13.0){
      console.log('Android version greater or equal 13, actual: ' + this.getVersion());
      isEnabled = await this.ap.checkPermission('android.permission.POST_NOTIFICATIONS');
    }
    console.log('isNotificationEnabled: '+ isEnabled.hasPermission);
    this.store.appState.isNotificationEnabled = isEnabled.hasPermission;
    this.store.appState.shouldDisableNt = isEnabled.hasPermission;
  }

  // Check battery optimization ignore permission
  async checkBatteryPermission(){
    if(this.shouldAbortNonAndroid()) { return;};

    console.log('android permissions: check battery permission');

    const isEnabled = await this._promiseBatteryPermissionCheck();
    this.zone.run(() => {
      console.log('isIgnoringBattery: '+ isEnabled);
      this.store.appState.isBackgroundModeEnabled = isEnabled;
      this.store.appState.shouldDisableBg = isEnabled;
    });
  }

  // Return result of isIgnoringBatteryOptimizations check as a promise for convenience
  async _promiseBatteryPermissionCheck(): Promise<boolean> {
    return new Promise((resolve) => {
      cordova.plugins.backgroundMode.isIgnoringBatteryOptimizations((status) => {
        resolve(status);
      });
    });
  }

  // Request storage access
  // Requires User Prompt on Android < 13
  // Not required for Android > 13
  async requestStoragePermission(){
    if(this.shouldAbortNonAndroid()) { return;};

    console.log('android permissions: request storage permission');
    const isEnabled = await this.ap.requestPermission('android.permission.WRITE_EXTERNAL_STORAGE');
    this.store.appState.isStorageEnabled = isEnabled.hasPermission;
    this.store.appState.shouldDisableSt = isEnabled.hasPermission;
  }

  // Request Notification Permission
  // Requires user prompt
  // Different permission name in Android > 12
  async requestNotificationPermission() {
    if(this.shouldAbortNonAndroid()) { return;};

    console.log('android permissions: request notification permission');
    let isEnabled;

    isEnabled = await this.ap.requestPermission('android.permission.ACCESS_NOTIFICATION_POLICY');

    if(this.getVersion() >= 13.0){
      console.log('Android version greater or equal 13, actual: ' + this.getVersion());
      isEnabled = await this.ap.requestPermission('android.permission.POST_NOTIFICATIONS');
    }

    this.store.appState.isNotificationEnabled = isEnabled.hasPermission;
    this.store.appState.shouldDisableNt = isEnabled.hasPermission;
  }

  // Request battery optimization ignore permission
  // Requires user prompt
  async requestBatteryPermission(){
    if(this.shouldAbortNonAndroid()) { return;};

    console.log('android permissions: request battery permission');
    this.background.disableBatteryOptimizations();
    this.checkBatteryPermission();
  }

  // Check status of all non-sensitive permissions
  // These do not require any user prompt to grant
  async checkAllNonSensitivePermission() {
    if(this.shouldAbortNonAndroid()) { return;};

    console.log('android permissions: check non-sensitive permissions');
    await this.ap.checkPermission('android.permission.ACCESS_WIFI_STATE');
    await this.ap.checkPermission('android.permission.ACCESS_NETWORK_STATE');
    await this.ap.checkPermission('android.permission.INTERNET');
    await this.ap.checkPermission('android.permission.BLUETOOTH');
    await this.ap.checkPermission('android.permission.FOREGROUND_SERVICE');
    await this.ap.checkPermission('android.permission.WAKE_LOCK');
    await this.ap.checkPermission('android.permission.READ_EXTERNAL_STORAGE');
    await this.ap.checkPermission('android.permission.MODIFY_AUDIO_SETTINGS');
    await this.ap.checkPermission('android.permission.REQUEST_IGNORE_BATTERY_OPTIMIZATIONS');

    if(this.getVersion() >= 13.0){
      console.log('Android version greater or equal 13, actual: ' + this.getVersion());
      await this.ap.checkPermission('android.permission.FOREGROUND_SERVICE_MEDIA_PLAYBACK');
    }
  }

  // Request all non-sensitive permissions
  // These do not require any user prompt to grant
  async requestAllNonSensitivePermission() {
    if(this.shouldAbortNonAndroid()) { return;};

    console.log('android permissions: request non-sensitive permissions');
    await this.ap.requestPermission('android.permission.ACCESS_WIFI_STATE');
    await this.ap.requestPermission('android.permission.ACCESS_NETWORK_STATE');
    await this.ap.requestPermission('android.permission.INTERNET');
    await this.ap.requestPermission('android.permission.BLUETOOTH');
    await this.ap.requestPermission('android.permission.FOREGROUND_SERVICE');
    await this.ap.requestPermission('android.permission.WAKE_LOCK');
    // auto requests storage on start in Android < 10 breaking some devices.
    // await this.ap.requestPermission('android.permission.READ_EXTERNAL_STORAGE');
    await this.ap.requestPermission('android.permission.MODIFY_AUDIO_SETTINGS');
    await this.ap.requestPermission('android.permission.REQUEST_IGNORE_BATTERY_OPTIMIZATIONS');

    if(this.getVersion() >= 13.0){
      console.log('Android version greater or equal 13, actual: ' + this.getVersion());
      await this.ap.requestPermission('android.permission.FOREGROUND_SERVICE_MEDIA_PLAYBACK');
    }
  }
}
